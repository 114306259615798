<template>
	<div class="u-view">
		<img 
			src="/img/backWhite.png" 
			srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
			class="back-icon"
			@click="$router.go(-1)"
			v-if="!isMe"
		/>
		<img 
			src="/img/backWhite.png" 
			srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
			class="back-icon"
			@click="editProfile = false"
			v-else-if="editProfile"
		/>
		<img 
			v-else-if="!editProfile"
			src="/img/adminAppIconProfileCopy.svg" 
			class="home-icon" 
			@click="$router.go(-1)"
		>
		<ItemImages 
			:data="photos"
			:class="{'-short': openInfo}"
			user
		/>
		<div class="u-view-info" v-if="user && !editProfile" :class="{'-short': openInfo}">
			<div class="u-view-info__name">
				{{ user.full_name }}{{ userAge }}
			</div>
			<div class="u-view-info__zodiac" v-if="!user.is_star_sign_hidden && user.zodiac && userAge">
				<img
					:src="`/img/iconZodiac${user.zodiac}.svg`"
					:key="user.zodiac"
				/>
				<div class="l-view__list-item__info-zodiac__text">
					{{$t(`zodiacs.${user.zodiac}`)}}
				</div>
			</div>
		</div>
		<img 
			v-show="!openInfo"
			class="u-view-infoicon" 
			src="/img/iconStatus.png" 
			width="24px"
			srcset="/img/iconStatus@3x.png 3x /img/iconStatus@2x.png 2x /img/iconStatus.png 1x"
			@click="openCloseInfo"
		>
		<div 
			class="u-view-infoicon-back"
			style="background-image: url(/img/backWhite@3x.png);"
			v-show="openInfo && !editProfile"
			@click="openCloseInfo"
		></div>
		<div class="u-view__rating-tabs" v-if="openInfo && !editProfile">
			<div class="u-view__rating-tabs__tab">
				<div>{{ rating }}</div>
				<div>Rating</div>
			</div>
			<div class="u-view__rating-tabs__tab">
				<div>{{ comments_counter }}</div>
				<div>Posts</div>
			</div>
			<div class="u-view__rating-tabs__tab">
				<div>{{ top_comments_counter }}</div>
				<div>Top posts</div>
			</div>
			<div class="u-view__rating-tabs__tab">
				<div>{{ rank }}</div>
				<div>Rank</div>
			</div>
		</div>
		<div class="u-view__bio" v-if="!editProfile && openInfo && user">
			{{ user.bio }}
		</div>
		<div class="u-view__posts" v-if="!editProfile && openInfo && user">
			<router-link
				tag="div" 
				class="u-view__posts-post"
				v-for="(post, index) in posts"
				:key="post.uid"
				:style='`background-image: url("${post.photo_uri}");`'
				:to="`/userposts/${userUid}?index=${index+1}`"
			>
			</router-link>
		</div>
		<div class="u-view__edit" v-if="openInfo && isMe">
			<div 
				v-if="!editProfile"
				class="m-cir-icon-button u-view__edit-pencil" 
				style="background-image: url(/img/editPencil.svg);"
				@click="editProfile = true"
				key="pencil"
			>
			</div>
			<div 
				v-else
				class="m-cir-icon-button u-view__edit-save" 
				style="background-image: url(/img/combinedShapeCopy6.svg);"
				@click="saveUserData"
				key="combinedShapeCopy6"
			>
			</div>
			<div 
				v-if="!editProfile"
				class="m-cir-icon-button u-view__edit-gear" 
				style="background-image: url(/img/iconCheck.svg);"
				@click="$router.push('/settings')"
			>
			</div>
		</div>
		<div class="u-view__editphotos" v-if="editProfile">
			<div class="u-view__editphotos-add">
				<input id="photo" type="file" ref="photo" @change="selectPhoto">
				<label for="photo"></label>
			</div>
			<div class="u-view__editphotos-list">
				<div
					class="u-view__editphotos-list__item"
					v-for="(image, i) in images"
					:key="image.url"
				>
					<img class="u-photo" :src="image.url">
					<div class="u-photo-del" style="background-image: url(/img/del.svg);" @click="removeImage(i)"></div>
				</div>
			</div>
		</div>
		<div class="u-view__editdata" v-if="editProfile">
			<div class="editdata__name" v-if="user">
				<input class="" :placeholder="$t('userView.name')" v-model="user.full_name">
			</div>
			<div class="editdata__bdate" v-if="user && !(user.birth_date && user.birth_date.seconds)">
				<div class="editdata__bdate-label">{{ $t('userView.bdate') }}</div>
				<input type="date" v-model="userBdate" :min="birthDateRange.min" :max="birthDateRange.max">
			</div>
			<div class="editdata__gender" v-if="user">
				<div class="editdata__gender-items" :class="{'-hasActive': user.gender}">
					<div 
						class="editdata__gender-items__item"
						:class="{'-active': user.gender === 'male'}"
						@click="user.gender = 'male'"
					>{{ $t('userView.male') }}</div>
					<div 
						class="editdata__gender-items__item"
						:class="{'-active': user.gender === 'female'}"
						@click="user.gender = 'female'"
					>{{ $t('userView.female') }}</div>
				</div>
			</div>
			<div class="editdata__bio" v-if="user">
				<textarea 
					rows="3" 
					:placeholder="$t('userView.writeAbout')"
					v-model="user.bio"
				></textarea>
			</div>
			<div class="editdata__divider">
				{{ $t('userView.profileConfig') }}
			</div>
			<div class="editdata__editgroup">
				<div class="editdata__editgroup-item" v-if="user">
					<div class="editdata__editgroup-item__text">
						{{ $t('userView.hideAge') }}
					</div>
					<label class="form-switch">
						<input type="checkbox" v-model="user.is_birth_date_hidden">
						<i></i>
					</label>
				</div>
				<div class="editdata__editgroup-divider"></div>
				<div class="editdata__editgroup-item" v-if="user">
					<div class="editdata__editgroup-item__text">
						{{ $t('userView.hideZodiaq') }}
					</div>
					<label class="form-switch">
						<input type="checkbox" v-model="user.is_star_sign_hidden">
						<i></i>
					</label>
				</div>
			</div>
			<div class="editdata__divider">
				{{ $t('userView.show') }}
			</div>
			<div class="editdata__editgroup">
				<!-- <div class="editdata__editgroup-item -showsex" v-if="user">
					<div 
						class="editdata__editgroup-item__showsex"
						:class="{'-active': user.show_me === 'male'}"
						@click="user.show_me = 'male'"
					>MEN</div>
					<div 
						class="editdata__editgroup-item__showsex"
						:class="{'-active': user.show_me === 'female'}"
						@click="user.show_me = 'female'"
					>WOMEN</div>
					<div 
						class="editdata__editgroup-item__showsex"
						:class="{'-active': user.show_me === 'all'}"
						@click="user.show_me = 'all'"
					>ALL</div>
				</div>
				<div class="editdata__editgroup-divider"></div>
				<div class="editdata__editgroup-item">
					<div class="editdata__editgroup-item__text">
						Только тех кто внутри заведения
					</div>
					<label class="form-switch">
						<input type="checkbox">
						<i></i>
					</label>
				</div>
				<div class="editdata__editgroup-divider"></div>
				<div class="editdata__editgroup-item">
					<div class="editdata__editgroup-item__text">
						Скрыть возраст
					</div>
					<label class="form-switch">
						<input type="checkbox">
						<i></i>
					</label>
				</div>
				<div class="editdata__editgroup-divider"></div> -->
				<div class="editdata__editgroup-item"  v-if="user">
					<div class="editdata__editgroup-item__text">
						{{ $t('userView.hideProfile') }}
					</div>
					<label class="form-switch">
						<input type="checkbox">
						<i></i>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ItemImages from '@/components/ItemImages'
	import { getBirthDateRange, isBirthDateInRange } from '../utils/birth-date';
	import { getZodiac } from '../utils/get-zodiac';

	export default {
		name: 'UserView',
		components: {
			ItemImages
		},
		props: {
			userUid: {
				type: String,
				required: true
			}
		},
		data() {
			const openInfo = !!this.$route.query.openInfo
			return {
				user: null,
				openInfo,
				posts: [],
				comments_counter: 0,
				top_comments_counter: 0,
				rating: 0,
				editProfile: false,
				images: [],
				requiredFields: ['birth_date', 'full_name', 'gender', 'photos'],
				hasSaved: false
			}
		},
		computed: {
			photos() {
				const photos = this.user?.photos
				if (photos && photos.length) return photos
				return ['/img/userNoImage@3x.png']
				
			},
			userAge() {
				const user = this.user
				if (user.is_birth_date_hidden) return ''
				const seconds = (user.birth_date?.seconds || 0) * 1000 
				if (!seconds) return ''
				return `, ${this.getAge(seconds)}`
			},
			rank() {
				const inTopIndex = this.$root.topUserIndexes[this.userUid]
				return inTopIndex || '>500'
			},
			me() {
				return this.$store.state.user.user
			},
			isMe() {
				return this.me?.uid === this.user?.uid
			},
			birthDateRange: getBirthDateRange,
			userBdate: {
				get() {
					return this.user.birth_date || this.birthDateRange.max
				},
				set(value) {
					this.user.birth_date = value
				}
			}
		},
		watch: {
			userUid: {
				immediate: true,
				handler: 'getUser'
			},
			user(val) {
				if (val) {
					this.images = (val?.photos || []).map((image) => {
						return {
							url: image
						}
					})
				}
			}
		},
		methods: {
			saveUserData( ) {
				this.$store.dispatch('places/showLoader', true);

				const photos = this.images.map(img => img.url);
				const profile_image_url = photos.length > 0 ? photos[photos.length - 1] : '';
				const birth_date =
					this.userBdate
						? this.userBdate.seconds
							? new Date(this.userBdate.seconds * 1000).toISOString()
							: new Date(this.userBdate).toISOString()
						: this.userBdate;

				const user = {
					...JSON.parse(JSON.stringify(this.user)),
					profile_image_url, 
					photos,
					birth_date
				};

				const required = {
					filled: true,
					errorType: null,
				};

				this.requiredFields.forEach(field => {
					const value = user[field];

					if (!value || (field === 'photos' && value.length === 0)) {
						required.filled = false;
						required.errorType = 'valueMissing';
						return;
					}

					if (field === 'birth_date' && !isBirthDateInRange(user.birth_date)) {
						required.filled = false;
						required.errorType = 'outOfRange';
						return;
					}
				});

				if (required.filled) {
					this.$HTTP('post', this.$API_URL('/users/set'), {user})
						.then(() => {
							this.getUser(this.userUid)
								.then(() => {
									this.$store.dispatch('places/hideLoader');
									this.editProfile = false;
								});
						})
						.catch(() => {
							this.$store.dispatch('places/hideLoader');
						})

					return;
				}

				let errorMessage;

				switch (required.errorType) {
					case 'outOfRange':
						errorMessage = {
							title: this.$t('userView.birthDateOutOfRange.title'),
							subtitle: this.$t('userView.birthDateOutOfRange.caption')
						};
						break;
					default:
						errorMessage = {
							title: this.$t('userView.fillAll'),
							subtitle: this.$t('userView.fillAll2')
						};
						break;
				}

				this.$bus.$emit('openContextMenu', {
					config: {
						type: 'modal', 
						style: 'pink',
						icon: '/img/iconSettings.svg',
						...errorMessage
					},
					actions: [{
						id: 'ok',
						text: 'OK'
					}],
					resolve: () => ({}),
					reject: () => ({})
				})
				this.$store.dispatch('places/hideLoader');
			},
			async selectPhoto() {
				const [file] = this.$refs.photo.files;

				if (!file) {
					return;
				}

				this.$refs.photo.disabled = true;

				try {
					const formData = new FormData();
					
					formData.append('uid', this.$uuid());
					formData.append('image', file);
					const data = await this.$HTTP('post', this.$API_PHOTO_URL('/user'), formData)

					if (data?.payload?.photo_uri) {
						this.images.unshift({ url: data.payload.photo_uri });
					} else {

						this.errorHandler()
						throw new Error('Can\'t get "photo_uri"');
					}
				} catch (error) {
					this.errorHandler()
					console.error(error);
				} finally {
					this.$refs.photo.value = '';
					this.$refs.photo.disabled = false;
				}
			},
			errorHandler() {
				this.$bus.$emit('openContextMenu', {
					config: {
						type: 'modal', 
						style: 'pink',
						icon: '/img/warningIcon.svg',
						title: this.$t('userView.uploadImagesError.title'),
						subtitle: this.$t('userView.uploadImagesError.subtitle')
					},
					actions: [{
						id: 'ok',
						text: 'OK'
					}],
					resolve: () => ({}),
					reject: () => ({})
				})
			},
 			removeImage(i) {
				this.images.splice(i, 1)
			},
			getAge(dateString) {
				var today = new Date();
				var birthDate = new Date(dateString);
				var age = today.getFullYear() - birthDate.getFullYear();
				var m = today.getMonth() - birthDate.getMonth();
				if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
				{
					age--;
				}
				return age;
			},
			getUser: async function(uid) {
				this.user = null
				if (!uid) return
				const [user] = (await this.$DB.db.collection('users').where('uid', '==', uid).get()).docs.map(doc => {
					const user = Object.freeze(doc.data())
					// let birth_date = (user.birth_date?.seconds || 0) * 1000
					// if (!birth_date) {
					// 	birth_date = ''
					// } else {
					// 	birth_date = new Date(birth_date)
					// 	birth_date = `${birth_date.getFullYear()}-${('0'+(birth_date.getMonth()+1)).substr(-2)}-${('0'+birth_date.getDate()).substr(-2)}`
					// }
					return {
						...user,
						zodiac: getZodiac(user.birth_date?.toDate())
					}
				})
				if (user?.full_name && user?.gender) {
					this.hasSaved = true
				}
				this.user = user
				this.$nextTick(() => {
					this.getPosts()
					this.getRating()
				})
			},
			getRating: async function() {
				const [rating] = (await this.$DB.db.collection('ratings').where('user_uid', '==', this.userUid).get()).docs.map(doc => Object.freeze(doc.data()))
				if (!rating) return
				this.comments_counter = rating.comments_counter || 0
				this.top_comments_counter = rating.top_comments_counter || 0
				this.rating = rating.rating || 0
			},
			getPosts: async function () {
				let posts = (await this.$DB.db.collection('comments').where('user_uid', '==', this.userUid).where('moderation_status', 'in', ['approved', 'waiting_for']).get()).docs.map(doc => Object.freeze(doc.data()))
				posts.sort((a,b) => {
					const [aName, bName] = [a?.created_at.seconds || 0, b?.created_at.seconds || 0]
					if (aName < bName) {
						return 1
					} else {
						return -1
					}
				})
				this.posts = posts
			},
			openCloseInfo () {
				window.requestAnimationFrame(() => {
					this.openInfo = !this.openInfo
				})
			}
		}
	}
</script>

<style lang="scss">
	.u-view {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 105;
		background: $black;
		overflow-y: scroll;
		overflow-x: hidden;

		&__editphotos {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 50%;
			background: rgba(0,0,0, 0.6);
			z-index: 1;
			
			&-add {
				display: block;

				@include pos-centered-v;
				width: 110px;
				height: 110px;
				left: 19px;

				overflow: hidden;

				background:
					no-repeat url("/img/iconPlusBigZeroStateAdding.svg") center center,
					no-repeat url("/img/backgroundcolored.svg") top left;
				background-size: 48px 48px, 100% 100%;

				input {
					position: absolute;
					top: 0;
					left: 0;
					visibility: hidden;
				}

				label {
					display: block;
					width: 100%;
					height: 100%;
				}

				input:disabled + label {
					background:
						no-repeat url("/img/spinner.svg") center center,
						no-repeat url("/img/backgroundcolored.svg") top left;
					background-size: 48px 48px, 100% 100%;
				}
			}

			&-list {
				position: absolute;
				left: 19px;
				bottom: 5px;
				right: 19px;
				height: 110px;
				overflow-x: scroll; 
				overflow-y: hidden;
				white-space: nowrap;
				width: 100%;

				&__item {
					width: 110px;
					height: 110px;
					position: relative;
					display: inline-block;
					background-color: rgba(255,255,255,0.25);
					
					&:not(:first-child) {
						margin-left: 10px;
					}
					>img.u-photo {
						width: 110px;
						height: 110px;
						object-fit: cover;
					}

					.u-photo-del {
						width: 34px;
						height: 34px;
						position: absolute;
						bottom: 4px;
						right: 4px;
						background-color: rgb(169, 25, 25);
						background-repeat: no-repeat;
						background-position: center;
						border-radius: 50%;
					}
				}
			}
		}

		&__editdata {
			// height: 500px;
			margin-bottom: 100px;
			.editdata {
				&__name {
					input {
						height: 60px;
						background: $blackTwo;
						border: none;
						width: 100%;
						color: $white;
						@include font(24, 27, normal);
						padding: 5px 19px;
						&:focus {
							outline: none;
						}
					}
				}
				&__bdate {
					margin-top: 16px;
					&-label {
						color: $white;
					}
					input {
						background: $black;
						border: none;
						&:focus {
							outline: none;
						}
						color: $white;
						&::-webkit-calendar-picker-indicator {
							filter: invert(1);
						}
						font-family: inherit;
					}
				}
				&__gender {
					margin-top: 16px;
					background: $blackTwo;
					@include flex(row, center, center);
					height: 44px;
					&-items {
						width: 80%;
						height: 44px;
						@include flex(row, center, center);
						background: rgba(118, 118, 128, .12);
						height: 27px;
						border-radius: 8px;
						// -items
						&__item {
							color: $white;
							@include font(13, 18, normal);
							letter-spacing: -0.08px;
							height: 100%;
							width: 50%;
							@include flex(row, center, center);
							&:not(:first-child) {
								border-left: 1px solid #ccc;
							}

							&.-active {
								background: rgb(99, 99, 102);
								border-radius: 8px;
								@include font(13, 18, 600);
							}
						}
						&.-hasActive {
							.editdata__gender-items__item {
								border-left: none !important;
							}
						}
					}
				}
				&__bio {
					margin-top: 16px;
					background: $blackTwo;
					> textarea {
						background: $blackTwo;
						border: none;
						outline: none;
						color: $white;
						width: 100%;
						padding: 16px;
						@include font(17, 18, normal);
						letter-spacing: 0.72px;
						&::placeholder {
							@include font(17, 18, normal);
							color: $white;
							letter-spacing: 0.72px;
						}
						font-family: inherit;
					}
				}
				&__divider {
					margin-top: 36px;
					margin-bottom: 7px;
					padding-left: 16px; 
					width: 100%;
					text-transform: uppercase;
					@include font(13, 22, normal);
					letter-spacing: -0.31px;
					color: rgb(142, 142, 147);
					text-align: left;
				}
				&__editgroup {
					width: 100%;
					padding-left: 16px;
					background: $blackTwo;
					&-divider {
						width: 100%;
						height: 1px;
						background: $white;
						opacity: 0.2;
					}
					&-item {
						min-height: 44px;
						&:not(:last-child) {
							border-bottom: rgba(255, 255, 255, .2);
						}
						@include flex(row, flex-start, center);
						position: relative;

						&__text {
							color: $white;
							@include font(17, 22, normal);
							text-align: left;
							height: 100%;
							width: 100%;
							letter-spacing: -0.41px;
						}

						.form-switch {
							@include pos-centered-v;
							right: 16px;
						}

						&.-showsex {
							@include flex(row, space-between, center);
							width: calc(100% - 32px);
							.editdata__editgroup-item__showsex {
								@include font(16, 22, 500);
								color: $white;
								border: 1px solid $goldenMain;
								border-radius: 21px;
								@include flex(row, center, center);
								width: 98px;
								height: 31px;
								&.-active {
									background: $goldenMain;
								}
							}
						}
					}
				}
			}
		}
		&__edit {
			&-pencil,
			&-save,
			&-gear {
				position: fixed;
				bottom: 44px;
				animation: .3s linear example alternate;
			}
			&-pencil {
				left: 16px;
			}
			&-gear {
				right: 16px;
			}
			&-save {
				left: 16px;
				background-color: rgba(84, 187, 117, 0.93) !important;
			}
		}

		.back-icon {
			left: 20px;
    		top: 20px;
			position: absolute;
			height: 30px;
			width: 30px;
			z-index: 2;
		}

		.home-icon {
			position: absolute;
			z-index: 2;
			top: 20px;
			right: 18px;
			width: 24px;
			height: 24px;
		}
		
		&-infoicon {
			position: absolute;
			z-index: 102;
			right: 28px;
			bottom: 48px;

			&-back {
				position: absolute;
				z-index: 2;
				right: 16px;
				top: calc(50% - 60px);
				width: 48px;
				height: 48px;
				background: rgba(0,0,0, 0.5);
				background-size: 24px 24px;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				border-radius: 50%;
				backdrop-filter: blur(10px);
				transform: rotate(-90deg);
			}
		}

		&-info {
			position: absolute;
			left: 16px;
			bottom: 96px;
			z-index: 2;

			&.-short {
				bottom: calc(50% + 24px) !important;
			}
			&__name {
				@include font(24, 29, 500);
				color: $white;
			}

			&__zodiac {
				@include flex(row, flex-start, center);
				margin-top: 8px;

				img {
					width: 24px;
					height: 24px;
				}

				&__text {
					@include font(15, 18, normal);
					margin-left: 8px;
					color: $white;
					text-transform: uppercase;
				}
			}
		}

		&__rating-tabs {
			height: 44px;
			background: $darkGreyTwo;
			padding: 10px 16px;
			@include flex(row, flex-start, flex-start);

			&__tab {
				&:not(:last-child) {
					border-right: 1px solid rgba( 255, 255, 255, 0.37);
				}
				width: 25%;
				height: 100%;

				div {
					color: $white;
					&:first-child {
						@include font(20, 24, 600);
					}
					&:last-child {
						@include font(14, 16, normal);
					}
				}
			}
		}

		&__bio {
			margin: 16px;
			text-align: left;
			@include font(17, 18, normal);
			color: $white;
			letter-spacing: 0.72px;
		}
		&__posts {
			@include flex(row, flex-start, flex-start);
			flex-wrap: wrap;
			margin-bottom: 20px;

			&-post {
				width: 33.1%;
				height: 124px;
				// display: inline-block;
				background-position: 50% 50%;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 1px;
				&:not(:nth-child(3n)) {
					margin-right: 1px;
				}
				
			}
		}
		@keyframes showFromBottom {
			from {transform: translateY(400px);}
			to {transform: translateY(0);}
		}
	}
</style>