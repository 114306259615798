<template>
	<div class="context-menu" :class="{'-open': open, '-modal': config.type === 'modal', '-pink': config.style === 'pink'}">
		<div class="context-menu__wrapper" @click="close(true)" >
			<div class="context-menu__actions" v-if="open" :class="{'-modal': config.type === 'modal', '-pink': config.style === 'pink'}">
				<div class="context-menu__actions-action -head" v-if="config.type === 'modal'">
					<div class="context-menu__actions-action__wrapper">
						<img class="context-menu__actions-action__icon" :src="config.icon" v-if="config.icon">
						<div class="context-menu__actions-action__title">
							{{ config.title }}
						</div>
						<div class="context-menu__actions-action__subtitle">
							{{ config.subtitle }}
						</div>
					</div>
				</div>
				<template v-for="(action, i) in actions">
					<a 
						v-if="action.url" 
						:href="action.url"
						class="context-menu__actions-action" 
						target="_blank"
						:class="`${(action.type) ? `-${action.type}` : ''}`"
						:key="i"
					>
						{{ action.text }}
					</a>
					<div 
						class="context-menu__actions-action" 
						v-else
						:key="i"
						@click.stop="actionClicked(i)"
						:class="`${(action.type) ? `-${action.type}` : ''}`"
					>
						{{ action.text }}
					</div>
				</template>
			</div>
			<div 
				v-if="open && !config.noCancel"
				class="context-menu__close"
				:class="{'-pink': config.style === 'pink'}" 
				@click="close()"  
			>
				{{ $t('toCancel') }}
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			open: false,
			actions: [],
			resolve: null,
			reject: null,
			config: {
				type: '', 
				icon: '',
				title: '',
				subtitle: '',
				style: '',
				noCancel: false,
				disableBackdropClose: false
			}
		}
	},
	mounted() {
		this.$bus.$on('openContextMenu', this.init)
	},
	methods: {
		init({actions=[], resolve, reject, config}) {
			this.$set(this, 'actions', actions)
			this.$set(this, 'resolve', resolve)
			this.$set(this, 'reject', reject)
			if (config) {
				for(let key in config) {
					this.config[key] = config[key]
				}
			}
			this.open = true

		},
		close(backdrop = false) {
			if (backdrop && this.config.disableBackdropClose) return
			const reject = this.reject
			if (reject && typeof reject === 'function') {
				reject()
			}
			window.requestAnimationFrame(this.clear)
		},
		actionClicked(index) {
			const action = this.actions[index]
			if (action.nonClicked) return
			const resolve = this.resolve
			this.clear()
			if (resolve && typeof resolve === 'function') {
				this.$nextTick(() => {
					resolve(index)
				})
			}
		},
		clear() {
			this.open = false
			this.resolve = null
			this.reject = null
			this.actions = []
			this.$set(this, 'config', {
				type: '', 
				icon: '',
				title: '',
				subtitle: '',
				style: ''
			})
		}
	}
}
</script>
<style lang="scss">
	.context-menu {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 1060;
		visibility: hidden;
		@include font(20, 25, normal);
		letter-spacing: 0.38px;
		color: $white;
		background-color: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: all 0.2s ease;

		&.-open {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
			.context-menu__actions,
			.context-menu__close {
				transform: translateY(0);
			}
		}

		&__wrapper {
			width: calc(100% - 16px);
			height: calc(100% - 15px);
			@include flex(column, flex-end, center);
			margin-left: 8px;
			margin-bottom: 15px;
		}

		&__actions,
		&__close {
			width: 100%;
			@include flex(column, center, center);
			background: rgb(37, 37, 37);
			border-radius: 10px;
			transform: translateY(300px);
			animation: .15s linear example alternate;
		}
		&__actions {
			&.-modal {
				width: calc(100% - 64px);
				@include pos-centered-v;
				transform: translateY(-50%) !important;
				background: rgb(235, 235, 235);
				color: $black;

				

				
			}

			&.-pink {
				background: linear-gradient(135deg, rgba(23, 115, 134, 0.901852) 0%, rgba(190, 34, 89, 0.895979) 98.75%);
				backdrop-filter: blur(13.5914px);
				
				color: $white;
				.context-menu__actions-action {
					height: 54px;
					@include font(20, 22, normal);
					letter-spacing: -0.48px;
					&.-danger {
						color: rgb(209, 41, 41);
					}
					&:not(:last-child) {
						border-bottom: 1px solid rgba(217, 217, 217, 1) !important;
					}
					text-decoration: none;
					color: $white;

					&:visited {
						color: $white;
					}
				}
			}
			&-action {
				height: 56px;
				width: 100%;
				@include flex(column, center, center);
				text-decoration: none;
				&:not(:last-child) {
					border-bottom: 1px solid rgba(141, 141, 141, 1);
				}

				&.-danger {
					color: rgb(255, 69, 58);
				}

				&.-head {
					height: auto !important;
					.context-menu__actions-action__title {
						margin-top: 16px;
						@include font(20, 24, 500);
					}
					.context-menu__actions-action__subtitle {
						@include font(15, 18, normal);
						letter-spacing: -0.09px;
						margin-top: 5px;
					}
				}
				&__wrapper {
					margin: 64px 16px 48px 16px;
				}

				// color: $white;

				// &:visited {
				// 	color: $white;
				// }
			}
		}

		&__close {
			height: 56px;
			margin-top: 8px;
			margin-bottom: 15px;
		}

		&.-modal {
			.context-menu__close {
				display: none;
			}
		}
	}
	@keyframes example {
		from {transform: translateY(400px);}
  		to {transform: translateY(0);}
	}
</style>