<template>
  <div class="swipe-container" ref="swipeContainer">
    <div
      class="swipe-item"
      v-for="(item, index) in items"
      :key="index"
      :data-index="index"
    >
      <slot :name="`item-${index}`">{{ item }}</slot>
    </div>
  </div>
</template>

<script>
import throttle from '@/utils/throttle'
export default {
  name: 'SwipeContainer',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slides: [], // массив слайдов
      activeSlide: 0, // индекс активного слайда
    }
  },
  mounted() {
    this.slides = this.$refs.swipeContainer.children // получаем дочерние элементы слайдера
    this.createObserver() // создаем observer
  },
  beforeDestroy() {
    this.observer.disconnect()
  },
  methods: {
    createObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.setActiveSlide(Number(entry.target.dataset.index))
            }
          })
        },
        { threshold: 0.5 }
      ) // пороговое значение для срабатывания observer

      Array.prototype.forEach.call(this.slides, (slide) => {
        this.observer.observe(slide)
      })
    },
    setActiveSlide(index) {
      this.activeSlide = index
      this.$emit('active-slide-changed', index) // эмитим событие изменения активного слайда
    },
  },
}
</script>

<style>
.swipe-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.swipe-item {
  flex: 0 0 auto;
  width: 100%;
  scroll-snap-align: start;
}
.swipe-container::-webkit-scrollbar {
  display: none;
}
</style>
