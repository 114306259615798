<template>
  <div class="bank-card-list">
    <div v-if="isAddingCardMode" class="paytype-add-card-wrapper">
      <div class="paytype-add-card-wrapper__head">
        <img
          src="/img/backWhite.png"
          srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
          class="back-icon"
          @click="doAddingCardMode(false)"
        />
        {{ $t('MG-703-002') }}
      </div>
      <AddNewCard
        :payment-sources-count="paymentSources.length"
        emit-close
        @close="doAddingCardMode(false)"
      />
    </div>
    <div
      v-if="paymentSources.length"
      class="cards-block"
      :class="{ '-compact': isCompactView }"
    >
      <div class="cards-block__title" @click="isCompactView = !isCompactView">
        {{ $t('MG-703-009')
        }}<img
          v-if="paymentSources.length > 1"
          src="/img/chevron-up-black.svg"
        />
      </div>
      <swipe-list
        v-if="isCompactView"
        :items="[mainPaymentSource]"
        transition-key="uid"
      >
        <template slot-scope="{ item }">
          <PaymentCardListItem
            :item="item"
            :key="item.uid"
            :disable-if-not-main="true"
            theme="light"
            use-store-to-prefer
          />
        </template>
        <template slot="right" slot-scope="{ item, close }">
          <div
            class="swipeout-action action-panel-left"
            style="height: 64px"
            @click="removeCard(item, close)"
          >
            <img src="/img/trash-icon-white.svg" width="24px" height="28px" />
          </div>
        </template>
      </swipe-list>
      <template v-else>
        <div class="cards-block__list">
          <swipe-list
            v-for="paymentSource in paymentSources"
            :key="paymentSource.uid"
            :items="[paymentSource]"
            transition-key="uid"
          >
            <template slot-scope="{ item }">
              <PaymentCardListItem
                :item="item"
                :key="item.uid"
                :show-checkbox="!isCompactView"
                theme="light"
                use-store-to-prefer
              />
            </template>
            <template slot="right" slot-scope="{ item, close }">
              <div
                class="swipeout-action action-panel-left"
                style="height: 64px"
                @click="removeCard(item, close)"
              >
                <img
                  src="/img/trash-icon-white.svg"
                  width="24px"
                  height="28px"
                />
              </div>
            </template>
            <div slot="empty">list is empty ( filtered or just empty )</div>
          </swipe-list>
        </div>
      </template>
    </div>
    <MayberLoader v-if="isShowLoader" />
  </div>
</template>
<script>
import { deletePaymentSource } from '@/init/api'
import AddNewCard from '@/views/UserSettings/AddNewCard'
import MayberLoader from '@/components/UI/MayberLoader'
import PaymentCardListItem from '@/components/PaymentCardListItem'
import PaymentCardsAddCardButton from '@/components/PaymentCardsAddCardButton'
import { SwipeList, SwipeOut } from 'vue-swipe-actions'

export default {
  props: {
    paymentSources: {
      type: Array,
      default: () => [],
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddNewCard,
    MayberLoader,
    PaymentCardsAddCardButton,
    PaymentCardListItem,
    SwipeList,
    SwipeOut,
  },
  data() {
    return {
      isAddingCardMode: false,
      isCompactView: true,
      isShowLoader: false,
    }
  },
  computed: {
    preferredByStoreCardUid() {
      return this.$store.state.payments.preferredPaymentSourceUid
    },
    mainPaymentSourceByStore() {
      return this.paymentSources.find(
        (paymentSource) => paymentSource.uid === this.preferredByStoreCardUid
      )
    },
    mainPaymentSource() {
      return (
        this.mainPaymentSourceByStore ||
        this.paymentSources.find(
          (paymentSource) =>
            paymentSource.preferred
        ) ||
        this.paymentSources[0]
      )
    },
  },
  watch: {
    open: 'initPreferrerCard',
  },
  methods: {
    doAddingCardMode(show = false) {
      this.isAddingCardMode = show
    },
    async removeCard(cardItem, closeFn) {
      this.isShowLoader = true
      try {
        if (closeFn) {
          closeFn()
        }
        const resp = await deletePaymentSource(cardItem.uid, cardItem.user_uid)
        if (resp?.error === 401) {
          await this.$bus.updateToken()
          this.removeCard(cardItem)
        } else if (this.preferredByStoreCardUid === cardItem.uid) {
          this.$store.commit('payments/SET_PREFERRED_PAYMENT_SOURCE_UID', '')
        }
      } catch (e) {
        console.error('can not remove card! ', e)
      } finally {
        this.isShowLoader = false
      }
    },
    initPreferrerCard(isOpened) {
      if (!isOpened) return

      const preferCard = this.paymentSources.find(
        (paymentSource) => paymentSource.preferred
      )
      if (preferCard && this.preferredByStoreCardUid !== preferCard.uid) {
        this.$store.commit(
          'payments/SET_PREFERRED_PAYMENT_SOURCE_UID',
          preferCard.uid
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.bank-card-list {
  margin-top: 24px;
  width: 100%;

  .paytype-add-card-wrapper {
    @include fixed-modal(1061);
    background: $black;

    &__head {
      @include flex(column, center, center);
      @include font(18, 21, 400);
      color: $white;
      height: 60px;
      position: relative;

      .back-icon {
        @include pos-centered-v();
        left: 20px;
        position: absolute;
        height: 30px;
        width: 30px;
        z-index: 3;
      }
    }
  }

  .no-cards-block,
  .cards-block {
    background: $white;
    border-radius: 12px;
    padding: 16px;
  }

  .no-cards-block {
    @include flex(column, flex-start, flex-start);
    margin-bottom: 32px;

    &__title {
      @include font(16, 20, 400);
      letter-spacing: -0.38px;
    }

    &__action {
      @include flex(column, flex-start, flex-start);
      @include font(15, 20, 400);
      color: #8e8e93;
      margin-top: 12px;
      // text-align: left;
      width: 100%;

      img {
        margin-left: 16px;
      }

      span {
        margin-bottom: 4px;
      }
    }
  }

  .cards-block {
    text-align: left;

    &.-compact {
      .cards-block__title > img {
        transform: rotate(0);
      }
    }

    .swipeout-list-item {
      height: 65px;
    }

    .swipeout-action.action-panel-left {
      background-color: #ef5350;
    }

    .swipeout-content {
      border-radius: 0px 10px 10px 0px;
    }

    &__title {
      @include flex(row, space-between, center);
      @include font(16, 20, 400);
      letter-spacing: -0.38px;
      margin-bottom: 20px;

      img {
        margin-right: 8px;
        transform: rotate(180deg);
      }
    }

    &__list {
      max-height: 330px;
      overflow: scroll;

      @media (max-width: 380px) {
        max-height: 150px;
      }
    }
  }
}
</style>
