var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bank-card-list"},[(_vm.isAddingCardMode)?_c('div',{staticClass:"paytype-add-card-wrapper"},[_c('div',{staticClass:"paytype-add-card-wrapper__head"},[_c('img',{staticClass:"back-icon",attrs:{"src":"/img/backWhite.png","srcset":"/img/backWhite@3x.png" + " 3x"},on:{"click":function($event){return _vm.doAddingCardMode(false)}}}),_vm._v(" "+_vm._s(_vm.$t('MG-703-002'))+" ")]),_c('AddNewCard',{attrs:{"payment-sources-count":_vm.paymentSources.length,"emit-close":""},on:{"close":function($event){return _vm.doAddingCardMode(false)}}})],1):_vm._e(),(_vm.paymentSources.length)?_c('div',{staticClass:"cards-block",class:{ '-compact': _vm.isCompactView }},[_c('div',{staticClass:"cards-block__title",on:{"click":function($event){_vm.isCompactView = !_vm.isCompactView}}},[_vm._v(" "+_vm._s(_vm.$t('MG-703-009'))),(_vm.paymentSources.length > 1)?_c('img',{attrs:{"src":"/img/chevron-up-black.svg"}}):_vm._e()]),(_vm.isCompactView)?_c('swipe-list',{attrs:{"items":[_vm.mainPaymentSource],"transition-key":"uid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('PaymentCardListItem',{key:item.uid,attrs:{"item":item,"disable-if-not-main":true,"theme":"light","use-store-to-prefer":""}})]}},{key:"right",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('div',{staticClass:"swipeout-action action-panel-left",staticStyle:{"height":"64px"},on:{"click":function($event){return _vm.removeCard(item, close)}}},[_c('img',{attrs:{"src":"/img/trash-icon-white.svg","width":"24px","height":"28px"}})])]}}],null,false,3050511265)}):[_c('div',{staticClass:"cards-block__list"},_vm._l((_vm.paymentSources),function(paymentSource){return _c('swipe-list',{key:paymentSource.uid,attrs:{"items":[paymentSource],"transition-key":"uid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('PaymentCardListItem',{key:item.uid,attrs:{"item":item,"show-checkbox":!_vm.isCompactView,"theme":"light","use-store-to-prefer":""}})]}},{key:"right",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('div',{staticClass:"swipeout-action action-panel-left",staticStyle:{"height":"64px"},on:{"click":function($event){return _vm.removeCard(item, close)}}},[_c('img',{attrs:{"src":"/img/trash-icon-white.svg","width":"24px","height":"28px"}})])]}}],null,true)},[_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_vm._v("list is empty ( filtered or just empty )")])])}),1)]],2):_vm._e(),(_vm.isShowLoader)?_c('MayberLoader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }