export default {
  data() {
    return {
      deliveryFlow: window.ordersStore.deliveryFlow,
      hasFirstSelfCheckin: !!localStorage.getItem('hasFirstSelfCheckin'),
    }
  },
  computed: {
    isShowSelfCheckinHelp() {
      return !this.hasFirstSelfCheckin && this.supportedFlows?.orders
    },
  },
  methods: {
    closeSelfCheckinHelp() {
      this.hasFirstSelfCheckin = true
      localStorage.setItem('hasFirstSelfCheckin', true)
    },
  },
}
