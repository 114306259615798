<template>
	<div class="apm" :class="{'-dragging': dragging, '-open': open}">
		<div class="apm-map" id="apm-map" v-if="open"></div>
        <div class="apm-back" @click="close(false)">
            <img
				src="/img/whiteCircleArrowBack.svg"
				alt=""
				class="apm-back-icon"
			/>
        </div>
        <div class="apm-address-input">
            <img
				src="/img/Subtract.svg"
				alt=""
				class="apm-address-icon"
			/>
            <input class="apm-address-input__input" v-model.lazy="addressString" @change="codeAddress">
        </div>
		<div class="apm-center">
			<img
				src="/img/mapPicker.svg"
				alt=""
				class="apm-center__flag"
				:class="{'-up': dragging}"
			/>
		</div>
        <div class="apm__myposition" @click="setMyLocation(userGeo)">
			<img src="/img/geoPosition.svg" />
		</div>
        <div class="apm-bottom" v-if="place">
            <div class="apm-bottom__name">{{ place.name }}</div>
            <div class="apm-bottom__description">{{ place.short_description }}</div>
            <div class="apm-bottom__notice" v-if="!addressInZone">{{ $t('delivery.outOfZone') }}</div>
            <div class="apm-bottom__button" :class="{'-disabled': !addressInZone}" @click="selectAddress">
                {{ $t('delivery.confirmAddress') }}
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'AddressPickerModal',
    data () {
        return {
            map: null,
            dragging: false,
            geocoder: null,
            timer: null,
            addressString: '',
            open: false,
            place: null,
            zoneRadius: null,
            deliveryZoneRadius: 0,
            addressInZone: true
        }
    },
    computed: {
        ...mapState('user', {
            userGeo: state => state.geolocation
        })
    },
    watch: {
        open (val) {
            if (val) {
                this.$nextTick(() => {
                    this.initMap()
                })
            } else {
                this.map = null
            }
        },
        addressString (val) {
            if (val) {
                const store = window.ordersStore
                store.checkDeliveryZone(val, this.place.uid)
                    .then((resp) => {
                        if (resp.error_code) {
                            this.addressInZone = false
                            store.updateDeliveryZone({}, true)
                        } else {
                            this.addressInZone = true
                            const deliveryZoneRadius = resp?.data?.radius_in_meters || 0
                            this.deliveryZoneRadius = deliveryZoneRadius
                            store.updateDeliveryZone(resp?.data || {})
                        }
                    })
            }
        },
        deliveryZoneRadius (val) {
            if (!val) return false
            if (this.zoneRadius) {
                this.zoneRadius.setMap(null)
            }
            const geopoint = this.place.geo_point
            const center = { lat: geopoint.latitude, lng: geopoint.longitude }
            const cityCircle = new window.google.maps.Circle({
                strokeColor: '#007AFF',
                strokeOpacity: 0.1,
                strokeWeight: 2,
                fillColor: '#007AFF',
                fillOpacity: 0.1,
                map: this.map,
                center,
                radius: val
            })
            this.zoneRadius = cityCircle
        }
    },
    mounted () {
        this.$bus.$on('AddresPickerOpen', this.onOpen)
    },
    methods: {
        onOpen (place) {
            this.place = place
            this.open = true
        },
        initMap () {
            if (!document.getElementById('google-map-script')) {
                const gMapScript = document.createElement('SCRIPT')
                gMapScript.id = 'google-map-script'
                gMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env['VUE_APP_GOOGLE_MAPS_API_KEY']}&libraries=geometry,pay`
                document.body.appendChild(gMapScript)
                this.timer = setTimeout(this.initMap, 100)
            } else if (window.google?.maps?.Map) {
                this.map = new window.google.maps.Map(document.getElementById('apm-map'), {
                    center: this.userGeo,
                    zoom: 16,
                    gestureHandling: 'greedy',
                    disableDefaultUI: true,
                    mapTypeId: 'roadmap'
                })
                this.map.addListener('dragstart', this.dragstart)
                this.map.addListener('dragend', this.dragend)
                this.map.addListener('zoom_changed', this.onZoom)
                this.geocoder = new window.google.maps.Geocoder()
                this.setPlaceMarker()
                this.dragend()
            } else {
                this.timer = setTimeout(this.initMap, 50)
            }
        },
        getAddressFromComponents (address) {
            const house = address[0]
            const street = address[1]
            const city = address[3]
            return `${city.short_name}, ${street.short_name}, ${house.short_name}`.replace('строение', 'стр.')
        },
        dragstart () {
            clearTimeout(this.timer)
            this.dragging = true
        },
        dragend () {
            this.$store.dispatch('places/hideLoader')
            clearTimeout(this.timer)
            this.$store.dispatch('places/showLoader', true)
            this.timer = setTimeout(() => {
                this.dragging = false
                const center = this.getCenter()
                this.getReverseGeocodingData(center)
                    .then(address => {
                        if (address && address.length && address[3]) {
                            this.addressString = this.getAddressFromComponents(address)
                        }
                        this.$store.dispatch('places/hideLoader')
                    })
                    .catch((err) => {
                        console.log('err', err)
                    })

            }, 200)
        },
        onZoom () {
            this.dragend()
        },
        getCenter () {
            const center = this.map.getCenter()
            return {
                lat: center.lat(),
                lng: center.lng()
            }
        },
        getReverseGeocodingData ({ lat, lng }) {
            return new Promise((resolve) => {
                const latlng = new window.google.maps.LatLng(lat, lng)
                // This is making the Geocode request
                const geocoder = this.geocoder
                geocoder.geocode({ latLng: latlng }, (results, status) => {
                    if (status !== window.google.maps.GeocoderStatus.OK) {
                        resolve('')
                    }
                    // This is checking to see if the Geoeode Status is OK before proceeding
                    if (status === window.google.maps.GeocoderStatus.OK) {
                        const address = results[0].address_components
                        resolve(address)
                    }
                    resolve('')
                })
            })
        },
        codeAddress (e) {
            this.$store.dispatch('places/hideLoader')
            const address = e.target.value
            const self = this
            if (!address) return
            this.$store.dispatch('places/showLoader', true)
            this.geocoder.geocode({ address: address }, function (results, status) {
                if (status === 'OK') {
                    const res = results[0]
                    if (res) {
                        self.map.setCenter(results[0].geometry.location)
                        const address_components = res.address_components
                        if (address_components && address_components.length && address_components[3]) {
                            self.addressString = self.getAddressFromComponents(address_components)
                        }
                    }
                } else {
                    self.$bus.$emit("openContextMenu", {
                        config: {
                            type: "modal",
                            style: "pink",
                            title: self.$t(
                                "delivery.errors.INVALID_ADDRESS.title"
                            ),
                            subtitle: self.$t(
                                "delivery.errors.INVALID_ADDRESS.text"
                            ),
                            icon: "/img/iconLocationFail.svg",
                        },
                        actions: [
                            {
                                id: "ok",
                                text: "OK",
                            },
                        ],
                        resolve: () => ({}),
                        reject: () => ({}),
                    })
                }
                self.$store.dispatch('places/hideLoader')
            })
        },
        close (success = true) {
            const address = this.addressString
            this.addressString = ''
            this.place = null
            this.open = false
            this.dragging = false
            clearTimeout(this.timer)
            this.timer = null
            this.zoneRadius = null
            this.deliveryZoneRadius = 0
            this.addressInZone = true
            this.$bus.$emit('AddresPickerClose', (success) ? address : success)
        },
        setMyLocation: async function (userGeo) {
            let newGeo = {}
            const zoom = 17
            if (!(userGeo.lat && userGeo.lng)) {
                try {
                    newGeo = await this.$utils.getUserGeo()
                    this.map.setCenter(newGeo)
                } catch (e) {
                    this.$iosAlert(this.$t('allowYourLocation'))
                        .then(function () {
                            console.log('alert')
                        })
                }
            } else {
                this.map.setCenter(userGeo)
            }
            this.map.setZoom(zoom)
        },
        setPlaceMarker () {
            const map = this.map
            const item = this.place
            const uid = item?.uid || ''
            const maps = window.google.maps
            const self = this
            const geopoint = item.geo_point
            const position = { lat: geopoint.latitude, lng: geopoint.longitude }
            const icon = {
                url: '/img/pickerBlackNoCheckIns.svg',
                size: new maps.Size(34, 45),
                origin: new maps.Point(0, 0),
                anchor: new maps.Point(24, 42),
                scaledSize: new maps.Size(34, 45)
            }

            const marker = new maps.Marker({
                position,
                map,
                name,
                icon,
                uid
            })
            if (item) {
                const infowindow = new maps.InfoWindow({
                    content: '',
                    disableAutoPan: true,
                    _cont: `<div class="map-popover__text">${item.name}</div>`
                })
                marker.infowindow = infowindow
                marker.addListener('click', function () {
                    const infowindow = this.infowindow
                    if (infowindow) {
                        if (self.lastInfoWindow) {
                            self.lastInfoWindow.close()
                        }
                        const div = document.createElement('DIV')
                        div.className = 'map-popover'
                        div.uid = this.uid
                        div.innerHTML = infowindow._cont
                        infowindow.setContent(div)
                        infowindow.open(map, marker)
                        self.lastInfoWindow = infowindow
                    }
                }, true)
            } else {
                console.log('Noitem', item)
            }
        },
        selectAddress () {
            localStorage.setItem('userAddress', this.addressString)
            this.close()
        }
    }
}
</script>

<style lang="scss" scoped>
	.apm {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1050;
		background: $white;
		display: none;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
		outline: 0;

        &.-open {
            display: block;
        }

        &.-dragging {
            .apm-back,
            .apm-address-input,
            .apm-bottom {
                opacity: 0;
                transition: opacity 0.2s;
            }
        }

		&-map {
			width: 100%;
			height: 100%;
		}

		&-center {
			@include pos-centered;
			width: 8px;
			height: 8px;
			background: #B8A05E;
			z-index: 1;
			border-radius: 50%;

			&__flag {
				@include pos-centered-h;
				bottom: -10px;
				width: 60px;
                z-index: 1;

				&.-up {
					bottom: 0px;
				}
			}
		}

        &__myposition {
            position: fixed;
            z-index: 1;
			bottom: 200px;
			right: 20px;
			width: 44px;
			height: 44px;
			border-radius: 50%;
			border: 2px solid #e02020;
			
			img {
				width: 24px;
				height: 24px;
				margin-top: 10px;
				margin-right: 2px;
			}
		}

        &-back {
            position: absolute;
            z-index: 1;
            top: 22px;
            left: 22px;
            width: 42px;
            height: 42px;
            opacity: 1;
            transition: opacity 0.2s;
        }

        &-address {
            
            &-input {
                @include pos-centered-h;
                z-index: 1;
                top: 76px;
                left: 22px;
                width: calc(100% - 92px);
                height: 33px;
                background: $white;
                transform: none;
                border-radius: 5px;
                padding: 5px 12px 5px 36px;
                opacity: 1;
                transition: opacity 0.2s;

                &__input {
                    position: absolute;
                    left: 36px;
                    right: 13px;
                    top: 5px;
                    bottom: 5px;
                    outline: none;
                    border: none;
                    @include font(17, 22, normal);
                }
            }
            &-icon {
                @include pos-centered-v;
                width: 20px;
                height: 25px;
                
                left: 12px;
            }
        }

        &-bottom {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            height: 130px;
            background: rgba(28, 28, 30, 0.95);
            backdrop-filter: blur(27.1828px);
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 10px 10px 0px 0px;
            opacity: 1;
            transition: opacity 0.2s;
            padding: 16px 22px;
            @include flex(column, flex-start, flex-start);
            text-align: left;
            color: $white;

            &__name {
                @include font(24, 24, normal);
            }

            &__description {
                @include font(16, 24, normal);
                @include textTruncate(100%);
            }

            &__notice {
                margin-top: 7px;
                @include font(12, 24, 600);
                @include flex(row, center, center);
                width: 100%;
                text-align: center;
            }

            &__button {
                width: 100%;
                height: 40px;
                margin-top: 20px;
                background: #B8A15E;
                backdrop-filter: blur(19.028px);
                /* Note: backdrop-filter has minimal browser support */
                border-radius: 10px;
                @include font(17, 20, 600);
                text-transform: uppercase;
                @include flex(row, center, center);
                user-select: none;

                &.-disabled {
                    margin-top: 2px;
                    background: #38383A;
                    color: #636366;
                    pointer-events: none;
                }
            }
        }
	}
</style>
