<template>
	<div 
		class="code" 
		:class="{'-black-backdrop': tableCodScenario}"
		@click="focusInput()"
	>
		<div class="code-main">
			<div class="code-main__row -top">
				<div class="top-title">
					{{ locale.title }}
				</div>
				<div class="top-subtitle">
					{{ locale.subtitle }}
				</div>
				<div class="top-code">
					<div 
						class="top-code__char"
						v-for="(char, i) in showedCode"
						:key="i"
					>{{ char }}</div>
				</div>
			</div>
			<div class="code-main__row -button" @click.stop="accept">{{ $t('order.accept') }}</div>
			<div class="code-main__row -button" @click.stop="$emit('close')">{{ $t('toCancelCap') }}</div>
		</div>
		<input type="tel" ref="input" class="code-input" v-model="code" /> 
	</div>
</template>
<script>
export default {
	name: 'TableCodeComponent',
	props: {
		tableCodScenario: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			code: '',
			step: 'table_code',
			steps: {
				'table_code': {
					minLen: 3,
					maxLen: 3
				},
				'guest_number': {
					minLen: 1,
					maxLen: 2
				}
			}
		}
	},
	computed: {
		codeLens() {
			return this.steps[this.step]
		},
		maxLen() {
			return this.codeLens.maxLen
		},	
		minLen() {
			return this.codeLens.minLen
		},	
		guestNumberUnderTen() {
			return this.step === 'guest_number' && this.code < 10
		},
		showedCode() {
			const code = this.code.split('')
			const maxLen = this.maxLen
			let out = []
			for (let i = 0; i < maxLen; i++) {
				out.push(code[i] || '–')
			}
			if (this.guestNumberUnderTen) {
				out.pop()
			}
			return out
		},
		locale() {
			const stepTable = this.step === 'table_code'
			return {
				title: this.$t(`order.${stepTable ? 'inputTableCode': 'inputSeatNumber'}`),
				subtitle: this.$t(`order.${stepTable ? 'codeProvidedWaiter': 'seatNumberProvidedWaiter'}`)
			}
		}
	},
	watch: {
		code(val) {
			const maxLen = this.maxLen
			if (this.step === 'guest_number' && val > 0 && val.startsWith('0') ) {
				this.code = String(parseInt(val))
			}
			if (val && val.length > maxLen) {
				this.code = val.substring(0, maxLen)
			}
		}
	},
	mounted() {
		this.$nextTick(this.focusInput)
		this.$emit('update', {key: 'table_code', value: 0})
		this.$emit('update', {key: 'guest_number', value: 0})
		this.$refs['input'].addEventListener('keydown', this.keup)
	},
	beforeDestroy() {
		this.$refs['input'].removeEventListener('keydown', this.keup)
	},
	methods: {
		keup(ev) {
			const key = ev.key
			if (!(key === 'Backspace' || !isNaN(key))) {
				ev.preventDefault()
			}
		},
		focusInput() {
			const input = this.$refs['input']
			if (input) {
				input.focus()
			}
		},
		accept() {
			const { step, code, maxLen, minLen } = this
			const codeLen = code.length
			if (!((codeLen < minLen || codeLen > maxLen) || isNaN(code))) {
				this.$emit('update', {key: step, value: Number(code)})
				if (step === 'table_code') {
					this.code = ''
					this.step = 'guest_number'
					this.$nextTick(this.focusInput)
				} else {
					this.$emit('submit')
				}
			} else {
				this.$nextTick(this.focusInput)
			}
		}
	}
}
</script>
<style scoped lang="scss">
	.code {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, .8);
		z-index: 1;
		user-select: none;

		&.-black-backdrop {
			background: rgba(0, 0, 0, 1);
		}

		&-input {
			position: absolute;
			left: -99999px;
		}
		
		&-main {
			@include pos-centered();
			@include flex(column, flex-start, center);
			width: 311px;
			height: 318px;
			background: rgba(255, 255, 255, .92);
			border-radius: 10px;

			&__row {
				width: 100%;
				height: calc(100% - 180px);
				@include flex(row, center, center);
				color: black;

				&.-top {
					padding: 36px;
					@include flex(column, flex-start, center);
				}

				&.-button {
					height: 54px;
					@include font(20, 22, normal);
					border-top: solid 0.5px rgba(0, 0, 0, .2);
				}

				.top {
					&-title {
						@include font(20, 22, 600);
					}
					&-subtitle {
						margin-top: 10px;
						@include font(14, 18, normal);
						color: rgb(93, 93, 93);
						width: 237px;
					}
					&-code {
						margin-top: 30px;
						@include font(50, 50, 600);
						@include flex(row, flex-start, center);

						&__char:not(:first-child) {
							margin-left: 9px;
						}
					}
				}
			}
		}
	}
</style>