<template>
	<div class="hotel-registration">
		<div class="hotel-registration__header">
			<img 
				src="/img/chevron@3x.png" 
				width="12px"
				class="hotel-registration__header-back-icon"
				@click="$emit('close')"
			/>
			<div class="hotel-registration__header-title">
				{{ $t('roomService.registration.title') }}
			</div>
		</div>
		<div class="hotel-registration__body">
			<div class="hotel-registration__body-way_to_treat">
				<div 
					class="hotel-registration__body-way_to_treat__item"
					:class="{'-active': value.way_to_treat === 'Mr.'}"
					@click="changeData('way_to_treat', 'Mr.')"
				>
					Mr.
				</div>
				<div 
					class="hotel-registration__body-way_to_treat__item"
					:class="{'-active': value.way_to_treat === 'Ms.'}"
					@click="changeData('way_to_treat', 'Ms.')"
				>
					Ms.
				</div>
			</div>
			<div class="hotel-registration__body-form" :class="{'-has-error': errors.firstName}">
				<div class="hotel-registration__body-form__item">
					<input type="text" autocomplete="chrome-off" placeholder=" " v-model="firstName" />
					<span class="hotel-registration__body-form__item-span">{{ $t('roomService.registration.firstName') }}</span>
				</div>
				<div class="hotel-registration__body-form__divider"></div>
				<div class="hotel-registration__body-form__error" v-if="errors.firstName">{{ $t('roomService.registration.firstNameError') }}</div>
			</div>
			<div class="hotel-registration__body-form" :class="{'-has-error': errors.lastName}">
				<div class="hotel-registration__body-form__item">
					<input type="text" autocomplete="chrome-off" placeholder=" " v-model="lastName" />
					<span class="hotel-registration__body-form__item-span">{{ $t('roomService.registration.lastName') }}</span>
				</div>
				<div class="hotel-registration__body-form__divider"></div>
				<div class="hotel-registration__body-form__error" v-if="errors.lastName">{{ $t('roomService.registration.lastNameError') }}</div>
			</div>
			<div class="hotel-registration__body-form" :class="{'-has-error': errors.roomNumber}">
				<div class="hotel-registration__body-form__item">
					<input type="tel" autocomplete="chrome-off" placeholder=" " v-model="roomNumber" />
					<span class="hotel-registration__body-form__item-span">{{ $t('roomService.registration.roomNumber') }}</span>
				</div>
				<div class="hotel-registration__body-form__divider"></div>
				<div class="hotel-registration__body-form__error" v-if="errors.roomNumber">{{ $t('roomService.registration.roomNumberError') }}</div>
			</div>
		</div>
		<div class="hotel-registration__bottom" @click="submitForm">
			{{ $t('roomService.registration.sendOrder') }}
		</div>
	</div>
</template>
<script>
export default {
	props: {
		value: {
			type: Object,
			required: true
		}	
	},
	data() {
		return {
			errors: {
				firstName: false,
				lastName: false,
				roomNumber: false
			}
		}
	},
	computed: {
		firstName: {
			get() {
				return this.value.guest_name
			},
			set(value) {
				this.changeData('guest_name', value)
			} 
		},
		lastName: {
			get() {
				return this.value.guest_last_name
			},
			set(value) {
				this.changeData('guest_last_name', value)
			} 
		},
		roomNumber: {
			get() {
				return this.value.object_number
			},
			set(value) {
				this.changeData('object_number', value)
			} 
		}
	},
	methods: {
		changeData(key, newValue) {
			const copyValue = JSON.parse(JSON.stringify(this.value))
			copyValue[key] = newValue
			this.$emit('input', copyValue)
		},
		clearErrors() {
			this.$set(this, 'errors', {
				firstName: false,
				lastName: false,
				roomNumber: false
			})
		},
		validateForm() {
			let out = false
			this.clearErrors()
			if (!this.firstName) {
				this.errors.firstName = true
				out = true
			}
			if (!this.lastName) {
				this.errors.lastName = true
				out = true
			}
			if (!this.roomNumber) {
				this.errors.roomNumber = true
				out = true
			}
			return out
		},
		submitForm() {
			const hasErrors = this.validateForm()
			if (!hasErrors) {
				this.$emit('submit')
			}
		}
	}
}
</script>
<style scoped lang="scss">
	div {
		box-sizing: border-box;
	}
	.hotel-registration {
		@include fixed-modal(1);
		background: $white;
		height: 100%;

		&__header {
			margin-top: 52px;
			height: 30px;
			position: relative;
			width: 100%;

			&-title {
				@include pos-centered-h;
				@include font(18, 20, normal);
				width: 100%;
				z-index: 1;
			}

			&-back-icon {
				position: absolute;
				top: 0;
				left: 25px;
				// height: 100%;
				z-index: 2;
			}
		}

		&__body {
			margin-top: 82px;
			width: 100%;
			padding: 0 24px;

			&-way_to_treat {
				@include flex(row, felx-start, flex-start);
				border: 1px solid $black;
				border-radius: 5px;
				margin-bottom: 4px;

				&__item {
					@include flex(row, center, center);
					width: 50%;
					height: 36px;

					&.-active {
						background: $black;
						color: $white;
					}
				}
			}

			&-form {
				@include flex(column, flex-start, flex-star);
				margin-top: 50px;

				&:first-child {
					margin-top: 54px !important;
				}

				&__item {
					width: 100%;
					height: 24px;
					position: relative;
					text-align: left;
					

					span {
						@include font(24, 22, normal);
						text-align: left;
						pointer-events: none;
						position:absolute;
						left:0;
						top:0;
						transition: 0.2s;
						transition-timing-function: ease;
						transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
						display: block;
						width: 100%;
						color: rgb(142, 142, 147);
					}
					input {
						width: 100%;
						background: $white;
						border: none;
						outline: none;
						height: 100%;
						@include font(20, 22, normal);
						color: $black;
						
						&:focus + .hotel-registration__body-form__item-span,
						&:not(:placeholder-shown) + .hotel-registration__body-form__item-span {
							transform: translateY(calc(-100% - 5px));
							@include font(13);
						}
					}

					&.-button {
						height: 48px;
						@include font(18, 22, 500);
						color: rgb(142, 142, 147);
						letter-spacing: -0.43px;
						text-transform: uppercase;
						border: 1px solid rgb(142, 142, 147);
						border-radius: 5px;
						@include flex(row, center, center);
						pointer-events: none;
						user-select: none;

						&.-active {
							color: $white;
							border: none;
							background: rgb(84, 187, 117);
							pointer-events: all;
						}
					}
				}

				&__divider {
					width: 100%;
					height: 1px;
					background: rgb(98, 98, 99);
					margin-top: 10px;
					// margin-bottom: 38px;
				}

				&__error {
					@include font(12, 14, normal);
					color: rgb(169, 25, 25);
					margin-top: 2.5px;
				}

				&__password-toggle {
					overflow: hidden;
					position: absolute;
					top: 50%;
					right: -12px;
					margin: 0;
					padding: 0;
					width: 48px;
					height: 48px;
					border: none;
					transform: translateY(-50%);
					background-color: transparent;
					background-image: url('/img/show-password.png');
					background-size: 24px;
					background-repeat: no-repeat;
					background-position: center;
					text-indent: -999px;
					
					@include retinaBackgroundImage('/img/show-password@2x.png');

					&.-active {
						background-image: url('/img/hide-password.png');

						@include retinaBackgroundImage('/img/hide-password@2x.png');
					}
				}
			}
		}

		&__bottom {
			width: 100%;
			height: 32px;
			@include pos-centered-h;
			bottom: 40px;
			@include font(20, 32, normal);
		}
	}
</style>