export const SPB_RESTRAUTNS_IDS = [
  '96661954-f0be-491c-848e-82210b42029b',
  'f475a25a-5746-4c3d-8c2e-215c235b46a3',
  '38e2a9c4-6fa7-41a9-bc7f-1818977836bb',
  'b679ceef-494f-4362-a768-fb21d838a506',
  '4c35c828-e18f-44e8-bf7b-ba431c9c3ed2',
  '432361ff-07f9-448c-b081-eefdc5bd5ad5',
  '2890d23c-925a-4557-b117-59a142aa7f88',
  'db56f126-65ee-47a4-a069-e6d1b34e7aa6',
  '0fc911d8-8025-4608-b035-29f8d173621a',
  '1724fa63-30db-40e4-95f4-41ab049843ab',
  'de9b46b9-1c60-40c9-8e0a-3e6e1f845227',
  '7679fe1b-1e83-40a2-ae64-0e4c92d7b241',
  'acabd4d4-2126-4736-8af3-566685fd0b77',
  '35a3ca3d-4285-4674-b566-ad34b33f4f42',
  '65e6a504-56cc-4881-946d-cb611872d3a7',
  '3f6498ca-ef84-4708-b6af-ab3269bc4715',
  '7e67d050-8522-4509-aad2-dd9114491a1d',
  '29677bc6-832d-4b9b-b7df-71e97560f439',
  '0828ad75-63c3-4a39-a5de-519a495f7323',
  'abdce6dc-7546-4236-a58f-fd4317d434db',
  'abdce6dc-7546-4236-a58f-fd4317d434db',
  '077ebd04-7b1d-41db-a272-828861074258',
  '94e9a634-06cc-4216-85bc-c63dd2078bbd',
  '0ce6e859-c5ed-4339-b770-60e1d9f25f3e',
  'e2c07014-c2e3-48f8-a468-f572d7863c5e',
  'c146bc08-fb10-4961-b70a-0715ab2edbfc',
  '7f50a80b-483c-4f68-a28f-f6d407a812ad',
  'd52c14ee-ac06-4f28-ae0d-b22b6bf7d44d',
  '86381a99-0f7f-49c4-8674-2af8584f097f',
  '911333d3-7c91-4ee2-97e1-a1ad94762bb9',
  '4666330f-4f31-4f1d-896b-bb3b0682e43c',
  'c2896036-4a3c-4c92-98bc-24708b86a8c5',
  '47acc97a-2ffb-4bb9-bdec-75f0da19ad78',
  '1ae9b131-657d-41d5-93e2-8ec496fe3cb0',
  'c5852328-28a7-455b-bd5f-8357b78a4e5a',
  '2424567d-c18b-4596-b089-3ff6b0fe9a4c',
  'd77bc06e-ef12-47c8-a87a-6288efecd45a',
  '2083f991-bf31-4ab8-a2e7-f469efd70463',
  '454fee55-49ed-4bcb-a636-2858325d5cac',
  'fce6d07e-f44d-470b-9f3a-b32d74c819f7',
  '12183570-65e6-4289-b502-1a8a8419350f',
  '0eea48e7-a215-4aca-ad04-d43c300b264d',
  '33479ccd-e475-4625-a299-9db3674f78ec',
  '067208e3-3d3b-4bb7-8e5b-367348f2cf86',
  '8bfaa2c6-72b5-4284-81ec-590a2cee3039',
  'dd45bf35-84aa-4337-bd2b-d7ae6ff530fa',
  '5d494014-5dad-4914-a40d-ac54211ca050',
  'cd89369d-6266-45b6-9fab-3353d908b3e4',
  '2a45ff71-96a7-436b-955c-1b20a69960c5',
  'a6d08cb6-9827-4c52-a5d0-347cc1b85a90',
  'ef7097eb-0ae0-4ef4-b1c6-16d8adfece3e',
  'ebf6b0c6-4df5-41da-be0f-32eda6b530c0',
  '2db0bcae-f71e-4dcd-91ec-79f77a1256dd',
  '225c886a-cd63-4de0-b5f6-7ef235a17a6a',
  '65019d59-db11-4f4e-b391-f9f6b0c546c2',
  'ebc2a32a-e6ea-474b-a61f-39712d039ea9',
  'ee4a70fd-68d9-4b3c-97e6-a4713fb2bc4f',
  'fa2e46bc-984c-4729-885a-b34190470e75',
  'fc6f9fbb-7d8d-4557-9bdd-35fb275dea92',
  'e03bf441-6a7e-44fa-9de8-f85e064952cd',
  '1aa84ffa-98e3-4104-bcc1-c1a42d0695bf',
  'caa4a907-120f-4f56-bc62-cae7d1853b90',
  '823bf5b0-1aa9-4cc7-8f32-1261681e5ee7',
  'b5b9fa3c-a9bd-43a7-ac2f-e58819a33629',
  'eba1f83d-e4da-4c5e-81dc-8c25d0471c21',
  'ec1bcec7-6ff8-4c8a-b3a9-59e7ed622773',
  '94ea5840-dc70-4ff4-bfdf-bf86fb91463b',
  '4f54712b-a233-4b9c-9ac3-85e55f808466',
  '8f0482aa-97b8-477b-bb3c-a5b2838946c9',
  '33e6eebe-5a68-4c26-8e90-f0227fc20c66',
  'fc13fce0-f14d-4bf4-b351-119fba3d9af8',
  '55594720-60e7-4719-a0f7-a5364b524818',
  '6fd0b53d-88b7-4365-adf8-77d04ba637cf',
  '959d7917-8857-4e05-a5b7-281207707ecc',
  'd01af7bc-a5d8-476a-9da5-a5fb67e4777f',
  '3565526f-bf25-4c24-8978-07a9e93112f2',
  '99e46d0c-9314-46fc-a1fa-cd4eab37f430',
  'bdd38043-2dc0-4857-b4f7-c86101a53f85',
  '92ec6e21-2239-4d62-b0c8-d6fa99cdd893',
  '0221fa26-36d0-4f15-94bd-f00359c34fde',
  '35dea7f1-9f20-4a99-bcbc-fbda3c56644c',
  '52a3c8a9-a8e1-491c-b7f7-d7d6a3f1ed1c',
  'f9034687-82d3-4554-8002-9ab0234a72b6',
  '0e1ce359-91df-4d27-af0c-52364cf3bc3e',
  'd62cb0af-3f85-4c1d-bb82-4b4778306173',
  '5671f2de-a409-4279-8b8a-08faf5c19749',
  '57bb5a90-69b7-4668-9bc9-b715e49e5098',
  'aa65ae97-b2bb-4532-9ee2-a96f490a57b8',
  '1965ec56-f808-42d8-82f4-8bf78b06a730',
  'c6858f20-0da8-44cf-852a-8b93ae87d9df',
  'c16db751-0559-4c53-9cf9-fb099fb470b0',
  'dbf9fb2d-d2b6-4560-82d8-c38f9cf97464',
  '88e418df-47bc-4a14-911d-6843035ca204',
  '54eb89c5-a2cf-48c2-9da2-4b880a08e447',
  'd6f67aae-afc2-403f-91f7-54736c718c4d',
  '90d197b2-fd9f-45d4-ac1d-cf974dede6cc',
  'ddf81008-3248-461c-a36a-ec5c7c0ba0c4',
  '12497c06-ede8-447c-835f-7882e30f5d54',
  'c13f69fc-16c5-410a-b3f3-50b462956cd4',
  '25059441-62a2-4e6f-bda9-f4cb619b15d9',
  'eb20ea5b-f13d-4f5c-aa8d-e12f521bb2ed',
  '16c822e1-0bfd-4117-a2a2-a46b3f655d31',
  'c3972b49-1348-437d-8010-0260393870e2',
  '1c503fa1-4e5d-4ba1-a3f3-2a9aa5bb4be4',
  '8140a809-7897-4106-a44b-47e424665523',
  '968299f9-68b1-49a9-bfaa-2d42460b487a',
  'f7d8b4ab-8fec-4f18-91ac-4904866cb22f',
  '84547f4f-6863-4d06-a8bc-b34097840315',
  '81624822-0fc8-413d-a74e-f5d3b373411e',
  '63f613f9-0082-4016-ac82-7b85032efb48',
  '51975baf-7910-4a50-a5fe-305baacfd1f9',
  'd8b0fa2b-ff07-452e-b9d5-49465c153611',
  'c04bc0b2-0a61-46a6-91f4-08068bfb0902',
  '94079547-e18c-488d-ba78-eb6c3a3df45d',
  '88ffb616-f280-48f0-a4c5-39ce345f0c69',
  'cca383a1-0b99-4f92-b8d9-f12fbfff4a36',
  '53e9910a-24e3-46a3-bf97-7eefe11535ff',
  '3a89c765-e586-4269-948e-c41ce1f3020c',
  'a98afac9-543e-46f9-896e-8a6ab1e37b08',
  '366ab183-ec36-4d59-804e-ac73c7d1463a',
  'c57afcc2-e752-4d09-b0dc-279606d94e6e',
  '8ce6af8e-22cd-41ab-a89b-6ed169f3c94e',
  '10ea405a-60ca-4868-a1fe-70286b499980',
  'd3b432d8-bf1e-4625-94f3-44c14f215c0f',
  'bf1c1f1c-e0b4-4f5d-b7cd-42b2b91fcf1b',
  '77910b02-597b-4696-803c-14edc5c129dd',
  'c9f0d282-13da-481d-8c12-8302298a5aff',
  '12bb7f97-7056-4886-85fa-6a93e3900e8a',
  'a1b899db-3d06-4c2f-a5e0-6342d0e4aef2',
  '0f9fa69b-a03b-4d8b-87ce-b9f669aeb4a5',
  '0307eae7-676c-457d-93fb-54523babfa47',
  'ea0efeb1-4e7c-4d2b-ae1d-3f00132e1987',
  'd4c14e9f-1bab-487b-8d2e-7e0ff1d00a7a',
  '24b38215-99ba-4afa-908d-3b56e5532648',
  'bd325a5f-cb4d-48f8-abe0-c480517b040b',
  '83a0861f-5118-418c-9c18-12e2ae388bd7',
  '240b0696-0cb3-4b54-8703-7b46eee2eb2e',
  '23173b12-7315-4d13-b788-a45c6ae3e370',
  '2dd67953-6782-4565-9ce1-03d832defee7',
  '9c47bd79-aaf4-4f1d-b6be-6ffbd9d41fe2',
  '96ed0b51-da96-4f8f-8765-e047dc635ada',
  '3a244851-12d9-4d6f-ba7f-1b3b108cd0a9',
  'e00c18b3-d5e5-4fef-88f6-c71b069099fe',
  '236a3bf2-92f1-4441-b71c-f7f5b40d10f1',
]
