<template>
	<div class="hip-wrapper" @click="$emit('openClose', false)">
		<div class="hip" @click.stop="">
			<div
				v-if="isReserveSupport"
				class="hip-reserve" 
				:class="{'-no-images': !copyItem.images.length}"
				@click="openReserveModal()"
			>
				<img src="/img/reserve-color.svg" />
				RESERVE
			</div>
			<div class="hip-clip"></div>
			<div class="hip-images" v-if="copyItem.images.length" @click.prevent="$emit('openPlace')">
				<Loader
					center
					v-if="loaderImages"
				/>
				<hooper :itemsToShow="1" :centerMode=" true" pagination="no" :trimWhiteSpace="true">
					<slide v-for="(image, index) in copyItem.images" :key="`${index}-${image}`">
						<img
							:src="image"
							width="100%"
							height="182px"
							@load="loadedImages(index)"
						>
					</slide>
				</hooper>
			</div>
			<div class="hip-header" @click.prevent="$emit('openPlace')">
				<div class="hip-header__name">
					{{ copyItem.name }}
				</div>
				<div class="hip-header__address">
					{{ copyItem.short_description }}
				</div>
				<div class="hip-header__close" @click.stop="$emit('openClose', false)" >
					<img
						width="30px"
						height="30px"
						src="/img/buttonClose.png"
						srcset="/img/buttonClose@3x.png 3x /img/buttonClose@2x.png 2x /img/buttonClose.png 1x"
					>
				</div>
			</div>
			<div class="hip-row -delivery" v-if="isDeliverySupported">
				<div class="itemview-topbuttons__button-deliverybtn" @click.prevent="openMenu(true)">Delivery</div>
			</div>
			<div class="hip-row -checkins" v-if="showCheckins">
				<div class="no-checkins" v-if="!fetching && !checkins.length">
					{{ $t('homepreview.noAnkets') }}
				</div>
				<template v-else>
					<Loader
						center
						v-show="loaderCheckIns"
					/>
					<hooper :itemsToShow="4.5" :centerMode="false" pagination="yes">
						<slide v-for="(checkin, idx) in checkins" :key="checkin.uid">
							<img
								:src="getUserImage(checkin)"
								width="74px"
								height="160px"
								@load="loadedcheckinImg(idx)"
								@click="clickUser(checkin)"
							>
						</slide>
					</hooper>
				</template>
			</div>
			<div class="hip-row -navigation">
				<a class="hip-row__col" :href="`https://maps.google.com/maps?q=${copyItem.queryPlace}`" target="_blank">
					<img src="/img/iconNav.svg" alt="" width="48px" height="48px">
					<span v-if="copyItem.distanceInKm">{{ copyItem.distanceInKm }} km</span>
				</a>
				<div class="hip-row__col -right">
					{{ workStatus }}
				</div>
			</div>
			<div class="hip-row">
				<a class="hip-row__col" :href="`tel:${copyItem.phone}`">
					<img src="/img/iconCall.svg" alt="" width="48px" height="48px">
				</a>
				<div class="hip-row__col -middle">
					<div class="hip-menu-button" @click.prevent="openMenu()">
						MENU
					</div>
				</div>
				<div class="hip-row__col -right">
					<img
						width="48px" height="48px"
						src="/img/iconChatsRequestsCopy7.svg"
						@click="showCheckins = !showCheckins"
					>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'
import openReserveMobileMixin from '@/mixins/openReserveMobileMixin'

export default {
    name: 'HomeItemPreview',
	mixins: [openReserveMobileMixin], 
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    components: {
        Hooper,
        Slide
    },
    data () {
        return {
            open: true,
            checkins: [],
            users: {},
            showCheckins: false,
            loaderCheckIns: true,
            loaderImages: true,
            fetching: true,
			item: null
        }
    },
    computed: {
        isDeliverySupported () {
            const item = this.item
            return item?.supported_flows?.delivery
        },
		copyItem () {
			const item = this.item
			return {
				images: (item?.photo_uris || []).map(i => i.url),
				name: item?.name || '',
				short_description: item?.short_description || '',
				distanceInKm: item?.distanceInKm,
				phone: item?.phone,
				working_hours: item?.working_hours,
				queryPlace: (item?.address || '').replace(/\,| /ig, '+')
			}
		},
        workStatus () {
            const working_hours = this.copyItem.working_hours
            if (!Array.isArray(working_hours)) return working_hours
            const now = new Date()
            const dayOfWeek = now.getDay()
            const todayHours = (working_hours[dayOfWeek] || '').replace('–', '-')
					
            if (todayHours) {
                try {
                    const [open, close] = todayHours.split('-')
                    const [openHH, openMM] = open.split(':')
                    let [closeHH, closeMM] = close.split(':')

                    if (close === '00:00') {
                        closeHH = 23
                        closeMM = 59
                    }
                    const openTime = new Date()
                    const closeTime = new Date()
                    const nowMs = now.getTime()
                    openTime.setHours(Number(openHH), Number(openMM), 0, 0)
                    closeTime.setHours(Number(closeHH), Number(closeMM), 0, 0)
                    if (closeTime > nowMs && openTime < nowMs) {
                        // открыто
                        return `${this.$t('homepreview.openTo')} ${close}`
                    } else {
                        return `${this.$t('homepreview.closedTo')} ${open}`
                    }
                } catch (e) {
                    console.log('Коряво заполнены часы работы: ', working_hours)
                }
                return todayHours
            }
				
            return Array.isArray(working_hours) ? working_hours[0] : working_hours
        },
        user () {
            return this.$store.state.user.user
        },
		isReserveSupport() {
			return this.item?.reservation_settings?.enabled
		}
    },
    watch: {
        'uid': {
            immediate: true,
            handler: 'getCheckIns'
        }
    },
    methods: {
		prePickAddress (currentItem) {
            return new Promise((resolve) => {
				this.$bus.$off('PreAddressClose')
                this.$bus.$on('PreAddressClose', (succes) => {
					this.$bus.$off('PreAddressClose')
                    resolve(succes)
                })
                this.$bus.$emit('PreAddressOpen', currentItem)
            })
        },
        pickAddress (currentItem) {
            return new Promise((resolve) => {
				this.$bus.$off('AddresPickerClose')
                this.$bus.$on('AddresPickerClose', (succes) => {
					this.$bus.$off('AddresPickerClose')
                    resolve(succes)
                })
                this.$bus.$emit('AddresPickerOpen', currentItem)
            })
        },
        openMenu: async function (delivery = false) {
			
            const currentItem = this.item
            const uid = currentItem.uid
            const languages = currentItem?.languages || []

			/**
			 * related
			 */
			const related_places_uids = currentItem.related_places_uids || []
            if (!delivery && related_places_uids.length) {
                this.$emit('openPlace', { openRelated: true })
            } else {
				let lang = ''
				if (languages && languages.length > 1) {
					try {
						lang = await this.$root.langModal.show(languages)
					} catch (e) {
						console.log(e)
					}
					if (lang) {
						localStorage.setItem('langKey:' + uid, lang)
						this.$emit('openPlace', { delivery, menu: !delivery })
					}
				} else {
					this.$emit('openPlace', { delivery, menu: !delivery })
				}
			}
        },
        getUsers: async function (uids, users = []) {
            const chunk = uids.splice(0, 10)
            if (!chunk.length) {
                return users
            }
            const usersByChunk = (await this.$DB.db.collection('users').where('uid', 'in', chunk).get()).docs.map(doc => doc.data())
            users.push(...usersByChunk)
            return this.getUsers(uids, users)
        },
        getCheckIns: async function (uid) {
            if (!uid) return
			await this.$PS.updatePlaces(uid)
			const item = this.$PS.getByUid(uid)
			this.$set(this, 'item', item)
            const checkins = (await this.$DB.db.collection('chat_checkins').where('place_uids', 'array-contains', uid).get()).docs.map(doc => doc.data())
            const users = ((await this.getUsers(checkins.map(ch => ch.user_uid))) || [])
            // console.log('users', users)
                .reduce((memo, user) => {
                    if (user.profile_image_url) {
                        memo[user.uid] = user
                    }
                    return memo
                }, {})
            this.$set(this, 'users', users)
            this.checkins = checkins.filter(ch => users[ch.user_uid])
            this.fetching = false
        },
        getUserImage (checkin) {
            return this.users[checkin.user_uid].profile_image_url
        },
        loadedcheckinImg (index) {
            if (index === 0) {
                this.loaderCheckIns = false
            }
        },
        loadedImages (index) {
            if (index === 0) {
                this.loaderImages = false
            }
        },
        clickUser (checkin) {
            const user = this.user
            if (user && user.uid && !user.isAnonymous) {
                // const platform = this.detectMob()
					
            } else {
                const actions = [
                    {
                        id: 'ok',
                        text: this.$t('homepreview.registration')
                    },
                    {
                        id: 'cancel',
                        text: this.$t('cancel')
                    }
                ]
                this.$bus.$emit('openContextMenu', {
                    config: {
                        type: 'modal',
                        style: 'pink',
                        title: this.$t('homepreview.fillAllFields'),
                        subtitle: this.$t('homepreview.viewAnkets'),
                        icon: '/img/iconProfiModal.svg'
                    },
                    actions,
                    resolve: (id) => {
                        if (id === 0) {
                            this.$bus.$emit('openCloseAuth', { open: true })
                        }
                    },
                    reject: () => ({})
                })
            }
        },
    }
}
</script>
<style lang="scss">
	.hip-wrapper {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		background: rgba(0, 0, 0, 0.6);
	}
	.hip {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 16px 22px 35px 22px;
		background-color: rgba(28, 28, 30, 0.95);
		@include flex(column, flex-start, flex-start);
		color: $white;
		text-align: left;
		border-radius: 10px 10px 0 0;
		// position: relative;

		&-clip {
			width: 37px;
			height: 5px;
			@include pos-centered-h;
			background: rgb(101, 101, 105);
			border-radius: 2.5px;
			top: 5px;
		}

		&-images {
			width: 100%;
			height: 182px;
			position: absolute;
			top: -172px;
			left: 0;
			z-index: -1;
			border-radius: 10px 10px 0 0;
			overflow: hidden;
			background-color: rgba(28, 28, 30, 0.2);

			img {
				object-fit: cover;
			}

			.hooper {
				background-color: rgba(28, 28, 30, 0.3);
			}
		}
		
		&-header {
			width: calc(100% - 40px);

			&__name {
				@include font(24, 24, 500);
				@include textTruncate(100%);
			}

			&__address {
				@include font(15, 24, normal);
				@include textTruncate(100%);
			}

			&__close {
				height: 44px;
				width: 44px;
				position: absolute;
				right: 13px	;
				top: 10px;
				
				img {
					@include pos-centered;
				}
			}
		}

		&-row {
			@include flex(row, flex-start, flex-start);
			width: 100%;

			&__col {
				@include font(17, 24, 500);
				@include flex(row, flex-start, center);
				height: 48px;
				width: 33.33%;
				text-decoration: none;
				color: inherit;
				
				&:visited {
					color: inherit;
				}

				&.-right {
					@include flex(row, flex-end, center);
				}
				&.-middle {
					@include flex(row, center, center);
				}

				.hip-menu-button {
					height: 40px;
					width: 107px;
					background-color: $goldenMain;
					border-radius: 20px;
					@include flex(row, center, center);
					@include font(17, 40, 600);
				}
			}

			&.-checkins {
				height: 172px;
				position: relative;
				@include font(20, 24, 500);
				
				.no-checkins {
					@include pos-centered;
					width: 250px;
					text-align: center;
				}

				.hooper-slide {
					&:not(:first-child) {
						margin-left: 4px !important;
					}
				}
				img {
					object-fit: cover;
					border-radius: 5px;
				}
				
			}

			&.-delivery {
				@include flex(row, center, center);
			}

			margin-top: 36px;

			&.-navigation {
				margin-top: 24px;

				.hip-row__col {
					width: 50%;

					span {
						margin-left: 12px;
					}
				}
				
			}
		}

		&-reserve {
			box-sizing: border-box;
			@include pos-centered-h;
			@include flex(row, flex-start, center);
			@include font(17, 20.5, 400);
			padding: 0 20px;
			width: 136px;
			height: 40px;
			top: -230px;
			z-index: -1;

			background: rgba(28, 28, 30, 0.95);
			backdrop-filter: blur(13.5px);
			/* Note: backdrop-filter has minimal browser support */

			border-radius: 24px;

			&.-no-images {
				top: -55px;
			}

			img {
				width: 24px;
				height: 24px;
				margin-right: 4px;
			}
		}
	}
</style>
