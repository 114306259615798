import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const env = process.env
// firebase init - add your own config here
const firebaseConfig = {
  apiKey: env.VUE_APP_FIREBASE_API_KEY,
  authDomain: env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.VUE_APP_FIREBASE_APP_ID
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth

  
// collection references
const menuItems = db.collection('places')
// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  menuItems
//   usersCollection,
//   postsCollection,
//   commentsCollection,
//   likesCollection
}