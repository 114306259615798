<template funtional>
  <div class="cards-block__list-button">
    <div class="cards-block__list-button__icon">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71572 23.2843 0 15 0C6.71573 0 0 6.71572 0 15ZM5.96387 15.6852V14.315H14.3164V5.96265H15.6866V14.315H24.0391V15.6852H15.6866V24.0379H14.3164V15.6852H5.96387Z"
          fill="black"
          fill-opacity="0.2"
        />
      </svg>
    </div>
    <div class="cards-block__list-button__text">
      {{ $parent.$t('MG-703-003') }}
    </div>
  </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.cards-block__list-button {
  @include flex(row, flex-start, center);
  background: #ededed;
  border-radius: 5px;
  color: $black;
  height: 48px;
  width: 100%;

  &__icon {
    @include flex(column, center, center);
    width: 76px;
  }

  &__text {
    @include font(15, 20, 400);
    margin-right: 16px;
    margin-left: 9px;
    text-align: left;
  }
}
</style>
