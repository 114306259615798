class Places {
    constructor({ DB, maps }) {
        this.DB = DB;
        this.maps = maps;
        this.userGeo = null;
        this.data = [];
        this.hashMap = new Map();
        this.place = null;
    }

    setUserGeo(userGeo) {
        this.userGeo = userGeo;
        this.placesDistanced(this.data, userGeo, "setUGeo");
    }

    setGMaps(maps) {
        this.maps = maps;
        this.placesDistanced(this.data, this.userGeo, "setGMaps");
    }

    getByUid(uid) {
        return this.data.find((i) => i.uid === uid);
    }

    getByUids(uids = []) {
        return uids.reduce((memo, uid) => {
            const place = this.getByUid(uid);
            if (place) {
                memo.push(place);
            }
            return memo;
        }, []);
    }

    placesDistanced(data, uGeo) {
        if (!uGeo) return data
        
        const out = data
            .map((place) => {
                const distance = this.#distance(uGeo.lat, uGeo.lng, place.geo_point.latitude, place.geo_point.longitude)
                place.distanceInKm = Math.floor(distance);
                place.distance = distance * 1000;
                return place;
            })
            .sort((a, b) => {
                const [distanceA, distanceB] = [a.distance, b.distance];
                return distanceA - distanceB;
            });
        return out;
    }

    async getShortPlaces() {
        const resp = (
            await this.DB.db.collection("short_places").get()
        ).docs.map((doc) => {
            const item = doc.data();
            let _search = (
                (item.name || "") + (item.lat_name || "")
            ).toLowerCase();
            if (~_search.indexOf("ё")) {
                _search += " " + _search.replace(/ё/g, "е");
            }
            if (~_search.indexOf("е")) {
                _search += " " + _search.replace(/е/g, "ё");
            }
            item._search = _search;
            return this.getDefault(item);
        });
        const _sorted = this.placesDistanced(
            resp,
            this.userGeo,
            "getShortPlaces"
        );
        this.data.push(..._sorted);
        return true;
    }

    async batchPlaces(place_uids = []) {
        const out = [];
        const uids = place_uids.slice();
        while (place_uids.length) {
            const _uidsTen = uids.splice(0, 10);
            if (!_uidsTen.length) break;
            const resp = await this.DB.db
                .collection("places")
                .where("uid", "in", _uidsTen)
                .get();
            out.push(...resp.docs);
        }
        return out;
    }

    async initOnePlace(uid) {
        const resp = await this.DB.db
            .collection("places")
            .where("uid", "==", uid)
            .get();
        const [place] = resp.docs.map((doc) => doc.data());
        if (place) {
            this.data.push(place);
            return true;
        }
        return false;
    }

    async updatePlaces(place_uids = []) {
        if (typeof place_uids === "string") {
            place_uids = [place_uids];
        }
        const hasPlaces = new Map();
        let i = place_uids.length - 1;
        while (i >= 0) {
            const uid = place_uids[i];
            const place = this.getByUid(uid);
            hasPlaces.set(uid, place);
            if (place.full) {
                place_uids.splice(i, 1);
            }
            i--;
        }
        const places = await this.batchPlaces(place_uids);
        places.forEach((doc) => {
            const newData = doc.data();
            const place = hasPlaces.get(newData.uid);
            newData.full = true;
            Object.assign(place, newData);
        });
        return true;
    }

    getDefault(item) {
        const defaultItem = {
            address: "",
            city: "",
            country: "",
            currency: "",
            description: "",
            distance: 0,
            distanceInKm: "",
            g: "",
            geo_point: { latitude: 0, longitude: 0 },
            has_best_dishes: false,
            has_menu: true,
            is_drinks_filled: true,
            is_list_menu_available: true,
            is_list_menu_hidden: false,
            is_swipe_menu_available_new: true,
            is_tile_menu_available_new: true,
            l: [0, 0],
            languages: ["rus"],
            lat_name: "",
            menu_updated_at: { seconds: 0, nanoseconds: 0 },
            name: "",
            phone: "",
            phones_for_tips: { kitchen_phone: "", administrator_phone: "" },
            photo_uris: [],
            radius: 0,
            service_fee_fix: "0",
            service_fee_percent: "0",
            short_description: "",
            supported_flows: {
                chats: false,
                cloud_tips: false,
                comments: true,
                delivery: false,
                orders: false,
                payments: false,
                room_balance: false
            },
            take_away_discount: "0",
            taxes: {},
            type: "",
            uid: "",
            urls_for_tips: { administrator_url: "", kitchen_url: "" },
            video_uri: "",
            working_hours: [],
            _search: "",
            full: false,
        };
        for (const key in defaultItem) {
            const value = item[key];
            if (!value) {
                item[key] = defaultItem[key];
            }
        }
        return item;
    }

    getSupportedFlowsByUid(uid) {
        if (!uid) return {};
        const place = this.data.find((p) => p.uid === uid);
        if (place) {
            return place.supported_flows || {};
        }
        return {};
    }

    #distance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Радиус Земли в километрах
        const dLat = this.#toRadians(lat2 - lat1); // Разница широт в радианах
        const dLon = this.#toRadians(lon2 - lon1); // Разница долгот в радианах

        // Кэшируем значения тригонометрических функций
        const sinLat = Math.sin(dLat/2);
        const sinLon = Math.sin(dLon/2);
        const cosLat1 = Math.cos(this.#toRadians(lat1));
        const cosLat2 = Math.cos(this.#toRadians(lat2));
        const a = sinLat * sinLat + cosLat1 * cosLat2 * sinLon * sinLon;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return R * c;
        }

    #toRadians(degrees) {
        return degrees * Math.PI / 180;
    }
}
export default Places;

