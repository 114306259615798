<template>
	<div class="p-view">
		<img 
			src="/img/backWhite.png" 
			srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
			class="back-icon"
			@click="$router.go(-1)"
		/>
		<DynamicScroller
			:items="comments"
			key-field="uid"
			:min-item-size="520"
			class="scroller p-view__list"
			ref="scroller"
		>
			<template v-slot="{ item, index, active }">
				<DynamicScrollerItem
					:item="item"
					:active="active"
					:data-index="index"
				>
					<PostItem 
						:post="item"
						:key="item.uid"
						v-if="item.uid"
					/>
					<div style="width: 100%; height: 65px;" v-else></div>
				</DynamicScrollerItem>
			</template>
		</DynamicScroller>
	</div>
</template>

<script>
	import PostItem from '@/components/PostItem.vue'

	export default {
		name: 'PostsView',
		components: {
			PostItem
		},
		props: {
			dishUid: {
				type: String
			},
			userUid: {
				type: String
			}
		},
		data() {
			return {
				comments: [],
				users: {

				}
			}
		},
		watch: {
			dishUid: {
				immediate: true,
				handler: 'getComments'
			}
		},
		methods: {
			getComments: async function(uid) {
				this.comments = []
				const {dishUid, userUid} = this
				const where = (dishUid) ? 'item_uid' : 'user_uid',
					_uid = (dishUid) ? dishUid : userUid,
					dishes_uids = new Set(),
					places_uids = new Set()
				if (!_uid) return
				let comments = (await this.$DB.db.collection('comments').where(where, '==', _uid).where('moderation_status', 'in', ['approved', 'waiting_for']).get()).docs.map(doc => {
					const comment = Object.freeze(doc.data()),
						likers = comment.likers || []
					dishes_uids.add(comment.item_uid)
					places_uids.add(comment.place_uid)

					return {
						...comment,
						likersCount: likers.length,
						likers: likers
					}
				})

				comments.sort((a,b) => {
					
					const [aName, bName] = [a?.created_at.seconds || 0, b?.created_at.seconds || 0]
					if (aName < bName) {
						return 1
					} else {
						return -1
					}
				})
				this.comments = [{uid: ''}, ...comments]
				setTimeout(()=>{
					const index = this.$route.query.index
					if (index) {
						this.$refs.scroller.scrollToItem(index)
					}
				}, 250)
			}
	
		}
	}
</script>

<style lang="scss">
	.p-view {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 105;
		background: $black;

		.back-icon {
			left: 20px;
    		top: 20px;
			position: absolute;
			height: 30px;
			width: 30px;
			z-index: 1;
		}

		&__list {
			height: 100%;
			width: 100%;
			// overflow-x: hidden;
			overflow-y: scroll;
		}

		// .vue-recycle-scroller__item-view:first-child {
		// 	.post-item {
		// 		padding-top: 64px;
		// 	}
		// }
	}
</style>