import { currentApplicationUrl } from '@/utils'

export default {
    methods: {
        openReserveModal() {
			const downloadUrl = currentApplicationUrl
			const actions = [
                {
                    id: "ok",
                    text: this.$t("reservation_download_app.button1"),
                },
            ];
            if (downloadUrl) {
                actions.unshift({
                    id: "download",
                    text: this.$t("reservation_download_app.button0"),
                    url: downloadUrl
                });
            }
            this.$bus.$emit("openContextMenu", {
                config: {
                    type: "modal",
                    style: "pink",
                    title: this.$t("reservation_download_app.header"),
                    subtitle: this.$t("reservation_download_app.text"),
                    icon: "/img/new_download_app.svg",
                },
                actions,
                resolve: () => ({}),
                reject: () => ({}),
            });
		}
    }
}