<template>
	<div v-if="deleted" class="l-view__list-item">
		<img
			class="l-view__list-item__avatar"
			:src="photo"
			:key="uid"
		/>
		<div class="l-view__list-item__info">
			<div class="l-view__list-item__info-name">{{ name }}</div>
		</div>
	</div>
	<router-link
		v-else
		class="l-view__list-item" 
		:to="`/user/${uid}`"
		tag="div"
	>
		<img
			class="l-view__list-item__avatar"
			v-if="photo"
			:src="photo"
			:key="uid"
		/>
		<img
			class="l-view__list-item__avatar"
			v-else
			src="/img/userNoImage@3x.png"
			key="nofoto"
		/>
		<div class="l-view__list-item__info">
			<div class="l-view__list-item__info-name">
				{{ name }}
			</div>
			<div class="l-view__list-item__info-zodiac" v-if="zodiac">
				<img
					:src="`/img/iconZodiac${zodiac}.svg`"
					:key="zodiac"
					width="16px"
					height="16px"
				/>
				<div class="l-view__list-item__info-zodiac__text">
					{{zodiac}}
				</div>
			</div>
		</div>
		<img
			class="l-view__list-item__right"
			src="/img/iconArrowRightCardDetails.svg"
			key="right"
		/>
	</router-link>
</template>

<script>
import { IdState } from 'vue-virtual-scroller';
import { getZodiac } from '../utils/get-zodiac';

export default {
	mixins: [
		IdState({
			idProp: vm => vm.uid,
		}),
	],
	props: {
		uid: {
			type: String,
			required: true
		}
	},
	watch: {
		doubleWatcher: {
			immediate: true,
			handler: 'getUser'
		}
	},
	idState () {
		return {
			user: null,
			ready: true
		}
	},
	computed: {
		isIdPropReady() {
			return this.idState !== undefined && this.idState !== null
		},
		doubleWatcher() {
			return `${this.isIdPropReady}-${this.uid}`
		},
		photo() {
			return this.idState.user?.profile_image_url
		},
		name() {
			return this.idState.user?.full_name || ''
		},
		zodiac() {
			return this.idState.user?.zodiac
		},
		deleted() {
			return this.idState.user?.deleted
		}
	},
	methods: {
		async getUser () {
			if (!this.isIdPropReady) {
				return;
			}

			if (window.users[this.uid]) {
				this.idState.user = window.users[this.uid];
				return;
			}

			const snapshot = await this.$DB.db.collection('users').doc(this.uid).get();

			if (!snapshot.exists) {
				this.idState.user = {
					deleted: true,
					full_name: 'Account deleted',
					profile_image_url: '/img/game-over.svg',
				};

				return;
			}

			const data = snapshot.data();
			const user = {
				...data,
				zodiac: getZodiac(data.birth_date?.toDate())
			}
			this.idState.user = user;
			window.users[user.uid] = user;
		},
	}
}
</script>