import { APPS_URLS } from '@/consts'

export function detectMobile() {
    const toMatchAndroid = [/Android/i]
    const toMatch = [/Android/i, /iPhone/i, /iPad/i, /iPod/i]
    const userAgent = navigator.userAgent

    const isAndroid = toMatchAndroid.some((toMatchItem) => {
      return userAgent.match(toMatchItem)
    })
    if (isAndroid) return 'google'
    const isIphone = toMatch.some((toMatchItem) => {
      return userAgent.match(toMatchItem)
    })
    if (isIphone) return 'apple'
    return ''
}

export function getApplicationDownloadUrl(platform) {
    return APPS_URLS[platform] || ''
}

export const currentPlatform = detectMobile()
export const currentApplicationUrl = getApplicationDownloadUrl(currentPlatform)