<template>
	<div class="d-l-modal -login" :class="{'-open': open}">
		<div class="d-l-modal__inner">
			<img 
				src="/img/backWhite.png" 
				srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
				class="login-back"
				v-if="state"
				@click="back"
			/>
			<img
				v-else
				src="/img/adminAppIconProfileCopy.svg" 
				class="login-homeicon" 
				@click="open = false"
			>
			<div class="d-l-modal__logo">
				<img 
					src="/img/logo.png" 
					srcset="/img/logo@3x.png 3x /img/logo@2x.png 2x /img/logo.png 1x"
				/>
			</div>
			<!-- initial state -->
			<div class="d-l-modal__email" v-if="email">
				<div class="d-l-modal__email-title">{{ emailTitle }}</div>
				<div class="d-l-modal__email-form">
					<div class="d-l-modal__email-form__item">
						<input type="email" autocomplete="chrome-off" placeholder=" " v-model="loginData.email" />
						<span class="d-l-modal__email-form__item-span">{{ $t('auth.email') }}</span>
					</div>
					<div class="d-l-modal__email-form__divider"></div>
					<div class="d-l-modal__email-form__item" v-if="state !== 'forgotPass'">
						<input :type="showPassword ? 'text' : 'password'" autocomplete="chrome-off" placeholder=" " v-model="loginData.password"/>
						<span class="d-l-modal__email-form__item-span">{{ $t('auth.password') }}</span>
						<button class="d-l-modal__email-form__password-toggle" :class="{'-active': showPassword}" @click="showPassword = !showPassword" type="button">{{ showPassword ? $t('auth.passwordVisibility.hidden'): $t('auth.passwordVisibility.visible') }}</button>
					</div>
					<div class="d-l-modal__email-form__divider" v-if="state !== 'forgotPass'"></div>
					<div class="d-l-modal__email-form__item" v-if="state === 'reg'">
						<input :type="showPassword ? 'text' : 'password'" autocomplete="chrome-off" placeholder=" " v-model="loginData.password2"/>
						<span class="d-l-modal__email-form__item-span">{{ $t('auth.passConfirmation') }}</span>
						<button class="d-l-modal__email-form__password-toggle" :class="{'-active': showPassword}" @click="showPassword = !showPassword" type="button">{{ showPassword ? $t('auth.passwordVisibility.hidden'): $t('auth.passwordVisibility.visible') }}</button>
					</div>
					<div class="d-l-modal__email-form__divider" v-if="state === 'reg'"></div>
				</div>
				<div class="d-l-modal__email-actions">
					<div class="d-l-modal__email-actions__action -error" v-if="errorText">{{ errorText }}</div>
					<div 
						v-if="state === 'login'"
						class="d-l-modal__email-form__item -button" 
						:class="{'-active': loginData.email && loginData.password}"
						@click="authWithEmail"
					>{{ $t('auth.log_in') }}</div>
					<div 
						v-else-if="state === 'reg'"
						class="d-l-modal__email-form__item -button" 
						:class="{'-active': loginData.email && loginData.password && loginData.password2}"
						@click="signUpWithEmail"
					>{{ $t('auth.createAccount') }}</div>
					<div 
						v-else
						class="d-l-modal__email-form__item -button" 
						:class="{'-active': loginData.email}"
						@click="resetPassword"
					>{{ $t('auth.passwordReset') }}</div>
					<div
						v-if="state === 'login'" 
						key="forgotpass"
						class="d-l-modal__email-actions__action -underlined"
						@click="state = 'forgotPass'"
					>
						{{ $t('auth.forgotPassword') }}
					</div>
					<div class="d-l-modal__email-actions__action" v-if="state === 'login'" key="alreadylogin">
						{{ $t('auth.notProfileYet') }} <a href="#" @click.prevent="state = 'reg'">{{ $t('auth.create') }}</a>
					</div>
					<div class="d-l-modal__email-actions__action" v-else-if="state === 'reg'" key="alreadyreg">
						{{ $t('auth.hasAccount') }} <a href="#" @click.prevent="state = 'login'">{{ $t('auth.log_in') }}</a>
					</div>
				</div>
			</div>
			<div v-else-if="!state">
				<div class="d-l-modal__actions -initial">
					<a 
						class="d-l-modal__actions-button -registration"
						@click.prevent="state = 'reg'"
					>
						{{ $t('registration') }}
					</a>
					<div class="d-l-modal__actions__noties">{{ $t('registration_notice') }}</div>
					<div 
						class="d-l-modal__actions-button -inversed -fixed -login" 
						@click="state = 'login'"
					>
						{{ $t('tologin') }}
					</div>
				</div>
			</div>
			<div class="d-l-modal__actions" v-else-if="state === 'login'">
				<a class="d-l-modal__actions-button" @click="authWithGoogle">
					<img 
						src="/img/logoGoogle.png" 
						srcset="/img/logoGoogle@3x.png 3x /img/logoGoogle@2x.png 2x /img/logoGoogle.png 1x"
					/>
					{{ $t('log_in_with_google') }}
				</a>
				<a class="d-l-modal__actions-button" @click="authWithApple">
					<img 
						src="/img/logoApple.png" 
						srcset="/img/logoApple@3x.png 3x /img/logoApple@2x.png 2x /img/logoApple.png 1x"
					/>
					{{ $t('log_in_with_apple') }}
				</a>
				<div 
					class="d-l-modal__actions-button -inversed -fixed" 
					@click="email = true"
				>
					<img 
						src="/img/emailCopy.png" 
						srcset="/img/emailCopy@3x.png 3x /img/emailCopy@2x.png 2x /img/emailCopy.png 1x"
					/>
					{{ $t('log_in_with_email') }}
				</div>
			</div>
			<div class="d-l-modal__actions" v-else>
				<a class="d-l-modal__actions-button" @click="signUpWithGoogle">
					<img 
						src="/img/logoGoogle.png" 
						srcset="/img/logoGoogle@3x.png 3x /img/logoGoogle@2x.png 2x /img/logoGoogle.png 1x"
					/>
					{{ $t('sign_up_google') }}
				</a>
				<a class="d-l-modal__actions-button" @click="signUpWithApple">
					<img 
						src="/img/logoApple.png" 
						srcset="/img/logoApple@3x.png 3x /img/logoApple@2x.png 2x /img/logoApple.png 1x"
					/>
					{{ $t('sign_up_apple') }}
				</a>
				<div 
					class="d-l-modal__actions-button -inversed -fixed" 
					@click="email = true"
				>
					<img 
						src="/img/emailCopy.png" 
						srcset="/img/emailCopy@3x.png 3x /img/emailCopy@2x.png 2x /img/emailCopy.png 1x"
					/>
					{{ $t('sign_up_email') }}
				</div>
				<div class="d-l-modal__email-actions__action -error" v-if="errorText">{{ errorText }}</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'GlobalAuthModal',
	data() {
		return {
			open: false,
			state: '',
			email: false,
			loginData: {
				email: '',
				password: '',
				password2: ''
			},
			errorText: '',
			showPassword: false
		}
	}, 
	computed: {
		termsOfUse() {
			return `https://mayber.com/terms-of-use/${this.$lang}/`
		},
		emailTitle() {
			const state = this.state
			if (state === 'forgotPass') return this.$t('auth.inputEmailForRecoveryPass')

			if (state === 'login') return this.$t('auth.inputEmailAndPass')

			return this.$t('auth.inputEmailAndnewPass')
		}
	},
	watch: {
		state() {
			this.errorText = ''
		}
	},
	mounted() {
		const height = window.innerHeight
		this.$el.style.height = height+'px'
		this.$bus.$on('openCloseAuth', this.openClose)
	},
	methods: {
		back() {
			if (this.state === 'forgotPass') {
				this.state = 'login'
				return
			}
			if (this.email) {
				this.email = false
				this.$set(this, 'loginData', {
					email: '',
					password: '',
					password2: ''
				})
				return
			}
			this.state = ''
		},
		openClose(params={}) {
			const {open} = params || false
			this.email = false
			this.open = open
			this.state = ''
			this.$set(this, 'loginData', {
				email: '',
				password: '',
				password2: ''
			})
		},
		signUpWithEmail() {
			this.errorText = ''
			const {email, password, password2} = this.loginData
			if (password !== password2) {
				this.errorText = this.$t('auth.pswdsNotSimilar')
				return
			} else if (password.length < 6) {
				this.errorText = this.$t('auth.pswdMinLength')
				return
			}
			this.$store.dispatch("places/showLoader", true)
			
			// link email and password to anonimus user
			const credential = this.$DB.auth.EmailAuthProvider.credential(email, password)
			this.$DB.auth().currentUser.linkWithCredential(credential)
				.then((usercred) => {
					this.email = false
					this.open = false
					this.state = ''
					this.$store.dispatch("places/hideLoader", true)
					this.openClose({open: false})
					console.log("Anonymous account successfully upgraded", usercred.user)
				}).catch((error) => {
					this.$store.dispatch("places/hideLoader", true)
					console.log("Error upgrading anonymous account", error)
					var errorCode = error.code;
					var errorMessage = error.message;
					if (errorCode === 'auth/invalid-email') {
						this.errorText = this.$t('auth.invalidEmail')
					} else if (errorCode === 'auth/user-not-found') {
						this.errorText = this.$t('auth.user_not_found')
					} else if (errorCode === 'auth/wrong-password') {
						this.errorText = this.$t('auth.invalidPassword')
					} else if (errorCode === 'auth/email-already-in-use' || (errorCode === 400 && errorMessage === 'EMAIL_EXISTS')) {
						this.errorText = this.$t('auth.emailExist')
					}
					this.$store.dispatch("places/hideLoader", true)
				})

		},
		authWithEmail() {
			this.errorText = ''
			const {email, password} = this.loginData
			this.$DB.auth()
				.signInWithEmailAndPassword(email, password)
				.then((userCredential) => {
					// Signed in
					var user = userCredential.user;
					// ...
					this.email = false
					this.open = false
					this.state = ''
				})
				.catch((error) => {
					var errorCode = error.code;
					var errorMessage = error.message;
					console.log('errorCode',errorCode, 'errorMessage', errorMessage)
					if (errorCode === 'auth/invalid-email') {
						this.errorText = this.$t('auth.invalidEmail')
					} else if (errorCode === 'auth/user-not-found') {
						this.errorText = this.$t('auth.user_not_found')
					} else if (errorCode === 'auth/wrong-password') {
						this.errorText = this.$t('auth.invalidPassword')
					}
				});
		},
		signUpWithGoogle() {
			this.errorText = ''
			this.$store.dispatch("places/showLoader", true)
			const provider = new this.$DB.auth.GoogleAuthProvider()
			this.$DB.auth().currentUser.linkWithPopup(provider)
			.then((usercred) => {
				this.$store.dispatch("places/hideLoader")
				this.openClose({open: false})
				console.log("Anonymous account successfully upgraded", usercred.user)
			}).catch((error) => {
				this.$store.dispatch("places/hideLoader")
				console.log("Error upgrading anonymous account", error)
				if (error.code === 'auth/credential-already-in-use') {
					this.errorText = this.$t('auth.credential-already-in-use')
				}
			})
		},
		authWithGoogle() {
			this.$store.dispatch("places/showLoader", true)
			var provider = new this.$DB.auth.GoogleAuthProvider()
			this.$DB.auth()
				.signInWithPopup(provider)
				.then(() => {
					this.$store.dispatch("places/hideLoader")
				}).catch((error) => {
					console.log('error', error)
					this.$store.dispatch("places/hideLoader")
				})

		},
		signUpWithApple() {
			this.errorText = ''
			this.$store.dispatch("places/showLoader", true)
			const provider = new this.$DB.auth.OAuthProvider('apple.com')
			this.$DB.auth().currentUser.linkWithPopup(provider)
			.then((usercred) => {
				this.$store.dispatch("places/hideLoader")
				this.openClose({open: false})
				console.log("Anonymous account successfully upgraded", usercred.user)
			}).catch((error) => {
				this.$store.dispatch("places/hideLoader")
				console.log("Error upgrading anonymous account", error)
				if (error.code === 'auth/credential-already-in-use') {
					this.errorText = this.$t('auth.credential-already-in-use')
				}
			})
		},
		authWithApple() {
			this.$store.dispatch("places/showLoader", true)
			const provider = new this.$DB.auth.OAuthProvider('apple.com')
			provider.addScope('email')
			provider.addScope('name')
			this.$DB.auth()
				.signInWithPopup(provider)
				.then((result) => {
					this.$store.dispatch("places/hideLoader")
					console.log('result',result)
				}).catch((error) => {
					this.$store.dispatch("places/hideLoader")
					console.log('error',error)
				})
		},
		async resetPassword() {
			this.$store.dispatch("places/showLoader", true)
			let result = true
			try {
				await this.$DB.auth().sendPasswordResetEmail(this.loginData.email)
				this.state = 'login'
			} catch (error) {
				console.log('resetPassword error',error)
				result = false
			}
			this.$bus.$emit('openContextMenu', {
				config: {
					type: 'modal', 
					style: 'pink',
					icon: '/img/warningIcon.svg',
					title: this.$t(`${result ? 'auth.passwordReset' : 'error'}`),
					subtitle: this.$t(`${result ? 'auth.passwordResetSubtitle' : 'auth.passwordResetError'}`)
				},
				actions: [{
					id: 'ok',
					text: 'OK'
				}],
				resolve: () => ({}),
				reject: () => ({})
			})
			this.$store.dispatch("places/hideLoader")
		}
	}
}
</script>

<style lang="scss">
	.d-l-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 103;
		display: none;
		background: $black;
		// background-color: rgba(0, 0, 0, 0.6);
		// backdrop-filter: blur(10px);

		&.-open {
			display: block;
		}

		&__inner {
			overflow-y: scroll;
			padding: 20px 0;
			height: 100%;
			box-sizing: border-box;
		}
		
		&.-login {
			.login-homeicon {
				position: fixed;
				z-index: 102;
				top: 29px;
				right: 29px;
				width: 24px;
				height: 24px;
			}

			.login-back {
				position: fixed;
				z-index: 102;
				width: 24px;
				height: 24px;
				top: 29px;
				left: 16px;
			}

			.d-l-modal__email {
				margin-top: 32px;
				@include font(17, 24, 200);
				color: $white;

				&-form {
					margin: 44px 16px 0 36px;
					@include flex(column, flex-start, flex-star);

					&__item {
						width: 100%;
						height: 24px;
						position: relative;
						text-align: left;
						

						span {
							@include font(20, 22, normal);
							text-align: left;
							pointer-events: none;
							position:absolute;
							left:0;
							top:0;
							transition: 0.2s;
							transition-timing-function: ease;
							transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
							display: block;
							width: 100%;
						}
						input {
							width: 100%;
							background: $black;
							border: none;
							outline: none;
							height: 100%;
							@include font(20, 22, normal);
							color: $white;
							
							&:focus + .d-l-modal__email-form__item-span,
							&:not(:placeholder-shown) + .d-l-modal__email-form__item-span {
								transform: translateY(calc(-100% - 5px));
								@include font(13);
							}
						}

						&.-button {
							height: 48px;
							@include font(18, 22, 500);
							color: rgb(142, 142, 147);
							letter-spacing: -0.43px;
							text-transform: uppercase;
							border: 1px solid rgb(142, 142, 147);
							border-radius: 5px;
							@include flex(row, center, center);
							pointer-events: none;
							user-select: none;

							&.-active {
								color: $white;
								border: none;
								background: rgb(84, 187, 117);
								pointer-events: all;
							}
						}
					}

					&__divider {
						width: 100%;
						height: 1px;
						background: rgb(98, 98, 99);
						margin-top: 10px;
						margin-bottom: 38px;
					}

					&__password-toggle {
						overflow: hidden;
						position: absolute;
						top: 50%;
						right: -12px;
						margin: 0;
						padding: 0;
						width: 48px;
						height: 48px;
						border: none;
						transform: translateY(-50%);
						background-color: transparent;
						background-image: url('/img/show-password.png');
						background-size: 24px;
						background-repeat: no-repeat;
						background-position: center;
						text-indent: -999px;
						
						@include retinaBackgroundImage('/img/show-password@2x.png');

						&.-active {
							background-image: url('/img/hide-password.png');

							@include retinaBackgroundImage('/img/hide-password@2x.png');
						}
					}
				}

				&-actions {
					margin: 0 36px;
					@include flex(column, flex-start, center);
					
					&__action {
						margin-top: 24px;
						@include font(17, 24, 400);

						&.-error {
							margin-top: 0;
							color: red;
						} 

						a {
							color: rgb(0, 145, 255);
							&:visited {
								color: rgb(0, 145, 255);
							}
						}
						
						&.-underlined {
							text-decoration: underline;
						}
					}
				}
			}

			.d-l-modal__actions {
				
				margin: 66px 16px;
				margin-top: 118px;

				&.-initial {
					
				}

				&-button {
					&.-registration {
						background: $goldenMain;
						@include font(17, 24, 500);
						letter-spacing: -0.13px;
						text-transform: uppercase;
						color: $white;
						text-align: center;
						@include flex(row, center, center);
						user-select: none;
					}
				}

				&__noties {
					@include font(15, 18, normal);
					letter-spacing: -0.28px;
					color: rgb(142, 142, 147);
					text-align: left;
				}
			}

			.d-l-modal__logo {
				margin-top: 44px;
			}
		}
		.d-l-modal__actions-button.-inversed {
			background: $black;
			border: 1px solid $white;
			color: $white;

			&.-fixed {
				position: absolute;
				left: 16px;
				right: 16px;
				bottom: 50px;
				width: calc(100% - 32px);

			}

			&.-login {
				@include flex(row, center, center);
			}
		}
	}
</style>