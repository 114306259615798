export default {
    methods: {
        closeModifiers(item) {
            this.showModifiersModal = false;
            if (item) {
                this.mixinAddToOrderHandler(item);
            }
        },
        mixinOpenModifiers() {
            this.showModifiersModal = true;
        },
        mixinAddToOrder(item) {
            sessionStorage.setItem("isFirstOrder", "0");
            return window.ordersStore.addToOrder({
                dish: JSON.parse(JSON.stringify(item)),
                currencySymbol: this.currencySymbol,
                selectedModifiers: this.dishModifiers,
                fromPlaceUid: this.rootPlaceUid,
            });
        },
        mixinAddToOrderHandler(item, closeFn) {
            if (!item.is_active) return;
            const tryToAdd = () => {
                const addError = this.mixinAddToOrder(item);
                if (addError?.error === "has_unpaid_invoice") {
                    this.$bus.$emit(
                        "GOM/invoices/unpaidInvoice",
                        addError.invoice
                    );
                } else if (addError?.error === "checkin_in_another_place") {
                    this.mixinAnotherPlaceCheckinAlert(addError.place);
                } else if (addError?.error === "from_different_flows") {
                    this.mixinFromDifferentFlowsAlert(addError.deliveryFlow);
                } else if (addError === "has_checkin") {
                    this.mixinShowNotClosedCheckinAlert();
                } else if (addError === "modificators_required") {
                    this.dishToModefiers = item;
                    this.modifiers = item.modifiers;
                    this.mixinShowModificatorsAlert();
                } else {
                    this.dishModifiers = [];
                    this.$bus.$emit("alertShow");
                }
                if (typeof closeFn === "function") {
                    closeFn();
                }
            };
            tryToAdd();
        },
        mixinAnotherPlaceCheckinAlert(place) {
            const { name, uid } = place;
            this.$bus.$emit("openContextMenu", {
                config: {
                    style: "pink",
                    noCancel: true,
                },
                actions: [
                    {
                        id: "ok",
                        text: this.$t("order.nowOrderIn") + ": " + name,
                        nonClicked: true,
                    },
                    {
                        id: "goto",
                        text: this.$t("order.goTo") + ": " + name,
                    },
                    {
                        id: "cancel",
                        text: this.$t("toCancelCap"),
                    },
                ],
                resolve: (index) => {
                    if (index === 1) {
                        this.$bus.$emit("GlobalModal/dishesOpen");
                        this.$bus.$emit("Home/drop", () => {
                            this.$bus.$emit("Home/loadMenu", uid);
                        });
                    }
                },
                reject: () => ({}),
            });
        },
        mixinFromDifferentFlowsAlert(deliveryFlow) {
            const erorrSection = deliveryFlow
                ? "FROM_DIFFERENT_FLOWS_ORDERS_PREV"
                : "FROM_DIFFERENT_FLOWS_DELIVERY_PREV";
            this.$bus.$emit("openContextMenu", {
                config: {
                    type: "modal",
                    style: "pink",
                    title: this.$t(`delivery.errors.${erorrSection}.title`),
                    subtitle: this.$t(`delivery.errors.${erorrSection}.text`),
                    icon: "/img/iconAttention.svg",
                },
                actions: [
                    {
                        id: "ok",
                        text: "OK",
                    },
                ],
                resolve: () => ({}),
                reject: () => ({}),
            });
        },
        mixinShowNotClosedCheckinAlert() {
            this.$bus.$emit("openContextMenu", {
                config: {
                    type: "modal",
                    style: "pink",
                    title: this.$t("delivery.errors.HAS_OPEN_CHECKIN.title"),
                    subtitle: this.$t("delivery.errors.HAS_OPEN_CHECKIN.text"),
                    icon: "/img/iconAttention.svg",
                },
                actions: [
                    {
                        id: "ok",
                        text: "OK",
                    },
                ],
                resolve: () => ({}),
                reject: () => ({}),
            });
        },
        mixinShowModificatorsAlert() {
            this.$bus.$emit("openContextMenu", {
                config: {
                    type: "modal",
                    style: "pink",
                    title: this.$t(
                        `delivery.errors.MODIFIERS_REQUIRED_DETAILS.title`
                    ),
                    subtitle: this.$t(
                        `delivery.errors.MODIFIERS_REQUIRED_DETAILS.text`
                    ),
                    icon: "/img/iconModify@3x.png",
                },
                actions: [
                    {
                        id: "ok",
                        text: this.$t("order.toChoose"),
                    },
                    {
                        id: "cancel",
                        text: this.$t("cancel"),
                    },
                ],
                resolve: (index) => {
                    if (index === 0) {
                        this.mixinOpenModifiers();
                    }
                },
                reject: () => ({}),
            });
        },
    },
};
