<template>
  <div class="s-checkin-m" :class="{ '-show': open }">
    <div
      class="s-checkin-m__popover -colored"
      v-if="activeCheckinStatus === 'deferred'"
    >
      <div class="s-checkin-m__popover-main -waiting">
        <img src="/img/recycle-icon.svg" />
        <div class="s-checkin-m__popover-main__title">
          {{ $t('selfCheckin.waiting.title') }}
        </div>
      </div>
      <div class="s-checkin-m__popover-button -bold" @click="close">
        {{ $t('selfCheckin.waiting.button0') }}
      </div>
    </div>
    <div
      class="s-checkin-m__popover"
      v-else-if="activeCheckinStatus === 'approved'"
    >
      <div class="s-checkin-m__popover-main -success">
        <img src="/img/greenSuccessIcon.svg" />
        <div class="s-checkin-m__popover-main__title -m-t">
          {{ $t('selfCheckin.success.title') }}
        </div>
        <div class="s-checkin-m__popover-main__subtitle">
          {{ $t('selfCheckin.success.subtitle') }}
        </div>
      </div>
      <div class="s-checkin-m__popover-button -bold" @click="close">
        {{ $t('selfCheckin.success.button0') }}
      </div>
    </div>
    <div class="s-checkin-m__popover" v-else>
      <div class="s-checkin-m__popover-main -tableNumber">
        <div class="s-checkin-m__popover-main__title">
          {{ $t('selfCheckin.tableNumber.title') }}
        </div>
        <div class="s-checkin-m__popover-main__subtitle">
          {{ $t('selfCheckin.tableNumber.subtitle') }}
        </div>
        <div class="s-checkin-m__popover-main__input">
          <input v-model.trim="tableCode" maxlength="3" />
        </div>
      </div>
      <div class="s-checkin-m__popover-button" @click="close">
        {{ $t('selfCheckin.tableNumber.button0') }}
      </div>
      <div class="s-checkin-m__popover-button -bold" @click="postCheckin()">
        {{ $t('selfCheckin.tableNumber.button1') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      tableCode: '',
      placeUid: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    activeCheckinStatus() {
      return this.$checkins.find((checkin) => checkin.type === 'restaurant')
        ?.status
    },
  },
  watch: {
    activeCheckinStatus(val, oldVal) {
      if (oldVal === 'deferred' && val === 'approved') {
        this.open = true
      }
    },
  },
  methods: {
    async postCheckin() {
      const { tableCode, placeUid, user } = this
      if (!(tableCode && placeUid && user?.uid)) return
      this.$store.dispatch('places/showLoader', true)
      try {
        const {
          data: { payload, error_code },
        } = await this.$axios.post(this.$API_URL('/selfCheckin'), {
          place_uid: placeUid,
          table_number: tableCode,
          user_uid: user.uid,
        })
        if (payload?.uid) {
          this.$bus.$emit("GOM/generateOrder", payload?.uid)
        }
        if (error_code) {
          if (error_code === 'WRONG_TABLE_NUMBER') {
            this.$bus.$emit('openContextMenu', {
              config: {
                type: 'modal',
                style: 'pink',
                title: this.$t('selfCheckin.errors.WRONG_TABLE_NUMBER.title'),
                subtitle: this.$t(
                  'selfCheckin.errors.WRONG_TABLE_NUMBER.subtitle'
                ),
                icon: '/img/iconAttention.svg',
                disableBackdropClose: true,
              },
              actions: [
                {
                  id: 'cancel',
                  text: this.$t(
                    'selfCheckin.errors.WRONG_TABLE_NUMBER.button0'
                  ),
                },
                {
                  id: 'retry',
                  text: this.$t(
                    'selfCheckin.errors.WRONG_TABLE_NUMBER.button1'
                  ),
                },
              ],
              resolve: (index) => {
                if (!index) {
                  this.close()  
                } else {
                  this.tableCode = ''
                }
              }
            })
          } else {
            throw new Error(error_code)
          }
        }
      } catch (error) {
        const errorStatus = error?.response?.status
        
        if (errorStatus === 401) {
            await this.$bus.updateToken(
                this.postCheckin.bind(this)
            )
        } else {
          this.$bus.$emit('openContextMenu', {
            config: {
              type: 'modal',
              style: 'pink',
              title: this.$t('selfCheckin.errors.default.title'),
              subtitle: this.$t('selfCheckin.errors.default.subtitle'),
              icon: '/img/iconAttention.svg',
              disableBackdropClose: true,
            },
            actions: [
              {
                id: 'ok',
                text: this.$t('selfCheckin.errors.default.button0'),
              },
              {
                id: 'ok',
                text: this.$t('selfCheckin.errors.default.button1'),
              },
            ],
            resolve: (index) => {
              if (!index) {
                this.postCheckin()
              } else {
                this.close()
              }
            },
            reject: () => ({}),
          })
          console.error('error', error)
        }
      }
      this.$store.dispatch('places/hideLoader')
    },
    close() {
      this.tableCode = ''
      this.placeUid = ''
      this.open = false
    },
    show(placeUid) {
      this.placeUid = placeUid
      this.open = true
    },
  },
}
</script>

<style lang="scss" scoped>
.s-checkin-m {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 105;
  @include font(20, 25, normal);
  letter-spacing: 0.38px;
  color: $white;
  background-color: rgba(0, 0, 0, 0.9);
  transition: visibility 0.3s, opacity 0.3s ease;
  @include flex(row, center, center);

  &.-show {
    visibility: visible;
    opacity: 1;
  }

  &__popover {
    @include flex(column, flex-end, center);
    width: 311px;
    height: 296px;
    background: $white;
    border-radius: 10px;
    color: $black;

    &.-colored {
      background: linear-gradient(
        142.14deg,
        rgba(23, 115, 134, 0.901852) 6.18%,
        rgba(190, 34, 89, 0.895979) 144.28%
      );
      backdrop-filter: blur(13.5914px);
      color: $white;
    }

    &-button {
      width: 100%;
      height: 54px;
      @include flex(column, center, center);
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      @include font(20, 22, 400);

      &.-bold {
        @include font(20, 22, 600);
      }
    }

    &-main {
      @include flex(column, center, center);
      &.-tableNumber {
        height: 186px;
      }
      &.-success {
        height: 241px;
      }
      &.-tableNumber,
      &.-success {
        .s-checkin-m__popover-main__title {
          @include font(18, 22, 600);
          padding: 0 20px;

          &.-m-t {
            margin-top: 21px;
          }
        }

        .s-checkin-m__popover-main__subtitle {
          @include font(15, 17, 400);
          color: #8e8e93;
          margin-top: 4px;
          padding: 0 20px;
        }

        .s-checkin-m__popover-main__input {
          margin-top: 10px;
          > input {
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom-style: solid;
            border-bottom: 2px solid $black;
            background-color: $white;
            @include font(24, 26, bold);
            width: 55px;
            text-align: center;

            &:focus {
              outline: none;
            }
          }
        }
      }

      &.-waiting {
        height: 241px;

        .s-checkin-m__popover-main__title {
          margin-top: 23px;
          @include font(20, 26, 500);
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
