<template>
	<div class="s-modal">
		<div class="s-modal-categories" ref="categories" v-if="currentPlace">
			<div 
				v-if="!hideSlider"
				class="swipe-up"
				@mousedown="mouseDown" 
				@touchstart="touchStart" 
				@mouseup="mouseUp" 
				@touchend="touchEnd"
			>
				<swiper 
					class="swiper" 
					:class="{'swiper-no-swiping': openInfo || deepLink}"
					:options="swiperOption"
				>
					<swiper-slide
						v-for="(item, index) in virtualSlidesToRender"
						:key="item.uid"
						:virtualIndex="index"
						:style="{ left: `${virtualData.offset}px` }"
					>
						<div 
							class="s-modal-categories__category s-category"
							:data-id="item.uid"
							:data-name="item.title"
							:key="item.uid"
							:id="item.uid"
							:data-mod="item.mode"
							:class="{'-info': openInfo}"
						>
							<ItemImages 
								:data="item.photo_uris"
								:class="{'-short': openInfo}"
								:key="item.uid"
							>
								<div class="itemview-items__item-label">
									<div class="l-item-distance" v-if="item.distanceInKm">{{ item.distanceInKm }} km</div>
									<div class="l-item-description">{{ item.short_description }}</div>
									<div class="l-item-name">{{ item.name }}</div>
									<div
										v-if="isReserveSupport"
										class="l-item-reserve" 
										@click="openReserveModal()"
									>
										<div>reserve</div>
									</div>
									<div 
										class="place-info-back"
										@click.stop.prevent="openCloseInfo"
										v-show="openInfo"
										style="background-image: url(/img/backWhite@3x.png);"
									></div>
								</div>
							</ItemImages>
							<PlaceInfo 
								v-if="currentUid === item.uid && openInfo" 
								:class="{'-open': openInfo}"
								:place="currentPlace"
								:openInfo="openInfo"
								:currentUid="currentUid"
							/>
						</div>
					</swiper-slide>
				</swiper>
			</div>
			<img 
				v-show="!openInfo"
				class="place-info-icon" 
				src="/img/iconStatus.png" 
				width="24px"
				srcset="/img/iconStatus@3x.png 3x /img/iconStatus@2x.png 2x /img/iconStatus.png 1x"
				@click.stop.prevent="openCloseInfo"
			>
		</div>
	</div>
</template>
<script>
import "@/assets/css/swiper.css";

import { swiper, swiperSlide } from '@/vendors/vue-awesome-swiper/src/index.js'

import ItemImages from '@/components/ItemImages'
import VueSwing from 'vue-swing'
import PlaceInfo from '@/components/Places/PlaceInfo'
import openReserveMobileMixin from '@/mixins/openReserveMobileMixin'
/**
   * Считает, был ли свайп вверх (с отклонением до 35 градусов в стороны).
   * @param {number} x - сдвиг по Ox
   * @param {number} y - сдвиг по Oy
   */
  function isSwipeDown (x, y) {
    const minUpDelta = 50; // Значение подбирается "на глаз"

    if (y <= 0 || Math.abs(y) < minUpDelta) {
      return false;
    }

    const minHorizontalDeviation = 10; // Значение подбирается "на глаз"
    const tan35deg = 0.7002;

    /*
      Вычисляем тангенс угла:
      тангенс = противолежащий катет / прилежащий
    */
    const tan = (Math.abs(x) || minHorizontalDeviation) / Math.abs(y);

    return tan < tan35deg;
  }

const options = {
    root: null,
    threshold: 0
}

export default {
	name: 'PlacesSlideModal',
	mixins: [openReserveMobileMixin],
	components: {
		ItemImages,
		PlaceInfo,
		swiper,
    	swiperSlide
	},
	props: {
		dishes: {
			type: Array,
			default: ()=>([])
		},
		currencySymbol: {
			type: String,
			default: '$'
		},
		startPoolAt: {
			type: String,
			default: ''
		},
		delivery: {
			type: Boolean,
			default: false
		},
		deepLink: {
            type: Boolean,
            default: false
        },
	},
	data() {
		const places = this.$PS.data
		let current = (this.startPoolAt) ? places.findIndex(cat => cat.uid === this.startPoolAt) : 0
		if (current === -1) {
			current = 0
		}
		const that = this
		return {
			photoIndex: null,
			current,
			startY: 0,
			startX: 0,
			trottlePaning: null,
			frame: null,
			panning: false,
			windowWidthHalf: 0,
			categoriesDom: null,
			busy: false, 
			pool: [],
			config: {
				allowedDirections: [
					VueSwing.Direction.LEFT,
					VueSwing.Direction.RIGHT,
					VueSwing.Direction.DOWN
				],
				isThrowOut: (xOffset, yOffset, element, throwOutConfidence) => { 
					if (xOffset > 0) {
						return false
					}
					return throwOutConfidence > 0.4
				}
			},
			openInfo: false,
			places,
			initialSlide: 0,
			hideSlider: false,
			virtualData: {
				from: 0,
				to: 0,
				offset: 0
			},
			swiperOption: {
				enabled: true,
				slidesPerView: 1,
				centeredSlides: false,
				spaceBetween: 0,
				edgeSwipeDetection: 'prevent',
				edgeSwipeThreshold: 0,
				initialSlide: current,
				effect: 'slide',
				direction: 'horizontal',
				virtual: {
					addSlidesBefore: 0,
					addSlidesAfter: 0,
					renderExternal: ({ from, offset, to, slides }) => {
						this.$set(this, 'virtualData', {from, offset, to});
						this.$nextTick(() => {
							this.$PS.updatePlaces(slides.map(i => i.uid))
						})
					},
					slides: Object.freeze(places.slice())
				},
				on: {
					activeIndexChange: function () {
						that.current = this.realIndex
					}
				}
			},
			coord: {
				x: 0,
				y: 0
			}
		}	
	},
	computed: {
		historyMode() {
			return this.$route.query.history
		},
		zIndexAndDishes() {
			const dishes = this.dishes,
				places = this.places,
				catLen = places.length
			return places.map((category, index) => {
				const c_uid = category.uid
				return {
					'z-index': catLen - index,
					dishes: dishes.filter(dish => dish.category_uid === c_uid)
				}
			})
		},
		currentPlace() {
			return this.places[this.current]
		},
		currentUid() {
			return this.currentPlace?.uid
		},
		categoriesLen() {
			return this.places.length
		},
		lastUid() {
			const places = this.places
			return places[places.length-1].uid
		},
		currentChunck() {
			return this.chunkedPlaces[this.currentChunckIndex]
		},
		virtualSlidesToRender() {
			const { virtualData: { from, to }, places } = this
			return places.slice(from, to + 1)
		},
		isReserveSupport() {
			return this.currentPlace?.reservation_settings?.enabled
		}
	},
	watch: {
		currentUid(val) {
			this.$emit('pickPlace', val)
		}
	},
	beforeDestroy() {
		this.$el.removeEventListener('touchmove', this.trottlePaning)
	},
	methods: {
		mouseDown (evt) {
			this.coord.x = evt.clientX;
			this.coord.y = evt.clientY;
		},
		touchStart (evt) {
			this.coord.x = evt.touches[0].clientX;
			this.coord.y = evt.touches[0].clientY;
		},
		mouseUp (evt) {
			if (isSwipeDown(evt.clientX - this.coord.x, evt.clientY - this.coord.y)) {
				this.$emit('drop', this.currentUid)
			}
			this.coord.x = null;
			this.coord.y = null;
		},
		touchEnd (evt) {
			if (isSwipeDown(evt.changedTouches[0].clientX - this.coord.x, evt.changedTouches[0].clientY - this.coord.y)) {
				this.$emit('drop', this.currentUid)
			}
			this.coord.x = null;
			this.coord.y = null;
		},
		openCloseInfo() {
			window.requestAnimationFrame(() => {
				this.openInfo = !this.openInfo
				this.$bus.$emit('openInfo', this.openInfo)
			})
		},
	}
}
</script>
<style lang="scss">
	.s-modal {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: $black;
		z-index: 98;
		overflow: hidden;

		.swiper {
			height: 100%;
			width: 100%;
		}
		&-categories {
			width: 100%;
			height: 100%;
			position: relative;
			

			&__category {
				position: absolute;
				
				will-change: transform;
				// transition: height 0.15s ease; 
				&.s-category {
					width: 100%;
					height: 100%;
					background: $black;
					&.-info {
						overflow-y: scroll;
						touch-action: initial !important;
					}
					
				}

				&.-hide {
					// transform: translate(-500px, 0px);
				}

				&[data-mod="hidden"] {
					transform: translate(-500px, 0px);
				}
			}
			.s-category {
				&-dishes {
					width: 100%;
					height: 100%;

					&__dish {
						width: 100%;
						height: 100%;
					}
				}
			}
			.m-list {
				background: $black;
				color: $white;
				.m-list-item__text {
					text-decoration: none;
					color: $white;

					&:visited {
						text-decoration: none;
						color: $white;
					}
				}
			}
		}

		.place-info-icon {
			position: fixed;
			z-index: 9998;
			right: 10px;
			bottom: 150px;
		}

		.place-info-back {
			width: 48px;
			height: 48px;
			background: rgba(0,0,0, 0.5);
			background-size: 24px 24px;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			border-radius: 50%;
			backdrop-filter: blur(10px);
			position: absolute;
			z-index: 9998;
			bottom: 10px;
			transform: rotate(-90deg);
			right: 0;
		}
	}
</style>