import Vue from 'vue'

const state = {
  geolocation: {
    lat: 0,
    lng: 0,
  },
  order: [],
  currencySymbol: '$',
  user: null,
  idToken: null,
  delivery: false,
  checkins: [],
  userInited: false,
  mainPaymentSourceUid: '',
}

const getters = {
  loginedIn: ({ user }) => {
    return user && user.uid && !user.isAnonymous
  },
  getCheckinByUid:
    ({ checkins }) =>
    (uid) => {
      return checkins.find((ch) => ch.uid === uid)
    },
}

const actions = {

}

const mutations = {
  SET_DELIVERY(state, val = false) {
    state.delivery = val
  },
  INIT_USER(state, { user, idToken }) {
    Vue.set(state, 'user', user)
    Vue.set(state, 'idToken', idToken)
    state.userInited = true
  },
  INIT_ORDER(state) {
    let cached = localStorage.getItem('user_order')
    if (cached) {
      try {
        cached = JSON.parse(cached)
      } catch (e) {
        console.log('can not parse cached order')
        cached = []
      }
      state.order.push(...cached)
    }
  },
  SET_USER_LOCATION(state, { lat, lng }) {
    Vue.set(state, 'geolocation', { lat, lng })
  },
  ADD_TO_ORDER(state, { dish, currencySymbol }) {
    const order = state.order,
      orderLen = order.length
    if (orderLen) {
      const place_uid = order?.[0]?.place_uid
      if (place_uid !== dish.place_uid) {
        order.splice(0, orderLen)
      }
    }
    state.order.push(dish)
    state.currencySymbol = currencySymbol
    localStorage.setItem('user_order', JSON.stringify(state.order))
  },
  REMOVE_FROM_ORDER(state, index) {
    if (index === -10) {
      state.order.splice(0, state.order.length)
      localStorage.setItem('user_order', JSON.stringify(state.order))
    } else if (~state.order[index]) {
      state.order.splice(index, 1)
      localStorage.setItem('user_order', JSON.stringify(state.order))
    }
  },
  PUSH_CHECKINS(state, checkins = []) {
    state.checkins.push(...checkins)
  },
  UPDATE_CHECKINS(state, checkins = []) {
    const stated = state.checkins
    checkins.forEach((checkin) => {
      const uid = checkin.uid
      const index = stated.findIndex((ch) => ch.uid === uid)
      if (~index) {
        stated.splice(index, 1, checkin)
      }
    })
  },
  REMOVE_CHECKINS(state, checkins = []) {
    const stated = state.checkins
    checkins.forEach((checkin) => {
      const uid = checkin.uid
      const index = stated.findIndex((ch) => ch.uid === uid)
      if (~index) {
        stated.splice(index, 1)
      }
    })
  },
  CLEAR_CHEKINS(state) {
    Vue.set(state, 'checkins', [])
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
