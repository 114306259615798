<template>
	<div class="t-menu">
		<div class="self-checkin-help-wrapper" v-if="!deliveryFlow && isShowSelfCheckinHelp">
			<img src="/img/selfCheckinNotiece.svg" alt="">
			<div class="self-checkin-help">
				<div class="self-checkin-help__chip"></div>
				<div class="self-checkin-help__wrapper">
					<div class="self-checkin-help__top">
						<div class="self-checkin-help__top-text">
							{{ $t("selfCheckin.helpText") }}
						</div>
					</div>
					<div class="self-checkin-help__bottom" @click="closeSelfCheckinHelp">OK</div>
				</div>
			</div>
		</div>
		<TabTile 
			v-if="isInDrinks" 
			:categories="drinks"
			:dishes="dishes"
			:currencySymbol="currencySymbol"
			inDrinks
			:isHotel="isHotel"
			@dropFromDrinks="selectCategory('')"
		/>
		<div class="t-menu-wrapper" v-else>
			<div class="t-menu-head" :class="{'-show': showNavbar}">
				<img 
					v-if="currentCategory || inDrinks" 
					src="/img/backWhite.png" 
					srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
					class="back-icon"
					@click="selectCategory('')"
				/>
				<img 
					v-else-if="isInRelationParent" 
					src="/img/backWhite.png" 
					srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
					class="back-icon"
					@click="$emit('close')"
				/>
				<img 
					v-else
					:key="'home'"
					width="24px" 
					height="24px" 
					src="/img/iconHome.png" 
					srcset="/img/iconHome@3x.png 3x /img/iconHome@2x.png 2x /img/iconHome.png 1x" 
					class="t-menu-toplace"
					@click="$emit('close')"
				>
				<img 
					v-if="!deliveryFlow && supportOrders"
					key="iconTableCode"
					class="t-menu-head__table-code"
					src="/img/call-bell-white.svg"
					@click="enterCode(placeUid)"
				/>
			</div>
			<div class="t-menu-items" v-if="!currentCategory" key="categories">
				<template v-for="drink in [drinks[0]]">
					<div 
						v-if="drink && drink.uid"
						class="t-menu-items__item"
						:key="drink.uid"
						@click="selectCategory(drink.uid)"
					>
						<img 
							:src="drink.photo_uri" 
							class="t-menu-items__item-image" 
							loading="lazy"
							onerror="this.onerror=null;this.src='/img/restaurantPlaceholder.jpg';"
						/>
						<div class="t-menu-items__item-backdrop"></div>
						<div class="t-menu-items__item-title">{{ drinkTitle }}</div>
					</div>
				</template>
				<div 
					class="t-menu-items__item"
					v-for="category in sortedCategories"
					:key="category.uid"
					@click="selectCategory(category.uid)"
				>
					<img 
						:src="category.photo_uri" 
						class="t-menu-items__item-image"
						loading="lazy" 
						onerror="this.onerror=null;this.src='/img/restaurantPlaceholder.jpg';"
					/>
					<div class="t-menu-items__item-backdrop"></div>
					<div class="t-menu-items__item-title">{{ category.title }}</div>
				</div>
			</div>
			<!-- <div class="t-menu-items" v-else-if="currentCategory === '1'" key="drinks">
				<div 
					class="t-menu-items__item"
					v-for="category in childs"
					:key="category.uid"
					:style="`background-image: url(${category.photo_uri || '/img/restaurantPlaceholder.jpg'});`"
					@click="selectCategory(category.uid)"
				>
					{{ category.title }}
				</div>
			</div> -->
			<div class="t-menu-items" v-else-if="currentCategory && currentCategory !== '1'" key="dishes">
				<div 
					class="t-menu-items__item"
					v-for="dish in childs"
					:key="dish.uid"
					@click="selectDish(dish.uid)"
				>
					<img 
						:src="dish.photo_uri || dish.comment_photo_uri ||'/img/restaurantPlaceholder.jpg'" 
						class="t-menu-items__item-image" 
						loading="lazy"
						onerror="this.onerror=null;this.src='/img/restaurantPlaceholder.jpg';"
						
					/>
					<div class="t-menu-items__item-backdrop" :class="{'-stop': !dish.is_active}"></div>
					<div class="t-menu-items__item-info">
						<div class="info-title">{{ dish.title }}</div>
						<div class="info-price">{{ currencySymbol }} {{ (deliveryFlow) ? dish.delivery_price : dish.price }}</div>
					</div>
					<div class="t-menu-items__item-stop" v-if="!dish.is_active">
						<img 
							src="/img/combinedShape.png" 
							srcset="/img/combinedShape@3x.png 3x /img/combinedShape@2x.png 2x /img/combinedShape.png 1x"
						/>
						<div class="stop-text">{{ dish.on_stop_title || $t('onstop') }}</div>
					</div>
				</div>
			</div>
			<!-- <div class="t-menu-modal" v-if="currentDish">
				<SwipeModalDishes
					v-if="currentDish"
					:category="currentCategoryItem"
					:currencySymbol="currencySymbol"
				/> -->
			
				<!-- <CategoryModal 
					:category="currentCategoryItem"
					:dishes="childs"
					@close="selectDish"
					:currentDish="currentDish"
					:currencySymbol="currencySymbol"
				/> -->
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
import callWaiterMixin from '@/mixins/callWaiterMixin'
import hasFirstSelfCheckinMixin from '@/mixins/hasFirstSelfCheckinMixin'
import SwipeModalDishes from './SwipeModalDishes'

export default {
	name: 'TabTile',
	mixins: [ callWaiterMixin, hasFirstSelfCheckinMixin ],
	props: {
		categories: {
			type: Array,
			default: ()=>([])
		},
		dishes: {
			type: Array,
			default: ()=>([])
		},
		currencySymbol: {
			type: String,
			default: '$'
		},
		drinks: {
			type: Array,
			default: ()=>([])
		},
		inDrinks: {
			type: Boolean,
			default: false
		},
		drinkTitle: {
			type: String,
			default: 'Drinks'
		},
		isHotel: {
			type: Boolean,
			default: false
		}
	},
	components: {
		SwipeModalDishes,
		// TabTile
	},
	inject: ['rootPlaceUid', 'rootPlaceType'],
	data() {
		return {
			currentCategory: '',
			currentDish: '',
			categoriesScrollTop: 0,
			showNavbar: false,
			supportedFlows: null,
		}
	},
	
	computed: {
		placeUid() {
			return this.rootPlaceUid
		},
		placeType() {
			return this.rootPlaceType
		},
		categoriesToShow() {
			const { currentCheckin, categories } = this
			if (currentCheckin) return categories

			return categories.filter(category => !category.for_checked_in_users_only)
		},
		sortedCategories() {
			return this.categoriesToShow
					.slice()
					.sort((a, b) => {
						return a.sort_index - b.sort_index
					})
		},
		isInDrinks() {
			const { currentCategory, drinks } = this
			return !!~drinks.findIndex(drink => drink.uid === currentCategory )
		},
		childs() {
			return this.getChidren(this.currentCategory)
		},
		currentCategoryItem() {
			return this.getCategoryItem(this.currentCategory)
		},
		supportOrders() {
			return this.supportedFlows?.orders
		},
	},
	watch: {
		currentDish(val) {
			if (val) {
				const startAt = val
				this.$bus.$emit('GlobalModal/dishesOpen', {
					category: this.currentCategoryItem,
					currencySymbol: this.currencySymbol,
					startAt, 
					onClose: this.selectDish,
					placeUid: this.placeUid,
					placeType: this.placeType
				})
			}
		},
		currentCategory(val) {
			this.$bus.tile.inCategory = !!val
			document.querySelector('.t-menu-wrapper').scrollTo(0, 1)
			this.setShowNavbar(1)

		},
		isInDrinks(val) {
			this.$bus.tile.inDrinks = val
			this.removeListener()
			this.$nextTick(this.setListener.bind(this))
		},
	},
	mounted() {
		this.$set(this, 'supportedFlows', this.$PS.getSupportedFlowsByUid(this.placeUid))
		this.$nextTick(this.setListener.bind(this))
		this.openDishImmediatly()
		if (this.supportedFlows?.orders) {
			this.$bus.$emit("openOrderTutorial", true)
		}
	},
	beforeDestroy() {
		this.$bus.tile.inDrinks = false
		this.$bus.tile.inCategory = false
		this.removeListener()
		this.$set(this, 'supportedFlows', null)
	},
	methods: {
		getChidren(categoryUid) {
 			if (categoryUid) {
				return this.dishes.filter(dish => dish.category_uid === categoryUid)
					.slice()
					.sort((a, b) => {
						const [aSort, bSort] = [a.sort_index, b.sort_index]
						if (aSort > bSort) {
							return 1
						} else if (aSort < bSort) {
							return -1
						}
						return 0
					})
			}
			return []
		},
		getCategoryItem(categoryUid) {
			const dishes = this.getChidren(categoryUid)
			const category = this.categories.find(cat => cat.uid === categoryUid)
			return {
				category,
				dishes
			}
		},
		openDishImmediatly() {
			const deepLinkQuery = JSON.parse(localStorage.getItem('deepLinkQuery') || '{}')
			if (deepLinkQuery.menu_item) {
				const category = this.getCategoryItem(deepLinkQuery.category)
				this.$bus.$emit('GlobalModal/dishesOpen', {
					category,
					currencySymbol: this.currencySymbol,
					startAt: deepLinkQuery.menu_item, 
					onClose: () => {
						localStorage.removeItem('deepLinkQuery')
					},
					placeUid: this.placeUid,
					placeType: this.placeType
				})
			}
		},
		setListener() {
			document.querySelector('.t-menu-wrapper').addEventListener('scroll', this.onScrollDiv)
		},
		removeListener() {
			const div = document.querySelector('.t-menu-wrapper')
			if (div) {
				div.removeEventListener('scroll', this.onScrollDiv)
			}
		},
		setShowNavbar(scrolltop) {
			const showNavbar = !!(scrolltop >= 3)
			if (this.showNavbar !== showNavbar) {
				this.showNavbar = showNavbar
			}
		},
		onScrollDiv(e) {
			const scrollTop = e.target.scrollTop
			this.setShowNavbar(scrollTop)
		},
		selectDish(uid) {
			window.requestAnimationFrame(() => {
				this.currentDish = uid
			})
		},
		selectCategory(uid) {
			if (!this.currentCategory && !uid && this.inDrinks) {
				this.$emit('dropFromDrinks', '')
			}
			const $menu = document.querySelector('.t-menu-wrapper')
			if (uid) {
				this.categoriesScrollTop = $menu.scrollTop || 0
			}
			window.requestAnimationFrame(() => {
				this.currentCategory = uid
				const scrollToY = (uid) ? 0 : this.categoriesScrollTop
				window.requestAnimationFrame(() => {
					$menu.scrollTo(0, scrollToY)
					this.setShowNavbar(scrollToY)
				})
			})
		},
	}
}
</script>

<style lang="scss">
.self-checkin-help {
	&-wrapper {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 102;
		background-color: rgba(0, 0, 0, 0.5);

		img {
			position: absolute;
			right: 10px;
   			top: 16px;
			z-index: 1;

			&.-inchooseModal {
				right: 19px;
				top: 19px;
			}
		}
	}


    @include pos-centered();
        top: 165px;
    width: 335px;
	z-index: 2;

    color: $white;
    &__wrapper {
        @include flex(column, flex-start, center);
        background: rgba(255, 255, 255, 0.3) !important;
        backdrop-filter: blur(10px);
        border-radius: 10px 0 10px 10px;
    }

    &__top {
        @include flex(row, flex-start, center);

        &-left-icon {
            width: 44px;
        }

        &-right-icon {
            width: 32px;
            height: 32px;
            position: absolute;
            right: 10px;
            top: 10px;
        }

        &-text {
            @include font(15, 20, 400);
			margin: 16px;
            text-align: left;
        }
    }
    &__bottom {
        @include font(20, 22, 400);
        @include flex(row, center, center);
        text-transform: uppercase;
        width: 100%;
        height: 54px;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    &__chip {
        position: absolute;
        top: -24px;
        right: 0;
        width: 16px;
        height: 25px;
        background: rgba(255, 255, 255, 0.3);
        -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        backdrop-filter: blur(10px);
    }

}
	.t-menu {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		// height: 100%;
		
		// height: 100%;
		overflow: hidden;

		&-toplace {
			position: fixed;
			top: 25px;
    		left: 25px;
			z-index: 99;
		}

		&-wrapper {
			position: absolute;
			background: $black;
			width: 100%;
			height: 100%;
			overflow: scroll;
		}

		&-head {
			position: fixed;
			top: 0;
			left: 0;
			height: 70px;
			width: 100%;
			z-index: 101;
			
			-webkit-transition: background-color 0.1s ease;
			-ms-transition: background-color 0.1s ease;
			transition: background-color 0.1s ease;

			&.-show {
				background-color: rgba(0, 0, 0, 0.7);
				backdrop-filter: blur(3px);
																
			}

			&__table-code {
				@include pos-centered-v;
				right: 16px;
			}
		}

		&-items {
			@include flex(row, space-between, flext-start);
			flex-wrap: wrap;

			&__item {
				width: calc(50% - 1px);
				margin-top: 1px;
				height: 25vh;
				@include flex-centered;
				@include font(16, 16, bold);
				text-shadow: 1px 1px #000;
				color: $white;
				position: relative;

				&:first-child {
					width: 100%;
					height: 50vh;
					margin-top: 0;
				}

				&-image {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}

				&-title {
					@include pos-centered();
					text-transform: uppercase;
				}

				&-backdrop {
					position: absolute;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.2);
				}

				&-stop {
					position: absolute;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.5);
					@include flex(column, center, center);

					.stop-text {
						@include font(16, 18, bold);
						margin-top: 13px;
					}

				}

				&-info {
					position: absolute;
					z-index: 1;
					bottom: 10px;
					left: 14px;
					right: 14px;
					height: 70%;
					@include flex(column, flex-end, flex-end);

					.info-title {
						text-align: left;
						width: 100%;

						@include font(18, 21, normal);
						@include textTruncate(100%, auto, 2);
						margin-bottom: 2px;
					}

					.info-price {
						width: 100%;
						text-align: left;
						@include font(18, 21, bold);
						// position: absolute;
						// width: auto;
						// background-color: rgba(0, 0, 0, 0.7);
						// transition: background-filter 200ms linear;
						// padding: 1px 5px;
						// border-radius: 5px;
						// bottom: 0px;
						// right: -10px;
					}

				}
			}
		}

		.back-icon {
			position: fixed;
			left: 20px;
			top: 20px;
			height: 30px;
			width: 30px;
			z-index: 101;
		}
	}
</style>