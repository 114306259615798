<template>
  <div id="app">
    <router-view />
    <GlobalDishesModal />
    <GlobalOrderModal ref="GOM" />
    <GlobalAuthModal />
    <GlobalContextMenu />
    <GlobalTermsModal />
    <AddressPickerModal />
    <PreAddressModal />
    <ChooseLanguageModal ref="langModal" />
    <EmailForBillWindow ref="emailBill" />
    <PaymentTypeWindow ref="paymentType" />
    <CommentToDishWindow ref="commentWindow" />
    <GlobalTutorialModal />
    <ChooseRestrauntsModal ref="chooseRests" />
    <SelfCheckinModal ref="selfCheckin" />
    <!-- <GlobalDownloadAppModal /> -->
    <GlobalPayLayer />
  </div>
</template>
<script>
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import GlobalDishesModal from '@/components/GlobalDishesModal'
import GlobalOrderModal from '@/components/GlobalOrderModal'
import GlobalAuthModal from '@/components/GlobalAuthModal'
import GlobalContextMenu from '@/components/GlobalContextMenu'
import GlobalTermsModal from '@/components/GlobalTermsModal'
import AddressPickerModal from '@/components/AddressPickerModal'
import ChooseLanguageModal from '@/components/ChooseLanguageModal'
import PreAddressModal from '@/components/PreAddressModal'
import EmailForBillWindow from '@/components/UI/EmailForBillWindow'
import PaymentTypeWindow from '@/components/UI/PaymentTypeWindow'
import CommentToDishWindow from '@/components/UI/CommentToDishWindow'
import GlobalTutorialModal from '@/components/GlobalTutorialModal'
import ChooseRestrauntsModal from '@/components/ChooseRestrauntsModal'
import SelfCheckinModal from '@/components/UI/SelfCheckinModal'
// import GlobalDownloadAppModal from '@/components/GlobalDownloadAppModal'
import GlobalPayLayer from '@/components/GlobalPayLayer'

export default {
  components: {
    GlobalDishesModal,
    GlobalOrderModal,
    GlobalAuthModal,
    GlobalContextMenu,
    GlobalTermsModal,
    AddressPickerModal,
    ChooseLanguageModal,
    PreAddressModal,
    EmailForBillWindow,
    PaymentTypeWindow,
    CommentToDishWindow,
    GlobalTutorialModal,
    ChooseRestrauntsModal,
    SelfCheckinModal,
    // GlobalDownloadAppModal,
    GlobalPayLayer,
  },
  mounted() {
    const { $root, $refs } = this
    $root.langModal = $refs.langModal
    $root.emailBill = $refs.emailBill
    $root.paymentType = $refs.paymentType
    $root.commentWindow = $refs.commentWindow
    $root.GOM = $refs.GOM
    $root.chooseRests = $refs.chooseRests
    $root.selfCheckin = $refs.selfCheckin
  },
}
</script>
<style lang="scss">
html,
body {
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  box-sizing: border-box;
  // font-size: 62.5%;
}

html {
  height: -webkit-fill-available;
  overflow: hidden;
}
body {
  margin: 0;
  overflow: hidden;
  height: 100vh;
  &.chrome {
    height: calc(100vh - 56px);
  }
  /* mobile viewport bug fix */
  // min-height: -webkit-fill-available;
}

#app {
  height: 100%;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// .gm-style-iw {
//   min-width: 150px !important;
//   min-height: 60px !important;
// }
.gm-ui-hover-effect {
  display: none !important;
}
</style>
