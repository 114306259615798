<template functional>
  <div class="tutorial-item -first">
    <img
      class="tutorial-item__image phone-image"
      src="/img/tutorial-phone.svg"
    />
    <div class="finger-div">
      <img
        class="tutorial-item__image blink-plus"
        src="/img/tutorial-blink.svg"
      />
      <img
        class="tutorial-item__image finger-image"
        src="/img/tutorial-finger.svg"
      />
    </div>
    <div class="tutorial-item__text" v-html="parent.$t('T000005')" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
$delay-between-steps: 100;
$step-1-duration: 300;
$step-2-delay: $step-1-duration + $delay-between-steps;
$step-2-duration: 300;
$step-3-delay: $step-2-duration + $delay-between-steps;
$step-3-duration: 300;

@keyframes step-1-phone-image {
  from {
    transform: translateY(45px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes step-1-finger-image {
  from {
    opacity: 0;
    transform: translate3d(-113px, 25px, 0);
    width: 52px;
    height: 52px;
  }
  to {
    opacity: 1;
    transform: translate3d(-8px, -25px, 0);
    width: 52px;
    height: 52px;
  }
}

@keyframes step-2-finger-image {
  from {
    opacity: 1;
    transform: translate3d(-8px, -25px, 0);
    width: 52px;
    height: 52px;
  }
  to {
    opacity: 1;
    transform: translate3d(-8px, -25px, 0);
    width: 36px;
    height: 36px;
  }
}

@keyframes step-2-blink-image {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes step-3-finger-image {
  from {
    opacity: 1;
    transform: translate3d(-8px, -25px, 0);
    width: 36px;
    height: 36px;
  }
  100% {
    opacity: 1;
    transform: translate3d(-8px, -25px, 0);
    width: 52px;
    height: 52px;
  }
}

@keyframes step-1-text {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tutorial-item {
  @include flex(column, flex-start, center);
  padding: 25px 32px;

  &__text {
    @include font(24, 30, 400);
    color: $white;
  }

  .finger-div {
    height: 56px;
    position: relative;
  }

  .finger-image {
    @include animate(step-1-finger-image, 900);
    animation: step-1-finger-image #{$step-1-duration}ms linear 0s 1 normal
        forwards,
      step-2-finger-image #{$step-2-duration}ms linear #{$step-2-delay}ms 1
        normal forwards,
      step-3-finger-image #{$step-3-duration}ms linear #{$step-3-delay}ms 1
        normal forwards;
  }

  .blink-plus {
    @include pos-centered-h();
    opacity: 0;
    top: -50px;
    @include animate(step-2-blink-image, $step-2-duration, $step-2-delay);
  }

  .phone-image {
    @include animate(step-1-phone-image, $step-1-duration);
  }

  &.-first {
    .tutorial-item__text {
      @include animate(step-1-text, $step-1-duration);
    }
  }
}
</style>
