import {
  ADDITIONAL_PAYMENTS_KEYS,
  IGNORE_ADDITIONAL_PAYMENTS_KEYS,
} from '@/consts'
const localizedPaymentsKeys = {}
localizedPaymentsKeys[ADDITIONAL_PAYMENTS_KEYS.MAYBER_FEE] = 'T000009'

export default {
  computed: {
    mixinAdditionalCustomPayments() {
      const additionalPayments =
        this.calculatedUnpaid?.additional_payments ?? {}
      return this.mixinGetAdditionalPayments(additionalPayments)
    },
    mixinAdditionalCustomPaymentsPreCheck() {
      const additionalPayments =
        this.ordersStore.calculated?.additional_payments ?? {}
      return this.mixinGetAdditionalPayments(additionalPayments)
    },
  },
  methods: {
    mixinGetAdditionalPayments(additionalPayments) {
      return Object.keys(additionalPayments).reduce((acc, paymentKey) => {
        if (
          !IGNORE_ADDITIONAL_PAYMENTS_KEYS.has(paymentKey) &&
          additionalPayments[paymentKey]
        ) {
          const localizedPaymentsKey = localizedPaymentsKeys[paymentKey]
          acc.push({
            title: localizedPaymentsKey ? this.$t(localizedPaymentsKey) : paymentKey,
            value: additionalPayments[paymentKey],
          })
        }
        return acc
      }, [])
    },
  },
}
