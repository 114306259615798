<template functional>
  <div class="tutorial-item -confirm">
    <img
      class="tutorial-item__image phone-image"
      src="/img/tutorial-phone-confirm.svg"
    />
    <div class="finger-div">
      <img
        class="tutorial-item__image blink-confirm"
        src="/img/tutorial-confirm-blink.svg"
      />
      <img
        class="tutorial-item__image finger-image-confirm"
        src="/img/tutorial-finger.svg"
      />
    </div>
    <div class="tutorial-item__text" v-html="parent.$t('T000007')" />
  </div>
</template>

<script>
export default {}
</script>
tutorial-phone-pay.svg

<style lang="scss">
$delay-between-steps: 100;
$step-1-duration: 300;
$step-2-delay: $step-1-duration + $delay-between-steps;
$step-2-duration: 300;
$step-3-delay: $step-2-duration + $delay-between-steps;
$step-3-duration: 300;

.tutorial-item {
  @include flex(column, flex-start, center);
  padding: 25px 32px;

  &__text {
    @include font(24, 30, 400);
    color: $white;
  }

  .finger-div {
    height: 56px;
    position: relative;
  }

  .blink-confirm {
    @include pos-centered-h;
    top: -53px;
  }

  &.-confirm {
    .finger-image-confirm {
      transform: translate3d(-8px, -40px, 0);
    }
  }
}
</style>
