<template>
	<div class="pam" :class="{'-open': open}">
		<div class="b-window__body">
			<img 
				class="b-window__body-close" 
				src="/img/iconCloseGrey.svg"
				@click="close(false)"
			>
			<div class="b-window__body-text -header">{{ $t('order.chooseVariant') }}</div>
			<div class="b-window__body-switcher">
				<div 
					class="b-window__body-switcher__item"
					:class="{'-active': !takeAway}"
					@click="takeAway = false"
				>{{ $t('order.delivery') }}</div>
				<div 
					class="b-window__body-switcher__item"
					:class="{'-active': takeAway}"
					@click="takeAway = true"
				>{{ $t('order.samovivoz') }}</div>
			</div>
			<PlaceInfo 
				v-if="place && takeAway" 
				:class="{'-open': true}"
				class="-grey"
				:place="place"
				:openInfo="true"
				:currentUid="place.uid"
				short-info
			/>
			<div v-else-if="!takeAway" class="b-window__body-text -header">
				{{ $t('delivery.forDeliveryEnterAddres') }}
			</div>
			<div class="b-window__body-confirmbtn" @click="close()">OK</div>
		</div>
	</div>
</template>
<script>
import PlaceInfo from "@/components/Places/PlaceInfo"
export default {
	name: 'PreAddressModal',
	data() {
		return {
			open: false,
			place: null,
			takeAway: false
		}
	},
	components: {
		PlaceInfo
	},
	mounted () {
        this.$bus.$on('PreAddressOpen', this.onOpen)
    },
	methods: {
		onOpen (place) {
			this.place = place
			this.open = true
		},
		close (success = true) {
            this.place = null
            this.open = false
			const {takeAway} = this
            this.takeAway = false
            this.$bus.$emit('PreAddressClose', (success) ? {success, takeAway} : null)
        }
	}
}
</script>
<style lang="scss">
	.pam {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1050;
		// background: rgb(28, 28, 30);
		display: none;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
		outline: 0;

		&.-open {
            display: block;
        }

		.b-window {
			&__body {
				position: absolute;
				bottom: 0;
				width: calc(100% - 32px);
				height: calc(50% - 32px);
				background-color: $darkGreyTwo;
				border-radius: 10px;
				padding: 16px;

				&-text {
					width: 100%;
					text-align: left;
					color: $white;
					@include font(18, 23, normal);

					&.-header {
						width: calc(100% - 60px);
					}
				}
				&-switcher {
					width: 100%;
					height: 44px;
					@include flex(row, center, center);
					background: rgba(118, 118, 128, .12);
					height: 36px;
					border-radius: 8px;
					margin-top: 52px;
					// -items
					&__item {
						color: $white;
						@include font(18, 18, normal);
						letter-spacing: -0.11px;
						height: 100%;
						width: 50%;
						@include flex(row, center, center);

						&.-active {
							background: $goldenMain;
							border-radius: 8px;
							@include font(18, 18, 500);
						}
					}
				}

				&-confirmbtn {
					@include flex(row, center, center);
					left: 16px;
					right: 16px;
					bottom: 30px;
					height: 48px;
					background: $goldenMain;
					border-radius: 5px;
					position: absolute;
					@include font(20, 20, 500);
					color: $white;
				}

				&-close {
					position: absolute;
					top: 15px;
					right: 13px;
				}
			}
		}
	}
</style>