<template>
    <div @click="closeAll">
        <RestrauntMenu
            v-if="drinksMode"
            :categories="drinksToShow"
            :dishes="dishes"
            :currencySymbol="currencySymbol"
            drinksModeProp
            :isHotel="isHotel"
            @closeDrinks="drinksMode = false"
        />
        <div v-else class="r-menu" :class="{ '-full': showItemsList }">
            <div class="r-menu-homechunk" v-if="!currentCategory"></div>
            <div
                class="r-menu-searchbar"
                :class="{ '-initial': !currentCategory && !isDrinks }"
                v-if="!drinksMode"
            >
                <img
                    src="/img/arrowLeft.svg"
                    class="back-icon"
                    v-if="currentCategory || isDrinks"
                    @click="chooseCategory('')"
                />
                <img
                    src="/img/backWhite.png"
                    srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
                    class="r-menu-toplace"
                    width="24px"
                    height="24px"
                    v-else-if="isInRelationParent"
                    @click="$emit('close')"
                />
                <img
                    v-else-if="!currentCategory && !isDrinks"
                    :key="'home'"
                    width="24px"
                    height="24px"
                    src="/img/iconHome.png"
                    srcset="/img/iconHome@3x.png 3x /img/iconHome@2x.png 2x /img/iconHome.png 1x"
                    class="r-menu-toplace"
                    @click="$emit('close')"
                />
                <img
                    v-if="!isDrinks"
                    src="/img/search.svg"
                    class="search-icon"
                    :class="{ '-short': currentCategory }"
                />
                <input type="text" placeholder="Search" v-model="searchText" />
                <img
                    v-if="!deliveryFlow && supportedFlows.orders"
                    src="/img/call-bell-white.svg"
                    class="call-icon"
                    @click="enterCode(placeUid)"
                />
            </div>
            <div class="r-menu-list" v-if="!currentCategory && !searchText">
                <div
                    class="r-menu-list__item"
                    v-if="
                        drinksToShow && drinksToShow.length && !drinksModeProp
                    "
                    @click="drinksMode = true"
                >
                    {{ drinkTitle }}
                </div>
                <div
                    class="r-menu-list__item"
                    v-for="(category, i) in categoriesToShow.categories"
                    :key="i"
                    @click="chooseCategory(category.uid)"
                >
                    {{ category.title }}
                </div>
            </div>
            <div class="r-menu-list" v-else-if="currentCategory === '1'">
                <div
                    class="r-menu-list__item"
                    v-for="(category, i) in dishesByCategory"
                    :key="i"
                    @click="chooseCategory(category.uid)"
                >
                    {{ category.title }}
                </div>
            </div>
            <swipe-list
                v-else-if="showItemsList"
                class="r-menu-dishes-list"
                :items="dishesByCategory"
                transition-key="created_at.seconds"
                :threshold="100"
                @swipeout:click="itemClick"
                @active="onActive"
                ref="list"
                :item-disabled="isDisabled"
            >
                <template slot-scope="{ item }">
                    <div
                        class="r-menu-dishes-list__item dishes-item"
                        :key="item.uid"
                        @click="selectDish(item.uid)"
                    >
                        <div
                            class="dishes-item__title"
                            :class="{ '-stop': !item.is_active }"
                        >
                            {{ item.title || "Untiteled" }}
                        </div>
                        <div
                            class="dishes-item__weight"
                            :class="{ '-stop': !item.is_active }"
                        >
                            {{ item.description || "." }}
                        </div>
                        <div class="dishes-item__price">
                            {{ currencySymbol }}
                            {{
                                (delivery ? item.delivery_price : item.price) ||
                                0
                            }}
                        </div>
                        <div class="dishes-item__onstop" v-if="!item.is_active">
                            <img
                                style="margin-top: 4px"
                                width="24px"
                                height="auto"
                                src="/img/icStop.png"
                                srcset="/img/icStop@3x.png 3x /img/icStop@2x.png 2x /img/icStop.png 1x"
                                class="m-list-item__icon"
                            />
                            <div class="m-list-item__text">
                                {{ $t("onstop") }}
                            </div>
                        </div>
                    </div>
                </template>
                <template slot="right" slot-scope="{ item, close }">
                    <div
                        class="swipeout-action action-panel-right"
                        @click="mixinAddToOrderHandler(item, close)"
                    >
                        <img
                            src="/img/iconPlusCircle.png"
                            srcset="/img/iconPlusCircle@3x.png 3x /img/iconPlusCircle@2x.png 2x /img/iconPlusCircle.png 1x"
                        />
                    </div>
                </template>
            </swipe-list>
            <div class="r-menu-bottom" v-if="!showItemsList"></div>
        </div>
        <DishModifiersModal
            v-if="modifiers && showModifiersModal"
            :modifiers="modifiers"
            :currencySymbol="currencySymbol"
            :addedModifiers="dishModifiers"
            :dish-to-return="dishToModefiers"
            @closeModifiers="closeModifiers"
        />
    </div>
</template>

<script>
import CategoryModal from "./CategoryModal";
import DishModifiersModal from "./DishModifiersModal";

import { SwipeList, SwipeOut } from "vue-swipe-actions";
import "vue-swipe-actions/dist/vue-swipe-actions.css";

import callWaiterMixin from "@/mixins/callWaiterMixin";
import addToOrderMixin from "@/mixins/addToOrderMixin";

export default {
    name: "RestrauntMenu",
    mixins: [callWaiterMixin, addToOrderMixin],
    props: {
        categories: {
            type: Array,
            default: () => [],
        },
        dishes: {
            type: Array,
            default: () => [],
        },
        currencySymbol: {
            type: String,
            default: "$",
        },
        drinks: {},
        drinksModeProp: {
            type: Boolean,
            default: false,
        },
        drinkTitle: {
            type: String,
            default: "Drinks",
        },
        isHotel: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CategoryModal,
        SwipeOut,
        SwipeList,
        DishModifiersModal,
    },
    inject: ["rootPlaceUid", "rootPlaceType"],
    data() {
        return {
            searchText: "",
            currentCategory: "",
            currentDish: "",
            categoriesScrollTop: 0,
            drinksMode: false,
            deliveryFlow: window.ordersStore.deliveryFlow,
            showModifiersModal: false,
            dishModifiers: [],
            modifiers: [],
            dishToModefiers: null,
        };
    },
    computed: {
        placeUid() {
            return this.rootPlaceUid;
        },
        placeType() {
            return this.rootPlaceType;
        },
        categoriesToShow() {
            const { currentCheckin, categories = [] } = this;
            const hiddenUids = {};
            if (currentCheckin)
                return {
                    categories,
                    hiddenUids,
                };
            return {
                hiddenUids,
                categories: categories.filter((category) => {
                    if (!category.for_checked_in_users_only) {
                        return true;
                    } else {
                        hiddenUids[category.uid] = true;
                        return false;
                    }
                }),
            };
        },
        drinksToShow() {
            const { currentCheckin, drinks = [] } = this;
            if (currentCheckin) return drinks;

            return drinks.filter(
                (category) => !category.for_checked_in_users_only
            );
        },
        supportedFlows() {
            return this.$PS.getSupportedFlowsByUid(this.placeUid);
        },
        delivery() {
            return this.$store.state.user.delivery;
        },
        showItemsList() {
            const { currentCategory, searchText } = this;
            return (currentCategory || searchText) && currentCategory !== "1";
        },
        dishesByCategory() {
            const { currentCategory, dishes, searchText, categoriesToShow } =
                this;
            const { hiddenUids } = categoriesToShow;
            if (searchText) {
                let _searchText = searchText.toLowerCase();
                return dishes
                    .filter(
                        (dish) =>
                            !hiddenUids[dish.category_uid] &&
                            ~dish._search.indexOf(_searchText)
                    )
                    .slice()
                    .sort((a, b) => {
                        const [aSort, bSort] = [a.sort_index, b.sort_index];
                        if (aSort > bSort) {
                            return 1;
                        } else if (aSort < bSort) {
                            return -1;
                        }
                        return 0;
                    });
            }
            if (!currentCategory) return [];
            return dishes
                .filter((dish) => dish.category_uid === currentCategory)
                .slice()
                .sort((a, b) => {
                    const [aSort, bSort] = [a.sort_index, b.sort_index];
                    if (aSort > bSort) {
                        return 1;
                    } else if (aSort < bSort) {
                        return -1;
                    }
                    return 0;
                });
        },
        currentCategoryItem() {
            const { currentCategory, dishesByCategory } = this,
                category = this.categories.find(
                    (cat) => cat.uid === currentCategory
                );
            return {
                category,
                dishes: dishesByCategory,
            };
        },
        isDrinks() {
            return this.drinksMode || this.drinksModeProp;
        },
        hideCarouselMenu() {
            return this.isDrinks || this.showItemsList;
        },
    },
    watch: {
        currentDish(val) {
            if (val) {
                const startAt = this.dishesByCategory.find(
                    (dish) => dish.uid === val
                );
                this.$bus.$emit("GlobalModal/dishesOpen", {
                    category: this.currentCategoryItem,
                    currencySymbol: this.currencySymbol,
                    startAt: (startAt && startAt.uid) || "",
                    onClose: this.selectDish,
                    placeUid: this.placeUid,
                    placeType: this.placeType,
                });
            }
        },
        hideCarouselMenu(val) {
            window.requestAnimationFrame(() => {
                this.$bus.$emit("openInfo", val);
            });
        },
    },
    methods: {
        isDisabled(item) {
            return !item.is_active;
        },
        onActive(value) {
            if (value) {
                this.closeAll();
            }
        },
        closeAll() {
            this.$refs.list?.closeActions();
        },
        itemClick(a) {
            this.closeAll();
            console.log("itemClick", a);
        },
        selectDish(uid) {
            window.requestAnimationFrame(() => {
                this.currentDish = uid;
            });
        },
        chooseCategory(uid) {
            if (!this.currentCategory && !uid && this.isDrinks) {
                this.$emit("closeDrinks");
            }
            const $menu = document.querySelector(".r-menu");
            if (uid) {
                this.categoriesScrollTop = $menu.scrollTop || 0;
            }

            window.requestAnimationFrame(() => {
                this.currentCategory = uid;
                const scrollToY = uid ? 0 : this.categoriesScrollTop;
                window.requestAnimationFrame(() => {
                    $menu.scrollTo(0, scrollToY);
                });
            });
        },
    },
};
</script>

<style lang="scss">
.r-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background: $black;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 70px;

    &-homechunk {
        position: fixed;
        top: 0;
        left: 0;
        height: 76px;
        width: 60px;
        background: $black;
    }
    &-toplace {
        position: fixed;
        top: 25px;
        left: 25px;
        z-index: 99;
    }

    &.-full {
        padding-bottom: 0px;
    }

    &-searchbar {
        @include flex(row, flex-start, center);
        position: fixed;
        padding: 18px 15px;
        top: 0;
        left: 0px;
        right: 0;
        background: $black;
        z-index: 1;

        &.-initial {
            left: 60px;
        }

        .back-icon {
            width: 40px;
            height: 30px;
            margin-right: 15px;
        }

        .search-icon {
            position: absolute;
            left: 25px;
            width: 14px;
            height: 14px;

            &.-short {
                left: 73px;
            }
        }

        .call-icon {
            position: absolute;
            right: 16px;
            width: 24px;
            margin-left: 16px;
        }

        input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
            outline: none;
            border: none;
            background: $darkGrey;
            padding: 5px 10px 5px 30px;
            @include font(17, 22);
            color: $white;
            margin-right: 40px;

            &::placeholder {
                color: $blueGrey;
                // @include font(17, 22);
            }
        }
    }

    &-list,
    &-dishes-list {
        width: 100%;
        margin-top: 63px;
        @include flex(column, flex-start, center);

        &__item {
            @include flex(column, center, center);
            min-height: 50px;
            width: 100%;
            border-bottom: 1px solid rgb(149, 149, 149);
            color: $white;
            @include font(18, 21, bold);
        }
    }

    &-dishes-list__item {
        @include flex(column, flex-start, flex-start);
        padding: 10px;
        position: relative !important;

        .dishes-item {
            &__title,
            &__weight,
            &__price {
                padding: 0 10px;
                text-align: left;
            }

            &__title,
            &__price {
                @include font(14, 16, bold);
                &.-stop {
                    @include textTruncate(calc(100% - 110px));
                }
            }

            &__weight {
                @include font(12, 14, normal);
                margin-top: 5px;
                @include textTruncate(calc(100% - 30px));

                &.-stop {
                    @include textTruncate(calc(100% - 110px));
                }
            }

            &__price {
                margin-top: 5px;
            }

            &__onstop {
                position: absolute;
                right: 0;
                bottom: 10px;
                @include flex(row, flex-start, flex-start);

                .m-list-item__text {
                    color: $white;
                    @include font(10, 14, bold);
                    // min-width: ;
                }
            }
        }
    }

    &-bottom {
        position: fixed !important;
        bottom: 0;
        width: 100%;
        height: 70px;
        z-index: 98;
        background: $black;
    }
}
.swipeout {
    &-list-item {
        width: 100%;
    }
}
.swipeout-action {
    width: 80px;
    background: green;
    position: relative;

    &.action-panel-right {
        background-color: rgb(50, 215, 75);
    }

    &.action-panel-left {
        background-color: rgb(255, 59, 48);
    }
    > img,
    .u-photo-del {
        @include pos-centered;
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
    }
}
</style>
