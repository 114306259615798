<template>
    <div class="dish-modifiers">
        <div class="dish-modifiers__head">
            <img
                class="dish-modifiers__head-back"
                src="/img/chevronLeft.svg"
                @click="$emit('closeModifiers')"
            />
            <div class="dish-modifiers__head-text">
                {{ $t("modifiers.dishModifiers") }}
            </div>
        </div>
        <div class="dish-modifiers__body">
            <div
                class="modifier-item"
                v-for="modifier in sortedModifiers"
                :key="modifier.uid"
            >
                <div class="modifier-item__label">
                    {{ modifier.title }}<span>{{ modifier.superValue }}</span>
                </div>
                <div class="modifier-item__description">
                    {{ modifier.description }}
                </div>
                <div
                    class="modifier-item__slab"
                    :class="{ '-optional': modifier.is_optional }"
                >
                    <div
                        class="modifier-item__slab-row"
                        v-for="(row, i) in getRow(modifier.options)"
                        :key="i"
                    >
                        <div
                            class="modifier-item__slab-col"
                            :data-count="row.length"
                            v-for="option in row"
                            :key="option.uid"
                            @click="setActive(modifier, option)"
                            :class="{
                                '-active': isActive(modifier, option) > -1,
                            }"
                        >
                            <div class="modifier-item__slab-col__text">
                                {{ option.name }}
                            </div>
                            <div
                                class="modifier-item__slab-col__text"
                                v-if="checkPrice(option.price)"
                            >
                                {{ currencySymbol }}{{ option.price }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="dish-modifiers__footer"
            :class="{ '-disabled': !enableButton }"
        >
            <div class="footer-btn" @click="addToOrder">
                {{ $t("order.addToOrder") }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DishModifiersModal",
    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        currencySymbol: {
            type: String,
            default: "$",
        },
        addedModifiers: {
            type: Array,
            default: () => [],
        },
        dishToReturn: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        sortedModifiers() {
            const modifiers = this.modifiers.map((modifier) => {
                return {
                    ...modifier,
                    sortValue: `${modifier.is_optional}${modifier.sort_index}${modifier.title}`,
                    superValue: !modifier.is_optional ? "*" : "",
                };
            });
            modifiers.sort((a, b) => {
                return a.sortValue.localeCompare(b.sortValue);
            });
            return modifiers;
        },
        requiredModifiers() {
            return this.modifiers.filter((m) => !m.is_optional);
        },
        enableButton() {
            const { requiredModifiers, addedModifiers } = this;
            const addedModifiersUids = addedModifiers.map(
                (m) => m.modifier_uid
            );
            const pickedReqs = requiredModifiers.reduce((memo, mod) => {
                if (~addedModifiersUids.indexOf(mod.uid)) {
                    memo.push(true);
                }
                return memo;
            }, []);
            return pickedReqs.length >= requiredModifiers.length;
        },
    },
    created() {
        this.$bus.modifiersOpen = true;
    },
    beforeDestroy() {
        this.$bus.modifiersOpen = false;
    },
    methods: {
        getRow(options = []) {
            const out = [],
                chunck = [];

            options.forEach((option) => {
                chunck.push(option);
                if (chunck.length === 3) {
                    out.push(JSON.parse(JSON.stringify(chunck)));
                    chunck.length = 0;
                }
            });
            if (chunck.length) {
                out.push(chunck);
            }
            return out;
        },
        isActive(modifier, option) {
            const addedModifiers = this.addedModifiers,
                modifier_uid = modifier.uid,
                modification_uid = option.uid;
            return addedModifiers.findIndex(
                (one) =>
                    one.modifier_uid === modifier_uid &&
                    one.modification_uid === modification_uid
            );
        },
        setActive(modifier, option) {
            const hasIndex = this.isActive(modifier, option),
                addedModifiers = this.addedModifiers,
                max_number = modifier.max_number;
            if (~hasIndex) {
                addedModifiers.splice(hasIndex, 1);
            } else {
                const modifier_uid = modifier.uid,
                    addedByModifier = addedModifiers.filter(
                        (one) => one.modifier_uid === modifier_uid
                    );
                if (addedByModifier.length < max_number) {
                    addedModifiers.push({
                        modifier_uid: modifier.uid,
                        modification_uid: option.uid,
                        name: option.name,
                        price: option.price,
                        uid: this.$uuid(),
                    });
                }
            }
        },
        checkPrice(price = "") {
            const out = Number(price);
            return out && !isNaN(out);
        },
        addToOrder() {
            this.$bus.$emit("SwipeUpDish");
            this.$emit("closeModifiers", this.dishToReturn);
        },
    },
};
</script>

<style lang="scss">
.dish-modifiers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 9999;
    transform: translate(0px);

    &__head {
        width: 100%;
        height: 54px;
        background: $white;
        position: relative;
        @include flex(row, flex-start, center);
        @include font(18, 24, 600);
        color: $black;
        padding-left: 16px;

        &-back {
            @include pos-centered;
            left: 25px;
        }
        &-text {
            @include pos-centered;
        }
    }

    &__body {
        width: 100%;
        height: calc(100% - 54px);
        overflow: scroll;
        text-align: left;
        color: $black;

        .modifier-item {
            margin: 24px 16px 16px 16px;

            &__label {
                @include font(20, 22, 500);

                > span {
                    vertical-align: super;
                    color: red;
                }
            }

            &__description {
                @include font(14, 18, normal);
            }

            &__slab {
                width: 100%;
                margin-top: 16px;
                @include flex(column, flex-start, center);
                border: 1px solid $black;
                border-radius: 5px;

                &.-optional {
                    border: none;

                    .modifier-item__slab-row {
                        &:not(:last-child) {
                            border-bottom: none;
                            margin-bottom: 5px;
                        }
                    }

                    .modifier-item__slab-col {
                        background: rgb(229, 229, 234);
                        border-radius: 5px;
                        color: rgb(142, 142, 147);

                        &[data-count="3"] {
                            width: calc(33.33% - 5px);
                            margin-left: 2.5px;
                            margin-right: 2.5px;

                            &:first-child {
                                margin-right: 2.5px;
                                margin-left: 0;
                                width: calc(33.33% - 2.5px);
                            }
                            &:last-child {
                                margin-left: 2.5px;
                                margin-right: 0;
                                width: calc(33.33% - 2.5px);
                            }
                        }
                        &[data-count="2"] {
                            width: calc(50% - 2.5px);
                            &:first-child {
                                margin-right: 2.5px;
                                margin-left: 0;
                            }
                            &:last-child {
                                margin-left: 2.5px;
                                margin-right: 0;
                            }
                        }
                        &[data-count="1"] {
                            width: 100%;
                        }

                        &:not(:last-child) {
                            border-right: none;
                        }

                        &.-active {
                            background: $black;
                            color: $white;
                        }
                    }
                }

                &-row {
                    width: 100%;
                    @include flex(row, flex-start, center);

                    &:not(:last-child) {
                        border-bottom: 1px solid $black;
                    }
                }

                &-col {
                    width: 33.33%;
                    height: 64px;
                    @include flex(column, center, center);
                    @include font(18, 20, 500);

                    &[data-count="3"] {
                        width: 33.33%;
                    }
                    &[data-count="2"] {
                        width: 50%;
                    }
                    &[data-count="1"] {
                        width: 100%;
                    }
                    &.-active {
                        background: $black;
                        color: $white;
                    }

                    &:not(:last-child) {
                        border-right: 1px solid $black;
                    }

                    &__text {
                        word-break: break-all;
                    }
                }
            }
        }
    }

    &__footer {
        @include pos-centered-h;
        position: fixed;
        bottom: 30px;
        width: 50%;
        height: 40px;
        color: $black;
        @include font(18, 22);
        @include flex(row, center, center);
        background-image: url("/img/buttonShape.png");
        background-repeat: no-repeat;
        background-size: contain;
        border: 1px solid black;
        border-radius: 5px;

        &.-disabled {
            opacity: 0.4;
            border: 1px solid #404040;
            background: #404040;
            // color: $white;
        }
    }
}
</style>
