<template>
    <div class="s-modal-dishes--wrap">
        <div class="s-modal-dishes" :class="{ '-short': openInfo }">
            <img
                v-if="openInfo"
                src="/img/backWhite.png"
                srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
                class="s-modal-dishes__back"
                @click="openCloseInfo"
            />
            <img
                v-if="!openInfoOnly"
                v-show="!openInfo"
                class="s-modal-dishes__more"
                src="/img/iconStatus.png"
                srcset="/img/iconStatus@3x.png 3x /img/iconStatus@2x.png 2x /img/iconStatus.png 1x"
                @click="openCloseInfo"
            />
            <div class="s-modal-dishes-paging">
                <div
                    class="s-modal-dishes-paging__page"
                    v-for="(dish, i) in dishes"
                    :key="i"
                    :class="{ '-active': i === index }"
                ></div>
            </div>
            <div class="s-modal-dishes-slider" :class="{ '-short': openInfo }">
                <div
                    class="s-modal-dishes-slider__item slider-item"
                    v-for="(dish, i) in dishes"
                    :class="{
                        '-active': i === index,
                        '-in-stop': !dish.is_active,
                    }"
                    :key="dish.uid"
                    :style="`background-image: url(&quot;${getImage(
                        dish
                    )}&quot;);`"
                    v-show="i <= index + 2 && i >= index - 2"
                >
                    <div class="category-name">{{ category.title }}</div>
                    <div class="backdrop-image"></div>
                    <div class="dish-info">
                        <div class="dish-info-rate">
                            <img
                                v-for="(star, i) in dishRate"
                                :key="i"
                                class="dish-info-rate__star"
                                :src="`/img/${star}@3x.png`"
                                width="24px"
                            />
                            <div
                                class="dish-info-rate__posts"
                                v-if="
                                    currentDish && currentDish.comments_number
                                "
                            >
                                {{ currentDish.comments_number }}
                                {{ $t("publications") }}
                            </div>
                        </div>
                        <div class="dish-info-price">
                            {{ currencySymbol }}
                            {{ delivery ? dish.delivery_price : dish.price }}
                            <span v-if="currentDish.is_price_per_base_amount">{{
                                amountPerUnitText
                            }}</span>
                        </div>
                        <div class="dish-info-title">{{ dish.title }}</div>
                    </div>
                    <div class="s-modal-dishes__stop" v-if="!dish.is_active">
                        <img
                            src="/img/combinedShape.png"
                            srcset="/img/combinedShape@3x.png 3x /img/combinedShape@2x.png 2x /img/combinedShape.png 1x"
                        />
                        <span>{{ dish.on_stop_title || $t("onstop") }}</span>
                    </div>
                    <div
                        class="s-modal-dishes__less"
                        style="background-image: url(/img/backWhite@3x.png)"
                        v-if="!openInfoOnly"
                        v-show="openInfo"
                        @click="openCloseInfo"
                    ></div>
                </div>
                <div
                    class="s-modal-dishes-slider__nav -prev"
                    @mouseup="goprev"
                    :class="{ '-stop': !currentDish || !currentDish.is_active }"
                ></div>
                <div
                    class="s-modal-dishes-slider__nav -next"
                    @mouseup="gonext"
                    :class="{ '-stop': !currentDish || !currentDish.is_active }"
                ></div>
            </div>

            <div class="s-modal-dish__info" v-if="currentDish && openInfo">
                <div class="m-list">
                    <div
                        class="m-list-item"
                        v-if="!currentDish || !currentDish.is_active"
                    >
                        <img
                            style="margin-top: 4px"
                            width="24px"
                            height="auto"
                            src="/img/icStop.png"
                            srcset="/img/icStop@3x.png 3x /img/icStop@2x.png 2x /img/icStop.png 1x"
                            class="m-list-item__icon"
                        />
                        <div class="m-list-item__text">{{ $t("onstop") }}</div>
                    </div>
                    <div class="m-list-item" v-if="currentDish.ingredients">
                        <img
                            width="24px"
                            height="auto"
                            src="/img/icSostav.png"
                            srcset="/img/icSostav@3x.png 3x /img/icSostav@2x.png 2x /img/icSostav.png 1x"
                            class="m-list-item__icon"
                        />
                        <div class="m-list-item__text">
                            {{ currentDish.ingredients }}
                        </div>
                    </div>
                    <div class="m-list-item" v-if="currentDish.description">
                        <img
                            width="24px"
                            height="auto"
                            src="/img/icInfoFood.png"
                            srcset="/img/icInfoFood@3x.png 3x /img/icInfoFood@2x.png 2x /img/icInfoFood.png 1x"
                            class="m-list-item__icon"
                        />
                        <div class="m-list-item__text">
                            {{ currentDish.description }}
                        </div>
                    </div>
                    <div
                        class="m-list-item"
                        v-if="modifiers"
                        @click="mixinOpenModifiers"
                    >
                        <img
                            width="24px"
                            height="auto"
                            src="/img/iconModify.png"
                            srcset="/img/iconModify@3x.png 3x /img/iconModify@2x.png 2x /img/iconModify.png 1x"
                            class="m-list-item__icon"
                        />
                        <div class="m-list-item__text">
                            {{ $t("modifiers.modifiers") }}
                        </div>
                        <img
                            class="m-list-item__arrow"
                            src="/img/arrow-right-white.svg"
                        />
                    </div>
                </div>
            </div>
            <div class="s-modal-dish__comments" v-if="currentDish && openInfo">
                <router-link
                    tag="div"
                    class="s-modal-dish__comments-comment"
                    v-for="comment in comments"
                    :key="comment.uid"
                    :style="`background-image: url(&quot;${comment.photo_uri}&quot;);`"
                    :to="`/itemposts/${currentDish.uid}`"
                >
                </router-link>
            </div>
        </div>
        <div
            class="s-modal-dishes__actions"
            v-if="currentDish && openInfo && !hideButtons"
        >
            <div class="add-to-order" @click="swipeUpDishHandler(true)">
                <img
                    src="/img/add-to-order.svg"
                    class="add-to-order__image"
                    alt=""
                />
                <div class="add-to-order__text">
                    {{ $t("order.addToOrder") }}
                </div>
            </div>
            <img
                v-if="orderLen || $checkins.length"
                key="notEmpty"
                class="m-cir-icon-button check-order"
                width="30px"
                height="30px"
                src="/img/iconCheck@3x.png"
                @click="openOrder"
            />
            <img
                v-else
                width="30px"
                height="30px"
                src="/img/iconCheckEmpty@3x.png"
                key="empty"
                class="m-cir-icon-button check-order"
                @click="openOrder"
            />
            <div class="check-order-counter" v-if="orderLen">
                {{ orderLen }}
            </div>
        </div>
        <DishModifiersModal
            v-if="modifiers && showModifiersModal"
            :modifiers="modifiers"
            :currencySymbol="currencySymbol"
            @closeModifiers="showModifiersModal = false"
            :addedModifiers="dishModifiers"
        />
    </div>
</template>
<script>
import { mapState } from "vuex";
import CategoryModal from "./CategoryModal";
import Icon from "./UI/Icon";
import DishModifiersModal from "./DishModifiersModal";
import addToOrderMixin from "@/mixins/addToOrderMixin";

export default {
    mixins: [addToOrderMixin],
    props: {
        category: {
            type: Object,
            required: true,
        },
        currencySymbol: {
            type: String,
            default: "$",
        },
        openInfoOnly: {
            type: Boolean,
            default: false,
        },
        startAt: {
            type: [Number, String],
            default: 0,
        },
        drinks: {
            type: Boolean,
            default: false,
        },
        currentCategoryUid: {
            type: String,
            default: "",
        },
        singleCategory: {
            type: Boolean,
            default: false,
        },
        hideButtons: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Icon,
        CategoryModal,
        DishModifiersModal,
    },
    data() {
        const startAt = this.startAt;
        return {
            index: typeof startAt === "string" ? 0 : startAt,
            openInfo: this.openInfoOnly,
            comments: [],
            dishesFromDB: [],
            showTranslate: false,
            order: window.ordersStore.orderItems,
            showModifiersModal: false,
            dishModifiers: [],
            adding: false,
            supportedFlows: null,
        };
    },
    inject: ["rootPlaceUid", "rootPlaceType"],
    computed: {
        ...mapState({
            delivery: (state) => state.user.delivery,
            swipeModalActive: (state) => state.places.swipeModalActive,
        }),
        startAtUid() {
            return typeof this.startAt === "string";
        },
        orderLen() {
            return this.order.length;
        },
        place() {
            const category = this.category?.category || this.category;
            const place_uid = category?.place_uid;
            return window.places.find((pl) => pl.uid === place_uid);
        },
        languages() {
            return this.place?.languages || [];
        },
        dishes() {
            const dishes = this.category?.dishes || this.dishesFromDB;
            return dishes.slice().sort((a, b) => {
                const [aSort, bSort] = [a.sort_index, b.sort_index];
                if (aSort > bSort) {
                    return 1;
                } else if (aSort < bSort) {
                    return -1;
                }
                return 0;
            });
        },
        currentDish() {
            return this.dishes[this.index];
        },
        modifiers() {
            const modifiers = this.currentDish?.modifiers;
            if (modifiers && modifiers.length) return modifiers;
            return null;
        },
        dishRate() {
            const rating = this.currentDish?.rating || 0;
            const out = [];
            for (let i = 1; i < 6; i++) {
                const range = rating - i;
                if (range >= 0 || range > -0.5) {
                    out.push("starDishRateGold");
                } else if (range > -1) {
                    out.push("starDishRateGoldHalf");
                } else {
                    out.push("starDishRateNone");
                }
            }
            return out;
        },
        currentUid() {
            return (this.dishes || [])[this.index]?.uid;
        },
        categoryIsCurrent() {
            return this.currentCategoryUid === this.category.uid;
        },
        amountPerUnitText() {
            if (!this.currentDish) return "";
            return `${this.$t("dishes.per")} ${this.$tc(
                `dishes.measures.${this.currentDish.measure_unit}`,
                Number(this.currentDish.measure_base_amount)
            )}`;
        },
    },
    watch: {
        currentDish: {
            immediate: true,
            handler: "getComments",
        },
        dishes: {
            immediate: true,
            handler(val) {
                if (val && val.length) {
                    const { startAtUid, startAt } = this;
                    if (startAtUid) {
                        this.index = val.findIndex((d) => d.uid === startAt);
                    }
                }
            },
        },
    },
    created() {
        this.$bus.$on("SwitchSlide", this.switchDishHandler);
        this.$bus.$on("SwipeUpDish", this.swipeUpDishHandler);
        this.$bus.$on("SlideChanged", this.resetIndex);
        const { menu_item } = this.$route.query;
        if (menu_item) {
            this.index = this.dishes.findIndex(
                (dish) => dish.uid === menu_item
            );
        }
        if (!this.category.dishes) {
            this.getDishes();
        }
    },
    beforeDestroy() {
        this.$bus.$off("SwitchSlide", this.switchDishHandler);
        this.$bus.$off("SwipeUpDish", this.swipeUpDishHandler);
        this.$bus.$off("SlideChanged", this.resetIndex);
        this.$set(this, "supportedFlows", null);
    },
    methods: {
        resetIndex() {
            if (this.index) {
                this.index = 0;
            }
        },
        switchDishHandler() {
            if ((this.$parent.isPrev || this.$parent.isNext) && this.openInfo) {
                this.openInfo = false;
                this.$bus.$emit("openInfo", false);
            }
        },
        swipeUpDishHandler(fromDetails = false) {
            /**
             * disable adding dish from bus when opened modifiers
             */
            if (this.swipeModalActive) return;
            if ((!this.categoryIsCurrent || this.openInfo) && !fromDetails) {
                return false;
            }

            const { currentCategoryUid, currentDish, singleCategory } = this;

            this.adding = true;

            if (!currentDish.is_active) return;
            const tryToAdd = () => {
                const addError = this.addToOrder();
                if (addError?.error === "has_unpaid_invoice") {
                    this.$bus.$emit(
                        "GOM/invoices/unpaidInvoice",
                        addError.invoice
                    );
                } else if (addError?.error === "checkin_in_another_place") {
                    this.mixinAnotherPlaceCheckinAlert(addError.place);
                } else if (addError?.error === "from_different_flows") {
                    this.mixinFromDifferentFlowsAlert(addError.deliveryFlow);
                } else if (addError === "has_checkin") {
                    this.mixinShowNotClosedCheckinAlert();
                } else if (addError === "modificators_required") {
                    this.mixinShowModificatorsAlert();
                } else {
                    this.dishModifiers = [];
                    this.$bus.$emit("alertShow");
                }
            };
            if (!singleCategory) {
                if (currentDish.category_uid === currentCategoryUid) {
                    tryToAdd();
                }
            } else {
                tryToAdd();
            }
        },
        getDishes: async function () {
            const dishes = (
                await this.$DB.db
                    .collection("menu_items")
                    .where("category_uid", "==", this.category.uid)
                    .get()
            ).docs.map((doc) => Object.freeze(doc.data()));
            if (dishes && dishes.length) {
                this.dishesFromDB.push(...dishes);
            }
        },
        getComments: async function (dish) {
            this.comments = [];
            this.dishModifiers = [];
            if (!dish) return;
            const { uid, comments_number } = dish;
            if (comments_number) {
                let comments = (
                    await this.$DB.db
                        .collection("comments")
                        .where("item_uid", "==", uid)
                        .where("moderation_status", "in", [
                            "approved",
                            "waiting_for",
                        ])
                        .get()
                ).docs.map((doc) => Object.freeze(doc.data()));
                comments.sort((a, b) => {
                    const [aName, bName] = [
                        a.created_at.seconds,
                        b.created_at.seconds,
                    ];
                    if (aName < bName) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                if (this.currentUid === uid) {
                    this.comments = comments;
                }
                // .where('moderation_status', '==', 'approved')
            }
        },
        addToOrder() {
            sessionStorage.setItem("isFirstOrder", "0");
            return window.ordersStore.addToOrder({
                dish: JSON.parse(JSON.stringify(this.currentDish)),
                currencySymbol: this.currencySymbol,
                selectedModifiers: this.dishModifiers,
                fromPlaceUid: this.rootPlaceUid,
            });
        },
        getImage({ photo_uri = "", comment_photo_uri = "" }) {
            if (!photo_uri && comment_photo_uri) {
                return comment_photo_uri;
            }
            if (
                !photo_uri ||
                photo_uri ===
                    "https://storage.googleapis.com/mayber-prod.appspot.com/"
            ) {
                return "/img/restaurantPlaceholder.jpg";
            }
            return photo_uri;
        },
        gonext() {
            setTimeout(() => {
                if (this.$parent.$hooper?.isSliding) {
                    return;
                }
                if (this.adding) {
                    this.adding = false;
                    return;
                }
                const len = this.dishes.length;
                const index = this.index;
                if (index + 1 === len) {
                    return;
                }
                window.requestAnimationFrame(() => {
                    this.index++;
                });
            }, 0);
        },
        goprev() {
            setTimeout(() => {
                if (this.$parent.$hooper?.isSliding) {
                    return;
                }
                if (this.adding) {
                    this.adding = false;
                    return;
                }
                const index = this.index;
                if (!index) {
                    return;
                }
                window.requestAnimationFrame(() => {
                    this.index--;
                });
            }, 0);
        },
        openCloseInfo() {
            this.$bus.$emit("GlobalModal/dishesOpen", {
                category: {
                    ...this.category,
                    dishes: this.dishes,
                },
                placeUid: this.rootPlaceUid,
                placeType: this.rootPlaceType,
                currencySymbol: this.currencySymbol,
                startAt: this.currentUid,
                dishInfoBackClose: true,
                onClose: (val, currentUid) => {
                    if (currentUid) {
                        const dishIndex = this.dishes.findIndex(
                            (dish) => dish.uid === currentUid
                        );
                        if (~dishIndex) {
                            this.index = dishIndex;
                        }
                    }
                },
            });
            // if (this.openInfoOnly) {
            //   this.$emit("close");
            // } else {
            //   this.openInfo = !this.openInfo;
            //   this.$bus.$emit("openInfo", this.openInfo);
            // }
        },
        openOrder() {
            this.$router.replace("/orders");
        },
    },
};
</script>
<style lang="scss">
.m-cir-icon-button.check-order {
    border-radius: 0px !important;
}
.m-list-item__arrow {
    @include pos-centered-v;
    right: 22px;
}
.s-modal-dish__translate {
    width: 150px;
    height: 36px;
    border: 2px solid $white;
    border-radius: 24px;
    @include pos-centered();
    @include flex(row, center, center);
    z-index: 2;
    color: $white;
    @include font(16, 16, normal);
    text-transform: uppercase;
    background: $black;
}
.s-modal-dish__info {
    margin-top: 10px;
    margin-bottom: 70px;
    // height: calc(50% - 70px);
    // overflow: scroll;
}
.s-modal-dish__comments {
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    &-comment {
        width: 33.1%;
        height: 124px;
        // display: inline-block;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 1px;
        &:not(:nth-child(3n)) {
            margin-right: 1px;
        }
    }
}
.s-modal-dishes--wrap {
    position: relative;
    height: 100%;
}

.s-modal-dishes {
    position: relative;
    background: $black;
    height: 100%;

    &__back,
    &__more,
    &__less,
    &__stop {
        z-index: 100;
        position: absolute;
    }

    &__back {
        top: 20px;
        left: 20px;
        width: 30px;
        height: 30px;
    }

    &__more {
        width: 24px;
        height: 24px;
        right: 17px;
        bottom: 134px;
    }

    &__less {
        z-index: 102;
        bottom: 30px;
        right: 10px;
        width: 48px;
        height: 48px;
        background: rgba(0, 0, 0, 0.5);
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 50%;
        backdrop-filter: blur(10px);
        transform: rotate(-90deg);
    }

    &__stop {
        @include pos-centered;
        @include flex(column, center, center);

        span {
            margin-top: 11px;
            color: $white;
        }

        .s-modal-dishes.-short & {
            span {
                @include font(24, 27, bold);
                margin-top: 22px;
            }
        }
        // @include flex(column, center, center);
        // position: absolute;
        // width: 100vw;
        // height: 50vh;
        // left: -10px;
        // bottom: -30px;
        // background: rgba(0, 0, 0, 0.7);

        // .stop-text {
        //   @include font(24, 27, bold);
        //   margin-top: 22px;
        //   color: $white;
        // }
    }

    &.-short {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    &-price {
        color: $white;
        @include font(24, 29, 500);
        text-shadow: 1px 1px #000;
        margin-top: 5px;
    }

    .m-list {
        background: $black;
        color: $white;
        .m-list-item__text {
            color: $white;
        }
    }

    &__actions {
        height: 0;

        .add-to-order {
            position: absolute;
            bottom: 40px;
            left: 11px;
            width: 36px;
            height: 36px;
            user-select: none;
        }

        .check-order {
            position: absolute;
            bottom: 45px;
            right: 15px;
            width: 30px;
            height: 30px;
            user-select: none;
        }

        .check-order-counter {
            position: absolute;
            bottom: 60px;
            right: 10px;
            user-select: none;
            background: red;
            @include font(10, 10);
            color: #ffffff;
            @include flex(row, center, center);
            padding: 5px;
            border-radius: 50%;
            min-width: 10px;
        }

        .text-left {
            width: 20%;
            // margin-left: 30px;
            @include font(30, 30);
        }

        .text-center {
            width: 60%;
            @include font(14, 32);
            text-transform: uppercase;
        }

        .text-right {
            width: 20%;
            position: relative;
        }
    }

    &-price {
        color: $white;
        @include font(24, 29, 500);
        text-shadow: 1px 1px #000;
        margin-top: 5px;

        span {
            @include font(15, 24, 500);
            margin-left: 10px;
        }
    }
    &-title {
        color: $white;
        @include font(24, 29, 500);
        text-align: left;
        text-shadow: 1px 1px #000;
        margin-top: 5px;
    }

    &-rate {
        @include flex(row, flex-start, center);
        margin-top: 12px;

        &__star {
            margin-right: 4px;
            user-select: none;
        }
    }

    &-paging {
        position: absolute;
        top: 5px;
        width: 100%;
        z-index: 101;
        @include flex(row, center, center);

        &__page {
            width: 100%;
            height: 3px;
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(10px);
            margin: 0 2px;

            &:last-child {
                margin-right: 10px;
            }
            &:first-child {
                margin-left: 10px;
            }
            &.-active {
                background-color: rgba(255, 255, 255, 0.9);
            }
        }
    }
    &-slider {
        width: 100%;
        height: 100%;
        position: relative;
        transition: height 0.2s linear;
        will-change: height;

        &.-short {
            height: 50%;
            transition: height 0.2s linear;

            .dish-info {
                bottom: 30px !important;
            }

            .s-modal-dishes-slider__nav {
                height: 100%;
            }
        }
        &__item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;

            &.-active {
                opacity: 1;
            }

            &.slider-item {
                .-short &.-in-stop::after {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                }

                .category-name {
                    position: absolute;
                    left: 10px;
                    top: 100px;
                    width: 90%;
                    text-align: left;
                    @include font(25, 25, bold);
                    color: $white;
                    text-shadow: 1px 1px #000;
                    z-index: 2;
                }

                .backdrop-image {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.1);
                }

                .dish-info {
                    position: absolute;
                    bottom: 100px;
                    left: 10px;
                    right: 63px;
                    z-index: 2;
                    @include flex(column-reverse, flex-start, flex-start);

                    &-price {
                        color: $white;
                        @include font(24, 29, 500);
                        text-shadow: 1px 1px #000;
                        margin-top: 5px;

                        span {
                            @include font(15, 24, 500);
                            margin-left: 10px;
                        }
                    }
                    &-title {
                        color: $white;
                        @include font(24, 29, 500);
                        text-align: left;
                        text-shadow: 1px 1px #000;
                        margin-top: 5px;
                    }

                    &-rate {
                        @include flex(row, flex-start, center);
                        margin-top: 12px;

                        &__star {
                            margin-right: 4px;
                            user-select: none;
                        }

                        &__posts {
                            @include font(17, 20, normal);
                            color: $white;
                            margin-left: 12px;
                        }
                    }
                }
            }
        }

        &__nav {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 50%;
            height: calc(100% - 180px);

            &.-prev {
                left: 0;
            }
            &.-next {
                right: 0;
            }
        }
    }
}
</style>
