<template>
  <div class="deep-link" id="root">
    <PlacepBanner
      v-if="isShowPlacepBanner"
      :promo-url="promoUrl"
      :city-name="cityName"
      :promo-image-url="promoImageUrl"
    />
    <div class="loader-wrapper" v-if="showLoader">
      <div class="loader-wrapper__loader">
        <div class="loader">
          <svg class="svg" viewBox="138 235 100 100">
            <path
              class="logo"
              fill="none"
              stroke="white"
              stroke-width="5"
              d="M161,322.6l6-74.7c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l20.7,74.4c0,0.1,0.1,0.1,0.1,0.1
								c0,0,0.1,0,0.1-0.1l20.6-74.4c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l6.1,74.7l0,0h-50.7c-7.7,0-14-6.3-14-14v-47.3
								c0-7.7,6.3-14,14-14h47.3c7.7,0,14,6.3,14,14V312c0,5.9-4.8,10.6-10.6,10.6l0,0l0,0"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="deep-link__content">
      <img class="deep-link__site-logo" src="/img/logo.svg" id="logo" />
      <!--
                Отрицание нужно, чтобы показать обе кнопки,
                если user agent не определён
            -->
      <div class="deep-link__store-links">
        <a
          v-if="!android"
          :href="appStoreLink"
          class="deep-link__store-link store-link store-link--app-store"
        >
          <span class="store-link__title">{{
            $t('app_download_link.app_store')
          }}</span>
          <!-- <span class="store-link__caption">{{ $t('app_download_link.call_to_action') }}</span> -->
        </a>
        <a
          v-if="!ios"
          :href="googlePlayLink"
          class="deep-link__store-link store-link store-link--google-play"
        >
          <span class="store-link__title">{{
            $t('app_download_link.google_play')
          }}</span>
          <!-- <span class="store-link__caption">{{ $t('app_download_link.call_to_action') }}</span> -->
        </a>
      </div>
      <button
        class="deep-link__continue-button"
        type="button"
        @click="goToWebApp"
      >
        {{ $t('cont_with_web') }}
      </button>
    </div>
  </div>
</template>

<script>
import { APPS_URLS } from '@/consts'
import PlacepBanner from '@/components/PlacepBanner'
import { SPB_RESTRAUTNS_IDS } from './rules'

const PLACES_WITH_DIRECT_REDIRECT = [
  'dd86a711-fc0a-4367-b043-0c57e797ed00',
  'ae92606b-3d67-4d89-95e6-c283f6dfd31b',
  'b6d7e93c-68fd-4f29-919f-a6174b752fb1',
  '5c5b9f52-976f-44d4-8d8a-04761982b9cb',
  '59f2e72f-ae6b-4263-8fb4-6fb86049d65e',
  '2f3a082a-38e7-4de1-8fe5-a6f26b324c26',
  'bc52d189-32e6-43e2-869e-bbb61b9e8e20',
  'f07275ba-456b-4f36-ab37-02af898b9b23',
  '375effe6-4026-44e2-bb85-a6d81ca65166',
  '69bc6add-216b-478d-b9dc-8bda369ae932',
  '7b76dbde-dffc-4974-bcae-5f00a19ba28c',
  '4af57de8-4584-46bf-8011-2ce703cdde65',
  'b3586ea6-2b3d-4106-913a-454d0c1605b2',
  '5a261142-09d9-4f69-ae87-672c3739280e',
  '86ec8df5-a082-48bd-ab61-ccd1c759c5a4',
  '487f50b1-dbb4-4114-a076-f7ba94e5bbc6',
  '55f95af4-68b1-4040-9e95-a5d3fc8aed00',
  '7871d6c3-a639-4efa-b957-5f521b675dab',
  '6b1e3a14-d829-4bd3-980a-7a2c9bdf8feb',
  '1867a2fb-34ae-4fb6-9f32-9a1efa1dac40',
  'a4ca2f02-a7f9-4243-aefd-6dabf7aed525',
  'a26a3672-4e5a-4feb-b975-bd33b9e4ceeb',
  'bd45cdb6-4b51-4d9b-98d8-1dfcb9673b9a',
  '984ccde6-72eb-4682-a3a2-df616c737175',
  '36a13f87-293a-4d04-bda9-bb43bcae66c4',
  '52cc5181-2dbd-4b77-8b06-22abc3d4ce9c',
  '2157a979-2872-4b5b-b611-b3f105c5f16d',
]

export default {
  components: {
    PlacepBanner,
  },
  data() {
    return {
      showLoader: true,
      isShowPlacepBanner: false,
      promoUrl: '',
      cityName: '',
      promoImageUrl: '',
    }
  },
  computed: {
    android() {
      const { ua = '' } = this.$route.query
      return ua.toLowerCase().trim() === 'android'
    },
    ios() {
      const { ua = '' } = this.$route.query
      return ua.toLowerCase().trim() === 'apple'
    },
    appStoreLink() {
      return APPS_URLS.apple
    },
    googlePlayLink() {
      const searchParams = new URLSearchParams()

      // Удаляем ненужный параметр для обратной ссылки из Google Play
      const query = { ...this.$route.query }
      delete query['ua']

      Object.entries(query).forEach(([key, value]) =>
        searchParams.append(key, value)
      )

      const backUrlParam = `https://mayber.com/place?${encodeURIComponent(
        searchParams.toString()
      )}`

      return `${APPS_URLS.google}&url=${backUrlParam}`
    },
    user() {
      return this.$store.state.user.user
    },
    place_uid() {
      return this.$route.query.place
    },
    doubleComputed() {
      return this.user?.uid && this.place_uid ? this.place_uid : ''
    },
  },
  watch: {
    doubleComputed: {
      immediate: true,
      handler: 'getPlace',
    },
  },
  methods: {
    getCityName(place) {
      return SPB_RESTRAUTNS_IDS.includes(place.uid) ? 'ПИТЕРА' : 'МОСКВЫ'
    },
    async getPlace(uid) {
      if (!uid) return

      if (~PLACES_WITH_DIRECT_REDIRECT.indexOf(uid)) {
        sessionStorage.setItem('hideDownloadAppModal', 'true')
        return this.goToWebApp()
      }
      try {
        const resp = await this.$axios.get(this.$API_URL(`/place/byUid/${uid}`))
        const place = resp.data?.payload?.place
        const landing_photo_uri = place?.landing_photo_uri
        if (landing_photo_uri) {
          this.$el.style['background-image'] = `url("${landing_photo_uri}")`
          this.$el.classList.add('-custom')
          const image = document.getElementById('logo')
          if (image) {
            image.style.display = 'none'
          }
        }
        if (place) {
          this.isShowPlacepBanner = !!place.promo
          this.promoUrl = place.promo_url || ''
          this.cityName = this.getCityName(place)
          this.promoImageUrl = place.promo_image_url || ''
        }
      } catch (e) {
        console.error('error in get place', e)
      }
      this.showLoader = false
    },
    setDevice: async function (user_uid, place_uid) {
      let answer = false
      try {
        await this.$axios.post(this.$API_URL('/users/setDevice'), {
          place_uid,
          user_uid,
          device: {
            device_uid: ' ',
          },
        })
        answer = true
      } catch (e) {
        console.log(e)
      }
      return answer
    },
    getMenuItem: async function (uid) {
      return (await this.$DB.db.collection('menu_items').doc(uid).get()).data()
    },
    goToWebApp: async function () {
      this.showLoader = true
      const place_uid = this.place_uid
      try {
        await this.setDevice(this.user?.uid, place_uid)
      } catch (e) {
        console.log('not inited user')
      }

      // return
      this.$store.commit('global/START_APPLICATION')

      const query = { ...this.$route.query }
      delete query['ua']

      if (this.$route.path.startsWith('/user') && this.$route.query.user) {
        delete query['user']

        this.$router.push({
          path: `/user/${this.$route.query.user}`,
          query,
        })
      } else {
        if (query.menu_item) {
          const item = await this.getMenuItem(query.menu_item)
          if (item) {
            query.language = item.language
            localStorage.setItem('deepLinkQuery', JSON.stringify(query))
          }
        }
        this.$router.replace({ path: '/places/' + place_uid })
      }
    },
  },
}
</script>

<style lang="scss">
/*
        Временное п
        ереопределение глобальных стилей.
        ToDo: убрать после отказа от "position: fixed"
    */
html.__deep-link__ #app {
  height: auto !important;
}

html.__deep-link__,
html.__deep-link__ body {
  overflow: auto !important;
  height: auto !important;
}
/* -- -- -- -- */

.deep-link {
  display: flex;
  min-height: MAX(665px, 100vh);
  background-color: $black;
  background-image: url('/img/deep-link-background@2x.jpg');
  background-repeat: no-repeat;
  background-position: center 275px;
  background-size: 361px auto;

  .safari & {
    min-height: -webkit-fill-available;
  }

  &.-custom {
    background-position: 50%, 50% !important;
    background-size: cover !important;
  }

  // @include retinaBackgroundImage("/img/deep-link-background@2x.jpg");

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 10px 32px;
    box-sizing: border-box;

    .safari & {
      min-height: 665px;
    }
  }

  &__site-logo {
    margin-bottom: 28px;
    width: 100px;
    height: 134px;
  }

  &__store-links {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__store-link {
    margin-bottom: 14px;
    width: 278px;
  }

  &__continue-button {
    @include font(16, 18, bold);
    position: fixed;
    bottom: 42px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 15px 29px;
    border: none;
    border-radius: 19px;
    background-color: rgba(255, 255, 255, 0.9);
    color: $black;
    font-family: inherit;
    letter-spacing: -0.15px;
    white-space: nowrap;
  }
}

.store-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 3px 0 77px;
  height: 90px;
  border-radius: 30px;
  background: linear-gradient(
    73.43deg,
    #ffffff -119.49%,
    rgba(0, 0, 0, 0.65) 49.42%
  );
  // background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
  color: $white;
  text-decoration: none;
  text-align: left;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 21px;
    left: 21px;
    width: 48px;
    height: 48px;
    border-radius: 19px;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.4);
  }

  &::after {
    content: '';
    position: absolute;
    background-position: top left;
    background-repeat: no-repeat;
  }

  &--app-store {
    &::after {
      top: 34px;
      left: 37px;
      width: 17px;
      height: 20px;
      background-image: url('/img/logo-apple.svg');
    }
  }

  &--google-play {
    &::after {
      top: 35px;
      left: 38px;
      width: 18px;
      height: 20px;
      background-image: url('/img/logo-google-play.svg');
    }
  }

  &--web {
    &::after {
      top: 35px;
      left: 38px;
      width: 18px;
      height: 20px;
      background-image: url('/img/web-2.svg');
    }
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.15px;
    white-space: pre-line;

    &.-web {
      @include font(16, 26, bold);
    }
  }

  &__caption {
    margin-top: 5px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.15px;
  }
}
.loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #3c445a;
  z-index: 1059;

  &__loader {
    width: 90%;
    @include pos-centered;

    .loader {
      width: 10em;
      height: 10em;
      margin: auto;
      margin-top: 30vh;
    }

    .svg {
      width: 10em;
      height: 10em;
    }

    .logo {
      stroke-dasharray: 584;
      stroke-dashoffset: 584;
      animation: loader 5s ease-in-out alternate infinite;
    }

    @keyframes loader {
      from {
        stroke-dashoffset: 584;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
  }

  &.-opacity {
    opacity: 0.5;
  }
}
</style>
