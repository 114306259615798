<template>
	<div 
		class="mailw" 
		:class="{'-open': open}"
		@click="$emit('submit', 'cancel')"
	>
		<div class="mailw__body" @click.stop="" :class="{'-fee': feeMode || paymentDocuments.length}">
			<div class="mailw__body-content" :class="{'-full-height': !isShowOkButton}">
				<div
					v-if="(feeMode && !accept.termsOfUse) || !isAllPaymentsDocumentsAccepted"
					class="mailw__body-close"
					@click="$emit('submit', 'cancel')"
				>
					<img src="/img/closeRound.svg" alt="">
				</div>
				<template v-if="!dontSendEmail">
					<div class="mailw__body-logo">
						<img src="/img/billWhiteCircle.svg" alt="">
					</div>
					<div class="mailw__body-text">
						{{ infoText }}
					</div>
					<div class="mailw__body-input">
						<input 
							v-if="isEmail"
							type="email" 
							v-model="text"
							:placeholder="$t('T000001')"
						>
						<textarea 
							v-else
							rows="4"
							v-model="text"
						/>
					</div>
				</template>
				<div class="mailw__body-checkboxes" v-if="feeMode || paymentDocuments.length">
					<div
						v-for="(paymentDocument, index) in paymentDocuments"
						:key="index"
						class="mailw__body-checkboxes__row"
					>
						<div class="mailw__body-checkboxes__row-checkbox" @click="paymentDocument.accepted = !paymentDocument.accepted">
							<img src="/img/Oval.svg" width="24px" height="24px" />
							<img
								v-if="paymentDocument.accepted"
								src="/img/combined-shape.svg" 
								width="14px" 
								height="20px" 
								class="checked-image" 
							/>
						</div>
						<div class="mailw__body-checkboxes__row-text">
							{{ $t('feeChecks.licencePartI') }}<a :href="paymentDocument.url" target="_blank">{{ paymentDocument.name }}</a>
						</div>
					</div>
					<template  v-if="feeMode">
						<div class="mailw__body-checkboxes__row">
							<div class="mailw__body-checkboxes__row-checkbox" @click="accept.termsOfUse = !accept.termsOfUse">
								<img src="/img/Oval.svg" width="24px" height="24px" />
								<img
									v-if="accept.termsOfUse"
									src="/img/combined-shape.svg" 
									width="14px" 
									height="20px" 
									class="checked-image" 
								/>
							</div>
							<div class="mailw__body-checkboxes__row-text">
								{{ $t('feeChecks.licencePartI') }}<a :href="`https://mayber.com/terms-of-use/${$lang}/`" target="_blank">{{ $t('feeChecks.licencePartII') }}</a>
							</div>
						</div>
						<div class="mailw__body-checkboxes__row" @click="accept.comission = !accept.comission">
							<div class="mailw__body-checkboxes__row-checkbox">
								<img src="/img/Oval.svg" width="24px" height="24px" />
								<img
									v-if="accept.comission"
									src="/img/combined-shape.svg" 
									width="14px" 
									height="20px" 
									class="checked-image" 
								/>
							</div>
							<div class="mailw__body-checkboxes__row-text">
								{{ $t('feeChecks.serviceFee') }} ({{ fee }} {{ currencySymbol }})
							</div>
						</div>
					</template>
				</div>
			</div>
			<!-- <div v-if="isInfalidEmail" class="mailw__body-error">{{ $t('auth.invalidEmail') }}</div> -->
			<div
				v-if="isShowOkButton"
				class="mailw__body-button" 
				@click.stop="applyEmail"
			>
				OK
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			open: false,
			text: '',
			isEmail: true,
			// isInfalidEmail: false,
			fee: 0,
			currencySymbol: '',
			accept: {
				termsOfUse: true,
				comission: true
			},
			paymentDocuments: [],
			dontSendEmail: false
		}
	},
	computed: {
		infoText() {
			return 	this.isEmail ? this.$t('delivery.emailText') : this.$t('order.commetToOrder')
		},
		feeMode() {
			return this.fee > 0
		},
		isAllPaymentsDocumentsAccepted() {
			return this.paymentDocuments.every(paymentDocument => paymentDocument.accepted)
		},
		isShowOkButton() {
			return (!this.feeMode || this.accept.termsOfUse) && this.isAllPaymentsDocumentsAccepted
		},
		isShowLargeForm() {
			return this.feeMode
		}
 	},
	watch: {
		open(val) {
			if (!val) {
				this.text = ''
				this.fee = 0
				this.isInfalidEmail = false
				this.currencySymbol = ''
				this.accept.termsOfUse = true
				this.accept.comission = true
				this.paymentDocuments.length = 0
				this.dontSendEmail = false
			}
		}
	},
	methods: {
		show(text = '', email = true, fee = 0, currencySymbol = '', paymentDocuments = [], dontSendEmail = false) {
			this.isEmail = email
			this.text = text
			this.fee = fee
			this.currencySymbol = currencySymbol
			this.paymentDocuments = paymentDocuments.map((paymentDocument) => {
				return {
					...paymentDocument,
					accepted: true
				}
			})
			this.dontSendEmail = dontSendEmail
			return new Promise((resolve, reject) => {
				this.$on('submit', (_mail, showFeeError = false) => {
					this.$off('submit')
					this.open = false
					if (_mail === 'cancel') {
						reject(showFeeError ? { toDo: 'showCancelFeeAlert'} : null)
					} else {
						resolve(_mail)
					}
				})
				this.open = true
			})
		},
		applyEmail() {
			if (!this.dontSendEmail) {
				const isValidEmail = this.$utils.validateEmail(this.text)
				if (!isValidEmail) {
					this.$iosAlert(this.$t('auth.invalidEmail'))
					return
				}
			}
			if (this.fee && !this.accept.comission) {
				this.$emit('submit', 'cancel', true)
			} else {
				if (this.dontSendEmail || this.text) {
					window.newPayWindow = window.open("/loader.html", "_blank")
				}
				this.$emit('submit', this.text)
			}
		}
	}
}
</script>
<style lang="scss" scoped>
	.mailw {
		@include fixed-modal(1060);
		background: $black;
		color: $white;
		@include flex(column, flex-start, center);
		display: none;

		&.-open {
			display: block;
		}

		&__body {
			@include b-gradient();
			width: 311px;
			height: auto;
			backdrop-filter: blur(27.1828px);
			/* Note: backdrop-filter has minimal browser support */
			border-radius: 10px;
			@include pos-centered();
			
			&.-fee {
				// height: 514px;

				&.-no-ok {
					margin-bottom: 52px;
				}

				.mailw__body-logo {
					margin-top: 32px;
				}
			}

			&-logo {
				height: 48px;
				margin-top: 73px;

				> img {
					width: 48px;
					height: 48px;
				}
			}

			&-text {
				@include font(20, 26, 400);
				margin: 30px 12px;
			}

			&-input {
				margin: 0 12px;
				
				> input, textarea {
					width: calc(100% - 20px);
					height: 46px;
					padding: 0 10px;
    				border: none;
					@include font(15, 18, 400);
					border-radius: 16px;
					outline: none;
				}
			}

			&-button {
				@include font(20, 22, 600);
				height: 54px;
				border-top: 1px solid rgba(255,255,255, .4);
				@include flex(row, center, center);
				width: 100%;
			}

			&-checkboxes {
				margin: 40px 12px 0 12px;

				&__row {
					@include flex(row, flex-start, flex-start);
					// &:first-child {
						margin-bottom: 24px;
					// }
					&-checkbox {
						position: relative;

						.checked-image {
							@include pos-centered-h();
							top: 2.5px;
						}
					}

					&-text {
						@include font(15, 18, 400);
						text-align: left;
						margin-left: 9px;

						> a {
							color: white;
							&:visited {
								color: white;
							}
						}
					}
				}
			}

			&-close {
				position: absolute;
				top: 16px;
				right: 16px;
				width: 30px;
				height: 30px;
				border-radius: 50%;

				> img {
					width: 100%;
					height: 100%;
				}
			}
			
			&-content {
				height: auto;
				// min-height: 335px;
				max-height: 514px;
				overflow: scroll;

				&.-full-height {
					height: 100%;
				}
			}
		}
	}
</style>