<template>
  <div class="us-view -card-list">
    <img
      src="/img/backWhite.png"
      srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
      class="back-icon"
      @click="$router.go(-1)"
    />
    <div class="us-view__header">{{ $t('MG-703-002') }}</div>
    <div class="us-view__body">
      <div class="us-view__body card-list">
        <swipe-list
          class="order-modal__body-checkins"
          :items="paymentSources"
          transition-key="uid"
        >
          <template slot-scope="{ item }">
            <PaymentCardListItem :item="item" />
          </template>
          <template slot="right" slot-scope="{ item, close }">
            <div
              class="swipeout-action action-panel-left"
              style="height: 74px"
              @click="removeCard(item, close)"
            >
              <img src="/img/trash-icon-white.svg" width="24px" height="28px" />
            </div>
          </template>
          <div slot="empty">list is empty ( filtered or just empty )</div>
        </swipe-list>
      </div>
      <div class="us-view__footer">
        {{ $t('MG-703-013') }}
      </div>
    </div>
  </div>
</template>
<script>
import { deletePaymentSource } from '@/init/api'

import { SwipeList, SwipeOut } from 'vue-swipe-actions'
import AddNewCard from './AddNewCard.vue'
import PaymentCardListItem from '@/components/PaymentCardListItem'

export default {
  components: {
    AddNewCard,
    SwipeList,
    SwipeOut,
    PaymentCardListItem,
  },
  data() {
    return {
      paymentSources: window.ordersStore.paymentSources,
    }
  },
  methods: {
    async removeCard(cardItem, closeFn) {  
      this.$store.dispatch('places/showLoader', true)
      try {
        if (closeFn) {
          closeFn()
        }
        const resp = await deletePaymentSource(cardItem.uid, cardItem.user_uid)
        if (resp?.error === 401) {
          await this.$bus.updateToken()
          this.removeCard(cardItem)
        } else if (
          this.$store.state.payments.preferredPaymentSourceUid === cardItem.uid
        ) {
          this.$store.commit('payments/SET_PREFERRED_PAYMENT_SOURCE_UID', '')
        }
      } catch (e) {
        console.error('can not remove card! ', e)
      } finally {
        this.$store.dispatch('places/hideLoader')
      }
    },
  },
}
</script>
<style lang="scss">
.us-view.-card-list {
  z-index: 106;

  .us-view__body {
    margin-top: 70px;
  }
}

.card-list {
  overflow: scroll;
  height: calc(100vh - 170px);
}

.us-view__footer {
  @include font(15, 18, 400);
  color: #c1c1c1;
  padding: 16px;
}
</style>
