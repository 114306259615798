<template>
  <div
    class="order-modal"
    :class="{ '-open': show, '-show-over-all': extraViewSettings.showOverAll }"
  >
    <TableCodeComponent
      v-if="codeInputShow"
      :tableCodScenario="tableCodScenario"
      @close="closeCodeTable"
      @update="updateCodes"
      @submit="submitCodes"
    />
    <HotelRegistrationComponent
      v-if="hotelRegistration.show"
      v-model="hotelRegistration.guestData"
      @submit="submitHotelRegistration"
      @close="closeHotelRegistration"
    />
    <div class="order-modal__header">
      <img
        src="/img/backWhite.png"
        srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
        class="back-icon"
        @click="backFromOrders"
      />
      <div class="order-modal__header-title">
        {{ $t('order.orders') }}
      </div>
      <div
        class="order-modal__header-tips"
        @click="tipsModal"
        v-if="cloud_tips"
      >
        <img src="/img/iconTipsAvaliable.png" width="100%" />
      </div>
    </div>
    <div
      v-if="!order.length && !checkinItems.length"
      class="order-modal__empty"
    >
      <img src="/img/empty-orders-image.svg" />
      <div class="order-modal__empty__text">
        {{ $t('order.emptyMessage') }}
      </div>
    </div>
    <div
      class="order-modal__body"
      :class="{
        '-payBottom': !order.length && needToPay && !deliveryFlow,
      }"
    >
      <swipe-list
        v-if="!isDeliveryCheckin && !unpaidInvoice"
        class="order-modal__body-checkins"
        :items="grouppedCheckins"
        transition-key="created_at.seconds"
      >
        <template slot-scope="{ item }">
          <OrderItem :checkin="item" />
        </template>
        <template slot="right" slot-scope="{ item, close }" v-if="!item.paid">
          <div
            class="swipeout-action action-panel-left"
            style="height: 104px"
            @click="removeFromCheckin(item.items, close)"
          >
            <img
              src="/img/closeCircleWhite.png"
              srcset="/img/closeCircleWhite@3x.png 3x /img/closeCircleWhite@2x.png 2x /img/closeCircleWhite.png 1x"
            />
            <span class="cancel-text">{{ $t('toCancelCap') }}</span>
          </div>
        </template>
        <div slot="empty">list is empty ( filtered or just empty )</div>
      </swipe-list>
      <div class="order-modal__body-checkins" v-else key="not_delete">
        <OrderItem
          v-for="(checkin, i) in grouppedCheckins"
          :key="i"
          :checkin="checkin"
          :invoices="invoices"
          :unpaidInvoice="unpaidInvoice"
          delivery
        />
      </div>
      <div class="order-modal__body-header" v-if="order.length">
        <div class="body-header__title">
          {{ $t('order.currentOrder') }}
        </div>
        <div
          class="body-header__pretotal -margin-16"
          v-if="
            deliveryFlow &&
            !takeAway &&
            hasPaidDelivery &&
            hasPaidDelivery.price
          "
        >
          <div>{{ $t('order.subtotal') }}:</div>
          <div>{{ currencySymbol }} {{ totalAmount.toFixed(2) }}</div>
        </div>
        <div class="body-header__pretotal -margin-16" v-if="showDeliveryPrice">
          <div>{{ $t('order.deliveryPrice') }}:</div>
          <div>
            {{
              hasPaidDelivery && hasPaidDelivery.price
                ? `${currencySymbol} ${hasPaidDelivery.price.toFixed(2)}`
                : $t('delivery.free')
            }}
          </div>
        </div>
        <div
          class="body-header__pretotal -error -margin-16"
          v-if="minimalAmountError"
        >
          {{ $t('delivery.errors.min_price_order') }}
          {{ currencySymbol }}{{ minimalAmountError.toFixed(2) }}
        </div>
        <template v-else>
          <div
            class="body-header__pretotal -margin-16"
            v-for="(addPayment, i) in mixinAdditionalCustomPaymentsPreCheck"
            :key="i"
          >
            <div>{{ addPayment.title }}</div>
            <div>
              {{ currencySymbol }}
              {{ addPayment.value.toFixed(2) }}
            </div>
          </div>
          <div class="body-header__pretotal -margin-16" v-if="serviceFee">
            <div>{{ $t('order.serviceFee') }}</div>
            <div>
              {{ currencySymbol }}
              {{ serviceFee.toFixed(2) }}
            </div>
          </div>
          <div class="body-header__total -margin-16">
            <div>{{ $t('order.total') }}</div>
            <div>
              {{ currencySymbol }}
              {{ totalAmountWithDelivery.toFixed(2) }}
            </div>
          </div>
        </template>
        <div
          class="body-header__pretotal -notice"
          v-if="!takeAway && !minimalAmountError && hasPaidDelivery"
        >
          {{ $t('order.left_for_free') }} {{ currencySymbol }}
          {{ hasPaidDelivery.leftUntil.toFixed(2) }}
        </div>
      </div>
      <swipe-list
        class="card order-modal__body-list"
        :items="order"
        transition-key="id"
      >
        <template slot-scope="{ item, close, index }">
          <!-- item is the corresponding object from the array -->
          <!-- index is clearly the index -->
          <!-- revealLeft is method which toggles the left side -->
          <!-- revealRight is method which toggles the right side -->
          <!-- close is method which closes an opened side -->
          <div class="card-content body-list__item__container" @click="close">
            <!-- style content how ever you like -->
            <div class="card-content body-list__item">
              <div
                class="body-list__item-image"
                :style="`background-image: url(${
                  item.photo_uri || '/img/restaurantPlaceholder.jpg'
                });`"
              ></div>
              <div class="body-list__item-info">
                <div class="body-list__item-info__name">
                  {{ item.title }}
                </div>
                <div class="body-list__item-info__price">
                  {{ currencySymbol }}
                  {{ deliveryFlow ? item.delivery_price : item.price }}
                  <img
                    v-if="
                      item.selectedModifiers && item.selectedModifiers.length
                    "
                    src="/img/iconRequiredModifierWarning.svg"
                    class="body-list__item-info__price__modifiers"
                    @click="item.showModifiers = !item.showModifiers"
                  />
                </div>
              </div>
            </div>
            <div
              class="card-content body-list__item-modificators"
              v-if="item.selectedModifiers && item.selectedModifiers.length"
              v-show="item.showModifiers"
            >
              <div class="card-content body-list__item-modificators-item">
                {{ $t('modifiers.modifiers') }}:
              </div>
              <div
                v-for="(mod, i) in item.selectedModifiers"
                :key="i"
                class="card-content body-list__item-modificators-item"
              >
                {{ mod.name }} - {{ mod.price }}{{ currencySymbol }}
              </div>
            </div>
            <div v-if="supportOrders" class="body-list__item-info__comment">
              <template v-if="item.comment">
                <div class="body-list__item-info__comment-text">
                  {{ item.comment }}
                </div>
                <img
                  src="/img/iconMessages.svg"
                  @click="addComment(index, item.comment)"
                />
                <!-- <div class="body-list__item-info__comment-edit" @click="addComment(index, item.comment)">
                                    {{ $t('order.edit') }} 
                                </div> -->
              </template>
              <span v-else @click="addComment(index)">
                {{ $t('order.addComment') }}
              </span>
              <!-- {{ item.comment || $t('order.addComment') }}
                            <div v-if="item.comment" class="body-list__item-info__comment-edit">
                                {{ $t('order.edit') }} 
                            </div> -->
            </div>
          </div>
        </template>
        <template slot="left" slot-scope="{ index, close }">
          <!-- left swipe side template and slot-scope="{ item }" is the item clearly -->
          <!-- remove <template slot="left" slot-scope="{ item }"> if you dont wanna have left swipe side  -->
          <div
            class="swipeout-action action-panel-left"
            @click="removeFromOrder(index, close)"
          >
            <img
              src="/img/iconMinus.png"
              srcset="/img/iconMinus@3x.png 3x /img/iconMinus@2x.png 2x /img/iconMinus.png 1x"
            />
          </div>
        </template>
        <template slot="right" slot-scope="{ item, close }">
          <!-- right swipe side template and slot-scope="{ item }" is the item clearly -->
          <!-- remove <template slot="right" slot-scope="{ item }"> if you dont wanna have right swipe side  -->
          <div
            class="swipeout-action action-panel-right"
            @click="addToOrder(item, close)"
          >
            <img
              src="/img/iconPlusCircle.png"
              srcset="/img/iconPlusCircle@3x.png 3x /img/iconPlusCircle@2x.png 2x /img/iconPlusCircle.png 1x"
            />
          </div>
        </template>
        <div slot="empty">
          <!-- change mockSwipeList to an empty array to see this slot in action  -->
          list is empty ( filtered or just empty )
        </div>
      </swipe-list>
      <div class="cook-review-info" v-if="compudedShowCookHelp">
        <div class="cook-review-info__chip"></div>
        <div class="cook-review-info__wrapper">
          <div class="cook-review-info__top">
            <div class="cook-review-info__top-left-icon">
              <img src="/img/cook-icon.svg" />
            </div>
            <div class="cook-review-info__top-text">
              {{ $t('order.tips.cookHelp.text') }}
            </div>
            <img
              class="cook-review-info__top-right-icon"
              src="/img/closeRound.svg"
              @click="closeCookHelp"
            />
          </div>
          <div class="cook-review-info__bottom" @click="openCookTutorial()">
            {{ $t('order.tips.cookHelp.button') }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="order.length">
      <div
        class="order-modal__clear"
        @click="removeFromOrder(-10, true)"
        v-if="!supportOrders"
        key="clearBtn"
      >
        {{ $t('order.clearOrder') }}
      </div>
      <div
        class="order-modal__clear"
        @click="submitOrder"
        v-else-if="!delivery"
        key="submitOrder"
      >
        {{ $t('order.accept') }}
      </div>
      <div
        class="order-modal__clear"
        :class="{ '-disabled': minimalAmountError }"
        @click="openDeliveryWindow"
        v-else
        key="confirmBtn"
      >
        {{ $t('order.accept') }}
      </div>
    </template>
    <div class="order-modal__b-window" v-show="deliveryWindow">
      <div class="b-window__body">
        <img
          class="b-window__body-close"
          src="/img/iconCloseGrey.svg"
          @click="
            deliveryWindow = false
            preCheckAdress = false
          "
        />
        <div class="b-window__body-text -header">
          {{
            preCheckAdress
              ? $t('order.adressPreCheckTitle')
              : $t('order.chooseVariant')
          }}
        </div>
        <div class="b-window__body-switcher" v-if="!preCheckAdress">
          <div
            class="b-window__body-switcher__item"
            :class="{ '-active': !deliveryData.take_away }"
            @click="deliveryData.take_away = false"
          >
            {{ $t('order.delivery') }}
          </div>
          <div
            class="b-window__body-switcher__item"
            :class="{ '-active': deliveryData.take_away }"
            @click="deliveryData.take_away = true"
          >
            {{ $t('order.samovivoz') }}
          </div>
        </div>
        <div class="dom-body" v-else>
          <div class="dom-body__item">
            <input
              type="text"
              autocomplete="chrome-off"
              placeholder=" "
              class="-grey"
              v-model="deliveryData.delivery.address"
            />
            <span class="dom-body__item-span">{{ $t('order.addres') }}</span>
          </div>
          <div class="dom-body__divider"></div>
        </div>
        <div class="b-window__body-confirmbtn" @click="preConfirm">OK</div>
      </div>
    </div>
    <div
      class="delivery-order-modal -tips"
      v-if="cloud_tips"
      v-show="tipsMode"
      @click="tipsMode = false"
    >
      <div class="tips-card">
        <div class="tips-card__item -header">ЧАЕВЫЕ</div>
        <div class="tips-card__item -border"></div>
        <div
          v-if="urls_for_tips.administrator_url"
          class="tips-card__item -tip-item"
          @click.stop="tipsType = 'administrator_url'"
        >
          <img src="/img/iconWaiter.svg" width="20px" class="tip-icon" />
          <div class="tip-item__text">Официанту</div>
          <img
            src="/img/circleFilled.png"
            width="48px"
            v-if="tipsType === 'administrator_url'"
            key="filledBtn"
          />
          <img src="/img/circleEmpty.png" width="48px" v-else key="emptyBtn" />
        </div>
        <div
          class="tips-card__item -tip-item"
          @click.stop="tipsType = 'kitchen_url'"
          v-if="urls_for_tips.kitchen_url"
        >
          <img src="/img/iconUnion.png" width="20px" class="tip-icon" />
          <div class="tip-item__text">Повару</div>
          <img
            src="/img/circleFilled.png"
            width="48px"
            v-if="tipsType === 'kitchen_url'"
            key="filledBtn"
          />
          <img src="/img/circleEmpty.png" width="48px" v-else key="emptyBtn" />
        </div>
        <a
          class="tips-card__item -ok"
          :href="urls_for_tips[tipsType]"
          target="_blank"
        >
          OK
        </a>
      </div>
    </div>
    <div class="delivery-order-modal" v-show="addressModal">
      <div class="dom-header">
        <img
          src="/img/backWhite.png"
          srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
          class="back-icon"
          @click="closeAddressModal"
        />
        {{ $t('order.doOrder') }}
      </div>
      <div class="dom-body">
        <div class="dom-body__item">
          <input
            type="text"
            autocomplete="chrome-off"
            placeholder=" "
            v-model="deliveryData.guest_name"
          />
          <span class="dom-body__item-span">{{ $t('order.name') }}</span>
        </div>
        <div class="dom-body__divider"></div>
        <div class="dom-body__item">
          <input
            v-model="deliveryData.phone"
            type="tel"
            autocomplete="chrome-off"
            placeholder=" "
          />
          <!-- <the-mask
                        :mask="'+7 (###) ###-##-##'"
                        v-model="deliveryData.phone"
                        :value="deliveryData.phone"
                        type="tel"
                        placeholder=" "
                        @focus="startInputPhone"
                    /> -->
          <span class="dom-body__item-span">{{ $t('order.contactTel') }}</span>
        </div>
        <div class="dom-body__divider -long"></div>
        <template v-if="!deliveryData.take_away">
          <div class="dom-body__item">
            <input
              type="text"
              autocomplete="chrome-off"
              placeholder=" "
              v-model="deliveryData.delivery.address"
            />
            <span class="dom-body__item-span">{{ $t('order.addres') }}</span>
          </div>
          <div class="dom-body__divider"></div>
          <div class="dom-body__splitter">
            <div class="dom-body__splitter-item">
              <div class="dom-body__item">
                <input
                  type="text"
                  autocomplete="chrome-off"
                  placeholder=" "
                  v-model="deliveryData.delivery.flat"
                />
                <span class="dom-body__item-span">{{ $t('order.flat') }}</span>
              </div>
              <div class="dom-body__divider"></div>
              <div class="dom-body__item">
                <input
                  type="text"
                  autocomplete="chrome-off"
                  placeholder=" "
                  v-model="deliveryData.delivery.porch"
                />
                <span class="dom-body__item-span">{{ $t('order.porch') }}</span>
              </div>
              <div class="dom-body__divider"></div>
            </div>
            <div class="dom-body__splitter-item">
              <div class="dom-body__item">
                <input
                  type="text"
                  autocomplete="chrome-off"
                  placeholder=" "
                  v-model="deliveryData.delivery.intercom_code"
                />
                <span class="dom-body__item-span">{{
                  $t('order.intercom_code')
                }}</span>
              </div>
              <div class="dom-body__divider"></div>
              <div class="dom-body__item">
                <input
                  type="text"
                  autocomplete="chrome-off"
                  placeholder=" "
                  v-model="deliveryData.delivery.floor"
                />
                <span class="dom-body__item-span">{{ $t('order.floor') }}</span>
              </div>
              <div class="dom-body__divider"></div>
            </div>
          </div>
          <div class="dom-body__item">
            <input
              type="text"
              autocomplete="chrome-off"
              placeholder=" "
              v-model="deliveryData.delivery.comment"
            />
            <span class="dom-body__item-span">{{ $t('order.comment') }}</span>
          </div>
          <div class="dom-body__divider"></div>
        </template>
      </div>
      <div
        class="dom-confirmbtn"
        :class="{
          '-disabled': !(deliveryData.guest_name && deliveryData.phone),
        }"
        @click="generateOrder()"
      >
        {{ $t('order.accept') }}
      </div>
    </div>
    <template v-if="!order.length">
      <div class="order-pay-control" v-if="needToPay && calculatedUnpaid.error">
        <div class="order-pay-control__body -error">
          {{ $t('delivery.errors.TOO_LOW_PRICE_FOR_DELIVERY') }}
        </div>
      </div>
      <HotelPaymentComponent
        v-else-if="!unpaidInvoice && needToPay && isHotel"
        :checkin="activeCheckin"
        :unpaidServiceFee="unpaidServiceFee"
        :currencySymbol="currencySymbol"
        :calculatedUnpaid="calculatedUnpaid"
        @callWaiter="callWaiter(false)"
        @payOrders="payOrders"
      />
      <div class="order-pay-control" v-else-if="needToPay">
        <div
          class="order-pay-control__header"
          v-if="!unpaidInvoice && !deliveryFlow && !isDeliveryCheckin"
        >
          <span
            ><span class="-uppercase">{{ $t('order.table') }}</span> #{{
              activeCheckin.object_number
            }}
            | {{ $t('order.guest') }} #{{ activeCheckin.guest_number }}</span
          >
          <img
            v-if="!unpaidInvoice"
            src="/img/bellWhite.svg"
            @click="callWaiter(false)"
          />
        </div>
        <div
          v-if="unpaidInvoice"
          class="order-pay-control__body"
          key="unpaidInvoice"
        >
          <div class="total-pay">
            <span>{{ $t('order.totalToPay') }}:</span>
            <span>{{ calculatedUnpaid.total }} {{ currencySymbol }}</span>
          </div>
          <div class="bodybtn -pay" @click="payOrders">
            {{ $t('delivery.buttons.pay') }}
          </div>
        </div>
        <div v-else key="notunpaidInvoice" class="order-pay-control__body">
          <div
            class="bodybtn -tips"
            v-if="cloud_tips && !deliveryFlow"
            @click="tipsModal"
          >
            {{ $t('order.leave_a_tip') }}
          </div>

          <div
            v-for="(addPayment, i) in mixinAdditionalCustomPayments"
            :key="i"
            class="total-pay -minus-m-b"
          >
            <span>{{ addPayment.title }}:</span>
            <span>{{ addPayment.value }} {{ currencySymbol }}</span>
          </div>
          <div class="total-pay -minus-m-b" v-if="unpaidServiceFee">
            <span>{{ $t('order.serviceFee') }}:</span>
            <span>{{ unpaidServiceFee }} {{ currencySymbol }}</span>
          </div>
          <div class="total-pay -minus-m-b" v-if="unpaidDelivery">
            <span>{{ $t('order.delivery') }}:</span>
            <span>{{ unpaidDelivery }} {{ currencySymbol }}</span>
          </div>
          <div class="total-pay">
            <span>{{ $t('order.totalToPay') }}:</span>
            <span>{{ calculatedUnpaid.total }} {{ currencySymbol }}</span>
          </div>
          <div class="bodybtn -pay" @click="payOrders">
            {{ $t('delivery.buttons.pay') }}
          </div>
        </div>
      </div>
      <div
        class="dom-confirmbtn -deferred"
        v-else-if="activeCheckinStatus === 'deferred'"
        @click="goToPay"
      >
        {{ $t(`delivery.buttons.${needToPay ? 'pay' : 'deffered'}`) }}
      </div>
    </template>
    <div class="order-modal__iframe" v-if="paymentData.confirmation_url">
      <div class="order-modal__iframe-head">
        <img
          width="30px"
          height="30px"
          src="/img/buttonClose.png"
          srcset="/img/buttonClose@3x.png 3x /img/buttonClose@2x.png 2x /img/buttonClose.png 1x"
          @click="clearPaymentData"
        />
      </div>
      <iframe
        :src="paymentData.confirmation_url"
        frameborder="no"
        id="pay-frame"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { TheMask } from 'vue-the-mask'
import { SwipeList, SwipeOut } from 'vue-swipe-actions'
import OrderItem from './OrderItem'
import TableCodeComponent from './TableCodeComponent'
import HotelRegistrationComponent from './HotelRegistrationComponent'
import HotelPaymentComponent from './HotelPaymentComponent'
import payMixin from '@/mixins/payMixin'
import callWaiterMixin from '@/mixins/callWaiterMixin'
import { CURRENCIES } from '@/consts'
import additionalPaymentsMixin from '@/mixins/additionalPaymentsMixin'

import { postCheckinPay } from '@/init/api'

import 'vue-swipe-actions/dist/vue-swipe-actions.css'
const extraViewSettingsDefaults = {
  showOverAll: false,
}
export default {
  mixins: [payMixin, callWaiterMixin, additionalPaymentsMixin],
  components: {
    TheMask,
    SwipeOut,
    SwipeList,
    OrderItem,
    TableCodeComponent,
    HotelRegistrationComponent,
    HotelPaymentComponent,
  },
  data() {
    return {
      show: false,
      deliveryData: {
        take_away: false,
        phone: '+',
        guest_name: '',
        delivery: {
          address: '',
          client_phone: '',
          comment: '',
          flat: '',
          floor: '',
          intercom_code: '',
          porch: '',
        },
      },
      deliveryWindow: false,
      addressModal: false,
      tipsMode: false,
      tipsType: 'administrator_url',
      preCheckAdress: false,
      busy: false,
      codeInputShow: false,
      guest_number: 0,
      table_code: 0,
      places: this.$PS.data,
      tableCodScenario: false,
      tableCodScenarioPlaceUid: '',
      hotelRegistration: {
        show: false,
        guestData: {
          way_to_treat: 'Mr.',
          guest_name: '',
          guest_last_name: '',
          object_number: '',
        },
      },
      showCookHelp: !localStorage.getItem('hideCookHelp'),
      approvedOrDeniedItemUids: {},
      hotelRegistrationScenario: false,
      hotelCheckinInfoOpened: false,
      unpayedCheckin: null,
      unpayedCheckinItems: [],
      unpaidInvoiceTimer: null,
      extraViewSettings: {
        showOverAll: false,
      },
    }
  },
  computed: {
    ...mapState('user', {
      delivery: (state) => state.delivery,
      user_uid: (state) => state.user?.uid,
      idToken: (state) => state.idToken,
      user: (state) => state,
    }),
    order() {
      return this.ordersStore?.orderItems ?? []
    },
    apiBusy() {
      return this.ordersStore?.apiBusy
    },
    currencySymbol() {
      return CURRENCIES[this.place?.currency || 'USD']
    },
    invoices() {
      return this.ordersStore?.invoices ?? []
    },
    unpaidInvoices() {
      return this.ordersStore?.unpayedInvoices ?? []
    },
    unpaidInvoice() {
      if (!this.unpaidInvoices.length) return null

      return this.unpaidInvoices.reduce((acc, invoice, index) => {
        if (!index) {
          return {
            ...invoice,
            uids: [invoice.uid],
          }
        } else {
          acc.uids.push(invoice.uid)
          acc.item_uids.push(...invoice.item_uids)
          acc.left_to_pay =
            Number(acc.left_to_pay) + Number(invoice.left_to_pay)
          acc.paid = Number(acc.paid) + Number(invoice.paid)
          acc.sub_total = Number(acc.sub_total) + Number(invoice.sub_total)
          acc.total = Number(acc.total) + Number(invoice.total)
          return acc
        }
      }, {})
    },
    showDeliveryPrice() {
      return (
        this.deliveryFlow &&
        this.deliveryData.delivery.address &&
        !this.takeAway &&
        !this.minimalAmountError
      )
    },
    isDeliveryCheckin() {
      const type = this.activeCheckin?.type
      return !!~['take_away', 'delivery'].indexOf(type)
    },
    compudedShowCookHelp() {
      return (
        this.checkinItems.some(
          (checkinItem) => checkinItem.status === 'ready'
        ) && this.showCookHelp
      )
    },
    grouppedCheckins() {
      const {
        activeCheckin = {},
        isDeliveryCheckin,
        invoices,
        checkinItems,
      } = this
      if (!checkinItems.length) return []
      const _byTime = checkinItems.reduce((memo, item) => {
        const seconds = item?.created_at?.seconds
        if (!memo.has(seconds)) {
          memo.set(seconds, [item])
        } else {
          memo.get(seconds).push(item)
        }
        return memo
      }, new Map())
      const _byTimeKeys = Array.from(_byTime.keys())
      _byTimeKeys.sort((a, b) => {
        return a - b
      })
      return _byTimeKeys.map((seconds) => {
        const items = _byTime.get(seconds)
        const item = items.find((i) => i.status !== 'canceled')
        const invoiceUid = item?.invoice_uid
        let paid = false
        if (invoiceUid) {
          const invoice = invoices.find((i) => i.uid === invoiceUid)
          if (invoice?.left_to_pay == 0) {
            paid = true
          }
        }
        return {
          ...activeCheckin,
          items: _byTime.get(seconds),
          created_at: {
            seconds,
          },
          paid,
        }
      })
    },
    takeAway() {
      return this.deliveryData.take_away
    },
    deliveryFlow() {
      return this.ordersStore?.deliveryFlow
    },
    deliveryZoneParams() {
      const deliveryZone = this.ordersStore?.deliveryZone
      if (deliveryZone) {
        return deliveryZone
      }
      return null
    },
    activeCheckin() {
      return this.unpayedCheckin || this.$checkins[0]
    },
    activeCheckinStatus() {
      return this.activeCheckin?.status
    },
    needToPay() {
      const activeCheckin = this.activeCheckin
      if (!activeCheckin) return false
      const leftToPay = Number(activeCheckin.left_to_pay)
      if (activeCheckin.type === 'hotel') {
        return leftToPay && !isNaN(leftToPay)
      }
      return (
        ['approved', 'canceled'].includes(activeCheckin.status) &&
        leftToPay &&
        !isNaN(leftToPay)
      )
    },
    totalAmount() {
      return this.ordersStore?.totalAmount || 0
    },
    calculatedSubTotal() {
      return this.ordersStore?.calculated?.sub_total || 0
    },
    serviceFee() {
      return this.ordersStore?.serviceFee || 0
    },
    totalAmountWithDelivery() {
      const totalAmount = this.totalAmount
      const deliveryAmount = this.hasPaidDelivery?.price || 0
      return totalAmount + deliveryAmount
    },
    openFromUid() {
      return this.$route.query.openFromUid
    },
    place_uid() {
      const { order, $checkins, openFromUid, unpaidInvoice } = this
      return (
        unpaidInvoice?.place_uid ||
        order?.[0]?.fromPlaceUid ||
        $checkins?.[0]?.place_uid ||
        openFromUid
      )
    },
    place() {
      const { places, place_uid } = this
      return places.find((p) => p.uid === place_uid)
    },
    placeFull() {
      return this.place?.full
    },
    supported_flows() {
      return this.place?.supported_flows
    },
    cloud_tips() {
      return this.supported_flows?.cloud_tips
    },
    supportOrders() {
      return this.supported_flows?.orders
    },
    urls_for_tips() {
      return this.place?.urls_for_tips
    },
    minimalAmountError() {
      const { deliveryZoneParams, calculatedSubTotal } = this,
        _totalAmount = Number(calculatedSubTotal)
      if (!(deliveryZoneParams && deliveryZoneParams.place_uid)) return false
      const min_price = deliveryZoneParams.min_price
      if (min_price) {
        if (_totalAmount < min_price) {
          return min_price
        }
      }
    },
    hasPaidDelivery() {
      const { deliveryZoneParams, totalAmount } = this,
        _totalAmount = Number(totalAmount)
      if (!(deliveryZoneParams && deliveryZoneParams.place_uid)) return false
      const { price, price_to_free } = deliveryZoneParams
      if (price_to_free && _totalAmount < price_to_free) {
        return {
          price,
          leftUntil: price_to_free - _totalAmount,
        }
      }
    },
    checkin_type() {
      const {
        deliveryData: { take_away },
        deliveryFlow,
        isHotel,
      } = this
      if (isHotel) return 'hotel'

      if (!deliveryFlow) return 'restaurant'

      return take_away ? 'take_away' : 'delivery'
    },
    object_uid() {
      return this.$checkins?.[0]?.object_uid
    },
    urlPath() {
      return this.$route.path
    },
    isHotel() {
      return this.hotelRegistrationScenario || this.place?.type === 'hotel'
    },
    queryZIndex() {
      return this.$route.query.zIndex
    },
  },
  watch: {
    place_uid: {
      immediate: true,
      handler: 'getDeliveryZone',
    },
    show: {
      immediate: true,
      handler: 'showModal',
    },
    urlPath: {
      immediate: true,
      handler: 'openCloseModal',
    },
    deliveryData: {
      deep: true,
      handler(val) {
        if (val?.delivery?.address) {
          // this.ordersStore.calculateOdrer('', val.delivery.address)
        }
        try {
          localStorage.setItem('deliveryData', JSON.stringify(val))
        } catch (e) {
          console.log('error in save deliveryData in localStorage', e)
        }
      },
    },
    apiBusy(value) {
      if (!this.show) return
      if (value) {
        this.$store.dispatch('places/showLoader', true)
      } else {
        this.$store.dispatch('places/hideLoader')
      }
    },
    placeFull(val) {
      if (val === false) {
        this.$PS.updatePlaces(this.place_uid)
      }
    },
    unpaidInvoice(invoice) {
      if (this.unpaidInvoiceTimer) {
        clearTimeout(this.unpaidInvoiceTimer)
      }
      if (invoice) {
        this.unpaidInvoiceTimer = setTimeout(() => {
          this.onUnpaidInvoice(invoice)
          this.getUnpayedCheckin(invoice)
        }, 2000)
      } else {
        if (this.unpaidInvoiceTimer) {
          clearTimeout(this.unpaidInvoiceTimer)
        }
        this.$set(this, 'unpayedCheckin', null)
      }
    },
    'order.length'(value) {
      if (!value) {
        this.preCheckAdress = false
        window.ordersStore.updateDeliveryZone({}, true)
        this.deliveryData.delivery.address = ''
      }
    },
  },
  created() {
    this.$bus.$on('GOM/invoices/unpaidInvoice', this.onUnpaidInvoice)
    this.$bus.$on(
      'GOM/checkinItem/priceChanged',
      this.onCheckinItemPriceChanged
    )
    this.$bus.$on('GOM/generateOrder', this.generateOrder)
  },
  methods: {
    async getUnpayedCheckin(invoice) {
      this.unpayedCheckin = null
      this.unpayedCheckinItems.length = 0
      const ref = this.$DB.db.collection('checkins').doc(invoice.checkin_uid)
      const doc = await ref.get()
      if (doc.exists) {
        this.$set(this, 'unpayedCheckin', doc.data())
        const items = (
          await this.$DB.db
            .collection('order_items')
            .where('invoice_uid', 'in', invoice.uids)
            .get()
        ).docs.map((doc) => Object.freeze(doc.data()))
        this.unpayedCheckinItems.push(...items)
      }
    },
    checkUnpaidInvoice(showModal = false) {
      if (this.unpaidInvoice) {
        if (showModal) {
          this.onUnpaidInvoice(this.unpaidInvoice)
        }
        return true
      }
      return false
    },
    async onUnpaidInvoice(invoice) {
      const ref = this.$DB.db.collection('invoices').doc(invoice.uid)
      const doc = await ref.get()
      if (doc.exists && doc.data().left_unpaid !== '0') {
        this.$bus.$emit('openContextMenu', {
          config: {
            type: 'modal',
            style: 'pink',
            title: this.$t('order.errors.HAS_UNPAID_INVOICE.title'),
            subtitle: this.$t('order.errors.HAS_UNPAID_INVOICE.subtitle'),
            icon: '/img/iconAttention.svg',
            disableBackdropClose: true,
          },
          actions: [
            {
              id: 'ok',
              text: this.$t('order.errors.HAS_UNPAID_INVOICE.button0'),
            },
            {
              id: 'ok',
              text: this.$t('order.errors.HAS_UNPAID_INVOICE.button1'),
            },
          ],
          resolve: (index) => {
            if (!index) {
              this.$router.replace(`/orders?openFromUid=${invoice.place_uid}`)
            }
          },
          reject: () => ({}),
        })
      }
    },
    closeCookHelp() {
      this.showCookHelp = false
      localStorage.setItem('hideCookHelp', true)
    },
    checkNeedApprovals(currencySymbol, skipUid = '') {
      const needApprovalItem = this.checkinItems.find(
        (item) =>
          item.uid !== skipUid &&
          item.status === 'needs_user_approval' &&
          item.measure_amount
      )
      if (needApprovalItem) {
        this.onCheckinItemPriceChanged(needApprovalItem, currencySymbol)
      }
    },
    onCheckinItemPriceChanged(entity, currencySymbol) {
      this.$bus.$emit('openContextMenu', {
        config: {
          type: 'modal',
          style: 'pink',
          title: this.$t('order.needsUserAprrovalWeightModal.title'),
          subtitle: this.$t('order.needsUserAprrovalWeightModal.text', [
            entity.title,
            currencySymbol,
            entity.price,
          ]),
          icon: '/img/iconAttention.svg',
          disableBackdropClose: true,
        },
        actions: [
          {
            id: 'ok',
            text: this.$t('order.needsUserAprrovalWeightModal.accept'),
          },
          {
            id: 'ok',
            text: this.$t('order.needsUserAprrovalWeightModal.decline'),
          },
          {
            id: 'ok',
            text: this.$t('order.needsUserAprrovalWeightModal.callTheWaiter'),
          },
        ],
        resolve: (index) => {
          if (!index) {
            // send user confirm
            this.confirmWeightApi([entity]).then(() => {
              this.checkNeedApprovals(currencySymbol, entity.uid)
            })
          } else if (index === 1) {
            // show new Modal with delete item
            window.requestAnimationFrame(() => {
              this.confirmDeleteITemFromOrder(entity, currencySymbol)
            })
          } else {
            // call the waiter
            this.callWaiter().then(() => {
              this.onCheckinItemPriceChanged(entity, currencySymbol)
            })
          }
        },
        reject: () => ({}),
      })
    },
    confirmDeleteITemFromOrder(entity, currencySymbol) {
      this.$bus.$emit('openContextMenu', {
        config: {
          type: 'modal',
          style: 'pink',
          title: this.$t('order.approveRemoveItemModal.title'),
          subtitle: this.$t('order.approveRemoveItemModal.text', [
            entity.title,
          ]),
          icon: '/img/iconAttention.svg',
          disableBackdropClose: true,
        },
        actions: [
          {
            id: 'ok',
            text: this.$t('order.approveRemoveItemModal.yes'),
          },
          {
            id: 'ok',
            text: this.$t('order.approveRemoveItemModal.no'),
          },
        ],
        resolve: (index) => {
          if (!index) {
            // delete item
            this.removeFromCheckin([entity]).then(() => {
              this.checkNeedApprovals(currencySymbol, entity.uid)
            })
          } else {
            // return to prev modal
            this.onCheckinItemPriceChanged(entity, currencySymbol)
          }
        },
        reject: () => ({}),
      })
    },
    confirmWeightApi: async function (items) {
      this.$store.dispatch('places/showLoader', true)
      const uids = items.map((i) => i.uid)
      try {
        const { data } = await this.$axios.post(
          this.$API_URL('/orders/status/user_approve'),
          {
            item_uids: uids,
            object_uid: this.object_uid,
            place_uid: this.place_uid,
            user_uid: this.user_uid,
          }
        )
        if (data.status === 'ERROR') {
          this.$iosAlert({
            title: this.$t('error'),
            text: data.error_code,
          })
        }
      } catch (e) {
        console.log('this.$iosAlert(this.$t("order.waiterCoomingSoon"));', e)
      }
      this.$store.dispatch('places/hideLoader')
      return true
    },
    tableCodScenarioMethod(place_uid) {
      this.tableCodScenario = true
      this.tableCodScenarioPlaceUid = place_uid
      this.show = true
      this.codeInputShow = true
    },
    roomServiceScenarioMethod(place_uid) {
      this.hotelRegistration.show = true
      this.tableCodScenarioPlaceUid = place_uid
      this.hotelRegistrationScenario = true
      this.show = true
    },
    backFromOrders() {
      const backToPlaceUrl = sessionStorage.getItem('backToPlaceUrl')
      this.$router.replace(backToPlaceUrl || '/').catch(() => {})
    },
    closeAddressModal() {
      this.addressModal = false
      try {
        this.deliveryData.delivery.address = ''
      } catch (e) {
        console.log(e)
      }
    },
    goToPay() {
      const activeCheckin = this.activeCheckin
      if (activeCheckin.status === 'approved') {
        this.$router.replace('/orders/' + activeCheckin.uid + '?pay=true')
      }
    },
    openDeliveryWindow() {
      if (this.deliveryData?.delivery?.address) {
        this.addressModal = true
      } else {
        this.deliveryWindow = true
      }
    },
    pickAddress(currentItem) {
      return new Promise((resolve) => {
        this.$bus.$off('AddresPickerClose')
        this.$bus.$on('AddresPickerClose', (succes) => {
          this.$bus.$off('AddresPickerClose')
          resolve(succes)
        })
        this.$bus.$emit('AddresPickerOpen', currentItem)
      })
    },
    preConfirm: async function () {
      this.$store.dispatch('places/showLoader', true)
      const deliveryData = this.deliveryData,
        take_away = deliveryData.take_away
      let address = deliveryData.delivery.address
      if (!address && !take_away) {
        address = await this.pickAddress(this.place)
        if (address) {
          this.deliveryData.take_away = false
          this.deliveryData.delivery.address = address
        }
      }
      if (!this.deliveryData.take_away) {
        if (!address) {
          this.$store.dispatch('places/hideLoader')
          return false
        }
        const allowDelivery = await this.checkDeliveryZone(
          address,
          this.place_uid
        )
        if (allowDelivery.success) {
          let { min_price, price, price_to_free } = allowDelivery.data
          min_price = Number(min_price)
          price = Number(price)
          price_to_free = Number(price_to_free)
          window.ordersStore.updateDeliveryZone(allowDelivery.data)
          // this.$set(this, "deliveryZoneParams", {
          //     ...allowDelivery.data,
          //     min_price,
          //     price,
          //     price_to_free,
          // });
          this.$nextTick(() => {
            const minimalAmountError = this.minimalAmountError,
              hasPaidDelivery = this.hasPaidDelivery
            this.preCheckAdress = false
            this.deliveryWindow = false
            if (!minimalAmountError && !hasPaidDelivery) {
              this.addressModal = true
            }
          })
          return true
        } else {
          const config = {
              type: 'modal',
              style: 'pink',
              title: '',
              subtitle: '',
              icon: '',
            },
            actions = [
              {
                id: 'ok',
                text: 'OK',
              },
            ]
          switch (allowDelivery.error_code) {
            case 'NO_SUITABLE_DELIVERY_ZONE':
              config.title = this.$t(
                'delivery.errors.NO_SUITABLE_DELIVERY_ZONE.title'
              )
              config.subtitle = this.$t(
                'delivery.errors.NO_SUITABLE_DELIVERY_ZONE.text'
              )
              config.icon = '/img/iconLocationFail.svg'
              break
            case 'INVALID_ADDRESS':
              config.title = this.$t('delivery.errors.INVALID_ADDRESS.title')
              config.subtitle = this.$t('delivery.errors.INVALID_ADDRESS.text')
              config.icon = '/img/iconLocationFail.svg'
              break
            default:
              break
          }
          if (config.title) {
            this.$bus.$emit('openContextMenu', {
              config,
              actions,
              resolve: () => ({}),
              reject: () => ({}),
            })
          }
          return false
        }
      } else {
        // window.ordersStore.changeDeliveryFlow(this.deliveryFlow, true)
        this.preCheckAdress = false
        this.deliveryWindow = false
        this.addressModal = true
        this.$store.dispatch('places/hideLoader')
        return true
      }
      // generateOrder(); addressModal = true
    },
    showModal(show) {
      if (!this.ordersStore) {
        if (window.ordersStore) {
          this.$set(this, 'ordersStore', window.ordersStore)
          this.showModal(show)
        } else {
          setTimeout(() => {
            this.showModal(show)
          }, 150)
        }
      } else {
        const place_uid = this.place_uid
        const place = show
          ? this.places.find((pl) => pl.uid === place_uid)
          : null
        const urls_for_tips = place?.urls_for_tips
        this.tipsType = urls_for_tips?.administrator_url
          ? 'administrator_url'
          : 'kitchen_url'
        this.deliveryData.take_away = this.ordersStore?.takeAway
        this.handleExtraQuery(this.$route.query, show)
      }
    },
    getDeliveryZone: async function (val) {
      return
      if (!val) return
      // delivery_zone
      let [delivery_zone] = (
        await this.$DB.db
          .collection('delivery_zones')
          .where('place_uid', '==', val)
          .get()
      ).docs.map((doc) => Object.freeze(doc.data()))
      // console.log('delivery_zone', delivery_zone)
    },
    checkDeliveryZone: async function (address, place_uid) {
      try {
        const {
          data: { status, payload, error_code },
        } = await this.$axios.post(this.$API_URL('/orders/deliveryZone'), {
          address,
          place_uid,
        })
        if (status === 'SUCCESS' && payload) {
          return { success: true, data: payload?.zone }
        }
        return { success: false, error_code }
      } catch (e) {
        return { success: false, error_code: '' }
      }
    },
    getCheckIn: async function (checkinUid) {
      const checkins = (
        await this.$DB.db
          .collection('checkins')
          .where('uid', '==', checkinUid)
          .get()
      ).docs.map((doc) => Object.freeze(doc.data()))
      return checkins[0]
    },
    createCheckin: async function () {
      let _uid = ''
      const {
        deliveryData: { take_away, phone, guest_name, delivery },
        place_uid,
        user_uid,
        deliveryFlow,
        table_code,
        guest_number,
        checkin_type,
        tableCodScenarioPlaceUid,
      } = this
      const postData = deliveryFlow
        ? {
            checkin_type,
            delivery: {
              ...delivery,
              client_phone: '+7' + phone,
              place_uid,
              user_uid,
              uid: this.$uuid(),
            },
            guest_name,
            phone,
            place_uid,
            take_away,
            user_uid,
          }
        : {
            place_uid: tableCodScenarioPlaceUid || place_uid,
            user_uid,
            checkin_type,
            table_code,
            guest_number,
          }
      if (deliveryFlow && take_away && postData.delivery) {
        delete postData.delivery
      }
      if (this.isHotel) {
        Object.assign(postData, this.hotelRegistration.guestData, {
          geo: [
            this.user?.geolocation?.lat ?? 0,
            this.user?.geolocation?.lng ?? 0,
          ],
        })
        delete postData.checkin_type
      }
      if (deliveryFlow) {
        if (!take_away && !postData.delivery?.address) {
          this.$bus.$emit('openContextMenu', {
            config: {
              type: 'modal',
              style: 'pink',
              title: this.$t('delivery.errors.EMPTY_ADDRESS.title'),
              subtitle: this.$t('delivery.errors.EMPTY_ADDRESS.text'),
              icon: '/img/infoWhite.svg',
            },
            actions: [
              {
                id: 'ok',
                text: 'OK',
              },
            ],
            resolve: () => ({}),
            reject: () => ({}),
          })
          return
        } else if (!phone || phone === '+7') {
          this.$bus.$emit('openContextMenu', {
            config: {
              type: 'modal',
              style: 'pink',
              title: this.$t('delivery.errors.EMPTY_PHONE.title'),
              subtitle: this.$t('delivery.errors.EMPTY_PHONE.text'),
              icon: '/img/iconTelefone.svg',
            },
            actions: [
              {
                id: 'ok',
                text: 'OK',
              },
            ],
            resolve: () => ({}),
            reject: () => ({}),
          })
          return
        }
        const allFieldsFilled =
          delivery.flat &&
          delivery.floor &&
          delivery.intercom_code &&
          delivery.porch
        if (!take_away && !allFieldsFilled) {
          const answer = await this.showNotFilledFieldsAlert()
          if (answer) return false
        }
      }
      try {
        const { uid, error_code, is_geo_valid, description } =
          await this.postCheckin(postData)
        if (error_code) {
          console.log('WRONG_TABLE_CODE', error_code)
          let title = ''
          let subtitle = ''
          if (
            ~['UNPAID_CHECKIN', 'WRONG_TABLE_CODE', 'CHAIR_IS_BUSY'].indexOf(
              error_code
            )
          ) {
            title = this.$t(`delivery.errors.${error_code}.title`)
            subtitle = this.$t(`delivery.errors.${error_code}.text`)
          } else {
            title = this.$t('error')
            subtitle = description
          }
          this.$bus.$emit('openContextMenu', {
            config: {
              type: 'modal',
              style: 'pink',
              title,
              subtitle,
              icon: '/img/infoWhite.svg',
            },
            actions: [
              {
                id: 'ok',
                text: 'OK',
              },
            ],
            resolve: () => ({}),
            reject: () => ({}),
          })
        } else {
          if (this.isHotel) {
            const title = this.$t(
              is_geo_valid
                ? 'roomService.success.VALID_GEO.title'
                : 'roomService.errors.INVALID_GEO.title'
            )
            const subtitle = this.$t(
              is_geo_valid
                ? 'roomService.success.VALID_GEO.text'
                : 'roomService.errors.INVALID_GEO.text'
            )
            const buttonText = this.$t(
              is_geo_valid
                ? 'roomService.success.VALID_GEO.button'
                : 'roomService.errors.INVALID_GEO.button'
            )
            const icon = is_geo_valid
              ? '/img/circleokwhite.svg'
              : '/img/iconFailLocation.svg'
            this.hotelCheckinInfoOpened = true
            this.$bus.$emit('openContextMenu', {
              config: {
                type: 'modal',
                style: 'pink',
                title,
                subtitle,
                icon,
              },
              actions: [
                {
                  id: 'ok',
                  text: buttonText,
                },
              ],
              resolve: () => {
                this.hotelCheckinInfoOpened = false
              },
              reject: () => {
                this.hotelCheckinInfoOpened = false
              },
            })
          }
          _uid = uid
        }
      } catch (e) {
        console.log(e)
      }
      return _uid
    },
    postCheckin(postData) {
      return new Promise((resolve) => {
        this.$axios
          .post(this.$API_URL('/checkin'), postData)
          .then((resp) => {
            const uid = resp?.data?.payload?.uid || ''
            const is_geo_valid = resp?.data?.payload?.is_geo_valid || false
            const error_code = resp?.data?.error_code
            const description = resp?.data?.description || ''
            resolve({ uid, error_code, is_geo_valid, description })
          })
          .catch((err) => {
            const eStatus = err?.response?.status
            if (eStatus === 401) {
              resolve(
                this.$bus.updateToken(this.postCheckin.bind(this), postData)
              )
            } else {
              resolve({ uid: '', error_code: '' })
            }
          })
      })
    },
    showNotFilledFieldsAlert() {
      return new Promise((resolve) => {
        this.$bus.$emit('openContextMenu', {
          config: {
            type: 'modal',
            style: 'pink',
            title: this.$t('delivery.errors.EMPTY_FIELDS.title'),
            subtitle: this.$t('delivery.errors.EMPTY_FIELDS.text'),
            icon: '/img/iconAttention.svg',
          },
          actions: [
            {
              id: 'ok',
              text: this.$t('delivery.errors.EMPTY_FIELDS.button0'),
            },
            {
              id: 'cancel',
              text: this.$t('delivery.errors.EMPTY_FIELDS.button1'),
            },
          ],
          resolve: (resp) => {
            resolve(resp)
          },
          reject: () => {
            resolve(1)
          },
        })
      })
    },
    generateOrder: async function (checkinUid) {
      this.$store.dispatch('places/showLoader', true)
      if (this.busy) return
      if (this.delivery) {
        const allow = await this.preConfirm()
        this.$store.dispatch('places/hideLoader')
        if (!allow) return
      }
      if (!checkinUid) {
        this.$store.dispatch('places/showLoader', true)
        checkinUid = await this.createCheckin()
        if (checkinUid && !this.order.length) {
          this.$store.dispatch('places/hideLoader')
          if (!this.hotelCheckinInfoOpened) {
            this.$bus.$emit('openContextMenu', {
              config: {
                type: 'modal',
                style: 'pink',
                title: this.$t('order.tableCodeSubmited.title'),
                subtitle: this.$t('order.tableCodeSubmited.text'),
                icon: '/img/circleokwhite.svg',
              },
              actions: [
                {
                  id: 'ok',
                  text: 'OK',
                },
              ],
              resolve: () => ({}),
              reject: () => ({}),
            })
          } else {
            this.closeHotelRegistration()
          }
        }
      }
      if (checkinUid) {
        const isCalculate = await this.ordersStore.calculateOdrer(checkinUid)
        if (isCalculate) {
          this.$store.dispatch('places/showLoader', true)
          await this.createNewOrder(checkinUid)
        }
      }
      this.busy = false
      this.$store.dispatch('places/hideLoader')
    },
    createNewOrder: async function (checkin_uid) {
      const { place_uid, user_uid, order } = this
      const checkin = await this.getCheckIn(checkin_uid)
      const sendData = {
        checkin_uid,
        place_uid,
        checkin_type: checkin.type,
        user_uid,
        items: order.map((item) => {
          return {
            comment: item.comment || '',
            item_uid: item.uid,
            priority: 1,
            uid: this.$uuid(),
            modifications: item.selectedModifiers,
          }
        }),
      }
      const orderItemsLength = sendData.items.length
      try {
        const resp = await this.$axios.post(
          this.$API_URL('/orders/new'),
          sendData
        )
        if (resp.status === 200) {
          this.removeFromOrder(-10, true)
          this.$bus.$emit('openContextMenu', {
            config: {
              type: 'modal',
              style: 'pink',
              title: this.$t('delivery.orderCreated.title'),
              subtitle: this.$t('delivery.orderCreated.text'),
              icon: '/img/circleokwhite.svg',
            },
            actions: [
              {
                id: 'ok',
                text: this.$t('delivery.statuses.deferred'),
              },
            ],
            resolve: () => ({}),
            reject: () => ({}),
          })
          this.addressModal = false
          this.deliveryWindow = false
        }
      } catch (e) {
        console.log(e)
      }
      return
    },
    addToOrder(item, close) {
      this.ordersStore.addToOrder({
        dish: JSON.parse(JSON.stringify(item)),
        currencySymbol: this.currencySymbol,
        selectedModifiers: item.selectedModifiers,
        fromPlaceUid: item.fromPlaceUid,
      })
      this.$bus.$emit('alertShow')
      if (typeof close === 'function') {
        close()
      }
    },
    removeFromOrder(index, close) {
      this.ordersStore.removeFromOrder(index)
      if (typeof close === 'function') {
        close()
      }
    },
    openCloseModal(path) {
      path = path.split('/')
      if (path[1] === 'orders') {
        this.show = true
      } else {
        this.show = false
      }
    },
    close() {
      this.show = false
    },
    startInputPhone() {
      const phone = this.deliveryData.phone
      if (!phone) {
        this.deliveryData.phone = '+7'
      }
    },
    tipsModal() {
      this.tipsMode = true
    },
    closeCodeTable() {
      this.codeInputShow = false
      if (this.tableCodScenario) {
        this.tableCodScenario = false
        this.show = false
      }
    },
    closeHotelRegistration() {
      this.hotelRegistration.show = false
      if (this.hotelRegistrationScenario) {
        this.hotelRegistrationScenario = false
        this.show = false
      }
    },
    updateCodes({ key, value }) {
      this[key] = value
    },
    submitCodes() {
      if (this.table_code !== undefined && this.guest_number !== undefined) {
        this.closeCodeTable()
        this.generateOrder()
      }
    },
    payOrders: async function () {
      // return
      this.$store.dispatch('places/showLoader', true)
      const { user_uid, activeCheckin } = this
      let paymentType = ''
      const supportedFlows = this.$PS.getSupportedFlowsByUid(this.place_uid)
      // for debug
      // const supportedFlows = {"card":false,"comments":true,"cloud_tips":true,"chats":true,"sbp":false,"orders":true,"stripe":false,"google_pay":false,"delivery":true,"cash":false,"payments":false,"apple_pay":false,"room_balance":false,"self_checkin":true}
      try {
        paymentType = await this.$root.paymentType.show(
          this.currencySymbol,
          this.leftToPay,
          supportedFlows,
          this.unpaidInvoice || activeCheckin.status === 'canceled'
            ? true
            : this.isDeliveryCheckin,
          this.unpaidInvoice ? false : this.isHotel
        )
      } catch (e) {
        console.log(e)
      }
      // this.$store.dispatch("places/hideLoader");
      // return
      const isNotRoomBalanceOrCash = ['cash', 'room_balance'].includes(
        paymentType
      )
      if (!paymentType) {
        this.$store.dispatch('places/hideLoader')
        return
      } else if (paymentType === 'cash' && !this.isHotel) {
        await this.callWaiter(true)
        return
      } else if (!isNotRoomBalanceOrCash && !supportedFlows.payments) {
        this.$bus.$emit('openContextMenu', {
          config: {
            type: 'modal',
            style: 'pink',
            title: this.$t('delivery.errors.PAYMENTS_NOT_SUPPORTED.title'),
            subtitle: this.$t('delivery.errors.PAYMENTS_NOT_SUPPORTED.text'),
            icon: '/img/iconAttention.svg',
          },
          actions: [
            {
              id: 'ok',
              text: 'OK',
            },
          ],
          resolve: () => ({}),
          reject: () => ({}),
        })
        this.$store.dispatch('places/hideLoader')
        return
      }
      if (!isNotRoomBalanceOrCash) {
        let email = ''
        const ref = this.$DB.db.collection('users_private').doc(user_uid)
        const doc = await ref.get()
        if (!doc.exists) {
          console.log('No such document!')
        } else {
          email = doc.data()?.mail || ''
        }
        const {
          payment_documents: paymentsDocuments = [],
          send_receipt_to_email_enabled: isNeedSendEmail,
        } = this.place

        if (
          this.unpaidServiceFee ||
          paymentsDocuments.length ||
          isNeedSendEmail
        ) {
          try {
            email = await this.$root.emailBill.show(
              email || '',
              true,
              this.unpaidServiceFee,
              this.currencySymbol,
              paymentsDocuments,
              !isNeedSendEmail
            )
          } catch (e) {
            if (e?.toDo === 'showCancelFeeAlert') {
              this.$bus.$emit('openContextMenu', {
                config: {
                  type: 'modal',
                  style: 'pink',
                  title: this.$t('delivery.errors.NOT_CONFIRM_FEE.title'),
                  subtitle: this.$t('delivery.errors.NOT_CONFIRM_FEE.text'),
                  icon: '/img/iconAttention.svg',
                },
                actions: [
                  {
                    id: 'ok',
                    text: 'OK',
                  },
                ],
                resolve: () => ({}),
                reject: () => ({}),
              })
            }
            console.log(e)
            this.$store.dispatch('places/hideLoader')
            return
          }
        }
        if (isNeedSendEmail && !email) {
          this.$store.dispatch('places/hideLoader')
          return
        }
        try {
          await this.$axios.post(this.$API_URL('/users/set'), {
            user: { email, uid: user_uid },
          })
        } catch (e) {
          this.$store.dispatch('places/hideLoader')
          console.log('e', e)
          return
        }
      }
      try {
        const isGeorgianBank = activeCheckin.currency === 'GEL'
        const localPaymentType = !isGeorgianBank &&['applePay', 'googlePay', 'card'].includes(
          paymentType
        )
          ? 'card'
          : paymentType
        const mainPaymentSourceUid =
          this.$store.state.payments.preferredPaymentSourceUid
        const { confirmationUrl, error } = await postCheckinPay(
          activeCheckin.uid,
          localPaymentType,
          mainPaymentSourceUid
        )

        if (confirmationUrl) {
          this.$store.dispatch('places/hideLoader')
          if (
            isGeorgianBank ||
            paymentType === 'sbp' ||
            ~confirmationUrl.indexOf('checkout.stripe.com')
          ) {
            window.newPayWindow.location.href = confirmationUrl
          } else {
            window.newPayWindow?.close()
            this.paymentData.confirmation_url = confirmationUrl
            this.$nextTick(() => {
              this.listenIframe()
              this.$store.dispatch('places/hideLoader')
            })
          }
        } else if (error) {
          window.newPayWindow?.close()
          window.newPayWindow = null
          this.$bus.$emit('openContextMenu', {
            config: {
              type: 'modal',
              style: 'pink',
              title: this.$t('error'),
              subtitle:
                error.code === 'GENERAL_ERROR'
                  ? this.$t('roomService.generalErorr')
                  : error.description || '',
              // subtitle: this.$t('roomService.paymentMethodNotSupported'),
              icon: '/img/iconAttention.svg',
            },
            actions: [
              {
                id: 'ok',
                text: 'OK',
              },
            ],
            resolve: () => ({}),
            reject: () => ({}),
          })
        } else {
          if (paymentType === 'cash' && this.isHotel) {
            await this.callWaiter(true)
            return
          } else if (paymentType === 'room_balance') {
            this.$bus.$emit('openContextMenu', {
              config: {
                type: 'modal',
                style: 'pink',
                title: this.$t('roomService.alertSuccess.title'),
                subtitle: this.$t('roomService.alertSuccess.text'),
                icon: '/img/iconAttention.svg',
              },
              actions: [
                {
                  id: 'ok',
                  text: 'OK',
                },
              ],
              resolve: () => ({}),
              reject: () => ({}),
            })
          }
        }
      } catch (e) {
        console.log('e', e)
        window.newPayWindow?.close()
        window.newPayWindow = null
      }
      this.$store.dispatch('places/hideLoader')
    },
    submitOrder() {
      const { activeCheckin } = this
      if (activeCheckin) {
        this.generateOrder(activeCheckin.uid)
      } else if (this.place.type === 'hotel') {
        this.hotelRegistration.show = true
      } else if (this.place?.supported_flows?.self_checkin) {
        this.$root.selfCheckin.show(this.place_uid)
      } else {
        this.codeInputShow = true
      }
    },
    removeFromCheckin: async function (items, close) {
      this.$store.dispatch('places/showLoader', true)
      const uids = items.map((i) => i.uid)
      try {
        const { data } = await this.$axios.post(
          this.$API_URL('/orders/delete'),
          {
            item_uids: uids,
            items_uids: uids,
            object_uid: this.object_uid,
            place_uid: this.place_uid,
            user_uid: this.user_uid,
          }
        )
        if (data.status === 'ERROR') {
          this.$iosAlert({
            title: this.$t('error'),
            text: data.error_code,
          })
        }
      } catch (e) {
        console.log('this.$iosAlert(this.$t("order.waiterCoomingSoon"));', e)
      }
      if (typeof close === 'function') {
        close()
      }
      this.$store.dispatch('places/hideLoader')
      return true
    },
    addComment: async function (index, comment = '') {
      try {
        comment = await this.$root.commentWindow.show(comment, true)
      } catch (e) {
        console.log(e)
      }
      if (comment) {
        window.ordersStore.addComment({ index: index, comment })
      } else {
        window.ordersStore.removeComment(index)
      }
    },
    submitHotelRegistration() {
      this.hotelRegistration.show = false
      this.generateOrder()
    },
    openCookTutorial() {
      this.$bus.$emit('openTutorial', [
        {
          img: '/img/cook-review-help-1.png',
          width: '65%',
          text: this.$t('order.tips.cookTutorial.page1'),
        },
        {
          img: '/img/cook-review-help-2.png',
          width: '65%',
          text: this.$t('order.tips.cookTutorial.page2'),
        },
        {
          img: '/img/cook-review-help-3.png',
          width: '65%',
          text: this.$t('order.tips.cookTutorial.page3'),
        },
      ])
    },
    handleExtraQuery(query = {}, isShow) {
      if (isShow) {
        if (query.showOverAll) {
          this.extraViewSettings.showOverAll = true
        }
      } else {
        this.$set(
          this,
          'extraViewSettings',
          Object.assign({}, extraViewSettingsDefaults)
        )
      }
    },
  },
}
</script>

<style lang="scss">
.cancel-text {
  @include pos-centered-h();
  @include font(12, 14, 600);
  color: $white;
  bottom: 10px;
}
.order-pay-control {
  width: 100%;
  background: #2b2b2f;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  &__header {
    width: calc(100% - 44px);
    height: 48px;
    color: #c1c1c1;
    @include flex(row, space-between, center);
    padding: 0 28px 0 16px;
    position: relative;

    > img {
      width: 24px;
      height: 26px;
    }
  }

  &__body {
    @include b-gradient();
    border-radius: 10px 10px 0px 0px;
    padding: 16px 16px 30px 16px;
    color: $white;

    &.-error {
      height: 20vh;
      @include flex(row, center, center);
      @include font(20, 22, 500);
    }
    .bodybtn {
      width: 100%;
      height: 48px;
      @include flex(row, center, center);
      border-radius: 5px;

      &.-tips {
        background: rgba(255, 255, 255, 0.3);
        height: 40px;
        @include font(20, 20, 400);
      }

      &.-pay {
        background: $white;
        backdrop-filter: blur(27.1828px);
        color: $black;
        @include font(20, 22, 500);
      }
    }

    .total-pay {
      width: 100%;
      height: 48px;
      @include flex(row, space-between, center);
      @include font(20, 18, 400);
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin-top: 17px;

      &.-minus-m-b {
        margin-bottom: -17px;
      }

      span:last-child {
        @include font(20, 18, 600);
      }
    }
  }
}
.order-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 104;
  background: $black;
  visibility: hidden;
  transform: translateX(100vw);
  transition: all 0.2s ease;

  &.-open {
    visibility: visible;
    transform: translateX(0);
  }

  &.-show-over-all {
    // z-index: 9000;
  }

  &__empty {
    @include pos-centered;
    @include font(18, 26, 400);
    color: $white;

    &__text {
      margin-top: 17px;
    }
  }

  &__iframe {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background: $black;
    z-index: 107;

    iframe {
      width: 100%;
      height: calc(100% - 50px);
    }

    &-head {
      height: 50px;
      position: relative;

      img {
        @include pos-centered-v;
        left: 16px;
      }
    }
  }

  .-margin-16 {
    margin-right: 16px;
    margin-left: 16px;
  }

  .delivery-order-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: $black;

    &.-tips {
      background: rgba(0, 0, 0, 0.8);
      z-index: 3;

      .tips-card {
        @include pos-centered;
        position: absolute;
        width: 311px;
        height: 257px;

        /* mediumGrey */

        background: #2b2b2f;
        backdrop-filter: blur(27px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 10px;

        @include flex(column, flex-start, flex-start);
        color: $white;

        &__item {
          width: 100%;
          height: 70px;
          @include flex(row, center, center);
          @include font(20, 20, normal);

          &.-header {
            height: 55px;

            @include font(20, 20, normal);
          }

          &.-border {
            height: 1px;
            background: rgba(255, 255, 255, 0.3);
            padding: 0 16px;
            width: calc(100% - 64px);
            margin-left: 16px;
          }

          &.-ok {
            height: 55px;
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            @include font(20, 22, 600);
            text-decoration: none;
            color: $white;

            &:visited {
              text-decoration: none;
              color: $white;
            }
          }

          .tip-icon {
            margin-left: 10px;
          }

          .tip-item__text {
            width: calc(100% - 96px);
            text-align: left;
            padding-left: 10px;
          }
        }
      }
    }
  }

  .dom {
    &-header {
      width: 100%;
      height: 54px;
      @include flex(row, center, center);
      position: relative;
      @include font(17, 22, 500);
      text-transform: uppercase;
      color: $white;

      .back-icon {
        @include pos-centered-v;
        left: 20px;
      }
    }

    &-body {
      margin: 54px 16px 0 16px;
      @include flex(column, flex-start, flex-star);
      color: $white;

      &__item {
        width: 100%;
        height: 24px;
        position: relative;
        text-align: left;

        span {
          @include font(20, 22, normal);
          text-align: left;
          pointer-events: none;
          position: absolute;
          left: 0;
          top: 0;
          transition: 0.2s;
          transition-timing-function: ease;
          transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
          display: block;
          width: 100%;
        }
        input {
          width: 100%;
          background: $black;
          border: none;
          outline: none;
          height: 100%;
          @include font(20, 22, normal);
          color: $white;

          &:focus + .dom-body__item-span,
          &:not(:placeholder-shown) + .dom-body__item-span {
            transform: translateY(calc(-100% - 5px));
            @include font(12);
            color: rgb(99, 99, 102);
          }

          &.-grey {
            background: #1c1c1e;
          }
        }
      }

      &__divider {
        width: 100%;
        height: 1px;
        background: rgb(98, 98, 99);
        margin-top: 10px;
        margin-bottom: 56px;

        &.-long {
          margin-bottom: 76px;
        }
      }

      &__splitter {
        width: 100%;
        @include flex(row, space-between, flex-star);

        &-item {
          width: 45%;
          @include flex(column, flex-start, flex-star);
        }
      }
    }

    &-confirmbtn {
      @include flex(row, center, center);
      left: 16px;
      right: 16px;
      bottom: 30px;
      height: 48px;
      background: $goldenMain;
      border-radius: 5px;
      position: absolute;
      @include font(20, 20, 500);
      color: $white;
      text-transform: uppercase;

      &.-disabled {
        pointer-events: none;
        color: $black;
        background: rgb(67, 58, 34);
      }

      &.-deferred {
        background: $white;
        color: $black;
        text-transform: none;
      }
    }
  }

  &__header {
    position: relative;
    height: 55px;
    .back-icon {
      @include pos-centered-v;
      left: 16px;
    }

    &-title {
      @include pos-centered();
      @include font(18, 22);
      color: $white;
    }

    &-tips {
      width: 40px;
      height: 40px;
      @include pos-centered-v;
      right: 16px;
    }
  }

  &__body {
    max-height: calc(100% - 55px);
    overflow: scroll;

    &.-payBottom {
      max-height: calc(100% - 300px);
    }

    > div {
      background: rgb(26, 26, 26);
    }

    &-checkins {
      @include flex(column, flex-start, center);

      .body-list__item__container {
        width: 100%;
        color: $white;
        @include flex(column, flex-start, center);
      }

      &__item {
        height: 104px;
        width: 100%;
        color: $white;

        @include flex(row, flex-start, center);

        .checkins__item {
          &-images {
            width: 40%;
            height: 100%;
            position: relative;

            &__counter {
              @include pos-centered();
              @include font(48, 48, 400);
            }
          }
          &-info {
            width: 60%;
            height: 100%;
            @include flex(column, flex-start, center);
          }
        }
      }
    }

    &-header {
      color: $white;
      text-align: left;
      padding: 0 16px 24px 16px;
      .body-header {
        &__title {
          @include font(20, 22, 600);
          padding-top: 28px;
        }
        &__pretotal {
          @include font(18, 22, 400);
          margin-top: 8px;
          @include flex(row, space-between, flex-start);
          color: #c1c1c1;

          &.-flip {
            margin-top: 5px;
          }

          &.-error {
            color: red;
            margin-bottom: 10px;
          }

          &.-notice {
            @include font(18, 22, 400);
            margin: 15px 16px 0 16px;
            color: $goldenMain;
          }
        }
        &__total {
          @include flex(row, space-between, flex-start);
          @include font(20, 24, 400);
          margin-top: 10px;
        }
      }
    }

    &-list {
      @include flex(column, flex-start, flex-start);
      width: 100%;
      overflow-x: hidden;
      margin-bottom: 205px;
      .body-list__item {
        width: 100%;
        @include flex(row, flex-start, flex-start);
        height: auto;
        position: relative;

        &-modificators {
          text-align: left;
          color: $white;
          @include flex(column, flex-start, flex-start);

          &-item {
            width: 100%;
            &:not(:first-child) {
              margin-top: 5px;
            }
          }
        }

        &-image {
          width: 146px;
          height: 102px;
          background-size: cover;
          background-position: 50%;
        }

        &-info {
          padding: 0 24px;
          text-align: left;
          color: $white;
          width: calc(60% - 48px);
          min-height: 102px;
          @include flex(column, space-between, flex-start);

          &__name {
            @include font(18, 22, 600);
            // text-transform: uppercase;
          }

          &__comment {
            @include flex(row, center, center);
            @include font(14, 22, normal);
            // width: 114px;
            width: auto;
            border: 1px solid $white;
            border-radius: 20px;
            padding: 4px 16px;
            margin: 5px;
            background: $black;
            text-align: center;
            text-transform: uppercase;
            color: $white;
            &-text {
              text-align: center;
              @include textTruncate(100%);
              width: 100%;
              // padding: 5px 0;
              text-transform: none;
            }
            &-edit {
              border-top: 1px solid #4c4c4e;
              // padding: 5px 0;
            }

            > img {
              width: 20px;
            }

            > span {
              width: 100%;
            }
          }

          &__price {
            width: 100%;
            @include font(18, 26, 400);
            position: relative;

            // bottom: 5px;
            // margin-top: 16px;

            &__modifiers {
              @include pos-centered-v;
              right: 0;
            }
          }
        }
      }
    }
  }

  &__clear {
    @include pos-centered-h;
    position: fixed;
    bottom: 30px;
    width: 50%;
    height: 40px;
    color: $white;
    @include font(18, 22);
    @include flex(row, center, center);
    // background-image: url("/img/buttonShape.png");
    background: #404040;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: contain;

    &.-tips {
      bottom: 80px;
    }

    &.-disabled {
      pointer-events: none;
      color: $black;
      background: rgb(67, 58, 34);
    }
  }

  .swipeout {
    &-list-item {
      width: 100%;
    }
  }
  .swipeout-action {
    width: 80px;
    background: green;
    position: relative;

    &.action-panel-right {
      background-color: rgb(50, 215, 75);
    }

    &.action-panel-left {
      background-color: rgb(255, 59, 48);
    }
    > img,
    .u-photo-del {
      @include pos-centered;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
    }
  }
  &__b-window {
    position: absolute;
    width: 100%;
    height: 100vh;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.8);

    .b-window {
      &__body {
        position: absolute;
        bottom: 0;
        width: calc(100% - 32px);
        height: calc(50% - 32px);
        background-color: $darkGreyTwo;
        border-radius: 10px;
        padding: 16px;

        &-text {
          width: 100%;
          text-align: left;
          color: $white;
          @include font(18, 23, normal);

          &.-header {
            width: calc(100% - 60px);
          }
        }
        &-switcher {
          width: 100%;
          height: 44px;
          @include flex(row, center, center);
          background: rgba(118, 118, 128, 0.12);
          height: 36px;
          border-radius: 8px;
          margin-top: 52px;
          // -items
          &__item {
            color: $white;
            @include font(18, 18, normal);
            letter-spacing: -0.11px;
            height: 100%;
            width: 50%;
            @include flex(row, center, center);

            &.-active {
              background: $goldenMain;
              border-radius: 8px;
              @include font(18, 18, 500);
            }
          }
        }

        &-confirmbtn {
          @include flex(row, center, center);
          left: 16px;
          right: 16px;
          bottom: 30px;
          height: 48px;
          background: $goldenMain;
          border-radius: 5px;
          position: absolute;
          @include font(20, 20, 500);
          color: $white;
        }

        &-close {
          position: absolute;
          top: 15px;
          right: 13px;
        }
      }
    }
  }
}
.order-modal__body {
  position: relative;
}
.cook-review-info {
  @include pos-centered();
  top: 190px;
  width: 303px;
  z-index: 3;
  border-radius: 0px 10px 10px 10px;

  color: $white;
  &__wrapper {
    @include flex(column, flex-start, center);
    background: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(10px);
    border-radius: 0px 10px 10px 10px;
  }

  &__top {
    @include flex(row, flex-start, center);
    height: 72px;

    &-left-icon {
      width: 44px;
    }

    &-right-icon {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &-text {
      @include font(15, 20, 400);
      width: calc(100% - 104px);
      text-align: left;
    }
  }
  &__bottom {
    @include font(20, 22, 400);
    @include flex(row, center, center);
    text-transform: uppercase;
    width: 100%;
    height: 54px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  &__chip {
    position: absolute;
    top: -24px;
    left: 0;
    width: 16px;
    height: 25px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    backdrop-filter: blur(10px);
  }
}
</style>
