<template>
  <div class="b-dishes">
    <img
      src="/img/backWhite.png"
      srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
      class="back-icon"
      width="24px"
      height="24px"
      v-if="isInRelationParent"
      @click="$emit('close')"
    />
    <img
      v-else
      width="24px"
      height="24px"
      src="/img/iconHome.png"
      srcset="/img/iconHome@3x.png 3x /img/iconHome@2x.png 2x /img/iconHome.png 1x"
      class="back-icon"
      @click="$emit('close')"
    />
    <img
      v-if="!deliveryFlow && supportOrders"
      key="iconTableCode"
      class="b-dishes__table-code"
      src="/img/call-bell-white.svg"
      @click="enterCode(placeUid)"
    />
    <swipe-list
      class="r-menu-dishes-list"
      :items="dishesToShow"
      transition-key="created_at.seconds"
      :threshold="100"
      @swipeout:click="itemClick"
      @active="onActive"
    >
      <template slot-scope="{ item }">
        <BestDishesItem
          :item="item"
          :key="item.uid"
          :imageHeight="windowWith"
          v-if="item.uid"
          :currencySymbol="currencySymbol"
        />
        <div style="width: 100%; height: 65px" v-else></div>
      </template>
      <template slot="right" slot-scope="{ item, close }">
        <div
          class="swipeout-action swipeout-action-best action-panel-right"
          @click="mixinAddToOrderHandler(item, close)"
        >
          <img
            src="/img/iconPlusCircle.png"
            srcset="/img/iconPlusCircle@3x.png 3x /img/iconPlusCircle@2x.png 2x /img/iconPlusCircle.png 1x"
          />
          <div class="action-panel-right-text">Add to order</div>
        </div>
      </template>
    </swipe-list>
    <!-- <DynamicScroller
            :items="dishesToShow"
            key-field="uid"
            :min-item-size="650"
            class="scroller p-view__list"
            ref="scroller"
        >
            <template v-slot="{ item, index, active }">
                <swipe-list
                    class="r-menu-dishes-list"
                    :items="[item]"
                    transition-key="created_at.seconds"
                    :threshold="100"
                    :ref="`list-${index}`"
                    @swipeout:click="itemClick"
                    @active="onActive"
                >
                    <template slot-scope="{ item }">
                        <DynamicScrollerItem
                            :item="item"
                            :active="active"
                            :data-index="index"
                        >
                            <BestDishesItem
                                :item="item"
                                :key="item.uid"
                                :imageHeight="windowWith"
                                v-if="item.uid"
                                :currencySymbol="currencySymbol"
                            />
                            <div style="width: 100%; height: 65px" v-else></div>
                        </DynamicScrollerItem>
                    </template>
                    <template slot="right" slot-scope="{ item, close }">
                        <div
                            class="swipeout-action swipeout-action-best action-panel-right"
                            @click="mixinAddToOrderHandler(item, close)"
                        >
                            <img
                                src="/img/iconPlusCircle.png"
                                srcset="/img/iconPlusCircle@3x.png 3x /img/iconPlusCircle@2x.png 2x /img/iconPlusCircle.png 1x"
                            />
                            <div class="action-panel-right-text">
                                Add to order
                            </div>
                        </div>
                    </template>
                </swipe-list>
            </template>
        </DynamicScroller> -->
    <DishModifiersModal
      v-if="modifiers && showModifiersModal"
      :modifiers="modifiers"
      :currencySymbol="currencySymbol"
      :addedModifiers="dishModifiers"
      :dish-to-return="dishToModefiers"
      @closeModifiers="closeModifiers"
    />
  </div>
</template>

<script>
import { SwipeList, SwipeOut } from 'vue-swipe-actions'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'

import callWaiterMixin from '@/mixins/callWaiterMixin'
import addToOrderMixin from '@/mixins/addToOrderMixin'

import BestDishesItem from './BestDishesItem'
import DishModifiersModal from './DishModifiersModal'

export default {
  name: 'BestDishes',
  mixins: [callWaiterMixin, addToOrderMixin],
  props: {
    dishes: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    currencySymbol: {
      type: String,
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
    drinks: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BestDishesItem,
    SwipeOut,
    SwipeList,
    DishModifiersModal,
  },
  inject: ['rootPlaceUid', 'rootPlaceType'],
  data() {
    return {
      windowWith: 0,
      supportedFlows: null,
      deliveryFlow: window.ordersStore.deliveryFlow,
      showModifiersModal: false,
      dishModifiers: [],
      modifiers: [],
      dishToModefiers: null,
    }
  },
  computed: {
    placeUid() {
      return this.rootPlaceUid
    },
    placeType() {
      return this.rootPlaceType
    },
    categoriesIdsToShow() {
      const { currentCheckin, categories = [], drinks = [] } = this
      if (currentCheckin) return categories

      return [...drinks, ...categories].reduce((memo, category) => {
        if (currentCheckin || !category.for_checked_in_users_only) {
          memo[category.uid] = true
        }
        return memo
      }, {})
    },
    dishesToShow() {
      const { currentCheckin, categoriesIdsToShow, dishes } = this
      if (currentCheckin) return dishes

      return dishes
        .filter((dish) => categoriesIdsToShow[dish.category_uid])
        .sort((a, b) => a.index_in_best - b.index_in_best)
    },
    supportOrders() {
      return this.supportedFlows?.orders
    },
  },
  mounted() {
    this.windowWith = window.innerWidth
    this.$set(
      this,
      'supportedFlows',
      this.$PS.getSupportedFlowsByUid(this.placeUid)
    )
  },
  beforeDestroy() {
    this.$set(this, 'supportedFlows', null)
  },
  methods: {
    onActive(value) {
      if (value) {
        this.closeAll()
      }
    },
    closeAll() {
      for (const refKey in this.$refs) {
        if (this.$refs[refKey].closeActions) {
          this.$refs[refKey]?.closeActions()
        }
      }
    },
    itemClick(a) {
      this.closeAll()
    },
  },
}
</script>

<style lang="scss">
.b-dishes {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background: $black;
  overflow-y: scroll;
  padding-bottom: 50px;

  &__table-code {
    position: fixed;
    right: 16px;
    top: 25px;
    width: 24px;
    z-index: 101;
  }

  .back-icon {
    top: 25px;
    left: 25px;
    position: fixed;
    z-index: 1;
  }

  &__list {
    height: 100%;
    width: 100%;
    // overflow-x: hidden;
    overflow-y: scroll;
  }

  // .vue-recycle-scroller__item-view:first-child {
  // 	.post-item {
  // 		padding-top: 64px;
  // 	}
  // }
  .action-panel-right-text {
    @include pos-centered;
    top: calc(50% + 36px);
    transform: translate3d(-50%, calc(-50% + 10px), 0);
    color: $white;
  }

  .swipeout-action-best {
    width: 100px;
  }
}
</style>
