<template>
	<div class="s-p">
	</div>
</template>
<script>
let unSubscribe = null
export default {
	name: 'SinglePlace',
	props: {
		placeUid: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			place: null
		}
	},
	watch: {
		placeUid: {
			immediate: true,
			handler(val) {
				if (val) {
					this.getPlace(val)
				}
			}
		}
	},
	methods: {
		getPlace(placeUid) {
			if (unSubscribe) {
				unSubscribe()
			}
			unSubscribe = this.$DB.db.collection('places')
                .where('uid', '==', placeUid)
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
						this.$set(this, 'place', change.doc.data())
                    })
                })
		}
	}
}
</script>

<style lang="scss" scoped>
	.s-p {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 1060;
		background: $black;
	}
</style>