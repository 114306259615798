<template>
	<div class="t-menu-modal" v-show="show" :style="addingStyle">
		<SwipeModalDishes
			v-if="category"
			:category="category"
			:currencySymbol="currencySymbol"
			:startAt="startAt"
			:hideButtons="hideButtons"
			:hideModifiers="hideModifiers"
			:hideComments="hideComments"
			:orderComment="orderComment"
			:dishInfoBackClose="dishInfoBackClose"
			:placeUid="placeUid"
			:placeType="placeType"
			openInfoOnly
			@close="close"
			singleCategory
		/>
		<div class="itemview-notify" v-show="addCartAlert">
			<img  src="/img/line11@3x.png">
			<div class="itemview-notify__text">{{ $t('added_to_order') }}</div>
		</div>
	</div>
</template>

<script>
import SwipeModalDishes from './SwipeModalDishes'
export default {
	components: {
		SwipeModalDishes
	},
	data() {
		return {
			show: false,
			category: null,
			onClose: null,
			currencySymbol: '',
			addCartAlert: false,
			timerAlert: null,
			addingStyle: '',
			hideButtons: false,
			hideModifiers: false,
			hideComments: false,
			orderComment: '',
			dishInfoBackClose: false,
			places: this.$PS.data,
			placeUid: '',
			placeType: ''
		}
	},
	computed: {
		place() {
			const place_uid = this.category?.category?.place_uid
			return this.places.find(pl => pl.uid === place_uid)
		},
		languages() {
			return this.place?.languages || []
		}
	},
	mounted() {
		this.$bus.$on('GlobalModal/dishesOpen', this.openCloseModal)
		this.$bus.$on('alertShow', this.alertShow)
	},
	methods: {
		openCloseModal(data) {
			if (!data) {
				this.close()
			} else {
				const {category, currencySymbol, onClose, startAt = 0, addingStyle, hideButtons = false, hideModifiers = false, hideComments = false, orderComment = '', dishInfoBackClose = false, placeUid = '', placeType = ''} = data
				this.category = category
				this.onClose = onClose
				this.startAt = startAt
				this.addingStyle = addingStyle
				this.currencySymbol = currencySymbol
				this.hideButtons = hideButtons
				this.hideModifiers = hideModifiers
				this.hideComments = hideComments
				this.orderComment = orderComment
				this.dishInfoBackClose = dishInfoBackClose
				this.placeUid = placeUid
				this.placeType = placeType
				if (this.category) {
					this.show = true
				}
			}
		},
		close(currentUid) {
			this.show = false
			const onClose = this.onClose
			if (onClose && typeof onClose === 'function') {
				onClose('', currentUid)
			}
			this.category = null
			this.onClose = null
			this.startAt = 0
			this.currencySymbol = null
			this.addingStyle = ''
			this.hideButtons = false
			this.hideModifiers = false
			this.hideComments = false
			this.orderComment = ''
			this.dishInfoBackClose = false
		},
		alertShow() {
			clearTimeout(this.timerAlert)
			this.addCartAlert = true
			this.timerAlert = setTimeout(() => {
				this.addCartAlert = false
			}, 1000)
		},
	}
}
</script>

<style lang="scss">
	.t-menu-modal {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 101;
	}
</style>