<template>
  <div class="add-card">
    <div class="add-card-form">
      <div class="add-card-form__item">
        <FormInput :title="$t('MG-703-005')" use-slot :hasError="errors.cardNumber" :errorMessage="$t('MG-703-011')">
          <the-mask :mask="'#### #### #### #### ###'" v-model="cardNumber" :value="''" type="tel"
            placeholder="хххх хххх хххх хххх ххх" />
        </FormInput>
        <div class="add-card-form__item-cardtype">
          {{ cardType }}
        </div>
      </div>
      <div class="add-card-form__item">
        <FormInput :title="$t('MG-703-007')" :border-bottom="false" use-slot :hasError="errors.expire" :errorMessage="$t('MG-703-012')">
          <div class="card-form-selects">
            <div class="card-form-selects__select">
              <span>{{ showDoubleDigits(expMonth) }}</span>
              <img src="/img/icon-arrow-down-black.svg" />
              <select v-model.number="expMonth">
                <option v-for="month in 12" :key="month" :value="month">
                  {{ showDoubleDigits(month) }}
                </option>
              </select>
            </div>
            <div class="card-form-selects__select">
              <span>{{ YEARS_TO_ADD + expYear }}</span>
              <img src="/img/icon-arrow-down-black.svg" />
              <select v-model.number="expYear">
                <option v-for="year in yearsToSelect" :value="year - YEARS_TO_ADD" :key="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
        </FormInput>
      </div>
    </div>
    <PaymentCardListItem :item="cardItemToMainCard" :isMainForce="isMainCard" @click.native="selectMainCard" />
    <div class="add-card__save-button" @click="saveCard()">
      <UIButton :text="$t('MG-703-001')" />
    </div>
    <MayberLoader v-if="isShowLoader" />
  </div>
</template>
<script>
import { BANK_CARDS_SYSTEMS_MAP } from '@/consts'
import { createPaymentSource } from '@/init/api'
import { padstartDigit } from '@/utils'

import FormInput from '@/components/UI/Form/FormInput.vue'
import UIButton from '@/components/UI/UIButton.vue'
import MayberLoader from '@/components/UI/MayberLoader'
import PaymentCardListItem from '@/components/PaymentCardListItem'

import { TheMask } from "vue-the-mask"

const YEARS_TO_ADD = 2000

export default {
  props: {
    paymentSourcesCount: {
      type: Number,
      default: 0,
    },
    emitClose: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    FormInput,
    MayberLoader,
    PaymentCardListItem,
    TheMask,
    UIButton,
  },
  data() {
    return {
      apiBusy: false,
      cardNumber: '',
      expMonth: 1,
      expYear: 25,
      isMainCard: this.paymentSourcesCount > 0 ? false : true,
      isShowLoader: false,
      errors: {
        cardNumber: false,
        expire: false
      },
    }
  },
  computed: {
    yearsToSelect() {
      const currentYear = new Date().getFullYear()
      const out = [currentYear]
      for (let i = 1; i < 11; i++) {
        out.push(currentYear + i)
      }
      return out
    },
    cardType() {
      const firstDigits = this.cardNumber.slice(0, 2)
      if (BANK_CARDS_SYSTEMS_MAP[firstDigits])
        return BANK_CARDS_SYSTEMS_MAP[firstDigits]

      return BANK_CARDS_SYSTEMS_MAP[firstDigits[0]] || ''
    },
    userUid() {
      return this.$store.state.user?.user?.uid
    },
    last4() {
      return this.cardNumber.slice(-4)
    },
    cardItemToMainCard() {
      return {
        exp_month: this.expMonth,
        exp_year: this.expYear,
        last4: this.last4,
        payment_system: this.cardType,
      }
    },
    YEARS_TO_ADD() {
      return YEARS_TO_ADD
    },
  },
  methods: {
    showDoubleDigits(digit) {
      return padstartDigit(digit, 2, 0)
    },
    checkIsValidCardNumber(cardNumber) {
      return cardNumber.length >= 16 && /^\d+$/.test(cardNumber)
    },
    checkExpireDate(year, month) {
      const now = new Date().getTime()
      const cardExpire = new Date(String(year + YEARS_TO_ADD), String(month - 1)).getTime()
      return cardExpire > now
    },
    validate() {
      for(let key in this.errors) {
        this.errors[key] = false
      }
      let hasError = false
      if (!this.checkIsValidCardNumber(this.cardNumber)) {
        this.errors.cardNumber = true
        hasError = true
      }
      if (!this.checkExpireDate(this.expYear, this.expMonth)) {
        this.errors.expire = true
        hasError = true
      }
      return hasError
    },
    async saveCard() {
      if (this.apiBusy) return
      const isHasError = this.validate()
      if (isHasError) return
      this.apiBusy = true
      this.isShowLoader = true
      try {
        const savedCard = await createPaymentSource(
          this.expMonth,
          this.expYear,
          this.cardNumber.slice(0, 4),
          this.last4,
          this.userUid
        )
        if (savedCard.error === 401) {
          await this.$bus.updateToken()
          this.apiBusy = false
          this.saveCard()
        } else {
          this.isShowLoader = false
          if (this.emitClose) {
            this.$emit('close')
          } else {
            this.$router.go(-1)
          }
        }
      } catch (error) {
        this.isShowLoader = false
      }
      this.apiBusy = false
    },
    selectMainCard() {
      if (!this.paymentSourcesCount) return

      this.isMainCard = !this.isMainCard
    }
  },
}
</script>
<style lang="scss" scoped>
.add-card {
  box-sizing: border-box;
  padding: 0 16px;

  &-form {
    &__item {
      margin-bottom: 20px;
      position: relative;

      &-cardtype {
        @include font(18, 21, 400);
        @include pos-centered-v;
        color: $white;
        font-style: italic;
        right: 0;
        position: absolute;
        text-transform: uppercase;
      }
    }
  }

  &__save-button {
    position: absolute;
    bottom: 46px;
    left: 16px;
    right: 16px;
  }

  .card-form-selects {
    @include flex(row, space-between, flex-start);
    margin-top: 3px;
    margin-left: -6px;
    width: calc(100% + 6px);

    &__select {
      @include flex(row, space-between, center);
      @include font(18, 21, 400);

      background: #ffffff;
      border-radius: 4px;
      color: $black;
      height: 42px;
      padding-left: 6px;
      padding-right: 14px;
      position: relative;
      width: calc(50% - 4px);

      >select {
        height: 100%;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }
}
</style>
