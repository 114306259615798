<template>
    <div class="map-page">
		<div id="map"></div>
		<div class="loader-wrapper" v-if="showLoader" :class="{'-opacity': opacity}">
			<div class="loader-wrapper__loader">
				<div class="loader">
					<svg class="svg" viewBox="138 235 100 100">
						<path class="logo" fill="none" stroke="white" stroke-width="5" d="M161,322.6l6-74.7c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l20.7,74.4c0,0.1,0.1,0.1,0.1,0.1
								c0,0,0.1,0,0.1-0.1l20.6-74.4c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1l6.1,74.7l0,0h-50.7c-7.7,0-14-6.3-14-14v-47.3
								c0-7.7,6.3-14,14-14h47.3c7.7,0,14,6.3,14,14V312c0,5.9-4.8,10.6-10.6,10.6l0,0l0,0"/>
						
					</svg>
				</div>
			</div>
		</div>
		<div class="map-page__backdrop" v-if="inputFocused" @click="closeSearch">
			<RecycleScroller
				class="map-page__backdrop-list"
				:items="filteredPlaces"
				:item-size="60"
				key-field="uid"
				v-slot="{ item }"
			>
				<div
					class="map-page__backdrop-list__item"
					@click.stop="selectItem(item, true)"
				>
					<div class="list-item__title" translate="no">
						{{ item.name }}
					</div>
					<div class="list-item__subtitle">
						{{ item.address || ''}}
					</div>
					<div class="list-item__subtitle -last" v-if="item.distanceInKm">
						{{ item.distanceInKm }} {{ $t('km') }}
					</div>
					<div class="divider"></div>
				</div>
			</RecycleScroller>
		</div>
		<div class="map-page__search">
			<img
				src="/img/resourcesSearch.svg"
			/>
			<input
				ref="search"
				:placeholder="$t('search')"
				@focus="inputFocused = true"
				v-model="searchText"
			>
		</div>
		
		<div class="map-page__myposition" @click="setMyLocation(userGeo)">
			<img src="/img/geoPosition.svg" />
		</div>
		<div class="map-page__navbar">
			<div
				class="map-page__navbar-item"
				v-for="(item, key) in menuItems"
				:class="{'-active': item.id === menuId}"
				:key="key"
				@click="menuId = item.id"
			>
				{{ item.name }}
			</div>
		</div>
		<ItemView
			v-if="currentItem"
			:placeUid="currentId"
			@drop="drop"
		/>
		<HomeItemPreview
			@openClose="openClosePreview"
			@openPlace="openPlace"
			v-if="previewShow && selectedItem"
            :uid="lastSelectedPlace"
		/>
		<!-- <keep-alive> -->
        <router-view></router-view>
		<!-- </keep-alive> -->
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Lottie from 'vue-lottie'
import animationData from '../init/planetAnimation.json'
import ItemView from './ItemView'
import HomeItemPreview from '../components/HomeItemPreview'

const markers = []
const markersIcons = {}

export default {
    components: {
	  lottie: Lottie,
	  ItemView,
	  HomeItemPreview
    },
    data () {
        return {
            map: null,
            timer: null,
            iconSize: 'small',
            markers: [],
            inputFocused: false,
            searchText: '',
            markersSet: false,
            menuItems: [
                { id: 'restaurant', name: this.$t('restrauns') }
                // {id: 'hotel', name: 'Отели'},
                // {id: 'beach', name: 'Пляжи'}
            ],
            menuId: 'restaurant',
            defaultOptions: {
                renderer: 'svg',
                loop: true,
                play: true,
                rendererSettings: {
                    // context: canvasContext, // the canvas context, only support "2d" context
                    preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
                    clearCanvas: false,
                    progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
                    hideOnTransparent: true // Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
				
                },
                animationData
            },
            lastInfoWindow: null,
            lastSelectedPlace: '',
            mapType: 'roadmap',
            mapTypes: [
                'roadmap',
                'satellite',
                'hybrid',
                'terrain'
            ],
            currentId: '',
            previewShow: false,
            places: this.$PS.data
        }
    },
    computed: {
        ...mapState('user', {
            userGeo: state => state.geolocation,
            user: state => state.user
        }),
        ...mapState('places', {
            showLoader: state => state.showLoader,
            opacity: state => state.opacity,
            placesReady: state => state.ready
        }),
        currentItem () {
            return this.places.find(place => place.uid === this.currentId)
        },
        selectedItem () {
            return this.places.find(place => place.uid === this.lastSelectedPlace)
        },
        filteredPlaces () {
            const searchText = this.searchText.toLowerCase()
            const placesDistanced = this.places
            if (!searchText) return placesDistanced
            return placesDistanced.filter(place => ~place._search.toLowerCase().indexOf(searchText))
        },
        filteredPlacesLen () {
            return this.filteredPlaces.length
        },
        lastIndex () {
            return this.filteredPlacesLen - 1
        },
        userGeoAndPlaces () {
            const { lat, lng } = this.userGeo
            return `${lat}-${lng}-${this.places.length}-${this.markersSet}`
        },
        initialState () {
            const { query } = this.$route
        }
    },
    watch: {
        // userGeo: {
        // 	immediate: true,
        // 	handler: 'setMeCenter'
        // },
        userGeoAndPlaces: 'firstInit',
        iconSize: 'zoomIconHandler',
        // places: {
        //     immediate: true,
        //     handler (items) {
                
        //     }
        // },
        menuId () {
            this.setFirst()
        },
        inputFocused (val) {
            document.body.style.overflow = (val) ? 'hidden' : 'scroll'
        },
        mapType (val) {
            window.requestAnimationFrame(() => {
                this.map.setMapTypeId(val)
            })
        },
        placesReady (val) {
            const uid = this.$route.query.place
            if (uid) {
                this.loadMenu(uid)
            }
        }
    },
    mounted () {
        // this.initMap()
        this.$bus.$on('Home/loadMenu', this.loadMenu)
        this.$bus.$on('Home/drop', this.drop)
        this.$bus.$on('Home/closePlace', this.closePlace)
        this.$bus.$on('translatePlace', this.translatePlace)
    },
    methods: {
        showNeedToPayModal (needToPay) {
            if (needToPay) {
                this.$bus.$emit('openContextMenu', {
                    config: {
                        type: 'modal',
                        style: 'pink',
                        title: this.$t('delivery.needToPay.title'),
                        subtitle: this.$t('delivery.needToPay.text'),
                        icon: '/img/iconNeedPayment.svg'
                    },
                    actions: [{
                        id: 'ok',
                        text: this.$t('delivery.needToPay.button1')
                    },
                    {
                        id: 'cancel',
                        text: this.$t('delivery.needToPay.button2')
                    }],
                    resolve: (id) => {
                        if (id === 0) {
                            this.$router.replace('/orders/' + needToPay.uid)
                        }
                    },
                    reject: () => ({})
                })
            }
        },
        closePlace () {
            this.currentId = ''
        },
        translatePlace (uid, key, delivery = false) {
            this.closePlace()
            this.$nextTick(() => {
                localStorage.setItem('langKey', key)
                this.$router.replace(`?category=first${ delivery ? '&delivery=true' : '' }`)
                this.loadMenu(uid, delivery)
            })
        },
        drop (uid) {
            if (!this.map) {
                this.initMap(this.drop.bind(this, uid))
                return
            }
            if (typeof uid === 'string') {
                const item = this.places.find(i => i.uid === uid)
                if (item) {
                    this.selectItem(item, true, true)
                }
            }
            this.currentId = ''
            this.$nextTick(() => {
                if (typeof uid === 'function') {
                    uid()
                }
            })
            sessionStorage.removeItem('backToPlaceUrl')
        },
        changeMapType () {
            const types = this.mapTypes
            const index = types.indexOf(this.mapType)
            if (index === types.length - 1) {
                this.mapType = types[0]
            } else {
                this.mapType = types[index + 1]
            }
        },
        clickMap (e) {
            console.log('e', e)
        },
        closeSearch () {
            this.searchText = ''
            this.inputFocused = false
        },
        selectItem (item, openInfo = false, drop = false) {
            const { map, lastSelectedPlace } = this
            const uid = item.uid
            const marker = markers.find(marker => marker.uid === uid)
            if (openInfo && marker) {
                window.google.maps.event.trigger(marker, 'click')
            }
            if (marker && !drop) {
                if (lastSelectedPlace && uid !== lastSelectedPlace) {
                    const lastMarker = markers.find(marker => marker.uid === lastSelectedPlace)
                    lastMarker.setIcon(markersIcons[lastSelectedPlace].big)
                }
                marker.setIcon(markersIcons[marker.uid].selected)
                this.lastSelectedPlace = uid
				
                this.previewShow = true
            }
            this.setMeCenter({ lat: item.geo_point.latitude, lng: item.geo_point.longitude })
            map.setZoom(16)
            window.requestAnimationFrame(() => {
                this.inputFocused = false
            })
        },
        setMyLocation: async function (userGeo) {
            let newGeo = {}
            const zoom = 16
            if (!(userGeo.lat && userGeo.lng)) {
                try {
                    newGeo = await this.$utils.getUserGeo()
                    this.setMarker(newGeo, 'me', '/img/currentPositionMarker.svg')
                    this.setMeCenter(newGeo)
                    this.map.setZoom(zoom)
                } catch (e) {
                    this.$iosAlert(this.$t('allowYourLocation'))
                        .then(function () {
                            console.log('alert')
                        })
                }
            } else {
                this.setMarker(userGeo, 'me', '/img/currentPositionMarker.svg')
                this.setMeCenter(userGeo)
                this.map.setZoom(zoom)
            }
        },
        initMap (callback) {
            const { lat = 0, lng = 0 } = this.userGeo
            clearTimeout(this.timer)
            if (callback) {
                this.$store.dispatch('places/showLoader')
            }
            if (!document.getElementById('google-map-script')) {
                const gMapScript = document.createElement('SCRIPT')
                gMapScript.id = 'google-map-script'
                gMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env['VUE_APP_GOOGLE_MAPS_API_KEY']}&libraries=geometry,pay`
                document.body.appendChild(gMapScript)
                this.timer = setTimeout(this.initMap, 100, callback)
            } else if (window.google?.maps?.Map) {
                this.map = new window.google.maps.Map(document.getElementById('map'), {
                    center: { lat, lng },
                    zoom: 10,
                    gestureHandling: 'greedy',
                    disableDefaultUI: true,
                    mapTypeId: this.mapType
                })
                this.map.addListener('zoom_changed', this.onZoom)
                this.map.addListener('click', this.mapClickHandler)
                this.map.addListener('dragstart', this.mapClickHandler)
				
                window.requestAnimationFrame(() => {
                    this.setMarkers(this.places, callback)
                    // this.setMeCenter({ lat, lng })
                })
            } else {
                this.timer = setTimeout(this.initMap, 50, callback)
            }
        },
        setMeCenter ({ lat, lng }) {
            if (this.map && lat && lng) {
                this.map.setCenter({ lat, lng })
            }
        },
        setMarker (position, name = '', url = '/img/pickerBlackNoCheckIns.svg', item = null, zoom = 0) {
            const map = this.map
            const uid = item?.uid || ''
            const maps = window.google.maps
            const self = this
            if (!zoom) {
                zoom = map.getZoom()
            }
            let icon
            if (name !== 'me') {
                const iconSet = {
                    small: this.getSmallIcon(maps),
                    big: this.getBigIcon(maps),
                    selected: this.getSelectedIcon(maps)
                }
                markersIcons[uid] = iconSet
                icon = (zoom >= 16) ? iconSet.big : iconSet.small
            } else {
                icon = {
                    url,
                    size: new maps.Size(32, 32),
                    origin: new maps.Point(0, 0),
                    anchor: new maps.Point(8, 8),
                    scaledSize: new maps.Size(16, 16)
                }
            }
            const marker = new maps.Marker({
                position,
                map,
                name,
                icon,
                uid
            })
            if (item) {
                // console.time('addImage')
                const image = item?.photo_uris?.[0]?.url
                // div = document.createElement('DIV')
                // div.className = 'map-popover'
                // div.uid = item.uid
                // div.innerHTML = `${(image) ? `<div class="map-popover__image"><img src="${image}"/></div>` : ''}<div class="map-popover__text">${item.name}</div>`
                const infowindow = new maps.InfoWindow({
                    content: '',
                    disableAutoPan: true,
                    _cont: `<div class="map-popover__text">${item.name}</div>`
                    // _cont: `${(image) ? `<div class="map-popover__image"><img width="32px" height="32px" src="${image}"/></div>` : ''}<div class="map-popover__text">${item.name}</div>`
                })
                // infowindow.content.addEventListener('click', function () {
                // 	const uid = this.uid
                // 	self.loadMenu(uid)
                // 	console.log('uid', uid)
                // })
                marker.infowindow = infowindow
                marker.addListener('click', function () {
                    self.selectItem(item)
                    const infowindow = this.infowindow
                    if (infowindow) {
                        if (self.lastInfoWindow) {
                            self.lastInfoWindow.close()
                        }
                        // if (!infowindow.content) {
                        const div = document.createElement('DIV')
                        div.className = 'map-popover'
                        // div.style['max-height'] = '475px'
                        div.uid = this.uid
                        div.innerHTML = infowindow._cont
                        div.addEventListener('click', function () {
                            // const uid = this.uid
                            // self.loadMenu(uid)
                            self.selectItem(item)
                        })
                        // infowindow.addListener("domready", function () {
                        // 	this.open(map, marker)
                        // }, true)
                        infowindow.setContent(div)
                        // }
                        // setTimeout(() => {
                        infowindow.open(map, marker)
						
                        self.lastInfoWindow = infowindow
                    }
                }, true)
                // console.timeEnd('addImage')
            } else {
                console.log('Noitem', item)
            }
            markers.push(marker)
        },
        setMarkersByChunks (data = [], zoom = 0) {
            return new Promise((resolve) => {
                const part = data.splice(0, 100)
                if (!part.length) {
                    resolve()
                } else {
                    for (let i = part.length - 1; i >= 0; i--) {
                        const place = part[i]
                        const geo_point = place.geo_point
                        this.setMarker({ lat: geo_point.latitude, lng: geo_point.longitude }, '', '', place, zoom)
                        this.markersSet = true
                    }
                    setTimeout(() => {
                        resolve(this.setMarkersByChunks(data, zoom))
                    }, 500)
                }
            })
        },
 		setMarkers (items = [], callback) {
            if (!this.map) {
                setTimeout(() => {
                    this.setMarkers(items, callback)
                }, 100)
            } else {
                if (items.length) {
                    const zoom = this?.map?.getZoom() || 0
                    this.setMarkersByChunks([...items], zoom)
                        .then(() => {
                            this.markersSet = true
                            if (typeof callback === 'function') {
                                callback()
                                this.$store.dispatch('places/hideLoader')
                            }
                        })
                }
            }
        },
        setFirst () {
            if (!this.markersSet) return
            const items = this.places
			
            const menuId = this.menuId
            const item = items.find(i => i.type === menuId)
            if (item) {
                const uid = item.uid
                const marker = markers.find(marker => marker.uid === uid)
                this.setMeCenter({ lat: item.geo_point.latitude, lng: item.geo_point.longitude })
                this.map.setZoom(16)
				
                if (marker) {
                    // window.google.maps.event.trigger(marker, 'click')
                    // const image = item?.photo_uris?.[0]?.url,
                    // 	div = document.createElement('DIV')
                    // div.className = 'map-popover'
                    // div.innerHTML = `${(image) ? `<div class="map-popover__image"><img src="${image}"/></div>` : ''}<div class="map-popover__text">${item.name}</div>`
                    // if (this.lastInfoWindow) {
                    // 	this.lastInfoWindow.close()
                    // }
                    // const infowindow = marker.infowindow
                    // infowindow.open(this.map, marker)
                    // this.lastInfoWindow = infowindow
                }

                setTimeout(() => {
                    if (!location.pathname.startsWith('/places/')) {
                        this.$store.dispatch('places/hideLoader')
                    }
                }, 250)
            }
        },
        onZoom () {
            const zoom = this.map.getZoom()
            if (zoom >= 16) {
                this.iconSize = 'big'
            } else {
                this.iconSize = 'small'
            }
        },
        mapClickHandler (e) {
            if (this.lastInfoWindow) {
                this.lastInfoWindow.close()
            }
            if (this.inputFocused) {
                this.$refs.search.blur()
                this.inputFocused = false
            }
        },
        zoomIconHandler (iconSize) {
            if (iconSize === 'big') {
                markers.forEach(marker => {
                    if (marker.name !== 'me') {
                        marker.setIcon(markersIcons[marker.uid].big)
                    }
                })
            } else {
                markers.forEach(marker => {
                    if (marker.name !== 'me') {
                        marker.setIcon(markersIcons[marker.uid].small)
                    }
                })
            }
        },
        getSmallIcon (_maps) {
            return {
                url: '/img/circleMarker.svg',
                size: new _maps.Size(32, 32),
                origin: new _maps.Point(0, 0),
                anchor: new _maps.Point(8, 8),
                scaledSize: new _maps.Size(16, 16)
            }
        },
        getBigIcon (_maps) {
            return {
                url: '/img/pickerBlackNoCheckIns.svg',
                size: new _maps.Size(34, 45),
                origin: new _maps.Point(0, 0),
                anchor: new _maps.Point(24, 42),
                scaledSize: new _maps.Size(34, 45)
            }
        },
        getSelectedIcon (_maps) {
            return {
                url: '/img/pickerBlackNoCheckInsSelectedInvertedColors.svg',
                size: new _maps.Size(34, 45),
                origin: new _maps.Point(0, 0),
                anchor: new _maps.Point(24, 42),
                scaledSize: new _maps.Size(34, 45)
            }
        },
        loadMenu (uid, delivery = false) {
            window.ordersStore.changeDeliveryFlow(delivery, false)
            this.currentId = uid
        },
        openClosePreview (state) {
            this.previewShow = state
        },
        openPlace (openData = {}) {
            const categoryOpen = openData.menu || false
            const delivery = openData.delivery || false
            const takeAway = openData.takeAway || false
            window.ordersStore.changeDeliveryFlow(delivery, takeAway)
            const uid = this.lastSelectedPlace
            if (categoryOpen) {
                this.$router.replace('?category=first')
            } else if (delivery) {
                this.$router.replace(`?category=first&delivery=true&takeAway=${(takeAway) ? true : ''}`)
            } else if (openData.openRelated) {
                this.$router.replace('?openRelated=true')
            }
            this.loadMenu(uid, delivery)
            this.openClosePreview(false)
        },
        firstInit() {
            if (this.currentId) return

            if (this.places.length) {
                this.currentId = this.places[0].uid
                this.$store.dispatch('places/hideLoader')
            }
        }
    },
    beforeDestroy () {
        clearTimeout(this.timer)
    }
}
</script>

<style lang="scss">
	.map-page {
		width: 100%;
		height: 100%;
		position: relative;

		.map-popover {
			// height: 32px;
			// @include flex(row, flex-start, center);
			&__image {
				width: 32px;
				height: 32px;
				margin-right: 10px;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.loader-wrapper {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: #3c445a;
			z-index: 1059;

			&__loader {
				width: 90%;
				@include pos-centered;

				.loader {
					width: 10em;
					height: 10em;
					margin: auto;
					margin-top: 30vh;
				}

				.svg {
					width: 10em;
					height: 10em;
				}

				.logo {
					stroke-dasharray: 584;
					stroke-dashoffset: 584;
					animation: loader 5s ease-in-out alternate infinite;
				}
				
				@keyframes loader {
					from {
					stroke-dashoffset: 584;
					}
					to {
					stroke-dashoffset: 0;
					}
				}
			}

            &.-opacity {
                opacity: 0.5;
            }

		}

		#map {
			height: 100%;
		}

		&__search,
		&__myposition,
		&__backdrop,
		&__navbar {
			position: fixed;
			z-index: 1;
		}
		
		&__backdrop {
			top: 0;
			bottom: 0;
			z-index: 2;
			width: 100%;
			// height: calc(100vh + 100px);
			background-color: rgba(0, 0, 0, 0.1);
			backdrop-filter: blur(10px);
			padding-bottom: 60px;

			&-list {
				// overflow-y: scroll;
				height: calc(100% - 60px);
				margin-top: 60px;

				&__item {
					position: relative;
					margin-left: 10px;
					text-align: left;
					padding-bottom: 5px;

					.list-item {
						&__title {
							// @include textTruncate;
							white-space: normal;
    						word-break: break-all;
							@include font(15, 14, bold);
							margin: 7px 0 5px 0;
						}
						&__subtitle {
							white-space: normal;
    						word-break: break-all;
							@include font(12, 14, 400);
							margin-bottom: 5px;
                            @include textTruncate();

							&.-last {
								margin-bottom: 2px;
							}
						}
					}

					.divider {
						position: absolute;
						bottom:0;
						height: 1px;
						width: 100%;
						background: rgba(0, 0, 0, .3);
					}
				}
			}
		}

		&__myposition {
			bottom: 90px;
			right: 10px;
			width: 44px;
			height: 44px;
			border-radius: 50%;
			border: 2px solid #e02020;
			
			img {
				width: 24px;
				height: 24px;
				margin-top: 10px;
				margin-right: 2px;
			}
		}

		&__navbar {
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.18);
			backdrop-filter: blur(6px);
			height: 48px;
			@include flex(row, center, center);

			&-item {
				margin: 0 10px;
				text-transform: uppercase;
				
				&.-active {
					color: #000;
					font-weight: bold;
				}
			}
		}

		&__search {
			width: auto;
			left: 14px;
			right: 18px;
			z-index: 3;
			top: 20px;
			// border-radius: 24px;
			overflow: hidden;
			box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.16);

			input {
				height: 36px;
				border-radius: inherit;
				border: none;
				background-color: rgba(255, 255, 255, 0.85);
				backdrop-filter: blur(3px);
				outline: none;
				color: $black;
				font-size: 17px;

				&::placeholder {
					color: $black;
					opacity: 1;
					font-size: 17px;
				}
				padding: 0 16px 0 34px;
				width: 100%;
			}

			img {
				width: 14px;
				height: 14px;
				z-index: 1;
				@include pos-centered-v;
				left: 10px;
			}
		}
	}
</style>
