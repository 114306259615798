<template>
	<div class="item-images">
		<div class="item-images__slider">
			<div class="item-images__slider-paging" v-if="images.length > 1">
				<div 
					class="item-images__slider-paging-page"
					v-for="(image, i) in images"
					:key="i"
					:class="{'-active': i === index}"
				>
				</div>
			</div>
			<div 
				class="item-images__slider-item"
				:class="{'-active': i === index}"
				v-for="(image, i) in images"
				:key="`${image}-${i}`"
				:style='`background-image: url("${image}");`'
				v-show="i <= index + 2 && i >= index - 2 "
			>
			</div>
			<div class="item-images__slider-prev" @click="goprev"></div>
			<div class="item-images__slider-next" @click="gonext"></div>
		</div>
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'ItemImages',
	props: {
		data: {
			type: Array,
			default: () => ({})
		},
		user: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			index: 0
		}
	},
	computed: {
		images() {
			if (this.user) return this.data
			if (!this.data.length) {
				return ['/img/restaurantPlaceholder.jpg']
			}
			return this.data.slice(0,5).map((uri) => {
				return uri.url
			})
		}
	},
	methods: {
		gonext() {
			const len = this.images.length,
				index = this.index
			if (index+1 === len) {
				return
			}
			window.requestAnimationFrame(() => {
				this.index++
			})
		},
		goprev() {
			const index = this.index
			if (!index) {
				return
			}
			window.requestAnimationFrame(() => {
				this.index--
			})
		}
	}
}
</script>
<style lang="scss">
	.item-images {
		width: 100%;
		height: 100%;
		transition: height 0.15s linear;
		will-change: height;
		position: relative;

		&.-short {
			height: 50%;
			transition: height 0.15s linear; 

			.itemview-items__item-label {
				// top: 30%;
				bottom: 20px;
				transition: top 0.15s linear;
			}
		}

		&__slider {
			width: 100%;
			height: 100%;
			position: relative;

			&-paging {
				position: absolute;
				top: 5px;
				width: 100%;
				@include flex(row, center, center);
				z-index: 3;
				// padding: 0 10px;

				&-page {
					width: 100%;
					height: 3px;
					border-radius: 15px;
					background-color: rgba(255, 255, 255, 0.5);
					backdrop-filter: blur(10px);
					margin: 0 2px;
					&:last-child {
						margin-right: 10px;
					}
					&:first-child {
						margin-left: 10px;
					}
					&.-active {
						background-color: rgba(255, 255, 255, .9);
					}
				}
			}

			&-item {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				background-size: cover;
				background-position: 50%;
				opacity: 0;

				&.-active {
					opacity: 1;
				}
				z-index: 1;
			}
			

			&-prev,
			&-next {
				position: absolute;
				top: 0;
				width: 50%;
				height: 100%;
				z-index: 1;
				background: rgba(0, 0, 0, .1);
			}

			&-prev {
				left: 0;
			}

			&-next {
				right: 0;
			}
		}
	}
</style>
