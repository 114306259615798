<script>
export default {
  name: 'UIButton',
  functional: true,
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  render(h, ctx) {
    const {
      props: { text },
      listeners,
    } = ctx
    return (
      <div class="ui-button">
        {text}
      </div>
    )
  },
}
</script>
<style lang="scss" scoped>
.ui-button {
  @include flex(row, center, center);
  @include font(20, 22, 400);
  box-sizing: border-box;
  color: $black;
  width: 100%;
  height: 48px;

  background: #ffffff;
  border-radius: 5px;
}
</style>
