export default {
  computed: {
    isInRelationParent() {
      const rootUid = this.rootPlaceUid || this.placeUid
      if (!rootUid) return false

      const categories = this.categories || this.categoriesWithDishes
      const currentPlaceUid = categories?.[0]?.place_uid
      return currentPlaceUid && currentPlaceUid !== rootUid
    },
    currentCheckin() {
      return this.$checkins.find((ch) => ch.place_uid === this.placeUid)
    },
    currentCheckinStatus() {
      return this.currentCheckin?.status
    },
  },
  methods: {
    enterCode(placeUid) {
      const hasUnpayedInvoice = this.$root.GOM.checkUnpaidInvoice(true)
      if (hasUnpayedInvoice) return
      const isSelfCheckin =
        this.$PS.getSupportedFlowsByUid(placeUid)?.self_checkin

      if (this.$checkins.length) {
        if (isSelfCheckin && this.currentCheckinStatus === 'deferred') {
          this.$root.selfCheckin.show(placeUid)
        } else {
          this.callWaiter(false)
        }
      } else {
        if (isSelfCheckin) {
          this.$root.selfCheckin.show(placeUid)
        } else if (this.placeType === 'hotel') {
          this.$root.GOM.roomServiceScenarioMethod(placeUid)
        } else {
          this.$root.GOM.tableCodScenarioMethod(placeUid)
        }
      }
    },
    showContextMenu(ask_for_bill) {
      return new Promise((resolve) => {
        this.$bus.$emit('openContextMenu', {
          config: {
            type: 'modal',
            style: 'pink',
            title: this.$t('order.waitUpperComa'),
            subtitle: ask_for_bill
              ? this.$t('order.nowWaiterBringBill')
              : this.$t('order.waiterCoomingSoon'),
            icon: `/img/${ask_for_bill ? 'bill' : 'waiter'}.svg`,
            disableBackdropClose: true,
          },
          actions: [
            {
              id: 'ok',
              text: 'OK',
            },
          ],
          resolve: () => {
            resolve()
          },
          reject: () => {
            resolve()
          },
        })
      })
    },
    callWaiter: async function (ask_for_bill = false) {
      this.$store.dispatch('places/showLoader', true)
      const answer = await window.ordersStore.callWaiter(ask_for_bill)
      this.$store.dispatch('places/hideLoader')
      if (answer.success) {
        await this.showContextMenu(ask_for_bill)
      }
      return
    },
  },
}
