<template>
  <div class="order-tut">
    <div class="order-tut__infolabel">
      <img
        class="order-tut__infolabel-close"
        src="/img/closeWhiteVector.svg"
        @click="closeTutorial()"
      />
      <div class="order-tut__infolabel-row -first">
        <img
          src="/img/orderIcon.svg"
          class="order-tut__infolabel-row__image -order"
        />
        <img
          src="/img/hand-pencil-bg.svg"
          class="order-tut__infolabel-row__image -hand"
        />
      </div>
      <div class="order-tut__infolabel-row -text">
        {{ $t('T000003') }}
      </div>
      <div class="more-button" @click="showScene()">{{ $t('T000004') }}</div>
    </div>
    <div class="order-tut__scene" v-if="isShownScene">
      <div class="order-tut__scene-container">
        <img class="scene-close" src="/img/closeWhiteVector.svg" @click="hideScene()"/>
        <div class="scene-pagination">
          <div 
            v-for="(slide, index) in slides"
            :key="index"
            class="scene-pagination__page"
            :class="{'-active': index === activeSlideIndex}"
          ></div>
        </div>
        <swipe-container :items="slides" @active-slide-changed="updateActiveSlide">
          <template #item-0>
            <Tutorial1 />
          </template>
          <template #item-1>
            <Tutorial2 />
          </template>
          <template #item-2>
            <Tutorial3 />
          </template>
          <template #item-3>
            <Tutorial4 />
          </template>
        </swipe-container>
      </div>
    </div>
  </div>
</template>

<script>
import Tutorial1 from './Tutorial1'
import Tutorial2 from './Tutorial2'
import Tutorial3 from './Tutorial3'
import Tutorial4 from './Tutorial4'
import SwipeContainer from './SwipeContainer.vue';

export default {
  components: {
    SwipeContainer,
    Tutorial1,
    Tutorial2,
    Tutorial3,
    Tutorial4,
  },
  data() {
    return {
      isShownScene: false,
      activeSlideIndex: 0,
      slides: [Tutorial1, Tutorial2, Tutorial3, Tutorial4]
    }
  },
  methods: {
    showScene() {
      this.isShownScene = true
    },
    hideScene() {
      this.isShownScene = false
    },
    updateActiveSlide(value) {
      this.activeSlideIndex = value
    },
    closeTutorial() {
      sessionStorage.setItem('tutorialClosed', 'true')
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
$delay-between-steps: 100;
$step-1-duration: 300;
$step-2-delay: $step-1-duration + $delay-between-steps;
$step-2-duration: 600;
$step-3-delay: $step-2-delay + $step-2-duration;
$step-3-duration: 600;

@keyframes step-1-infolabel {
  0% {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    opacity: 0;
  }
  100% {
    width: calc(100% - 64px);
    height: 341px;
    opacity: 1;
    border-radius: 10px;
  }
}

@keyframes step-1-close-btn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes step-1-order-icon {
  from {
    width: 24px;
    height: 24px;
  }
  to {
    width: 52.5px;
    height: 67.5px;
  }
}

@keyframes step-1-pencil-icon {
  from {
    opacity: 0;
    transform: translate3d(calc(-50% + 130px), calc(-50% + 110px), 0);
  }
  to {
    opacity: 1;
    transform: translate3d(calc(-50% + 17px), calc(-50% + 13px), 0);
  }
}

@keyframes step-1-text-block {
  from {
    opacity: 0;
    transform: translate3d(0, 67px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes step-2-pencil-icon {
  from {
    transform: translate3d(calc(-50% + 17px), calc(-50% + 13px), 0);
  }
  to {
    transform: translate3d(calc(-50% + 40px), calc(-50% + 13px), 0);
  }
}

@keyframes step-3-more-button {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-17px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

.order-tut {
  @include fixed-modal(103);

  &__infolabel {
    position: absolute;
    bottom: 45px;
    right: 32px;
    background: #2b2b2f;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.6);

    @include animate(step-1-infolabel);

    &-close {
      height: 14px;
      position: absolute;
      right: 19px;
      top: 19px;
      width: 14px;
      z-index: 2;

      @include animate(step-1-close-btn);
    }

    &-row {
      position: relative;
      &.-first {
        height: 148px;
      }
      &__image {
        @include pos-centered;

        &.-order {
          @include animate(step-1-order-icon);
        }

        &.-hand {
          width: 98px;
          height: 91px;
          animation: step-1-pencil-icon #{$step-1-duration}ms ease-in 0s 1
              normal forwards,
            step-2-pencil-icon #{$step-2-duration}ms ease-in #{$step-2-delay}ms
              1 normal forwards;
        }
      }

      &.-text {
        @include font(26, 34, 400);
        @include animate(step-1-text-block);
        color: $white;
      }
    }
    .more-button {
      @include pos-centered-h;
      @include flex(row, center, center);
      z-index: 1;
      @include font(17, 21, 400);
      // bottom: 20px;
      width: 141px;
      height: 40px;
      background: #2b2b2f;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.6);
      border-radius: 21px;
      opacity: 0;
      color: #c1c1c1;
      text-transform: uppercase;
      bottom: 20px;

      @include animate(step-3-more-button, $step-3-duration, $step-3-delay);
    }
  }

  @keyframes scene-open {
    from {
      background-color:#2B2B2F;
      border-radius: 10px;
      bottom: 45px;
      height: 341px;
      right: 32px;
      width: calc(100% - 64px);
    }
    to {
      background-color:#000000;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      
    }
  }

  @keyframes scene-close-btn-open {
    from {
      transform: translateY(72px);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes scene-pagination-open {
    from {
      transform: translateY(-10px) translateX(-50%);
      opacity: 0;
    }
    to {
      transform: translateY(0) translateX(-50%);
      opacity: 1;
    }
  }

  @keyframes pagination-active {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &__scene {
    @include flex(row, center, center);
    position: absolute;
    z-index: 2;

    @include animate(scene-open);
    animation-fill-mode: forwards;

    &-container {
      width: 100%;
      height: 450px;
      position: relative;

      .scene {
        &-close {
          position: absolute;
          top: 0;
          right: 51px;

          @include animate(scene-close-btn-open);
        }

        &-pagination {
          @include pos-centered-h;
          @include flex(row, space-between, center);
          bottom: 0;

          @include animate(scene-pagination-open);

          &__page {
            width: 5px;
            height: 5px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 2.5px;
            margin-right: 5px;

            &.-active {
              background: $white;
              position: relative;
              &::after {
                content: '';
                position: absolute;
                width: 15px;
                height: 5px;
                background: rgba(255, 255, 255, 1);
                border-radius: 2.5px;
                
                @include animate(pagination-active);
              }

              &:last-child {
                &::after {
                  right: 0px;
                }
              }
              // @include animate(pagination-active, 600);
            }
          }
        }

        .swipe-container {
          display: flex;
          overflow-x: auto;
          scroll-snap-type: x mandatory;
        }

        .swipe-item {
          flex: 0 0 auto;
          width: 100%;
          scroll-snap-align: start;
        }
      }
    }
  }
}
</style>
