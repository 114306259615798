<template>
	<div class="all-place-info">
		<div class="m-list">
			<div class="m-list-item" v-if="place.address">
				<img width="24px" height="auto" src="/img/iconAddressNav.png" srcset="/img/iconAddressNav@3x.png 3x /img/iconAddressNav@2x.png 2x /img/iconAddressNav.png 1x" class="m-list-item__icon">
				<a class="m-list-item__text" :href="`https://maps.google.com/maps?q=${queryPlace}`">{{ place.address }}</a>
			</div>
			<div class="m-list-item" v-if="place.phone">
					<img width="20px" height="auto" src="/img/iconPhone.png" srcset="/img/iconPhone@3x.png 3x /img/iconPhone@2x.png 2x /img/iconPhone.png 1x" class="m-list-item__icon">
					<div class="m-list-item__text"><a style="text-decoration: none; color: inherit;" :href="`tel:${place.phone}`">{{ place.phone }}</a></div>
			</div>
			<div 
				class="m-list-item" 
				v-if="working_hours && working_hours.length"
				@click="hoursOpened = true"
			>
				<img 
					width="20px" 
					height="auto"
					src="/img/checkboxOff.png" 
					srcset="/img/checkboxOff@3x.png 3x /img/checkboxOff@2x.png 2x /img/checkboxOff.png 1x" 
					class="m-list-item__icon"
				>
				<div 
					class="m-list-item__text" 
					v-html="working_hours"
					:class="{'-closed': !hoursOpened}"
				></div>
				<img 
					v-if="!shortInfo"
					width="24px" 
					height="auto" 
					src="/img/iconArrowDown.png" 
					srcset="/img/iconArrowDown@3x.png 3x /img/iconArrowDown@2x.png 2x /img/iconArrowDown.png 1x" 
					class="m-list-item__opener"
					:class="{'-open': hoursOpened}"
					@click.stop="hoursOpened = !hoursOpened"
				>
			</div>
			<div 
				class="m-list-item" 
				v-if="description && !shortInfo" 
				@click="aboutOpened = true"
			>
				<img 
					width="20px" 
					height="auto" 
					src="/img/iconInfo.png" 
					srcset="/img/iconInfo@3x.png 3x /img/iconInfo@2x.png 2x /img/iconInfo.png 1x" 
					class="m-list-item__icon"
				>
				<div 
					class="m-list-item__text -place" 
					v-html="description"
					:class="{'-closed': !aboutOpened}"
				></div>
				<img 
					width="24px" 
					height="auto" 
					src="/img/iconArrowDown.png" 
					srcset="/img/iconArrowDown@3x.png 3x /img/iconArrowDown@2x.png 2x /img/iconArrowDown.png 1x" 
					class="m-list-item__opener"
					:class="{'-open': aboutOpened}"
					@click.stop="aboutOpened = !aboutOpened"
				>
			</div>
		</div>
		<div class="all-place-info__images" v-if="!shortInfo && currentUid === place.uid && currentPhotos.length && openInfo">
			<div class="all-place-info__images-video" v-if="place.video_uri">
				<video width="100%" autoplay loop muted>
					<source :src="place.video_uri" type="video/mp4">
				</video>
			</div>
			<div 
				class="all-place-info__images-image"
				v-for="(photo, i) in currentPhotos"
				:key="`${photo.url}-${i}`"
			>
				<img 
					v-preview:scope-a
					:key="`${photo.url}-${i}-img`"
					:src="photo.url"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
import createPreviewDirective from "vue-photoswipe-directive"

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

export default {
	props: {
		place: {
			type: Object,
			required: true
		},
		openInfo: {
			type: Boolean,
			default: false
		},
		currentUid: {
			type: String,
			default: ''
		},
		shortInfo: {
			type: Boolean,
			default: false
		}
	},
	directives: {
		preview: createPreviewDirective({shareEl: false}, PhotoSwipe, PhotoSwipeUI)
	},
	data() {
		return {
			hoursOpened: false,
			aboutOpened: false
		}
	},
	computed: {
		queryPlace() {
			return this.place.address.replace(/\,| /ig, '+')
		},
		currentPhotos() {
			const allPhoto = (this.place?.photo_uris || [])
			return allPhoto.slice(5, allPhoto.length)
		},
		working_hours() {
			const working_hours = this.place?.working_hours || [],
				len = working_hours.length
			if (!len) return null
			const days = [this.$t('plSwModal.mon'), this.$t('plSwModal.tue'), this.$t('plSwModal.wed'), this.$t('plSwModal.thr'), this.$t('plSwModal.fri'), this.$t('plSwModal.sat'), this.$t('plSwModal.sun')]
			if (len === 2) {
				const time = working_hours.join(' – ')
				return days.map((day) => {
					return `<p>${day} ${time}</p>`
				}).join('')
			} else if (len === 1) {
				return working_hours[0]
			}
			return days.map((day, i) => {
				return `<p>${day} ${working_hours[i]}</p>`
			}).join('')
		},
		description() {
			let description = this.$t('plSwModal.info')+'<br>'
			description+= this.place?.description || ''
			return description.replace(/\n/ig, '<br>')
		}
	}
}
</script>

<style lang="scss">
	.all-place-info {
		width: 100%;
		// overflow: scroll;
		height: 0;
		will-change: height;
		transition: height 0.15s linear; 
		&.-open {
			height: auto;
			transition: height 0.15s linear;
		}

		&__images {
			padding: 0 15px;
			margin-top: 10px;
			@include flex(row, flex-start, flex-start);
			flex-wrap: wrap;
			position: relative;
			&-video {
				width: 100%;
			}
			&-image {
				width: 33.1%;
				height: 124px;
				// display: inline-block;
				background-position: 50% 50%;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 1px;
				&:not(:nth-child(3n)) {
					margin-right: 1px;
				}
				overflow: hidden;
				img {
					width: 90%; /* or any custom size */
					height: 90%; 
					object-fit: cover;
				}
			}
		}
	}

	.all-place-info {
		.m-list-item {
			padding: 12px 0 10px 0;
		}
		.m-list-item__text{
			display: block;
			@include font(14, 24, normal);
			
			&.-closed {
				height: 20px;
				overflow: hidden;
			}
			p {
				margin: 0;
			}
		}

		&.-grey {
			.m-list {
				background: $darkGreyTwo;
				color: $white;
				.m-list-item__text {
					color: $white;
				}
			}
		}
	}
</style>