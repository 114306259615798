<template>
  <div class="ch-r-m" :class="{ '-open': open }">
    <div
      class="self-checkin-help-wrapper"
      v-if="!deliveryFlow && isShowSelfCheckinHelp"
    >
      <img src="/img/selfCheckinNotiece.svg" alt="" class="-inchooseModal" />
      <div class="self-checkin-help">
        <div class="self-checkin-help__chip"></div>
        <div class="self-checkin-help__wrapper">
          <div class="self-checkin-help__top">
            <div class="self-checkin-help__top-text">
              {{ $t('selfCheckin.helpText') }}
            </div>
          </div>
          <div class="self-checkin-help__bottom" @click="closeSelfCheckinHelp">
            OK
          </div>
        </div>
      </div>
    </div>
    <img
      src="/img/backWhite.png"
      srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
      class="back-icon"
      @click="close()"
    />
    <div class="ch-r-m__header">
      {{ $t('roomService.chooseRestaurant') }} ({{ places.length }})
    </div>
    <img
      v-if="isShowBellIcon"
      src="/img/call-bell-white.svg"
      class="call-icon"
      @click="enterCode(placeUid)"
    />
    <div class="ch-r-m__body">
      <div v-for="place in places" :key="place.uid" class="ch-r-m__body-item">
        <div class="ch-r-m__body-item__image">
          <img
            :src="place.images[place.imageIndex]"
            :key="place.images[place.imageIndex]"
            @click="imageClick($event, place)"
          />
          <div class="ch-r-m__body-item__image-filter"></div>
          <div class="ch-r-m__body-item__image-paging">
            <div
              v-for="(image, index) in place.images"
              :key="image"
              class="ch-r-m__body-item__image-paging-page"
              :class="{ '-active': index === place.imageIndex }"
            ></div>
          </div>
        </div>
        <div class="ch-r-m__body-item__title">{{ place.name }}</div>
        <div class="ch-r-m__body-item__subtitle">
          {{ place.short_description }}
        </div>
        <div class="ch-r-m__body-item__go-button" @click="openMenu(place)">
          <img
            src="/img/backWhite.png"
            srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import callWaiterMixin from '@/mixins/callWaiterMixin'
import hasFirstSelfCheckinMixin from '@/mixins/hasFirstSelfCheckinMixin'

export default {
  mixins: [callWaiterMixin, hasFirstSelfCheckinMixin],
  data() {
    return {
      open: false,
      placeUIds: [],
      places: [],
      appWith: 0,
      resolve: null,
      placeUid: '',
      placeType: '',
      supportedFlows: null,
    }
  },
  computed: {
    isShowBellIcon() {
      return this.supportedFlows?.orders
    },
  },
  watch: {
    open(value) {
      if (!value) {
        this.placeUIds.length = 0
        this.places.length = 0
        this.placeUid = ''
        this.supportedFlows = null
      }
    },
  },
  mounted() {
    this.appWith = window.innerWidth
    this.getPlaces()
  },
  methods: {
    show(placeUIds = [], placeUid = '', placeType = '', resolve) {
      this.placeUid = placeUid
      this.placeType = placeType
      this.$set(
        this,
        'supportedFlows',
        this.$PS.getSupportedFlowsByUid(placeUid)
      )
      this.placeUIds.push(...placeUIds)
      this.getPlaces()
      this.open = true
      this.resolve = resolve
    },
    close(resolve = false) {
      if (resolve && typeof this.resolve === 'function') {
        this.resolve()
      }
      this.open = false
    },
    async getPlaces() {
      if (!this.placeUIds.length) return

      this.$store.dispatch('places/showLoader', true)
      const resp = (
        await this.$DB.db
          .collection('places')
          .where('uid', 'in', this.placeUIds)
          .get()
      ).docs.reduce((accumulator, doc) => {
        const item = doc.data()
        if (item.has_menu) {
          accumulator.push({
            ...item,
            images: (item?.photo_uris ?? [])
              .slice(0, 5)
              .map((photo_uri) => photo_uri.url),
            imageIndex: 0,
          })
        }
        return accumulator
      }, [])
      const sortedPlaces = this.placeUIds.reduce((acc, palceUid) => {
        const place = resp.find((findedPlace) => findedPlace.uid === palceUid)
        if (place) {
          acc.push(place)
        }
        return acc
      }, [])
      this.places.push(...sortedPlaces)
      this.$store.dispatch('places/hideLoader')
    },
    imageClick({ clientX }, item) {
      const direction = this.appWith / 2 > clientX ? 'left' : 'right'
      if (direction === 'right' && item.images.length - 1 !== item.imageIndex) {
        // cleck right
        item.imageIndex++
      } else if (direction === 'left' && item.imageIndex) {
        item.imageIndex--
      }
    },
    async openMenu(place) {
      const languages = place?.languages || []
      let lang = ''
      if (languages && languages.length > 1) {
        this.$store.dispatch('places/hideLoader')
        const menuLangQuery = this.menuLangQuery
        try {
          lang = await this.$root.langModal.show(languages)
        } catch (e) {
          console.log(e)
        }
        if (lang) {
          // localStorage.setItem('langKey:' + uid, lang)
          // if (this.deepLink) {
          // 	const placeUrl = this.$route.path+'?menumode=true&menulang='+lang
          // 	this.$router.replace(placeUrl).catch(()=>{})
          // 	sessionStorage.setItem('backToPlaceUrl', placeUrl)
          // 	this.loadMenu(this.uid, delivery, false)
          // } else {
          // 	this.$bus.$emit('translatePlace', uid, lang, delivery)
          // }
        }
        if (lang && this.resolve) {
          this.open = false
          this.resolve(lang, place.uid)
        }
      } else if (this.resolve) {
        this.open = false
        this.resolve(lang, place.uid)
      }
    },
  },
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
  color: $white;
}
.ch-r-m {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 103;
  background: black;
  visibility: hidden;
  transform: translateY(100vh);
  transition: all 0.3s ease;

  &.-open {
    visibility: visible;
    transform: translateY(0);
  }

  .back-icon {
    position: absolute;
    left: 25px;
    top: 25px;
    z-index: 6;
  }

  .call-icon {
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 6;
  }

  &__header {
    @include font(18, 24, 400);
    @include pos-centered-h();
    width: 100%;
    top: 25px;
    z-index: 5;
  }

  &__body {
    padding-top: 75px;
    overflow: scroll;
    height: 100%;

    &-item {
      height: 316px;
      background: #2b2b2f;
      border-radius: 6px 6px 21px 6px;
      overflow: hidden;
      text-align: left;
      position: relative;
      padding: 2px;
      margin-bottom: 7px;

      &__image {
        width: 100%;
        height: 218px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px 6px 0 0;
        }

        &-filter {
          position: absolute;
          width: 100%;
          height: 51px;
          left: 0px;
          bottom: 0;
          background: linear-gradient(
            180deg,
            rgba(43, 43, 47, 0) 0%,
            #2b2b2f 100%
          );
          z-index: 1;
        }

        &-paging {
          @include flex(row, space-between, center);
          padding: 0 14px;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 0px;
          right: 0;
          z-index: 2;

          &-page {
            background: #7e7e80;
            border-radius: 0.5px;
            height: 100%;
            margin-left: 2px;
            width: 100%;

            &.-active {
              background: $white;
            }
          }
        }
      }

      &__title {
        @include textTruncate(calc(100% - 70px));
        @include font(24, 42, 600);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        margin-top: 10px;
        padding: 0 14px;
      }

      &__subtitle {
        @include textTruncate(calc(100% - 70px));
        @include font(15, 24, 400);
        padding: 0 14px;
      }

      &__go-button {
        @include flex(row, center, center);
        width: 76px;
        height: 40px;
        position: absolute;
        right: 2px;
        bottom: 2px;
        background: #b8a05e;
        border-radius: 20px;

        img {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
