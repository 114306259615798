<template functional>
  <div class="tutorial-item -pay">
    <img
      class="tutorial-item__image phone-image"
      src="/img/tutorial-phone-pay.svg"
    />
    <div class="finger-div">
      <img
        class="tutorial-item__image blink-pay-payment"
        src="/img/tutorial-pay-blink-payment.svg"
      />
      <img
        class="tutorial-item__image blink-pay-btn"
        src="/img/tutorial-payment-btn-final.svg"
      />
      <img
        class="tutorial-item__image finger-image-pay"
        src="/img/tutorial-finger.svg"
      />
    </div>
    <div class="tutorial-item__text" v-html="parent.$t('T000008')" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
$delay-between-steps: 100;
$step-1-duration: 300;
$step-2-delay: $step-1-duration + $delay-between-steps;
$step-2-duration: 300;
$step-3-delay: $step-2-duration + $delay-between-steps;
$step-3-duration: 300;

.tutorial-item {
  @include flex(column, flex-start, center);
  padding: 25px 32px;

  &__text {
    @include font(24, 30, 400);
    color: $white;
  }

  .finger-div {
    height: 56px;
    position: relative;
  }

  .blink-pay-payment {
    @include pos-centered-h;
    top: -85px;
  }

  .blink-pay-btn {
    @include pos-centered-h;
    top: -36px;
  }

  &.-pay {
    .finger-image-pay {
      transform: translate3d(-8px, -30px, 0);
    }
  }
}
</style>
