<template>
	<div class="c-modal" @touchmove="touchmove" @touchstart="touchstart" @touchend="touchend">
		<img 
			src="/img/arrowLeft.svg" 
			class="back-icon"
			@click="$emit('close', '')"
		/>
		<div class="c-modal-paging">
			<div 
				class="c-modal-paging-page"
				v-for="(dish, i) in dishes"
				:key="i"
				:class="{'-active': i === index}"
			>
			</div>
		</div>
		<div class="c-modal-slider">
			<div 
				class="c-modal-slider__item slider-item"
				v-for="(dish, i) in dishes"
				:class="{'-active': i === index}"
				:key="dish.uid"
				:style='`background-image: url("${getImage(dish.photo_uri)}");`'
			>
				<div class="category-name">{{ category.title }}</div>
				<div class="dish-title">{{ dish.title }}</div>
				<div class="dish-price">{{ currencySymbol }} {{ ((delivery) ? dish.delivery_price : dish.price) || 0 }}</div>
			</div>
			<div class="c-modal-slider__nav -prev" @click="goprev"></div>
			<div class="c-modal-slider__nav -next" @click="gonext"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CategoryModal',
		props: {
			dishes: {
				type: Array,
				default: () => ([])
			},
			category: {
				type: Object,
				required: true
			},
			currentDish: {
				type: String,
				required: true
			},
			currencySymbol: {
				type: String,
				default: '$'
			}
		},
		data() {
			const {currentDish, dishes} = this,
				_index = dishes.findIndex(dish => dish.uid === currentDish)
				
			return {
				index: (~_index) ? _index : 0,
				startTouch: 0,
				imageHeight: 0,
				image: null,
				sliderItem: null,
				frame: null
			}
		},
		computed: {
			delivery() {
				return this.$store.state.user.delivery
			}
		},
		methods: {
			getImage(photo_uri) {
				if (!photo_uri || photo_uri === 'https://storage.googleapis.com/mayber-prod.appspot.com/') {
					return '/img/restaurantPlaceholder.jpg'
				}
				return photo_uri
			},
			gonext() {
				const len = this.dishes.length,
					index = this.index
				if (index+1 === len) {
					return
				}
				window.requestAnimationFrame(() => {
					this.index++
				})
			},
			goprev() {
				const index = this.index
				if (!index) {
					return
				}
				window.requestAnimationFrame(() => {
					this.index--
				})
			},
			touchstart(ev) {
				this.startTouch = ev.touches[0].screenY
				this.image = document.querySelector('.c-modal-slider')
				this.imageHeight = this.image.clientHeight
				this.sliderItem = document.querySelector('.c-modal-slider__item.slider-item.-active')
				this.image.style['transition'] = ''
				this.sliderItem.style['transition'] = ''
			},
			touchmove(ev) {
				const posY = ev.touches[0].screenY,
					{startTouch, imageHeight} = this,
					addHeight =  posY - startTouch,
					nextHeight = imageHeight + addHeight,
					percents = Math.floor(nextHeight * 100 / imageHeight)
				if (addHeight > 0 && addHeight < 200) {
					window.cancelAnimationFrame(this.frame)
					this.frame = window.requestAnimationFrame(() => {
						this.image.style.height = `${nextHeight}px`
						this.sliderItem.style['background-size'] = `${percents}% auto`
					})
				}
					

			},
			touchend(ev) {
				this.startTouch = 0
				window.cancelAnimationFrame(this.frame)
				this.frame = window.requestAnimationFrame(() => {
					this.image.style['transition'] = 'height 0.15s linear'
					this.sliderItem.style['transition'] = 'background-size 0.15s linear'
					this.image.style.height = `${this.imageHeight}px`
					this.sliderItem.style['background-size'] = '100% auto'
					this.image = null
					this.sliderItem = null
				})
			}
		}
	}
</script>

<style lang="scss">
	.c-modal {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: $white;
		z-index: 100;

		&-paging {
			position: fixed;
			top: 5px;
			width: 100%;
			z-index: 101;
			@include flex(row, center, center);

			&-page {
				width: 100%;
				height: 3px;
				border-radius: 15px;
				background-color: rgba(255, 255, 255, 0.5);
				backdrop-filter: blur(10px);
				margin: 0 2px;
				&:last-child {
					margin-right: 10px;
				}
				&:first-child {
					margin-left: 10px;
				}
				&.-active {
					background-color: rgba(255, 255, 255, .9);
				}
			}
		}
				

		.back-icon {
			position: fixed;
			left: 20px;
			top: 20px;
			height: 40px;
			width: 40px;
			z-index: 101;
		}

		&-slider {
			width: 100%;
			height: 50%;
			position: relative;
			// will-change: height;
			// transition: height 0.1s linear; 

			&__item {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				background-position: 50% 50%;
				background-size: 100% auto;
				background-repeat: no-repeat;
				
				
				&.-active {
					opacity: 1;
				}

				&.slider-item {
					.category-name {
						position: absolute;
						left: 10px;
						top: 26%;
						@include font(25, 25, bold);
						color: $white;
						text-shadow: 1px 1px #000;
					}
					.dish-price {
						position: absolute;
						bottom: 20px;
						left: 10px;
						color: $white;
						@include font(16, 18, 500);
						text-shadow: 1px 1px #000;
					}

					.dish-title {
						position: absolute;
						bottom: 40px;
						left: 10px;
						color: $white;
						@include font(18, 20, 500);
						text-align: left;
						text-shadow: 1px 1px #000;
					}
				}
			}

			&__nav {
				position: absolute;
				top: 0;
				width: 50%;
				height: 100%;

				&.-prev {
					left: 0
				}
				&.-next {
					right: 0
				}
			}
		}
	}
</style>