import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const MIN_AGE = 18;
const MAX_AGE = 100;

export function getBirthDateRange () {
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() < 10 ? `0${date.getUTCMonth() + 1}` : `${date.getUTCMonth() + 1}`;
    const day = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`
    
    return {
        min: `${year - MAX_AGE}-${month}-${day}`,
        max: `${year - MIN_AGE}-${month}-${day}`,
    };
};

export function isBirthDateInRange (birthDate) {
    const range = getBirthDateRange();

    if (dayjs(birthDate).isBetween(range.min, range.max, 'day', '[]')) {
        return true;
    }

    return false;
}
