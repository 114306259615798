import Vue from 'vue'

const state = {
	places: [],
	showLoader: true,
	menuCategories: [],
	menuItems: [],
	ready: false,
	opacity: false,
	timer: null,
	maximumLoaderTime: 5000,
	swipeModalActive: false
}

const getters = {
	
}

const actions = {
	showLoader({commit}, opacity=false) {
		commit('SET_LOADER', true)
		if (opacity) {
			commit('SET_OPACITY', opacity)
		}
		
	},
	hideLoader({commit}) {
		commit('SET_LOADER', false)
		commit('SET_OPACITY', false)
	}
}

const mutations = {
	'SET_PLACES'(state, data=[]) {

		// console.time('SET_PLACES')
		// data = data
		// Vue.set(state, 'places', data.reduce((memo, item) => {
		// 	const {name, geo_point} = item
		// 	let _search = (item.name || '' + item.lat_name || '').toLowerCase()
		// 	if (~_search.indexOf('ё')) {
		// 		_search+= _search.replace(/ё/g, 'е')
		// 	}
		// 	item._search = _search
		// 	if (name && geo_point) {
		// 		return [...memo, Object.freeze(item)]
		// 	}
		// 	return memo
		// }, [])/*.slice(0,200)*/)
		state.ready = true
		// console.timeEnd('SET_PLACES')
	},
	'SET_LOADER'(state, val) {
		state.showLoader = val
	},
	'SET_MENU_CATEGORIES'(state, data=[]) {
		Vue.set(state, 'menuCategories', data)
	},
	'SET_MENU_ITEMS'(state, data=[]) {
		Vue.set(state, 'menuItems', data)
	},
	'SET_OPACITY'(state, opacity) {
		state.opacity = opacity
	},
	'SET_SWIPE_MODAL_ACTIVE'(state, active = false) {
		state.swipeModalActive = active
	}
}

export default {
	state,
    getters,
    actions,
    mutations,
	namespaced: true
}
 