<template>
  <div class="form-input" :class="{ '-border-bottom': borderBottom, '-has-error': hasError }">
    <div class="form-input__title">{{ title }}</div>
    <slot v-if="useSlot"></slot>
    <input
      v-else
      :value="value"
      @input="$emit('input', $event.target.value)"
      :type="type"
    />
    <div class="form-input__title -error-message" v-if="hasError && errorMessage">{{ errorMessage }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    borderBottom: {
      type: Boolean,
      default: true,
    },
    useSlot: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.form-input {
  @include flex(column, flex-start, flex-start);
  width: 100%;
  padding-left: 6px;

  &.-has-error {
    .form-input__title {
      color: red;
      
    }

    input {
      animation: shake 0.2s ease-in-out 0s 2;
    }
  }

  &.-border-bottom {
    input {
      border-bottom: 1px solid #ffffff;
      padding-bottom: 14px;
    }
  }

  &__title {
    @include font(12, 14, 400);
    color: #717171;
    margin-bottom: 5px;
  }

  input {
    @include font(18, 21, 400);
    background: transparent;
    border: none;
    color: $white;
    outline: none;
    width: 100%;
  }

  &.-uppercase-input {
    input {
      text-transform: uppercase;
    }
  }

  @keyframes shake {
    0% { margin-left: 0rem; }
    25% { margin-left: 0.5rem; }
    75% { margin-left: -0.5rem; }
    100% { margin-left: 0rem; }
  }
}
</style>
