let iframeWindow = null;
export default {
    data() {
        return {
            paymentData: {
                confirmation_url: "",
                payment_id: "",
            },
            ordersStore: window.ordersStore,
        };
    },
    computed: {
        checkinItems() {
            const items = this.unpayedCheckin
                ? this.unpayedCheckinItems
                : this.ordersStore?.checkinItems ?? [];
            const uniqUids = new Set()
            return items.filter((item) => {
                if (!uniqUids.has(item.uid)) return uniqUids.add(item.uid)
            })
        },
        leftToPay() {
            const checkin = this.activeCheckin || this.currentCheckin;
            return checkin?.left_to_pay || 0;
        },
        payedInvoices() {
            return this.invoices.reduce((memo, invoice) => {
                if (invoice.left_to_pay == 0 && invoice.item_uids?.length) {
                    memo.add(invoice.uid);
                }
                return memo;
            }, new Set());
        },
        itemsToPay() {
            const { checkinItems, payedInvoices } = this;
            return checkinItems.filter((item) => {
                const invoice_uid = item.invoice_uid;
                return (
                    item.status !== "canceled" &&
                    (!invoice_uid || !payedInvoices.has(invoice_uid))
                );
            });
        },
        calculatedUnpaid() {
            return this.unpaidInvoice || this.ordersStore.calculatedUnpaid;
        },
        unpaidServiceFee() {
            return this.calculatedUnpaid?.additional_payments?.service_fee || 0;
        },
        unpaidDelivery() {
            return this.calculatedUnpaid?.additional_payments?.delivery || 0;
        },
    },
    watch: {
        leftToPay: {
            immediate: true,
            handler(amount) {
                if (amount == 0) {
                    this.clearPaymentData();
                } else {
                    this.ordersStore.calculateUnpaid();
                }
            },
        },
    },
    methods: {
        clearPaymentData() {
            if (window.newPayWindow) {
                setTimeout(() => {
                    window.newPayWindow.close();
                    window.newPayWindow = null;
                }, 1000);
            }
            window.removeEventListener("message", this.onMessage);
            iframeWindow = null;
            this.paymentData.confirmation_url = "";
            this.paymentData.payment_id = "";
        },
        createInvoice: async function (
            checkinUid,
            itemUids = [],
            placeUid,
            userUid
        ) {
            const invoices = (
                await this.$DB.db
                    .collection("invoices")
                    .where("checkin_uid", "==", checkinUid)
                    .get()
            ).docs.map((doc) => doc.data());
            itemUids.sort();
            const newItUidsStr = itemUids.join("");
            const activeInvoice = invoices.find((invoice) => {
                if (itemUids.length === invoice.item_uids?.length) {
                    const _uids = invoice.item_uids || [];
                    _uids.sort();
                    const invItUidsStr = _uids.join("");
                    return newItUidsStr === invItUidsStr;
                }
                return false;
            });
            // const invUids = invoices.map(i => i.uid)
            if (!activeInvoice) {
                try {
                    const {
                        data: { status, payload, error_code },
                    } = await this.$axios.post(this.$API_URL("/orders/pay"), {
                        checkin_uid: checkinUid,
                        item_uids: itemUids,
                        place_uid: placeUid,
                        user_uid: userUid,
                    });
                    if (status === "SUCCESS" && payload) {
                        invoices.push(payload);
                        return {
                            success: true,
                            invoices,
                            activeInvoice: payload,
                        };
                    }
                    return { success: false, error_code };
                } catch (e) {
                    return { success: false, error_code: e };
                }
            } else {
                return { success: true, invoices, activeInvoice };
            }
        },
        createPayCardLink(invoiceUids = [], amount) {
            return new Promise((resolve) => {
                this.$axios
                    .post(this.$PAYMENTS_API_URL("/card"), {
                        invoice_uids: invoiceUids,
                        amount: amount,
                    })
                    .then(({ data: { status, payload, error_code } }) => {
                        if (status === "SUCCESS" && payload) {
                            resolve({ success: true, data: payload });
                        } else {
                            resolve({ success: false, error_code });
                        }
                    })
                    .catch((err) => {
                        if (~err.toString().indexOf("code 400")) {
                            resolve({ success: false, error_code: 400 });
                            console.log("err", 400);
                        }
                        resolve({ success: false, error_code: 500 });
                    });
            });
        },
        listenIframe() {
            iframeWindow = document.getElementById("pay-frame").contentWindow;
            window.addEventListener("message", this.onMessage);
        },
        onMessage(message) {
            if (message.source !== iframeWindow) {
                return; // Skip message in this event listener
            }
            console.log("message", message);
        },
    },
};
