<template>
	<div class="send-review" :style="height">
		<div class="send-review__head" @click="closeMe()">
			<div class="send-review__head-blind">
			</div>
		</div>
		<div class="send-review__body">
			<div class="send-review__body-nav">
				<div class="send-review__body-nav-item">
					<img src="/img/cook-icon.svg" />
					{{ $t('order.tips.reviewChef') }}
				</div>
				<img 
					class="send-review__body-nav-close" 
					src="/img/icon-down-rounded.svg" 
					@click="closeMe()"
				/>
			</div>
			<div class="send-review__body-wrapper">
				<div class="send-review__body-comments">
					<div 
						v-for="comment in comments"
						:key="comment.uid"
						class="send-review__body-comments__item"
					>
						{{ comment.text }}
					</div>
				</div>
				<div class="send-review__body-actions">
					<textarea 
						class="send-review__body-actions-input" 
						v-model="commentText"
						:rows="rows" 
						@keyup="onIput"
						@focus="onFocus"
						@blur="onBlur"
						ref="input"
					/>
					<img 
						class="send-review__body-actions-send" 
						src="/img/check-rounded.svg"
						@click="sendComment" 
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		orderItem: {
			type: Object,
			required: true
		},
		userUid: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			commentText: '',
			rows: 1,
			prevHeight: 40,
			height: 'height:100%'
		}
	},
	computed: {
		comments() {
			return this.orderItem.guest_comments || []
		}
	},
	watch: {
		commentText(val) {
			if (!val) {
				this.rows = 1
				this.prevHeight = 40
			}
		}
	},
	methods: { 
		onFocus() {
			window.requestAnimationFrame(() => {
				this.height = `height:${window.innerHeight}px`
			})
		},
		onBlur() {

		},
		onIput(ev) {
			const scrollHeight = ev.target.scrollHeight
			if (this.prevHeight < scrollHeight && this.rows < 3) {
				this.prevHeight = scrollHeight
				this.rows++ 
			} else if (this.prevHeight > scrollHeight && this.rows > 1) {
				this.prevHeight = scrollHeight
				this.rows--
			}
		},
		closeMe() {
			this.$emit('close')
		},
		async sendComment() {
			const { commentText, orderItem, userUid } = this
			if (!commentText) return
			this.commentText = ''
			try {
                await this.$axios.post(
                    this.$API_URL('/orders/addComment'),
                    {
						'guest_comments': [
							{
								'order_item_uid': orderItem.uid,
								'text': commentText,
								'uid': this.$uuid()
							}
						],
						'place_uid': orderItem.place_uid,
						'user_uid': userUid
					}
                )
            } catch (e) {
                console.log('e', e)
            }
		}
	}
}
</script>

<style lang="scss">
	.send-review {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 20;
		border-radius: 10px 10px 0px 0px;
		background: linear-gradient(135deg, rgba(23, 115, 134, 0.901852) 0%, rgba(190, 34, 89, 0.895979) 98.75%);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(27px);

		&__head {
			position: relative;
			min-height: 26px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);

			&-blind {
				@include pos-centered-h();
				top: 6px;
				width: 36px;
				height: 4px;
				background: rgba(255, 255, 255, 0.2);
				border-radius: 2.5px;
			}
		}

		&__body {
			box-sizing: content-box;
			padding: 13px 20px 20px 20px;

			&-nav {
				position: relative;
				height: 40px;
				&-item {
					@include pos-centered();
					@include flex(row, flex-end, center);
					@include font(17, 20, 400);
					text-transform: uppercase;
					color: $white;
					
					> img {
						margin-right: 8px;
					}
				}

				&-close {
					position: absolute;
					right: 0;
				}
			}

			&-wrapper {
				box-sizing: content-box;
				width: calc(100% - 40px);
				position: absolute;
				padding: 0 20px 13px 20px;
				bottom: 0px;
				left: 0;
			}
			&-actions {
				@include flex(row, flex-end, flex-end);

				&-send {
					margin-left: 13px;
				}

				&-input {
					width: 100%;
					resize: none;
					border-radius: 20px;
					padding: 10px 20px;
					@include font(17, 20, 400);
					font-family: inherit;
					border: none;
					outline: none;
					box-sizing: border-box;
				}
			}

			&-comments {
				width: 100%;
				@include flex(column, flex-end, flex-start);
				margin-bottom: 20px;

				&__item {
					background: rgba(255, 255, 255, 0.8);
					border-radius: 20px;
					padding: 10px 20px;
					margin-top: 8px;
					text-align: left;
					max-width: calc(100% - 93px);
					word-break: break-word;
				}
			}
		}
	}
</style>