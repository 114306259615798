import Vue from 'vue'
const pathname = location.pathname

const userDeepLinkUrlRegexp = /^\/user(\/\?.*|\?.*|\/|)$/i;

const state = {
	showCaruosel: true,
	startApplication: !(~pathname.indexOf('place') || ~pathname.indexOf('/app') || userDeepLinkUrlRegexp.test(pathname))
}

const getters = {

}

const actions = {

}

const mutations = {
	'TOGGLE_CAROUSEL'(state, val) {
		state.showCaruosel = val
	},
	'START_APPLICATION'(state) {
		state.startApplication = true
	}
}

export default {
	state,
    getters,
    actions,
    mutations,
	namespaced: true
}
 