import Vue from 'vue'
export default new Vue({
	data: {
		tile: {
			inDrinks: false,
			inCategory: false
		},
		modifiersOpen: false
	},
	computed: {
		showTileHooper() {
			const {tile, modifiersOpen} = this
			const {inDrinks, inCategory} = tile
			return !modifiersOpen && !inDrinks && !inCategory
		}
	},
	watch: {
		showTileHooper: 'handlerTileHooper'
	},
	methods: {
		handlerTileHooper(value) {
			const hooper = document.getElementById('carousel-menu')
			if (hooper) {
				const isVisible = hooper.style.display !== 'none'
				if (value !== isVisible) {
					const styleValue = value ? 'block' : 'none'
					hooper.style.display = styleValue
					if (styleValue === 'block') {
						window.requestAnimationFrame(() => {
							window.dispatchEvent(new Event('resize'));
						})
					}
				}
			}
			const homeIcon = document.getElementById('s-modal__home')
			if (homeIcon) {
				const isVisible = homeIcon.style.display !== 'none'
				if (value !== isVisible) {
					homeIcon.style.display = value ? 'block' : 'none'
				}
			}

			const categorySwiper = document.getElementById('category-swiper')
			if (categorySwiper) {
				const categorySwiperClassList = categorySwiper.classList
				if (categorySwiperClassList.contains('swiper-no-swiping')) {
					categorySwiperClassList.remove('swiper-no-swiping')
				} else {
					categorySwiperClassList.add('swiper-no-swiping')
				}
			}
		},
		updateToken(callBack, ...args) {
			return new Promise((resolve) => {
				this.$on('AUTH/tokenUpdated', () => {
					this.$off('AUTH/tokenUpdated')
					if(typeof callBack === 'function') {
						resolve(callBack(...args))
					} else {
						resolve()
					}
				})
				this.$emit('AUTH/update-token')
			})
		}
	}
})