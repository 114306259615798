<template>
	<div class="tutorial" :class="{'-show': show}">
		<div class="tutorial-body">
			<img 
				src="/img/tutorial-close-icon.svg" 
				class="tutorial-body__close" 
				@click="show = false"
			/>
			<swiper 
				v-if="slides.length && show"
				class="swiper" :options="swiperOption">
				<swiper-slide 
					v-for="(slide, index) in slides"
					:key="index"
				>
					<div class="tutorial-body__item">
						<img :src="slide.img" :width="slide.width" />
						<div class="tutorial-body__item-text">{{ slide.text }}</div>
					</div>
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
	</div>
</template>

<script>
import "@/assets/css/swiper.css";

import { swiper, swiperSlide } from '@/vendors/vue-awesome-swiper/src/index.js'
export default {
	components: {
		swiper,
    	swiperSlide
    },
    data() {
		return {
			show: false,
			swiperOption: {
				pagination: {
					el: '.swiper-pagination'
				}
			},
			slides: []
		}
    },
	mounted() {
		this.$bus.$on('openTutorial', this.init)
	},
	methods: {
		init(slides=[]) {
			this.$set(this, 'slides', slides)
			this.show = true
		},
		close() {
			this.show = false
			this.$nextTick(() => {
				this.$set(this, 'slides', [])
			})
		}
	}
}
</script>

<style lang="scss">
	.tutorial {
		position: fixed;
		visibility: hidden;
		opacity: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 1060;
		@include font(20, 25, normal);
		letter-spacing: 0.38px;
		color: $white;
		background-color: rgba(0, 0, 0, 0.9);
		transition: visibility 0.3s, opacity 0.3s ease;

		&.-show {
			visibility: visible;
			opacity: 1;
		}

		&-body {
			@include pos-centered;
			width: 303px;
			height: 377px;
			background: #2B2B2F;
			border-radius: 10px;
			padding: 52px 16px 32px 16px;

			&__item {
				&-text {
					@include font(15, 20, 400);
				}
			}

			&__close {
				position: absolute;
				width: 12px;
				height: 12px;
				top: 20px;
				right: 20px;
			}
		}
		.swiper-pagination {
			bottom: 0px;
			&-bullet {
				width: 5px !important;
				height: 5px !important;
				background: rgba(255, 255, 255, 0.5);

				&.swiper-pagination-bullet-active {
					background: $white;
				}
			}
		}
		
	}
</style>