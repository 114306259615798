<template>
	<div class="g-t-modal" :class="{'-open': open}">
		<img 
			src="/img/backWhite.png" 
			srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
			class="g-t-modal__back"
			@click="close"
		/>
		<div class="g-t-modal__body">
			<img 
				class="g-t-modal__body-logo"
				src="/img/logo.svg" 
			/>
			<i18n path="terms_modal_text" tag="label" for="terms_link">
				<a :href="termsOfUse" target="_blank">{{ $t('terms_link') }}</a>
			</i18n>
		</div>
		<div class="g-t-modal__accept" @click="accept">
			{{ $t('agree_terms') }}
		</div>
	</div>
</template>

<script>

export default {
	name: 'GlobalTermsModal',
	data() {
		return {
			open: false,
			resolve: null
		}
	}, 
	computed: {
		termsOfUse() {
			return `https://mayber.com/terms-of-use/${this.$lang}/`
		}
	},
	mounted() {
		this.$bus.$on('openCloseTerms', this.openClose)
	},
	methods: {
		accept() {
			this.resolve(true)
			this.open = false
			this.resolve = null
		},
		close() {
			this.resolve(false)
			this.open = false
			this.resolve = null
		},
		openClose(resolve) {
			this.resolve = resolve
			this.open = open
		
		}
	}
}
</script>

<style lang="scss">
	.g-t-modal {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 999;
		display: none;
		background: $black;
		// background-color: rgba(0, 0, 0, 0.6);
		// backdrop-filter: blur(10px);

		&.-open {
			display: block;
		}
		
		&__back {
			position: fixed;
			z-index: 1;
			width: 24px;
			height: 24px;
			top: 29px;
			left: 16px;
		}

		&__body {
			@include pos-centered;
			@include font(15, 20, normal);
			color: $white;
			text-transform: uppercase;
			width: calc(100% - 32px);
			
			a {
				color: rgb(0, 145, 255);
				&:visited {
					color: rgb(0, 145, 255);
				}
			}

			&-logo {
				@include pos-centered-h;
				top: calc(-48px - 86px);
				width: 64px;
				height: 86px;
			}
		}

		&__accept {
			position: absolute;
			left: 16px;
			right: 16px;
			bottom: 50px;
			height: 48px;
			border-radius: 5px;
			width: calc(100% - 32px);
			background: $goldenMain;
			@include font(17, 24, 500);
			letter-spacing: -0.41px;
			text-transform: uppercase;
			color: $white;
			text-align: center;
			@include flex(row, center, center);
		}
	}
</style>