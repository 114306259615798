<template>
    <div class="order-item" @click="routeTo">
        <div class="order-item-images" :data-count="images.length">
            <img
                v-for="(image, i) in images"
                :key="`${image}-${i}`"
                :src="image"
                class="order-item-images__image"
            />
            <div class="order-item-images__backdrop"></div>
            <div class="order-item-images__counter">
                {{ items.length }}
            </div>
        </div>
        <div class="order-item-info">
            <div class="order-item-info__top">
                {{ createdAtStr }}
                <img src="/img/arrow-right-white.svg" />
                <span class="order-item-info__top-label">{{
                    $t("delivery.order")
                }}</span>
            </div>
            <div class="order-item-info__bottom">
                {{ checkin.currencySymbol }} {{ amount.toFixed(2) }}
            </div>
            <template v-if="paid">
                <div class="order-item-info__backdrop"></div>
                <span class="order-item-info__badge">paid</span>
            </template>
            <img
                v-else-if="hasCanceled"
                class="order-item-info__statused"
                src="/img/iconDishIsCancelled.svg"
            />
        </div>
        <!-- <div class="order-item__divider"></div> -->
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "OrderItem",
    props: {
        checkin: {
            type: Object,
            required: true,
        },
        invoices: {
            type: Array,
            default: () => [],
        },
        delivery: {
            type: Boolean,
            default: false,
        },
        unpaidInvoice: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            hasCanceled: false,
        };
    },
    computed: {
        items() {
            let hasCanceled = false;
            const items = this.checkin.items.filter((item) => {
                const canceled = item.denied || item.status === "canceled";
                if (canceled) {
                    hasCanceled = true;
                }
                return !canceled;
            });
            this.hasCanceled = hasCanceled;
            return items;
        },
        createdAt() {
            return (
                this.checkin?.created_at?.seconds ||
                this.checkin?.checkin_time?.seconds
            );
        },
        createdAtStr() {
            return moment(this.createdAt * 1000).format("DD MMM HH:mm");
            const seconds = this.createdAt;
            if (!seconds) return "";
            const date = new Date(seconds * 1000);
            return `${String(date.getMonth() + 1).padStart(2, "0")}-${String(
                date.getDate()
            ).padStart(2, "0")}-${String(date.getHours()).padStart(
                2,
                "0"
            )}:${String(date.getMinutes()).padStart(2, "0")}`;
        },
        images() {
            const items = this.items.slice(0, 4);
            return items.map(
                (item) => item.photo_uri || "/img/restaurantPlaceholder.jpg"
            );
        },
        amount() {
            const { items = [] } = this;
            return items.reduce((acc, item) => {
                if (item.status !== "canceled") {
                    let price = Number(item.price);
                    const modifications = item.modifications;
                    if (modifications) {
                        for (const key in modifications) {
                            const modArray = modifications[key];
                            for (const modificator of modArray) {
                                const _price = Number(modificator.price);
                                price += _price;
                            }
                        }
                    }
                    return (acc += price);
                } else {
                    return acc;
                }
            }, 0);
        },
        paid() {
            const checkin = this.checkin;
            return (
                checkin.paid === true ||
                (checkin.left_to_pay == 0 && checkin.payed != "0")
            );
        },
        toOrderViewLink() {
            let url = `orders/${this.checkin.uid}`;
            if (!this.delivery) {
                url += `?created=${this.createdAt}`;
            }
            return url;
        },
    },
    methods: {
        routeTo() {
            if (!this.unpaidInvoice) {
                this.$router.replace(this.toOrderViewLink);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.order-item {
    height: 104px;
    width: 100%;
    color: $white;
    @include flex(row, flex-start, center);
    border-bottom: solid 8px $black;

    &-images {
        width: 40%;
        height: 100%;
        position: relative;
        @include flex(row, flex-start, center);
        flex-wrap: wrap;

        &__image {
            object-fit: cover;
        }

        &[data-count="4"] {
            .order-item-images__image {
                height: 52px;
                width: 50%;
            }
        }

        &[data-count="3"] {
            .order-item-images__image {
                height: 52px;
                width: 50%;

                &:first-child {
                    width: 100%;
                }
            }
        }

        &[data-count="2"] {
            .order-item-images__image {
                height: 100%;
                width: 50%;
            }
        }

        &[data-count="1"] {
            .order-item-images__image {
                height: 100%;
                width: 100%;
            }
        }

        &__backdrop {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: rgba(0, 0, 0, 0.5);
        }

        &__counter {
            @include pos-centered();
            @include font(48, 48, 400);
            z-index: 2;
        }
    }
    &-info {
        width: 60%;
        height: 100%;
        @include flex(column, flex-start, flex-start);
        text-align: left;
        padding-left: 23px;
        position: relative;

        &__backdrop {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: rgba(0, 0, 0, 0.5);
            width: 100% !important;
            height: 100% !important;
        }

        &__badge,
        &__statused {
            position: absolute;
        }

        &__badge {
            bottom: 5px;
            right: 16px;
            width: 54px;
            height: 24px;
            background: $white;
            color: $black;
            z-index: 2;
            bottom: 5px;
            right: 16px;
            padding: 1px 0px;
            border-radius: 5px;
            @include font(16, 22, bold);
            text-align: center;
        }

        &__statused {
            z-index: 3;
            bottom: 10px;
            right: 33px;
            width: 26px;
            height: 26px;
        }

        div {
            width: calc(100% - 23px);
            height: 50%;
            @include flex(row, flex-start, center);
            @include font(22, 26, 400);

            &:first-child {
                border-bottom: 1px solid #4c4c4e;
                @include font(24, 28, 600);
                position: relative;

                span {
                    @include font(12, 10, 500);
                    letter-spacing: 0;
                    text-transform: lowercase;
                    position: absolute;
                    bottom: 4px;
                }

                img {
                    @include pos-centered-v;
                    right: 0;
                }
            }
        }
    }
}
</style>
