<template>
    <div class="b-dish-i" @click="openDish">
        <div class="b-dish-i__title" v-if="category">
            {{ category.title }}
        </div>
        <div
            class="b-dish-i__image"
            ref="image"
            :class="{ '-onstop': !item.is_active }"
        >
            <img :src="image" :alt="`image of ${item.title}`" />
            <div
                class="b-dish-i__image-stop-backdrop"
                v-if="!item.is_active"
            ></div>
            <div class="b-dish-i__image-stop" v-if="!item.is_active">
                <img
                    src="/img/combinedShape.png"
                    srcset="/img/combinedShape@3x.png 3x /img/combinedShape@2x.png 2x /img/combinedShape.png 1x"
                />
                <div class="stop-text">
                    {{ item.on_stop_title || $t("onstop") }}
                </div>
            </div>
        </div>
        <img
            ref="star"
            class="b-dish-i__star"
            src="/img/combinedShapeCopy5.png"
            srcset="/img/combinedShapeCopy5@3x.png 3x /img/combinedShapeCopy5@2x.png 2x /img/combinedShapeCopy5.png 1x"
        />
        <div class="b-dish-i__info">
            <div class="b-dish-i__info-title">
                {{ item.title }}
            </div>
            <div class="b-dish-i__info-description">
                {{ item.description }}
            </div>
            <div class="b-dish-i__info-price">
                {{ (delivery ? item.delivery_price : item.price) || 0 }}
                {{ currencySymbol }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        imageHeight: {
            type: Number,
            default: 0,
        },
        currencySymbol: {
            type: String,
        },
    },
    inject: ["rootPlaceUid", "rootPlaceType"],
    data() {
        return {
            mount: false,
            category: null,
        };
    },
    computed: {
        delivery() {
            return this.$store.state.user.delivery;
        },
        image() {
            const item = this.item;
            return (
                item?.photo_uri ||
                item.comment_photo_uri ||
                "/img/restaurantPlaceholder.jpg"
            );
        },
        widthAndImage() {
            return this.imageHeight && this.image && this.mount;
        },
        categoryUid() {
            return this.item.category_uid;
        },
    },
    watch: {
        widthAndImage: {
            immediate: true,
            handler(val) {
                if (!val) return;
                this.$refs.image.style.height = this.imageHeight + "px";
                this.$refs.star.style.top = `${this.imageHeight - 48}px`;
            },
        },
        categoryUid: {
            immediate: true,
            handler: async function (val) {
                if (!val) return;
                const [category] = (
                    await this.$DB.db
                        .collection("menu_categories")
                        .where("uid", "==", val)
                        .get()
                ).docs.map((doc) => doc.data());
                if (category) {
                    this.$set(this, "category", category);
                }
            },
        },
    },
    mounted() {
        this.mount = true;
    },
    methods: {
        openDish() {
            this.$bus.$emit("GlobalModal/dishesOpen", {
                category: this.category,
                currencySymbol: this.currencySymbol,
                startAt: this.item.uid,
                onClose: this.selectDish,
                placeUid: this.rootPlaceUid,
                placeType: this.rootPlaceType,
            });
        },
        selectDish(val) {
            console.log("selectDish", val);
        },
    },
};
</script>

<style lang="scss">
.b-dish-i {
    min-height: 100px;
    position: relative;
    color: $white;

    &__title {
        position: absolute;
        left: 24px;
        top: 36px;
        @include font(32, 32, normal);
        text-transform: uppercase;
        z-index: 1;
        @include textTruncate(100%);
    }

    &__image {
        width: 100%;
        position: relative;

        &.-onstop {
            background: rgba(0, 0, 0, 0.7);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-stop {
            @include pos-centered;
        }

        &-stop-backdrop {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
        }
    }

    &__star {
        position: absolute;
        left: 24px;
    }

    &__info {
        padding-top: 12px;
        text-align: left;
        padding-left: 24px;
        padding-bottom: 36px;
        background: $blackTwo;

        &-title {
            @include font(24, 28, normal);
        }

        &-description {
            margin-top: 4px;
            @include font(16, 18, normal);
        }

        &-price {
            margin-top: 8px;
            @include font(21, 28, normal);
        }
    }
}
</style>
