export default () => {
	return new Promise((resolve) => {
		const baseRequest = {
			apiVersion: 2,
			apiVersionMinor: 0
		}
		
		const allowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'MIR', 'VISA']
		
		const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']
		
		const baseCardPaymentMethod = {
			type: 'CARD',
			parameters: {
			  allowedAuthMethods: allowedCardAuthMethods,
			  allowedCardNetworks: allowedCardNetworks
			}
		}
		
		const paymentsClient = new window.google.payments.api.PaymentsClient({environment: 'TEST'})
		
		const isReadyToPayRequest = Object.assign({}, baseRequest)
		isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod]

		paymentsClient.isReadyToPay(isReadyToPayRequest)
			.then(function(response) {
				resolve(!!response.result)
			})
			.catch(function(err) {
				// show error in developer console for debugging
				console.log('Google pay error ', err)
				resolve(false)
			});
	})
}