<template functional>
  <div class="tutorial-item -second">
    <img
      class="tutorial-item__image phone-image"
      src="/img/tutorial-phone-order.svg"
    />
    <div class="finger-div">
      <img
        class="tutorial-item__image blink-order"
        src="/img/tutorial-order-blink.svg"
      />
      <img
        class="tutorial-item__image finger-image-second"
        src="/img/tutorial-finger.svg"
      />
    </div>
    <div class="tutorial-item__text" v-html="parent.$t('T000006')" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
$delay-between-steps: 100;
$step-1-duration: 300;
$step-2-delay: $step-1-duration + $delay-between-steps;
$step-2-duration: 300;
$step-3-delay: $step-2-duration + $delay-between-steps;
$step-3-duration: 300;

.tutorial-item {
  @include flex(column, flex-start, center);
  padding: 25px 32px;

  &__text {
    @include font(24, 30, 400);
    color: $white;
  }

  .finger-div {
    height: 56px;
    position: relative;
  }

  .blink-order {
    top: -50px;
    right: -31px;
    position: absolute;
  }

  &.-second {
    .finger-image-second {
      transform: translate3d(35px, -25px, 0);
      // @include animate(step-1-finger-image, 900);
      // animation: step-1-finger-image #{$step-1-duration}ms ease 0s 1 normal forwards,
      //     step-2-finger-image #{$step-2-duration}ms ease #{$step-2-delay}ms 1 normal forwards,
      //     step-3-finger-image #{$step-3-duration}ms ease #{$step-3-delay}ms 1 normal forwards;
    }
  }
}
</style>
