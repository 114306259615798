import Vue from 'vue'
import Vuex from 'vuex'

import global from './global'
import payments from './payments'
import places from './places'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    payments,
    places,
    user,
  },
  strict: false,
})
