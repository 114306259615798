<template>
	<div class="s-modal">
		<img 
			src="/img/backWhite.png" 
			srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
			class="back-icon"
			v-if="drinks && !childrenInfoOpened"
			@click="$emit('close')"
		/>
		<div class="s-modal-categories" ref="categories">
			<vue-swing
				v-if="pool.length > 1"
				@throwout="throwout"
				@throwoutend="throwoutend"
				:config="config"
				@dragmove="dragstart"
				@dragend="dragend"
				@throwinend="throwinend"
				ref="swing"
			>
				<div 
					class="s-modal-categories__category s-category -category"
					v-for="category in pool"
					:data-id="category.uid"
					:data-name="category.title"
					:key="category.uid"
					:style="`z-index: ${category['z-index']}`"
					:id="category.uid"
					:data-mod="category.mode"
				>
					<SwipeModalDishes
						:category="category"
						:currencySymbol="currencySymbol"
						@destroyCard="destroyCard"
						:drinks="drinks"
						:currentCategoryUid="currentUid"
						@openinfo="onInfoOpen"
					/>
				</div>
			</vue-swing>
			<div v-else>
				<div 
					class="s-modal-categories__category s-category -category"
					v-for="category in pool"
					:data-id="category.uid"
					:data-name="category.title"
					:key="category.uid"
					:style="`z-index: ${category['z-index']}`"
					:id="category.uid"
					:data-mod="category.mode"
				>
					<SwipeModalDishes
						:category="category"
						:currencySymbol="currencySymbol"
						@destroyCard="destroyCard"
						:drinks="drinks"
						:currentCategoryUid="currentUid"
						@openinfo="onInfoOpen"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import callWaiterMixin from '@/mixins/callWaiterMixin'
import SwipeModalDishes from './SwipeModalDishes'
import VueSwing from 'vue-swing'

const options = {
    root: null,
    threshold: 0
}

/**
 * Считает, был ли свайп вверх (с отклонением до 35 градусов в стороны).
 * @param {number} x - сдвиг по Ox
 * @param {number} y - сдвиг по Oy
 */
function isSwipeUp (x, y) {
	const minUpDelta = 50; // Значение подбирается "на глаз"

	if (y >= 0 || Math.abs(y) < minUpDelta) {
		return false;
	}

	const minHorizontalDeviation = 10; // Значение подбирается "на глаз"
	const tan35deg = 0.7002;

	/*
		Вычисляем тангенс угла:
		тангенс = противолежащий катет / прилежащий
	*/
	const tan = (Math.abs(x) || minHorizontalDeviation) / Math.abs(y);

	return tan < tan35deg;
}

export default {
	name: 'SwipeModal',
	components: {
		SwipeModalDishes
	},
	mixins: [callWaiterMixin],
	props: {
		categories: {
			type: Array,
			default: ()=>([])
		},
		dishes: {
			type: Array,
			default: ()=>([])
		},
		currencySymbol: {
			type: String,
			default: '$'
		},
		useSlot: {
			type: Boolean,
			default: false
		},
		drinks: {
			type: Boolean,
			default: false
		}
	},
	data() {
		const { category } = this.$route.query
		let current = (category) ? this.categories.findIndex(cat => cat.uid === category) : 0
		if (current < 0) {
			current = 0
		}

		return {
			current,
			startY: 0,
			startX: 0,
			trottlePaning: null,
			frame: null,
			panning: false,
			windowWidthHalf: 0,
			categoriesDom: null,
			busy: false, 
			pool: [],
			config: {
				allowedDirections: [
					VueSwing.Direction.LEFT,
					VueSwing.Direction.RIGHT
				],
				isThrowOut: (xOffset, yOffset, _, throwOutConfidence) => {
					if (isSwipeUp(xOffset, yOffset)) {
						this.$bus.$emit('SwipeUpDish');
						return false;
					}

					if (xOffset > 0) {
						return false;
					}
					
					return throwOutConfidence > 0.4;
				}
			},
			showRecognizer: false,
			childrenInfoOpened: false
		}	
	},
	computed: {
		placeUid() {
			return this.dishes?.[0]?.place_uid
		},
		categoriesWithDishes() {
			const { dishes, categories, currentCheckin } = this
			const catLen = categories.length
			
			return categories.reduce((memo, category, index) => {
				const c_uid = category.uid
				if (!currentCheckin && category.for_checked_in_users_only) return memo
				const _dishes = dishes.filter(dish => dish.category_uid === c_uid)
				if (_dishes && _dishes.length) {
					return [
						...memo,
						{
							...category,
							'z-index': catLen - index,
							dishes: _dishes
						}
					]
				}
				return memo
			}, [])
		},
		currentUid() {
			return this.categoriesWithDishes[this.current].uid
		},
		categoriesLen() {
			return this.categories.length
		},
		lastUid() {
			const categoriesWithDishes = this.categoriesWithDishes
			return categoriesWithDishes[categoriesWithDishes.length-1].uid
		}
	},
	created() {
		this.initPool()
	},
	mounted() {
		// this.windowWidthHalf = window.innerWidth / 2
		// this.trottlePaning = lodash.throttle(this.touchmoveHandler, 25)
		// this.$el.addEventListener('touchmove', this.trottlePaning, false)
		this.$nextTick(() => {
			const categories = document.querySelector('.swipe-recognizer-layer')
			if (categories) {
				categories.addEventListener("touchstart", (e) => {
					// is not near edge of view, exit
					// if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;

					// prevent swipe to navigate back gesture
					// e.preventDefault();
					setTimeout(()=> {
						this.showRecognizer = false
						setTimeout(()=> {
							this.showRecognizer = true
						}, 2000)
					}, 2000)
				});
			}
		})
	},
	beforeDestroy() {
		this.$el.removeEventListener('touchmove', this.trottlePaning)
	},
	methods: {
		onInfoOpen(val) {
			this.childrenInfoOpened = val
		},
		destroyCard(el) {
			const stack = this.$refs['swing']?.stack
			if (stack) {
				const card = stack.getCard(el.parentNode)
				if (!card) {
					stack.createCard(el.parentNode, true)
				} else {
					card.destroy()
				}
			}
		},
		dragstart(inp) {
			const {target, throwDirection}=inp
			const stack = this.$refs['swing'].stack
			if (throwDirection ===  VueSwing.Direction.RIGHT) {
				if (!this.current) {
					return
				} else {
					if (this.busy) return
					this.busy = true
					const tcard = stack.getCard(target)
					tcard.destroy()
					const prev = document.querySelector('[data-mod="hidden"]')
					if (prev) {
						const card = stack.getCard(prev)
						if (card) {
							card.throwIn(-100, 0)
							// card.destroy()
						}	
						prev.dataset.mod = ''
						// prev.style['transform'] = `translate3d(0, 0, 0) translate(0px, 0px) rotate(0deg)`
					}
				}
				this.prevPool()
				this.$nextTick(() => {
					const newCard = stack.createCard(target, true)
					newCard.throwIn(0, 0)
					this.busy = false
				})
				
			} else  if (throwDirection ===  VueSwing.Direction.UP) {
				// console.log('i[p', inp)
				

			}
		},
		throwinend({target}) {
			// const card = this.$refs['swing'].stack.getCard(target)
			// if (card) {
			// 	card.throwIn(0, 0)
			// 	// card.destroy()
			// }	
			// target.style['touch-action'] = 'none'
		},
		dragend({target, lol}) {
			this.busy = false
		},
		throwout ({target, throwDirection}) {
			const uid = target.dataset.id
			const direction = throwDirection.toString() 
			const swing = this.$refs['swing']
			if (direction === 'Symbol(LEFT)') {
				this.nextPool()
				if (uid === this.lastUid) {
					this.current = 0
					this.pool = []
					this.$nextTick(() => {
						this.initPool()
					})
				}
			} else  if (direction === 'Symbol(UP)') {
				console.log('up')
			}
		},
		throwoutend({target}) {
			target.style['transform'] = `translate3d(0, 0, 0) translate(-500px, 0px) rotate(0deg)`
			target.dataset.mod = 'hidden'
		},
		initPool() {
			// this.pool = []
			const current = this.current
			this.pool = this.categoriesWithDishes.slice((current) ? current-1 : current, current+2)
			if (current) {
				this.$nextTick(() => {
					const target = document.querySelector('.s-modal-categories__category.s-category.-category')
					if (target) {
						this.throwoutend({target})
					}
				})
			}
		},
		nextPool() {
			const {categoriesWithDishes, pool} = this,
				lastInPoolUid = pool[pool.length-1].uid,
				nextToPoolIndex = categoriesWithDishes.findIndex(cat => cat.uid === lastInPoolUid),
				nextToPool = (~nextToPoolIndex) ? categoriesWithDishes[nextToPoolIndex+1] : null
			this.current++
			if (!nextToPool) return
			
			if (pool.length === 2) {
				pool.push(nextToPool)
			} else {
				pool.shift()
				pool.push(nextToPool)
			}

		},
		prevPool() {
			const {categoriesWithDishes, pool} = this,
				firstPoolUid = pool[0].uid,
				prevToPoolIndex = categoriesWithDishes.findIndex(cat => cat.uid === firstPoolUid),
				prevToPool = (~prevToPoolIndex) ? categoriesWithDishes[prevToPoolIndex-1] : null
	
			this.current--
			const prevCurrent = this.current-1
			if (~prevCurrent) {
				const prevCurrentUid = this.categoriesWithDishes[prevCurrent]?.uid
				if (prevCurrentUid) {
					let prevCurrentNode = document.getElementById(prevCurrentUid)
					if (prevCurrentNode) {
						this.$nextTick(() => {
							prevCurrentNode.style['transform'] = `translate3d(0, 0, 0) translate(-500px, 0px) rotate(0deg)`
							prevCurrentNode.dataset.mod = 'hidden'
						})
						
					} else {
						pool.unshift({
							...prevToPool,
							'mode': 'hidden'
						})
						this.$nextTick(() => {
							prevCurrentNode = document.getElementById(prevCurrentUid)
							if (prevCurrentNode) {
								this.$nextTick(() => {
									prevCurrentNode.style['transform'] = `translate3d(0, 0, 0) translate(-500px, 0px) rotate(0deg)`
									prevCurrentNode.dataset.mod = 'hidden'
									this.$nextTick(() => {
										pool.pop()
									})
								})
								
							}
						})
					}
				}
			}
		}
	}
}
</script>
<style lang="scss">
	.s-modal {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: $white;
		z-index: 98;
		overflow: hidden;

		.swipe-recognizer-layer {
			position: fixed;
			z-index: 0;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: red;
			opacity: 0.4;

			&.-show {
				z-index: 2000;
			}
		}

		&-categories {
			width: 100%;
			height: 100%;
			position: relative;
			

			&__category {
				position: absolute;
				
				will-change: transform;
				// transition: height 0.15s ease; 
				&.s-category {
					width: 100%;
					height: 100%;
					background: $white;
					
				}

				&[data-mod="hidden"] {
					transform: translate(-500px, 0px);
				}
			}
			.s-category {
				&-dishes {
					width: 100%;
					height: 100%;

					&__dish {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.back-icon {
			position: fixed;
			left: 20px;
			top: 20px;
			height: 30px;
			width: 30px;
			z-index: 100;
		}
	}
</style>