<script>
export default {
	name: 'UIIcon',
	functional: true,
	props: {
		iconName: {
			type: String,
			required: true
		},
		svg: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: 'auto'
		},
		height: {
			type: String,
			default: 'auto'
		}
	},
	render(h, ctx) {
		const {
			props,
			props: {
				iconName,
				width,
				height
			},
			listeners,
			data
		} = ctx
		return (
			<img 
				v-on={listeners} 
				class={data.staticClass || ''} 
				width={width}
				height={height}
				src={`/img/${iconName}.png`}
				srcset={`/img/${iconName}@3x.png 3x /img/${iconName}@2x.png 2x /img/${iconName}.png 1x`}
			/>
		)
	}
}
</script>