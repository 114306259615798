<template>
	<div 
		class="comment-dish" 
		:class="{'-open': open}"
	>
		<div class="comment-dish__body" @click.stop="">
			<div class="comment-dish__body-logo">
				<img src="/img/billWhiteCircle.svg" alt="">
			</div>
			<div class="comment-dish__body-text">
				{{ $t('order.commetToOrder') }}
			</div>
			<div class="comment-dish__body-input">
				<textarea
					v-model="text"
					ref="textarea"
				/>
			</div>
			<div class="comment-dish__body-button" @click.stop="submit(text)">
				{{ $t('order.yes') }}
			</div>
			<div 
				class="comment-dish__body-button" 
				:class="{'-negative': editMode}"
				@click.stop="$emit('submit', '')"
			>
				{{ $t(`order.${editMode ? 'remove' : 'no'}`) }}
			</div>
			<div 
				v-if="!hideNotiece"
				class="comment-dish__body-input comment-dish__checkbox-input" 
				style="text-align: left"
			>
				<label>
					<input type="checkbox" v-model="dontAskForComments">
					<div class="comment-dish__checkbox-input__info">
						<div>{{ $t('order.doNotShowAgain') }}</div>
						<div class="comment-dish__checkbox-input__info-note">{{ $t('order.doNotShowAnainNotice') }}</div>
					</div>
				</label>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'CommentToDishWindow',
	data() {
		return {
			open: false,
			text: '',
			hideNotiece: false,
			// localStorage.getItem('dontAskForComments')
			dontAskForComments: false,
			editMode: false
		}
	},
	watch: {
		open(val) {
			if (!val) {
				this.text = ' '
			}
			this.initTextAreaHeight()
		},
		dontAskForComments(val) {
			if (val) {
				localStorage.setItem('dontAskForComments', true)
			} else {
				localStorage.removeItem('dontAskForComments')
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.autoheight()
		})
	},
	methods: {
		initTextAreaHeight() {
			this.$nextTick(() => {
				const el = this.$refs.textarea
				if (el) {
					el.setAttribute('style', 'height: ' + el.scrollHeight + 'px');
				}
			})
		},
		autoheight() {
			const el = this.$refs.textarea
			this.initTextAreaHeight()
			el.classList.add('auto');
			el.addEventListener('input', e => {
				el.style.height = 'auto';
				el.style.height = (el.scrollHeight) + 'px';
			});
		},
		submit(text) {
			if (!text) return
			this.$emit('submit', text)
		},
		show(text = '', hideNotiece = false) {
			this.hideNotiece = hideNotiece
			this.text = text
			this.editMode = (text) ? true : false
			this.initTextAreaHeight()
			return new Promise((resolve, reject) => {
				this.$on('submit', (_text) => {
					_text.trim()
					this.$off('submit')
					this.open = false
					if (_text === 'cancel') {
						reject()
					} else {
						resolve(_text)
					}
				})
				this.open = true
			})
		}
	}
}
</script>
<style lang="scss" scoped>
	@keyframes shake {
		10%, 90% {
			transform: translate3d(-1px, 0, 0);
		}
		
		20%, 80% {
			transform: translate3d(2px, 0, 0);
		}

		30%, 50%, 70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%, 60% {
			transform: translate3d(4px, 0, 0);
		}
	}

	.comment-dish__checkbox-input {
		@include font(14, 22, bold);
		user-select: none;
		border-top: 1px solid rgba(255,255,255, .4);
		width: 100%;
		outline: none;
		> label {
			@include flex(row, flex-start, flex-start);

			> input {
				margin-right: 5px; 
			}
		}
	}
	.comment-dish__checkbox-input__info-note {
		margin-top: 5px; 
		@include font(12, 18, normal);
		user-select: none;
	}
	.comment-dish {
		@include fixed-modal(1060);
		background: $black;
		color: $white;
		@include flex(column, flex-start, center);
		display: none;

		

		&.-open {
			display: block;
		}

		&__body {
			@include b-gradient();
			width: 311px;
			// height: 389px;
			height: auto;
			backdrop-filter: blur(27.1828px);
			/* Note: backdrop-filter has minimal browser support */

			border-radius: 10px;
			@include pos-centered();

			&-logo {
				height: 48px;
				margin-top: 15px;

				> img {
					width: 48px;
					height: 48px;
				}
			}

			&-text {
				@include font(20, 26, 400);
				margin: 12px;
			}

			&-input {
				padding: 12px 12px 12px 12px;
				width: calc(100% - 24px);

				> textarea {
					width: calc(100% - 20px);
					// height: 46px;
					padding: 10px;
    				border: none;
					@include font(15, 18, 400);
					border-radius: 16px;
					outline: none;
					&::placeholder {
						@include font(17, 18, normal);
						color: $white;
						letter-spacing: 0.72px;
					}
					resize: none;
					font-family: inherit;
				}
			}

			&-button {
				@include font(20, 22, 600);
				height: 54px;
				border-top: 1px solid rgba(255,255,255, .4);
				// margin-top: 48px;
				@include flex(row, center, center);
			}
		}
	}
</style>