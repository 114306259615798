<template>
  <div class="s-modal">
    <img
      src="/img/backWhite.png"
      srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
      class="back-icon"
      v-if="drinks"
      @click="$emit('close')"
    />
    <img
      src="/img/backWhite.png"
      srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
      class="back-icon"
      v-if="isInRelationParent"
      @click="closeSlideMenu"
    />
    <img
      v-else-if="!drinks && !historyMode && !isOpenInfo"
      src="/img/iconHome.png"
      srcset="/img/iconHome@3x.png 3x /img/iconHome@2x.png 2x /img/iconHome.png 1x"
      class="s-modal__home"
      id="s-modal__home"
      @click="closeSlideMenu"
    />
    <img 
      v-if="!deliveryFlow && supportOrders"
      key="iconTableCode"
      class="s-modal__table-code"
      src="/img/call-bell-white.svg"
      @click="enterCode(placeUid)"
    />
    <div 
				class="swipe-up"
				@mousedown="mouseDown" 
				@touchstart="touchStart" 
				@mouseup="mouseUp" 
				@touchend="touchEnd"
			>
      <swiper 
					class="swiper" 
          id="category-swiper"
					:options="swiperOption"
          ref="categoriesSwiper"
				>
					<swiper-slide
						v-for="(item, index) in virtualData.slides"
						:key="item.uid"
						:virtualIndex="index"
						:style="{ left: `${virtualData.offset}px` }"
					>
            <SlideModalDishes
              :key="item.uid"
              :category="item"
              :drinks="drinks"
              :currencySymbol="currencySymbol"
              :currentCategoryUid="currentCategory.uid"
            />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
  import "@/assets/css/swiper.css";

  import { swiper, swiperSlide } from '@/vendors/vue-awesome-swiper/src/index.js'
  import callWaiterMixin from '@/mixins/callWaiterMixin'
  import SlideModalDishes from "./SlideModalDishes";


  /**
   * Считает, был ли свайп вверх (с отклонением до 35 градусов в стороны).
   * @param {number} x - сдвиг по Ox
   * @param {number} y - сдвиг по Oy
   */
  function isSwipeUp (x, y) {
    const minUpDelta = 50; // Значение подбирается "на глаз"

    if (y <= 0 || Math.abs(y) < minUpDelta) {
      return false;
    }

    const minHorizontalDeviation = 10; // Значение подбирается "на глаз"
    const tan35deg = 0.7002;

    /*
      Вычисляем тангенс угла:
      тангенс = противолежащий катет / прилежащий
    */
    const tan = (Math.abs(x) || minHorizontalDeviation) / Math.abs(y);

    return tan < tan35deg;
  }

  export default {
    name: "SlideModal",
    mixins: [ callWaiterMixin ],
    components: {
      swiper,
    	swiperSlide,
      SlideModalDishes,
    },
    props: ["drinks", "categories", "dishes", "currencySymbol", "useSlot", "categoriesWithDishes", "isHotel"],
    inject: ['rootPlaceUid', 'rootPlaceType'],
    data() {
      const that = this
      return {
        current: 0,
        helperHidden: false,
        endOfSlider: false,
        virtualData: {
          slides: []
        },
        swiperOption: {
          enabled: true,
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 0,
          edgeSwipeDetection: 'prevent',
          edgeSwipeThreshold: 0,
          effect: 'slide',
          direction: 'horizontal',
          virtual: {
            addSlidesBefore: 0,
            addSlidesAfter: 0,
            renderExternal: (data) => {
              this.$set(this, 'virtualData', data);
            },
            slides: this.categoriesWithDishes
          },
          on: {
            activeIndexChange: function () {
              that.current = this.realIndex
              that.slide()
              that.closeHelper()
            },
            click: this.closeHelper
          }
        },
        coord: {
          x: 0,
          y: 0,
        },
        isOpenInfo: false,
        supportedFlows: null,
        swipeleftCounter: 0,
			  deliveryFlow: window.ordersStore.deliveryFlow
      }	
    },
    computed: {
      currentCheckin() {
        return this.$checkins.find(ch => ch.place_uid === this.placeUid)
      },
      historyMode() {
        return this.$route.query.history;
      },
      placeUid() {
        return this.rootPlaceUid
      },
      placeType() {
        return this.rootPlaceType
      },
      supportOrders() {
        return this.supportedFlows?.orders
      },
      currentCategory() {
        return this.categoriesWithDishes[this.current]
      },
      modifiersOpen() {
        return this.$bus.modifiersOpen
      }
    },
    watch: {
      'categoriesWithDishes.length'() {
        this.swiperOption.virtual.slides.splice(0, 100, ...this.categoriesWithDishes)
        
      }
    },
    created () {
      this.$bus.$on('openInfo', this.onOpenInfo)
    },
    mounted() {
      this.$set(this, 'supportedFlows', this.$PS.getSupportedFlowsByUid(this.placeUid))
      this.$options.swiper = this.$refs.categoriesSwiper.swiper
    },
    beforeDestroy() {
      this.$set(this, 'supportedFlows', null)
    },
    methods: {
      closeHelper() {
        if (this.helperHidden) return
        if (sessionStorage.getItem('isFirstOrder')) return
        
        sessionStorage.setItem('isFirstOrder', '0')
        const helperNode = document.getElementById('swipe-up-helper')
        if (helperNode) {
          helperNode.style.display = 'none'
        }
        this.helperHidden = true
      },
      onOpenInfo (value) {
        this.isOpenInfo = value;
      },
      slide() {
        this.$bus.$emit("SlideChanged")
      },
      afterSlide () {
        this.$bus.$emit('SwitchSlide');
      },
      mouseDown (evt) {
        this.coord.x = evt.clientX;
        this.coord.y = evt.clientY;
      },
      touchStart (evt) {
        this.coord.x = evt.touches[0].clientX;
        this.coord.y = evt.touches[0].clientY;
      },
      mouseUp (evt) {
        if (this.modifiersOpen) return
        if (isSwipeUp(this.coord.x - evt.clientX, this.coord.y - evt.clientY)) {
          this.$bus.$emit('SwipeUpDish');
        }
        this.coord.x = null;
        this.coord.y = null;
      },
      touchEnd (evt) {
        if (this.modifiersOpen) return
        const [thisX, thisY, endX, endY] = [this.coord.x, this.coord.y, evt.changedTouches[0].clientX, evt.changedTouches[0].clientY]
        const [xOffset, yOffset] = [thisX - endX, thisY - endY]

        if (isSwipeUp(xOffset, yOffset)) {
          this.$bus.$emit('SwipeUpDish');
        }
        // is swipeLeft 
        if (isSwipeUp(yOffset, xOffset)) {
          this.swipeleftCounter++
          if (this.swipeleftCounter > this.current) {
            this.$options.swiper.slideTo(0)
            this.swipeleftCounter = 0
          }
        // is swipeRight
        } else if (isSwipeUp(endY - thisY, endX - thisX)) {
          if (this.swipeleftCounter) {
            this.swipeleftCounter--
          }
        }
        this.coord.x = null;
        this.coord.y = null;
      },
      closeSlideMenu () {
        this.$bus.$emit("closeMenuMode");
      },
    }
  };
</script>

<style lang="scss">
.swiper {
			height: 100%;
			width: 100%;
		}
  .s-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 98;
    overflow: hidden;

    &__table-code {
      z-index: 100;
      position: absolute;
      top: 25px;
      right: 16px;
      width: 24px;
      height: 24px;
    }

    .back-icon {
      position: absolute;
      left: 20px;
      top: 20px;
      height: 30px;
      width: 30px;
      z-index: 100;
    }

    .swipe-up {
      height: 100%;
    }
    
    &__home {
      z-index: 100;
      position: absolute;
      top: 25px;
      left: 25px;
      width: 24px;
      height: 24px;
    }
  }
  #disable-swipe {
    display: none;
  }
</style>