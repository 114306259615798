(function() {
	/**
	 * Корректировка округления десятичных дробей.
	 *
	 * @param {String}  type  Тип корректировки.
	 * @param {Number}  value Число.
	 * @param {Integer} exp   Показатель степени (десятичный логарифм основания корректировки).
	 * @returns {Number} Скорректированное значение.
	 */
	function decimalAdjust(type, value, exp) {
	  // Если степень не определена, либо равна нулю...
	  if (typeof exp === 'undefined' || +exp === 0) {
		return Math[type](value);
	  }
	  value = +value;
	  exp = +exp;
	  // Если значение не является числом, либо степень не является целым числом...
	  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
		return NaN;
	  }
	  // Сдвиг разрядов
	  value = value.toString().split('e');
	  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
	  // Обратный сдвиг
	  value = value.toString().split('e');
	  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
	}

	// Десятичное округление к ближайшему
	if (!Math.round10) {
	  Math.round10 = function(value, exp) {
		return decimalAdjust('round', value, exp);
	  };
	}
	// Десятичное округление вниз
	if (!Math.floor10) {
	  Math.floor10 = function(value, exp) {
		return decimalAdjust('floor', value, exp);
	  };
	}
	// Десятичное округление вверх
	if (!Math.ceil10) {
	  Math.ceil10 = function(value, exp) {
		return decimalAdjust('ceil', value, exp);
	  };
	}
})()
export default {
	getUserGeo: () => {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				function ({ coords }) {
					resolve({
						lat: coords.latitude,
						lng: coords.longitude
					})
				},
				function (err) {
					reject(err)
				}, 
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0
				}
			)
		})
	},
	validateEmail(email) {
		return String(email)
			.toLowerCase()
			.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}
}