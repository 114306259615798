<template>
  <div
    :key="item.uid"
    class="card-list__item"
    :class="[{ '-disabled': isDisabled }, `theme-${theme}`]"
  >
    <div class="card-list__item-image">
      <img :src="cardImage" />
    </div>
    <div class="card-list__item-info">
      <div class="card-list__item-info__row">
        {{ item.payment_system }} .... {{ item.last4 }}
      </div>
      <div class="card-list__item-info__row -color-grey">
        {{ padstartDigit(item.exp_month) }}/{{ item.exp_year }}
      </div>
    </div>
    <div class="card-list__item-checkbox" @click="setMainCard">
      <template v-if="isMainCard && isDarkTheme">{{
        $t('MG-703-004')
      }}</template>
      <img v-if="isMainCard" :src="icons.CHECKBOX_CHECKED" key="checkedIcon" />
      <img v-else :src="icons.CHECKBOX" key="nocheckedIcon" />
    </div>
  </div>
</template>
<script>
import { setPreffered } from '@/init/api'
import { BANK_CARDS_IMAGES_URLS } from '@/consts'
import { padstartDigit } from '@/utils'
const DARK_ICONS = {
  CHECKBOX: '/img/checkbox-white.svg',
  CHECKBOX_CHECKED: '/img/checkbox-white-checked.svg',
}

const LIGHT_ICONS = {
  CHECKBOX: '/img/checkbox-grey.svg',
  CHECKBOX_CHECKED: '/img/checkbox-grey-checked.svg',
}
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isMainForce: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'dark',
    },
    disableIfNotMain: {
      type: Boolean,
      default: false,
    },
    useStoreToPrefer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPreferredByStore() {
      return (
        this.$store.state.payments.preferredPaymentSourceUid === this.item.uid
      )
    },
    isMainCard() {
      if (this.useStoreToPrefer) return this.isPreferredByStore
      return this.isMainForce || this.item.preferred
    },
    isDarkTheme() {
      return this.theme === 'dark'
    },
    icons() {
      return this.isDarkTheme ? DARK_ICONS : LIGHT_ICONS
    },
    cardImage() {
      const paymentSystem = this.item.payment_system
      return (
        BANK_CARDS_IMAGES_URLS[paymentSystem] || BANK_CARDS_IMAGES_URLS.DEFAULT
      )
    },
    isDisabled() {
      return this.disableIfNotMain && !this.isMainCard
    },
  },
  methods: {
    padstartDigit,
    async setMainCard() {
      if (this.useStoreToPrefer) return this.preferCardInStore()
      if (this.item.preferred) return
      this.$store.dispatch('places/showLoader', true)
      try {
        const resp = await setPreffered(this.item.uid, this.item.user_uid)
        if (resp?.error === 401) {
          await this.$bus.updateToken()
          this.setMainCard()
        }
      } catch (e) {
        console.log(e)
      }
      this.$store.dispatch('places/hideLoader')
    },
    preferCardInStore() {
      this.$store.commit(
        'payments/SET_PREFERRED_PAYMENT_SOURCE_UID',
        this.item.uid
      )
    },
  },
}
</script>

<style lang="scss">
.card-list__item {
  @include flex(row, flex-start, center);
  background: #1c1c1e;
  border-radius: 4px;
  height: 74px;
  margin-bottom: 8px;
  position: relative;
  width: 100%;

  &.theme-light {
    background: transparent !important;
    height: 64px;
    margin-bottom: 0;

    .card-list__item-info__row {
      color: $black;
    }

    .card-list__item-image {
      @include flex(row, flex-start, center);
      width: 85px;
    }

    .card-list__item-checkbox {
      right: 0;
    }
  }

  &.-disabled {
    opacity: 0.7;
  }

  &-image {
    @include flex(row, center, center);
    width: 98px;
  }

  &-info {
    @include font(15, 20, 400);
    color: $white;

    &__row {
      text-align: left;
    }
  }

  &-checkbox {
    @include flex(row, flex-start, center);
    @include font(12, 18, 400);
    @include pos-centered-v();
    color: #8e8e93;
    right: 16px;

    > img {
      margin-left: 6px;
    }
  }
}
</style>
