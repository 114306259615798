<template>
	<div class="us-view">
		<router-view />
		<img 
			src="/img/backWhite.png" 
			srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
			class="back-icon"
			@click="$router.go(-1)"
		/>
		<div class="us-view__header">
			{{ (tab === 'about') ? $t('aboutSite') : $t('settings') }}
		</div>
		<div class="us-view__header-backdrop"></div>
		<div class="us-view__actions-wrapper" v-if="!tab">
			<div class="us-view__actions-wrapper__main">
				<div class="us-view__actions">
					<template v-for="(action, i) in actions">
						<div 
							v-if="!action.link"
							class="us-view__actions-action" 
							:key="i"
							:class="{'-disabled': !action.enabled}"
						>
							<img :src="action.icon" class="us-view__actions-action__icon">
							<span class="us-view__actions-action__text">{{ action.text }}</span>
						</div>
						<router-link 
							v-else
							class="us-view__actions-action" 
							:key="`not-${i}`"
							:class="{'-disabled': !action.enabled}"
							tag="div"
							:to="action.link"
						>
							<img :src="action.icon" class="us-view__actions-action__icon">
							<span class="us-view__actions-action__text">{{ action.text }}</span>
						</router-link>
					</template>
				</div>
				<div class="us-view__actions-action -logout" @click="logout">
					<img src="/img/iconLogOut.svg" class="us-view__actions-action__icon">
					<span class="us-view__actions-action__text">{{ $t('settingsButtons.logout') }}</span>
				</div>
			</div>
			<div class="us-view__actions-wrapper__delete">
				<div class="us-view__actions-action -notFill" @click="deleteAccount">
					<img src="/img/iconDeleteAccountWhite.svg" class="us-view__actions-action__icon">
					<span class="us-view__actions-action__text -red">{{ $t('userSettings.delete.action2') }}</span>
				</div>
				<div class="us-view__actions-action__notice">{{ $t('userSettings.delete.alertSubtitle') }}</div>
			</div>
		</div>
		<div class="us-view__about" v-else-if="tab === 'about'">
			<div class="us-view__about-list">
				<a class="us-view__about-list__item" :href="termsOfUse" target="_blank">
					<div class="us-view__about-list__item-text">{{ $t('terms_of_use') }}</div>
					<img class="us-view__about-list__item-icon" src="/img/iconArrowDown.svg" />
				</a>
				<a class="us-view__about-list__item" :href="privacyPolicy" target="_blank">
					<div class="us-view__about-list__item-text">{{ $t('privacy_policy') }}</div>
					<img class="us-view__about-list__item-icon" src="/img/iconArrowDown.svg" />
				</a>
				<a class="us-view__about-list__item"  :href="dataPolicy" target="_blank">
					<div class="us-view__about-list__item-text">{{ $t('data_policy') }}</div>
					<img class="us-view__about-list__item-icon" src="/img/iconArrowDown.svg" />
				</a>
			</div>
			<img 
				class="us-view__about-logo"
				src="/img/logo.svg" 
			/>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'UserSettings',
		data() {
			return {
				logoutFlag: false,
				timer: null,
				paymentSources: window.ordersStore.paymentSources
			}
		},
		computed: {
			actions() {
				const paymentSourcesLength = this.paymentSources.length
				return [
					{
						enabled: false,
						icon: '/img/iconHistory.svg',
						text: this.$t('settingsButtons.orders'),
					},
					{
						enabled: !!paymentSourcesLength,
						icon: '/img/iconCard.svg',
						link: '/settings/cards-list',
						text: `${this.$t('settingsButtons.cards')} (${paymentSourcesLength})`,
					},
					{
						enabled: true,
						icon: '/img/iconAbout.svg',
						link: '/settings?tab=about',
						text: this.$t('settingsButtons.about'),
					},
					{
						enabled: false,
						icon: '/img/iconHelp.svg',
						text: this.$t('settingsButtons.help'),
					}
				]
			},
			tab() {
				return this.$route.query.tab 
			},
			termsOfUse() {
				return `https://mayber.com/terms-of-use/${this.$lang}/`
			},
			privacyPolicy() {
				return `https://mayber.com/privacy-policy/${this.$lang}/`
			},
			dataPolicy() {
				return `https://mayber.com/data-policy/${this.$lang}/`
			},
			user() {
				return this.$store.state.user?.user
			},
			isAnonimus() {
				return this.user?.isAnonymous
			},
			userUid() {
				return this.user?.uid
			}
		},
		watch: {
			isAnonimus(value) {
				if (value) {
					this.$router.go(-2)
					this.$store.dispatch("places/hideLoader")
				}
			}
		},
		beforeDestroy() {
			if (this.timer) {
				clearTimeout(this.timer)
			}
		},
		methods: {
			logoutHandler() {
				if (this.isAnonimus && this.logoutFlag) {
					this.$store.dispatch("places/hideLoader")
					this.logoutFlag = false
					this.$router.go(-2)
				}
			},
			selectedMenuItem(index) {
				if (!index) {
					this.$store.dispatch('places/showLoader', true)
					this.logoutFlag = true
					this.$DB.auth().signOut()
					if (this.timer) {
						clearTimeout(this.timer)
					}
					this.timer = setTimeout(this.logoutHandler, 10000)
				}
			},
			logout() {
				this.$bus.$emit('openContextMenu', {
					config: {
						type: 'modal', 
						style: 'pink',
						title: this.$t('logoutModal.title'),
						subtitle: this.$t('logoutModal.subtitle'),
						icon: '/img/warningIcon.svg'
					},
					actions: [
						{
							id: 'logout',
							text: this.$t('logoutModal.action1')
						},
						{
							id: 'cancel',
							text: this.$t('logoutModal.action2')
						}
					],
					resolve: this.selectedMenuItem,
					reject: () => ({})
				})
			},
			deleteAccount() {
				this.$bus.$emit('openContextMenu', {
					config: {
						type: 'modal', 
						icon: 'attention',
						title: this.$t('userSettings.delete.alertTitle'),
						subtitle: this.$t('userSettings.delete.alertSubtitle'),
						icon: '/img/warning.svg'
					},
					actions: [
						{
							id: 'cancel',
							text: this.$t('userSettings.delete.action1')
						},
						{
							id: 'logout',
							text: this.$t('userSettings.delete.action2'),
							type: 'danger'
						},
						{
							id: 'cancel',
							text: this.$t('userSettings.delete.action3')
						}
					],
					resolve: async (index) => {
						if (index === 1) {
							this.$store.dispatch('places/showLoader', true)
							await this.$HTTP('post', this.$API_URL('/users/delete'), {
								uid: this.userUid
							})
							this.selectedMenuItem()
						}
					},
					reject: () => ({})
				})
			}
		}
	}
</script>

<style lang="scss">
	.us-view {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		z-index: 105;
		background: $black;

		.back-icon {
			left: 20px;
    		top: 20px;
			position: absolute;
			height: 30px;
			width: 30px;
			z-index: 3;
		}

		&__about {
			margin-top: 80px;
			border-top: 1px solid $charcoalGreyTwo;
			border-bottom: 1px solid $charcoalGreyTwo;

			&-list {
				@include flex(column, flex-start, flex-start);
				padding-left: 32px;
				background: $blackTwo;

				&__item {
					display: block;
					width: 100%;
					height: 48px;
					position: relative;
					
					&:not(:last-child) {
						border-bottom: 1px solid $charcoalGreyTwo;
					}

					&-text,
					&-icon {
						@include pos-centered-v;
					}

					&-text {
						@include font(17, 24, normal);
						color: $white;
					}

					&-icon {
						right: 9px;
					}

				}
			}

			&-logo {
				@include pos-centered-h;
				width: 64px;
				height: 86px;
				bottom: 86px;
			}
		}

		&__list {
			height: 100%;
			width: 100%;
			// overflow-x: hidden;
			overflow-y: scroll;
		}

		&__header {
			@include pos-centered-h;
			top: 20px;
			@include font(17, 22, 600);
			color: $white;
			z-index: 2;
			text-transform: uppercase;

			&-backdrop {
				position: absolute;
				background: rgba(22, 22, 22, 0.75);
				top: 0;
				left: 0;
				right: 0;
				height: 60px;
				z-index: 1;
				backdrop-filter: blur(10px);
			}
		}

		&__actions {
			margin: 124px 16px 16px 16px;
			@include flex(column, flex-start, flex-start);
			width: calc(100% - 32px);

			&-wrapper {
				width: 100%;
				height: 100%;
				overflow: scroll;

				&__main {
					width: 100%;
					position: relative;
					height: 100%;

					@include flex(column, space-between, flex-start);
				}

				&__delete {
					margin: 0 16px 85px 16px;
					width: calc(100% - 32px);
					position: relative;
				}
			}

			&-action {
				width: 100%;
				height: 48px;
				background: $white;
				border-radius: 5px;
				position: relative;
				margin-bottom: 8px;

				&__icon {
					@include pos-centered-v;
					left: 16px;
				}

				&__text {
					@include pos-centered;
					@include font(17, 22, 500);
					text-transform: uppercase;
					letter-spacing: -0.41px;
					color: $black;

					&.-red {
						color: rgb(209, 41, 41);
						text-decoration: underline;
					}
				}

				&__notice {
					@include font(15, 18, normal);
					text-align: left;
					color: rgb(142, 142, 147);
				}

				&.-disabled {
					background: rgba(255, 255, 255, .4);
					pointer-events: none;
				}

				&.-logout {
					margin: 0 16px;
					width: calc(100% - 32px);
					bottom: 62px;
				}

				&.-notFill {
					background: transparent;
				}
			}
		}
		// .vue-recycle-scroller__item-view:first-child {
		// 	.post-item {
		// 		padding-top: 64px;
		// 	}
		// }
	}
</style>