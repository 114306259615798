<template>
  <div class="global-pay-layer" :class="{ '-open': isOpened }" @click="close()" v-show="isNeedToShow">
    <div
      class="info-button"
      :class="{ '-hidden': isOpened }"
      @click.stop="open()"
    >
      <div class="info-button__currency">{{ currentCurrencySymbol }}</div>
    </div>
    <div class="bottom-bar" :class="{ '-opened': isOpened }">
      <div class="bottom-bar__table-info">
        <span v-if="activeCheckin"><span class="-uppercase">{{ $t('order.table') }}</span> #{{ activeCheckin.object_number }}<template v-if="activeCheckin.guest_number"> |
          {{ $t('order.guest') }} #{{ activeCheckin.guest_number }}</template></span
        >
        <img src="/img/bellWhite.svg" @click.stop="callWaiter()"/>
      </div>
      <div class="bottom-bar__order-info" @click.stop>
        <div 
          v-for="(additionalPayment, i) in mixinAdditionalCustomPayments"
          :key="`additionalCustomPayments${i}`"
          class="bottom-bar__order-info__row"
        >
          <div class="bottom-bar__order-info__row-label">
            {{ additionalPayment.title }}:
          </div>
          <div class="bottom-bar__order-info__row-amount">
            {{ currentCurrencySymbol }} {{ additionalPayment.value }}
          </div>
        </div>
        <div 
          v-for="(additionalPayment, i) in additionalPayments"
          :key="i"
          class="bottom-bar__order-info__row"
        >
          <div class="bottom-bar__order-info__row-label">
            {{ additionalPayment.title }}:
          </div>
          <div class="bottom-bar__order-info__row-amount">
            {{ currentCurrencySymbol }} {{ additionalPayment.value }}
          </div>
        </div>
        <div class="bottom-bar__order-info__row">
          <div class="bottom-bar__order-info__row-label">
            {{ $t('order.totalToPay') }}:
          </div>
          <div class="bottom-bar__order-info__row-amount -bold">
            {{ currentCurrencySymbol }} {{ calculatedUnpaid.total }}
          </div>
        </div>
        <div class="bottom-bar__order-info__button" @click="goToPay()">{{ $t('T000002') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { CURRENCIES } from '@/consts'
import callWaiterMixin from '@/mixins/callWaiterMixin'
import additionalPaymentsMixin from '@/mixins/additionalPaymentsMixin'

export default {
  mixins: [ callWaiterMixin, additionalPaymentsMixin ],
  data() {
    return {
      isOpened: false,
      calculatedUnpaid: window.ordersStore.calculatedUnpaid,
    }
  },
  computed: {
    currentCurrencySymbol() {
      return CURRENCIES[this.currencyId]
    },
    activeCheckin() {
      return this.$checkins[0]
    },
    placeUid() {
      return this.activeCheckin?.place_uid
    },
    currencyId() {
      const placeUid = this.placeUid
      if (!placeUid) return 'RUB'

      return this.$PS.data.find(p => p.uid === placeUid)?.currency || 'RUB'
    }, 
    isNeedToShow() {
      return this.activeCheckin && this.activeCheckin.left_to_pay !== '0' && !~this.$route.path.indexOf('/orders') && this.calculatedUnpaid?.total
    },
    unpaidServiceFee() {
      return this.calculatedUnpaid?.additional_payments?.service_fee || 0;
    },
    unpaidDelivery() {
      return this.calculatedUnpaid?.additional_payments?.delivery || 0;
    },
    additionalPayments() {
      return [
        {computedKey: 'unpaidServiceFee', title: this.$t('order.serviceFee')},
        {computedKey: 'unpaidDelivery', title: this.$t('order.delivery')}
      ]
      .reduce((acc, { computedKey, title }) => {
        const computedValue = this[computedKey]
        if (computedValue) {
          acc.push({
            title,
            value: computedValue
          })
        }
        return acc
      }, [])
    },
    
  },
  methods: {
    open() {
      this.isOpened = true
    },
    close() {
      this.isOpened = false
    },
    goToPay() {
      this.$router.replace(`/orders?openFromUid=${this.placeUid}&showOverAll=true`)
      this.close()
    }
  },
}
</script>

<style lang="scss" scoped>
*div {
  box-sizing: border-box;
}
.global-pay-layer {
  position: fixed;
  width: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: red;
  z-index: 1050;
  @include flex(column, flex-end, center);

  &.-open {
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .info-button {
    @include pos-centered-v;
    @include flex(row, flex-start, center);
    right: 0;
    width: 50px;
    height: 40px;
    background: linear-gradient(
      92.16deg,
      rgba(28, 106, 125, 0.8) 11.68%,
      rgba(173, 36, 85, 0.8) 100.14%
    );
    border-radius: 20px 0px 0px 20px;
    transition: transform 0.2s ease;

    &.-hidden {
      transform: translate3d(50px, -50%, 0) !important;
    }

    &__currency {
      @include flex(row, center, center);
      width: 30px;
      height: 30px;
      background: $white;
      @include font(17, 20, 600);
      color: $black;
      border-radius: 20px;
      margin-left: 6px;
    }
  }

  .bottom-bar {
    width: 100%;
    background: #2b2b2f;
    border-radius: 10px 10px 0px 0px;
    transform: translate3d(0, 50vh, 0);
    transition: transform 0.2s ease;

    &.-opened {
      transform: translate3d(0, 0, 0);
    }
    &__table-info {
      @include flex(row, space-between, center);
      @include font(15, 18, 400);
      width: 100%;
      height: 48px;
      border-radius: 10px 10px 0px 0px;
      letter-spacing: -0.0923077px;
      color: #c1c1c1;
      padding: 16px 28px 16px 16px;
    }

    &__order-info {
      padding: 16px 16px 42px 16px;
      background: linear-gradient(
        135deg,
        rgba(23, 115, 134, 0.901852) 0%,
        rgba(190, 34, 89, 0.895979) 98.75%
      );
      backdrop-filter: blur(13.5px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 10px 10px 0px 0px;

      &__row,
      &__button {
        height: 48px;
        width: 100%;
        @include font(20, 20, 400);
        letter-spacing: -0.0923077px;
      }

      &__row {
        color: $white;
        @include flex(row, space-between, center);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        &-amount {
          &.-bold {
            font-weight: 600;
          }
        }
      }

      &__button {
        @include flex(row, center, center);
        @include font(20, 22, 500);
        background: #ffffff;
        backdrop-filter: blur(13.5914px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 5px;
        color: $black;
      }
    }
  }
}
</style>
