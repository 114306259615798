<template>
    <div class="hotel-payment">
        <div class="order-pay-control">
            <div class="order-pay-control__header">
                <span class="-uppercase"
                    >{{ $t("roomService.registration.roomNumber") }} #{{
                        checkin.object_number
                    }}</span
                >
                <img src="/img/bellWhite.svg" @click="$emit('callWaiter')" />
            </div>
            <div class="order-pay-control__body">
                <div class="bodybtn -pay" @click="$emit('payOrders')">
                    {{ $t("roomService.paymentType") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        checkin: {
            type: Object,
            required: true,
        },
        unpaidServiceFee: {
            type: [Number, String],
            default: 0,
        },
        currencySymbol: {
            type: String,
            default: "",
        },
        calculatedUnpaid: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.hotel-payment {
    .bodybtn.-pay {
        text-transform: uppercase;
    }
}
</style>
