<template>
	<div class="post-item" v-if="post">
		<div class="post-item__header">
			<div v-if="idState.author">
				<img
					@click="!idState.author.deleted ? $router.push('/user/'+idState.author.uid) : () => {}"
					class="post-item__header__avatar"
					:src="idState.author.profile_image_url || '/img/userNoImage@3x.png'"
				/>
				<div class="post-item__header-uname">
					{{ idState.author.full_name }}{{ age }}
				</div>
			</div>
			<div class="post-item__header-place" v-if="idState.place">
				{{ idState.place.name }}
			</div>
			<div class="post-item__header__actions" @click="openMenu">
				<img
					src="/img/iconPostOptions.svg"
				/>
			</div>
		</div>
		<img
			class="post-item__photo"
			:src="post.photo_uri"
			:key="post.photo_uri"
		/>
		<div class="post-item__dishname" v-if="idState.place_item">
			{{ idState.place_item.title }}
		</div>
		<div class="post-item__rate">
			<img
				v-for="(star, i) in dishRate"
				:key="i" 
				class="post-item__header-rate__star" 
				:src="`/img/${star}@3x.png`" 
				width="16px"
			>
		</div>
		<div class="post-item__likers">
			<img
				class="post-item__likers-heart"
				src="/img/combinedShape.svg"
				width="24px"
			/>
			<div class="post-item__likers-count">
				{{ post.likersCount }} {{ $t('postItem.likes') }}
			</div>
			<router-link class="post-item__likers-images" tag="div" :to="`/post/${post.uid}/likers`">
				<img 
					v-for="(liker, idx) in idState.likers"
					:src="liker.profile_image_url || '/img/userNoImage@3x.png'"
					:key="liker.uid || idx"
				/>
			</router-link>
		</div>
		<div class="post-item__text">
			{{ post.text | format }}
		</div>
		<div class="post-item__date">
			{{ date }}
		</div>
	</div>
	<div class="post-item -first" v-else></div>
</template>

<script>
	import { IdState } from 'vue-virtual-scroller'
	const months = [
		"jan",
		"feb",
		"mar",
		"apr",
		"may",
		"jun",
		"jul",
		"aug",
		"sep",
		"oct",
		"nov",
		"dec"
	]
	export default {
		mixins: [
			IdState({
				idProp: vm => vm.post.uid,
			}),
		],
		props: {
			post: {
				type: Object,
				required: true
			}
		},
		idState () {
			return {
				likers: [],
				author: null,
				place: null,
				place_item: null
			}
		},
		watch: {
			post: {
				immediate: true,
				handler: 'getData'
			}
		},
		computed: {
			actions() {
				return [
					{
						id: 'go_to_rest',
						text: this.$t('actions.go_to_rest')
					},
					{
						id: 'go_to_dish',
						text: this.$t('actions.go_to_dish')
					},
					{
						id: 'dish_not_like_in_menu',
						text: this.$t('actions.dish_not_like_in_menu'),
						type: 'danger'

					},
					{
						id: 'inappropriate_content',
						text: this.$t('actions.inappropriate_content'),
						type: 'danger'
					}
				]
			},
			age() {
				const user = this.idState.author;
				if (!user || user.is_birth_date_hidden || !user.birth_date || !user.birth_date.seconds) return ''
				return `, ${this.getAge(user.birth_date.seconds * 1000)}`
			},
			dishRate() {
				const rating = this.post?.stars || 0
				const out = []
				for (let i=1; i < 6; i++) {
					const range = rating - i
					if (range >= 0 || range > -0.5) {
						out.push('starDishRateGold')
					} else if (range > -1) {
						out.push('starDishRateGoldHalf')
					} else {
						out.push('starDishRateNone')
					}
				}
				return out
			},
			date() {
				const timestamp = this.post?.created_at?.seconds
				let date
				if (timestamp) {
					date = new Date(timestamp*1000)
				} else {
					date = new Date()
				}
				const day = date.getDate(),
					month = date.getMonth(),
					year = date.getFullYear()
				return `${day} ${this.$tc('months.'+months[month], 2)} ${year}`
			}
		},
		methods: {
			getData: async function(post) {
				const dish = (await this.$DB.db.collection('menu_items').doc(post.item_uid).get()).data();
				const place = (await this.$DB.db.collection('places').doc(post.place_uid).get()).data();
                const authorSnapshot = await this.$DB.db.collection('users').doc(post.user_uid).get();

				let author;

				if (authorSnapshot.exists) {
					author = authorSnapshot.data();
				} else {
					author = {
						deleted: true,
						full_name: 'Account deleted',
						profile_image_url: '/img/game-over.svg',
					};
				}

                let likers = [];

                if (post.likers.length) {
                    const previewLikers = post.likers.slice(0, 3);
                    const users = (await this.$DB.db.collection('users').where('uid', 'in', previewLikers).get()).docs.map(doc => doc.data());

                    likers = previewLikers.map(
						liker => (
							users.find(user => user.uid === liker) || {
								deleted: true,
								full_name: 'Account deleted',
								profile_image_url: '/img/game-over.svg',
							}
						)
					);
                }

				this.idState.author = author;
				this.idState.likers = likers;
				this.idState.place = place;
				this.idState.place_item = dish;
			},
			getAge(dateString) {
				var today = new Date()
				var birthDate = new Date(dateString)
				var age = today.getFullYear() - birthDate.getFullYear()
				var m = today.getMonth() - birthDate.getMonth()
				if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
					age--
				}
				return age
			},
			selectedMenuItem(index) {
				const actionId = this.actions[index]?.id,
					{place_uid} = this.post,
					{category_uid, uid} = this.idState.place_item
				switch(actionId) {
					case 'go_to_rest':
						// this.$bus.$emit('Home/closePlace')
						// this.$router.push(`/?place=${place_uid}&history=true`)
						// this.$nextTick(() => {
						// 	this.$bus.$emit('Home/loadMenu', place_uid)
						// })
						this.$bus.$emit('GlobalModal/dishesOpen')
						this.$bus.$emit('Home/drop', () => {
							this.$bus.$emit('Home/loadMenu', place_uid)
						})
						// 
					break;
					case 'go_to_dish':
						this.$bus.$emit('Home/closePlace')
						this.$router.push(`/?place=${place_uid}&category=${category_uid}&menu_item=${uid}&history=true`)
						this.$nextTick(() => {
							this.$bus.$emit('Home/loadMenu', place_uid)
						})
					break;
					default:
					break;
				}
				
				
			},
			openMenu() {
				this.$bus.$emit('openContextMenu', 
					{
						actions: this.actions,
						resolve: this.selectedMenuItem,
						reject: () => ({})
					}
				)
			}
		},
		filters: {
			format: function (value) {
				return value.trim().replace(/\n\s*\n/g, '\n\n');
			}
		}
	}
</script>

<style lang="scss">
	.post-item {
		position: relative;
		padding-bottom: 40px;

		&.-first {
			width: 100%;
			height: 65px;
		}
		// &:first-child {
		// 	padding-top: 64px;
		// }

		&__header {
			height: 44px;
			padding-left: 84px;
			width: calc(100% - 84px);
			@include flex(column, flex-start, flex-start);
			position: relative;
			
			&-uname {
				@include font(17, 20, 600);
				color: $white;
			}

			&-rate {
				@include flex(row, flex-start, center);
				height: 20px;
			}

			&__avatar {
				position: absolute;
				width: 64px;
				height: 48px;
				object-fit: cover;
				left: 10px;
				border-radius: 4px;
			}

			&-place {
				@include font(14, 16, 400);
				letter-spacing: 0.59px;
				color: $white;
			}

			&__actions {
				@include pos-centered-v;
				right: 10px;
				width: 24px;
				height: 24px;
			}
		}

		&__photo {
			width: 100%;
			height: 100vw;
			object-fit: cover;
		}

		&__dishname {
			position: absolute;
			top: 100vw;
			@include font(17, 20, 500);
			color: $white;
			letter-spacing: 0.72px;
			left: 15px;
		}
		
		&__rate {
			position: absolute;
			top: calc(100vw + 20px);
			@include font(17, 20, 500);
			color: $white;
			letter-spacing: 0.72px;
			left: 15px;
		}

		&__likers {
			height: 40px;
			@include flex(row, flex-start, center);
			position: relative;

			&-heart {
				margin-left: 15px;
			}
			
			&-count {
				margin-left: 8px;
				@include font(17, 18, 600);
				color: $white;
			}

			&-images {
				position: absolute;
				right: 10px;
				top: -10px;

				img {
					width: 43px;
					height: 43px;
					object-fit: cover;
					border-radius: 5px;
					margin-right: 2px;
				}
			}
		}

		&__text {
			margin: 0 15px;
			@include font(17, 18, normal);
			text-align: left;
			color: $white;
			white-space: pre-line;
		}

		&__date {
			width: 100%;
			margin-left: 15px;
			margin-top: 8px;
			@include font(13, 16, 500);
			text-align: left;
			color: $steel;
			letter-spacing: 0.55px;
		}
	}
</style>
