import axios from 'axios'
import { API_URL } from './utils'

/**
 * @param {number} expMonth
 * @param {number} expYear
 * @param {string} first4
 * @param {string} last4
 * @param {string} userUid
 * @returns paymentSource Object
 */
export async function createPaymentSource(
  expMonth,
  expYear,
  first4,
  last4,
  userUid
) {
  try {
    const {
      data: {
        error_code: errorCode,
        payload: { payment_source: paymentSource },
      },
    } = await axios.post(API_URL('/paymentSources/create'), {
      exp_month: expMonth,
      exp_year: expYear,
      first4,
      last4,
      user_uid: userUid,
    })
    if (errorCode) {
      throw new Error(errorCode)
    }
    return paymentSource
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: 401 }
    }
    throw Error(error)
  }
}

export async function setPreffered(uid, userUid) {
  try {
    const {
      data: { error_code: errorCode, payload },
    } = await axios.post(API_URL('/paymentSources/setPreferred'), {
      payment_source_uid: uid,
      user_uid: userUid,
    })
    if (errorCode) {
      throw new Error(errorCode)
    }
    return payload
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: 401 }
    }
    throw new Error(error)
  }
}

/**
 *
 * @param { string } uid
 * @param { string } userUid
 * @returns { string }
 */
export async function deletePaymentSource(uid, userUid) {
  try {
    const {
      data: { error_code: errorCode, payload },
    } = await axios.post(API_URL('/paymentSources/delete'), {
      uid,
      user_uid: userUid,
    })
    if (errorCode) {
      throw new Error(errorCode)
    }
    return payload
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: 401 }
    }
    throw new Error(error)
  }
}
